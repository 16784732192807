import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PendingChangesGuard, PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CustomerDetailDialogComponent } from './customer-detail-dialog/customer-detail-dialog.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { ServiceAddressDialogComponent } from './service-address-dialog/service-address-dialog.component';
import { ServiceAddressOverviewComponent } from './service-address-overview/service-address-overview.component';
import { RateDialogComponent } from './rate-dialog/rate-dialog.component';
import { StatementDialogComponent } from './statement-dialog/statement-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'customers',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'search', pathMatch: 'full' },
                    {
                        path: 'search',
                        component: CustomerSearchComponent,
                        data: { title: 'Customer Search' }
                    },
                    {
                        path: ':id',
                        component: CustomerDetailComponent,
                        data: { title: 'Customer' },
                        canDeactivate: [PendingChangesGuard]
                    }
                ]
            }
        ])
    ],
    declarations: [
        CustomerSearchComponent,
        CustomerDetailComponent,
        CustomerDetailDialogComponent,
        ServiceAddressOverviewComponent,
        ServiceAddressDialogComponent,
        RateDialogComponent,
        StatementDialogComponent
    ]
})
export class CustomersModule {}
