<div class="header-label" *ngIf="payment">{{ 'Receive Payment' }}</div>
<div class="flex-fill d-flex flex-column" *ngIf="payment">
    <div class="dx-card responsive-paddings">
        <div class="row">
            <div class="col-4">
                <button (click)="save()" class="btn btn-success mr-2" *ngIf="hasChanges()">Save</button>
                <button (click)="cancel()" class="btn btn-danger" *ngIf="hasChanges()">Cancel</button>
                <button (click)="close()" class="btn btn-primary" *ngIf="!hasChanges()">Close</button>
                <button (click)="saveAndSend()" class="btn btn-primary ml-5 mr-2">Save & Send</button>
                <button *ngIf="!hasChanges() && readonly" (click)="printPaymentReceipt()" class="btn btn-primary ml-2">Print Payment</button>
            </div>
            <div class="col-5 d-flex align-items-center ml-auto">
                <div class="d-flex ml-auto mr-1 mt-2">
                    <div class="d-flex align-items-center mr-5">
                        <label class="mt-2 mr-2">Payment Date</label>
                        <dx-date-box [(value)]="payment.paymentDate"></dx-date-box>
                    </div>
                    <!-- <div class="d-flex align-items-center">
                        <label class="mr-2">Status</label>
                        <h4 class="mr-2">
                            <strong>{{ payment.status?.name }}</strong>
                        </h4>
                        <button (click)="updateStatus()" class="btn btn-primary mr-2">Update Status</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row" class="p-2">
            <dx-form [formData]="payment" colCount="6" validationGroup="invoiceValidationGroup" [disabled]="readonly">
                <dxi-item itemType="group" caption="Customer">
                    <dxi-item *ngIf="customer">
                        <div *dxTemplate>
                            <h3 class="mt-0 mb-1">{{ customer.displayName }}</h3>
                            <div>{{ customer.billAddress1 }}</div>
                            <div>{{ customer.billAddress2 }}</div>
                            <div>
                                {{ customer.billCity ? customer.billCity + ',' : '' }}
                                {{ customer.billState }}
                                {{ customer.billZip }}
                            </div>
                            <!-- <div *ngIf="useHaulingRate">Distance: {{ payment?.rental?.serviceAddress?.distance }}</div> -->
                            <!-- <div *ngIf="useHaulingRate">Range: {{ payment?.rental?.serviceAddress?.range }}</div> -->
                        </div>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Payment Info">
                    <dxi-item
                        dataField="paymentMethodId"
                        [label]="{ text: 'Method' }"
                        editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: paymentMethods, valueExpr: 'id', displayExpr: 'name' }"
                    >
                    </dxi-item>

                    <dxi-item dataField="referenceNumber"></dxi-item>
                </dxi-item>

                <dxi-item itemType="group" caption="Totals">
                    <dxi-item [label]="{ text: 'Amount Received' }">
                        <div *dxTemplate>
                            <dx-number-box
                                [(value)]="amountReceived"
                                [step]="0"
                                [readOnly]="true"
                                [format]="{ type: 'currency', precision: 2 }"
                                (onValueChanged)="creditCheck($event)"
                            ></dx-number-box>
                        </div>
                    </dxi-item>
                    <dxi-item [label]="{ text: 'Balance' }">
                        <div *dxTemplate>
                            <dx-number-box
                                [(value)]="balance"
                                [step]="0"
                                [readOnly]="true"
                                [format]="{ type: 'currency', precision: 2 }"
                            ></dx-number-box>
                        </div>
                    </dxi-item>
                    <dxi-item [label]="{ text: 'Credit' }">
                        <div *dxTemplate>
                            <dx-number-box
                                [(value)]="creditAmount"
                                [step]="0"
                                [readOnly]="true"
                                [format]="{ type: 'currency', precision: 2 }"
                            ></dx-number-box>
                        </div>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="QuickBooks Info">
                    <dxi-item dataField="quickBooksId" [label]="{ text: 'QuickBooks Id' }" [editorOptions]="{ readOnly: true }"> </dxi-item>
                    <dxi-item
                        dataField="quickBooksExport"
                        [label]="{ text: 'QuickBooks Last Exported' }"
                        [editorOptions]="{ readOnly: true }"
                    >
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <div class="flex-fill dx-card responsive-paddings">
        <dx-data-grid
            #invoiceLineGrid
            keyExpr="id"
            class="h-100"
            [dataSource]="paymentLines"
            [showBorders]="true"
            [showRowLines]="true"
            (onRowPrepared)="rowPrepare($event)"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="rowDoubleClick($event)"
            (onRowUpdated)="rowUpdated($event)"
        >
            <dxi-column
                dataField="lineNumber"
                caption="No."
                [visible]="true"
                width="80"
                [sortIndex]="1"
                [sortOrder]="'asc'"
                [allowEditing]="false"
            ></dxi-column>
            <dxi-column dataField="description" width="325" [allowEditing]="false"></dxi-column>
            <dxi-column dataField="invoiceDueDate" caption="Due Date" width="100" [allowEditing]="false"></dxi-column>
            <dxi-column
                dataField="invoiceAmount"
                [format]="{ type: 'currency', precision: 2 }"
                width="125"
                [allowEditing]="false"
            ></dxi-column>
            <dxi-column
                dataField="startingBalance"
                [format]="{ type: 'currency', precision: 2 }"
                [visible]="key === 'new'"
                width="100"
                [allowEditing]="false"
            ></dxi-column>
            <dxi-column
                dataField="remainingBalance"
                [format]="{ type: 'currency', precision: 2 }"
                width="100"
                [allowEditing]="false"
            ></dxi-column>
            <dxi-column
                dataField="amount"
                [format]="{ type: 'currency', precision: 2 }"
                width="100"
                [allowEditing]="!readonly"
                [editorOptions]="{ step: 0 }"
                ></dxi-column>
            <dxi-column
                dataField="relatedPaymentIds"
                caption="Related Payments"
                [allowEditing]="false"
                width="150"
                cellTemplate="relatedPaymentTemplate"
            ></dxi-column>
            <dxi-column dataField="notes" [allowEditing]="true"></dxi-column>

            <div *dxTemplate="let cell of 'relatedPaymentTemplate'">
                <span *ngFor="let paymentId of cell.data.relatedPaymentIds; index as i" class="pr-1">
                    <a *ngIf="cell" [routerLink]="[]" (click)="openPayment(paymentId)"
                        >#{{ paymentId + (i + 1 < cell.data.relatedPaymentIds.length ? ', ' : '') }}</a
                    >
                </span>
            </div>

            <dxo-editing [mode]="'cell'" [allowUpdating]="'always'"></dxo-editing>
            <dxo-selection [mode]="'multiple'" [showCheckBoxesMode]="true"></dxo-selection>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-lines-grid"></dxo-state-storing>

            <div *dxTemplate="let data of 'toolbarButtons'">
                <!-- <button
                    class="btn btn-danger mr-2 mb-2"
                    (click)="removeInvoiceLine(invoiceLineGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="invoiceLineGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Clear Payments
                </button> -->
            </div>
        </dx-data-grid>
    </div>
    <div class="dx-card responsive-paddings">
        <dx-form [formData]="payment" colCount="2">
            <dxi-item dataField="notes" editorType="dxTextArea" [label]="{ text: 'Payment Memos' }" [editorOptions]="{ height: 100 }">
            </dxi-item>
        </dx-form>
    </div>
</div>
