import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, SessionInformationService } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { CustomerType } from 'src/app/core/model/customer-type';
import { SalesPerson } from 'src/app/core/model/sales-person';

@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
    customers: Customer[];
    filter: any = {};
    customerTypes: CustomerType[];
    billingTypeOptions = Customer.BillingTypeOptions;
    isCustomerAdmin = false;
    salesPersons: SalesPerson[];

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private sessionInfo: SessionInformationService,
        private appInfo: AppInformationService,
        private auth: AuthService
    ) {}

    async ngOnInit() {
        this.appInfo.pleaseWaitShow();
        this.salesPersons = await this.uow.salesPersons.whereActive('displayOrder, name');
        this.customerTypes = this.uow.lookups.customerTypes as CustomerType[];
        this.filter = this.sessionInfo.getItem('customer-search-filter');
        if (this.filter) await this.search();
        else this.clear();
        if (!this.customers) await this.search(500);
        this.isCustomerAdmin = this.auth.hasRole('Customer:Admin');
        this.appInfo.pleaseWaitHide();
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after',
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    rowDoubleClick(e) {
        this.open(e.data);
    }

    async search(take?: number) {
        this.appInfo.pleaseWaitShow()
        this.customers = await this.uow.customers.search(this.filter, 'type', take);
        this.sessionInfo.setItem('customer-search-filter', this.filter);
        this.appInfo.pleaseWaitHide()
    }

    clear() {
        this.filter = {};
    }

    open(customer?: Customer) {
        if (!this.isCustomerAdmin) return;
        this.router.navigate([`/customers/${customer ? customer.id : 'new'}`]);
    }

    async delete(customer: Customer) {
        const selectedRows = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        if (!(await confirm('Are you sure you want to delete the selected customer?', this.appInfo.appTitle))) return;

        selectedRows.forEach(row => {
            row.deleted = moment(new Date()).toDate();
        });
        await this.uow.commit();
        await this.search();
    }
}
