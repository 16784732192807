<dx-popup
    [title]="dialogHeader"
    [width]="dialogWidth"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ icon: 'save', text: 'Save', type: 'success', onClick: onSave }">
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ icon: 'save', text: 'Save And New', type: 'success', visible: saveAndNewVisible, onClick: onSaveAndNew }"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ icon: 'close', text: 'Cancel', type: 'default', onClick: cancel }">
    </dxi-toolbar-item>
    <div class="modal-body" (keyup.esc)="cancel()" (keyup.enter)="enterPressed()">
        <div class="mb-2" *ngIf="dialogPrompt">{{ dialogPrompt }}</div>
        <div *ngIf="mode === 'text'">
            <dx-text-box #inputComponent [(value)]="dialogValue" valueChangeEvent="input"></dx-text-box>
        </div>
        <div *ngIf="mode === 'password'">
            <dx-text-box
                #inputComponent
                [(value)]="dialogValue"
                [mode]="'password'"
                valueChangeEvent="input"
                (onInput)="onInput($event)"
            ></dx-text-box>
        </div>
        <div *ngIf="mode === 'textarea'">
            <dx-text-area #inputComponent [(value)]="dialogValue" [height]="300"></dx-text-area>
        </div>
        <div *ngIf="mode === 'select'">
            <dx-select-box #inputComponent [(value)]="dialogValue" [items]="dialogValueArray"></dx-select-box>
        </div>
        <div *ngIf="mode === 'selectWithId'">
            <dx-select-box
                #inputComponent
                [(value)]="dialogValue"
                [items]="dialogValueArray"
                [valueExpr]="'id'"
                [displayExpr]="'name'"
            ></dx-select-box>
        </div>
        <div *ngIf="mode === 'date'">
            <dx-date-box #inputComponent [(value)]="dialogValue"></dx-date-box>
        </div>
        <div class="mt-3" *ngIf="!!dialogMessage">
            {{ dialogMessage }}
        </div>
    </div>
</dx-popup>
