<!-- <div class="header-label">Invoices</div> -->
<div class="flex-fill d-flex dx-card">
    <div *ngIf="!showAsComponent" class="d-flex flex-fill flex-column col-sm-6">
        <div class="d-flex flex-column flex-fill p-2">
            <h4>Residential / Commercial Delivery</h4>
            <dx-data-grid
                #residentialJobsGrid
                [keyExpr]="'id'"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="residentialJobs"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onResidentialRowDoubleClick($event)"
                [columnAutoWidth]="true"
            >
                <dxi-column dataField="jobIdDisplay" caption="Job" width="75"></dxi-column>
                <dxi-column dataField="rentalIdDisplay" caption="Rental" width="75" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="customer" caption="Customer" width="150" cellTemplate="customerLinkTemplate"></dxi-column>
                <dxi-column dataField="completed" dataType="date" width="100"></dxi-column>
                <dxi-column dataField="dumpster" caption="Dumpster" width="75"></dxi-column>
                <dxi-column dataField="revenue" caption="Est. Rev" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="reason" caption="Type" width="125"></dxi-column>
                <dxi-column dataField="serviceAddress.displayAddress" caption="Site"></dxi-column>

                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>
                </div>

                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>
                </div>

                <dxo-selection mode="single"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-residential-rentals-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-success mr-1 mb-2"
                        (click)="createInvoice(residentialJobsGrid.instance.getSelectedRowsData()[0].rentalId)"
                        [disabled]="residentialJobsGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Create Invoice
                    </button>
                    <button
                        class="btn btn-sm btn-danger mr-1 mb-2"
                        (click)="createRevenueLines(residentialJobsGrid.instance.getSelectedRowsData()[0].rentalId)"
                        [disabled]="residentialJobsGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Create Revenue Lines
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="residentialJobs" class="mr-3">
                        <strong>{{ residentialJobsGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>

        <div class="d-flex flex-column flex-fill p-2">
            <h4>Commercial</h4>
            <div class="d-flex flex-column flex-fill p-1">
                <dx-data-grid
                    #commercialJobsGrid
                    [keyExpr]="'id'"
                    class="flex-fill d-flex flex-column"
                    height="100px"
                    [dataSource]="rentals"
                    [showBorders]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)"
                    (onRowDblClick)="onRentalRowDoubleClick($event)"
                    (onRowPrepared)="onRowPrepared($event)"
                    [columnAutoWidth]="false"
                >
                    <!-- <dxi-column dataField="id" caption="Id" width="75"></dxi-column> -->
                    <dxi-column dataField="rentalId" caption="Rental" width="75" cellTemplate="rentalLinkTemplate"></dxi-column>
                    <dxi-column dataField="customer" caption="Customer" width="200" cellTemplate="customerLinkTemplate"></dxi-column>
                    <dxi-column dataField="dumpster" caption="Dumpster" width="75"></dxi-column>
                    <dxi-column dataField="jobCount" caption="# Jobs" width="75"></dxi-column>
                    <dxi-column dataField="dumpstersOnSite" caption="OnSite" width="70"></dxi-column>
                    <dxi-column dataField="completed" width="90" dataType="date"></dxi-column>
                    <dxi-column
                        dataField="revenue"
                        caption="Est. Rev"
                        [format]="{ type: 'currency', precision: 2 }"
                        width="100"
                    ></dxi-column>
                    <dxi-column dataField="serviceAddress.displayAddress" caption="Site"></dxi-column>

                    <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                        <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/rentals/' + cell.data.id" [innerHtml]="cell.text"></a>
                        <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>
                    </div>

                    <div *dxTemplate="let cell of 'customerLinkTemplate'">
                        <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                        <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>
                    </div>

                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-rentals-grid"></dxo-state-storing>

                    <div *dxTemplate="let data of 'toolbarButtons'">
                        <button
                            class="btn btn-sm btn-success mr-1 mb-2"
                            (click)="createInvoice(commercialJobsGrid.instance.getSelectedRowsData()[0].id)"
                            [disabled]="commercialJobsGrid.instance.getSelectedRowsData().length !== 1"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Create Invoice
                        </button>
                        <button
                            class="btn btn-sm btn-danger mr-1 mb-2"
                            (click)="createRevenueLines(commercialJobsGrid.instance.getSelectedRowsData()[0].id)"
                            [disabled]="commercialJobsGrid.instance.getSelectedRowsData().length !== 1"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Create Revenue Lines
                        </button>
                    </div>
                    <div *dxTemplate="let data of 'toolbarText'">
                        <span *ngIf="rentals" class="mr-3">
                            <strong>{{ commercialJobsGrid.instance.totalCount() + ' items' }}</strong>
                        </span>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>

    <!-- Invoice Overview List -->
    <div [class]="(showAsComponent ? '' : '  col-sm-6 ') + ' d-flex flex-column flex-fill p-2'">
        <h4 *ngIf="!showAsComponent">Invoices</h4>
        <div class="d-flex flex-column flex-fill p-1">
            <dx-data-grid
                #overviewGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="650px"
                [dataSource]="invoices"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onRowDoubleClick($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column width="55" cellTemplate="iconTemplate"></dxi-column>
                <dxi-column dataField="id" caption="Id" width="75"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="75"></dxi-column>
                <dxi-column dataField="rentalIdDisplay" caption="Rental" width="75" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="status.name" caption="Status" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="invoiceDate" caption="Date" width="100"></dxi-column>
                <dxi-column
                    dataField="customer.displayName"
                    caption="Customer"
                    width="175"
                    cellTemplate="customerLinkTemplate"
                ></dxi-column>
                <dxi-column dataField="sent" format="shortDate" caption="Sent" width="150"></dxi-column>
                <!-- <dxi-column dataField="customer.email" caption="Email" width="175"></dxi-column> -->
                <dxi-column dataField="displayServiceAddress" caption="Service Address"></dxi-column>

                <div *dxTemplate="let cell of 'iconTemplate'" class="d-flex">
                    <i *ngIf="cell.data.showCreditCard" class="fa fa-cc-visa" aria-hidden="true"></i>
                    <i *ngIf="cell.data.showEmail" class="fa fa-envelope" aria-hidden="true"></i>
                    <i *ngIf="cell.data.showHome" class="fa fa-home" aria-hidden="true"></i>
                    <i *ngIf="cell.data.readyToExport" class="fa fa-arrow-right green-readyToExport ml-1" aria-hidden="true"></i>
                    <i *ngIf="cell.data.readyToExport && cell.data.quickBooksLastExport" class="fa fa-check-square-o green-exported ml-1" aria-hidden="true"></i>
                </div>

                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>

                </div>


                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell && isInvoiceAdmin" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isInvoiceAdmin" [innerHtml]="cell.text"></div>
                </div>

                <dxo-selection [mode]="'multiple'"></dxo-selection>
                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                <dxo-state-storing
                    [enabled]="true"
                    type="localStorage"
                    storageKey="{{ customerId ? 'customer-' : '' }}invoice-overview-grid"
                ></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <span *ngIf="!showAsComponent">
                        <button
                            class="btn btn-sm btn-primary mr-1 mb-2"
                            (click)="open(overviewGrid.instance.getSelectedRowsData()[0])"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Open
                        </button>
                        <button class="btn btn-sm btn-success mr-2 mb-2" (click)="open()" [hidden]="!isInvoiceAdmin">Add</button>
                        <button
                            class="btn btn-sm btn-danger mr-2 mb-2"
                            (click)="delete(overviewGrid.instance.getSelectedRowsData()[0])"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Delete
                        </button>
                        <button
                            class="btn btn-sm btn-success mr-1 mb-2"
                            (click)="sendForReview(overviewGrid.instance.getSelectedRowsData())"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Send For Review
                        </button>
                        <button
                            class="btn btn-sm btn-success mr-1 mb-2"
                            (click)="receivePayment()"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Receive Payment
                        </button>

                        <dx-drop-down-button
                            *ngIf="!false"
                            class="print-email-dx-button"
                            text="Print/Email"
                            [useSelectMode]="false"
                            [items]="['Print', 'Email', 'Send To']"
                            [dropDownOptions]="{ width: 150 }"
                            [disabled]="false"
                            (onItemClick)="printEmailButtonClick($event)"
                            [hidden]="!isInvoiceAdmin"
                        ></dx-drop-down-button>
                        <button
                            class="btn btn-sm btn-success mr-1 mb-2"
                            (click)="readyForExport()"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                            [hidden]="!isInvoiceAdmin"
                        >
                            Ready To Export
                        </button>
                        <!-- <button class="btn btn-sm btn-warning mb-2" (click)="toggleShowAll()" [hidden]="!isInvoiceAdmin">
                            {{ showAll ? 'Show Compact' : 'Show All' }}
                        </button> -->
                    </span>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="invoices" class="mr-3">
                        <strong>{{ overviewGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
