import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionInformationService {
    constructor() {}

    getItem(key: string, useLocalStorage: boolean = false) {
        let storage = sessionStorage;
        if (useLocalStorage) storage = localStorage;

        let value;
        try {
            value = JSON.parse(storage.getItem(key));
        } catch (err) {}

        return value;
    }

    setItem(key: string, value: any, useLocalStorage: boolean = false) {
        let storage = sessionStorage;
        if (useLocalStorage) storage = localStorage;

        if (value) {
            if (value instanceof Array) {
                value = value.map(entity => {
                    delete entity.entityAspect;
                    return entity;
                });
            }
            storage.setItem(key, JSON.stringify(value));
        } else storage.removeItem(key);
    }

    removeItem(key: string, useLocalStorage: boolean = false) {
        let storage = useLocalStorage ? localStorage : sessionStorage;
        storage.removeItem(key);
    }

    retrieve(key: string, retrieveMethod: () => Promise<any>, useLocalStorage: boolean = false) {
        let value = this.getItem(key, useLocalStorage);
        if (value) {
            return value;
        } else {
            return retrieveMethod().then(data => {
                this.setItem(key, data, useLocalStorage);
                return data;
            });
        }
    }
}
