import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { AppInformationService, AuthService, CdrUnitOfWork, SessionInformationService } from 'src/app/core/core.module';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceLine } from 'src/app/core/model/invoice-line';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-invoice-history',
    templateUrl: './invoice-history.component.html',
    styleUrls: ['./invoice-history.component.scss']
})
export class InvoiceHistoryComponent implements OnInit {
    filter: { statusIds: number[]; includeDeleted: boolean; createdFrom: Date, createdTo: Date };
    invoices: Invoice[];
    invoiceStatuses: InvoiceStatus[];
    selectedInvoice: Invoice;
    invoiceDetails: InvoiceLine[];
    isInvoiceAdmin = false;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        public auth: AuthService,
        private sessionInfo: SessionInformationService
    ) {}

    async ngOnInit() {
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        this.filter = this.sessionInfo.getItem('invoice-history-filter') ?? {
            statusIds: [InvoiceStatus.Statuses.Emailed, InvoiceStatus.Statuses.Printed],
            includeDeleted: false
        };
        this.isInvoiceAdmin = this.auth.hasRole('Invoice:Admin');
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        const invoices = await this.uow.getActiveInvoices({
            statusIds: this.filter.statusIds,
            includeDeleted: this.filter.includeDeleted,
            createdFrom: this.filter.createdFrom,
            createdTo: this.filter.createdTo,
            expand: ['customer', 'paymentLines']
        });
        invoices.forEach(invoice => {
            invoice.payments = _(invoice.paymentLines).sumBy('amount');
        });
        this.invoices = invoices;
        this.sessionInfo.setItem('invoice-history-filter', this.filter);
        this.appInfo.pleaseWaitHide();
    }

    async selectionChange(e) {
        // this.selectedInvoice = e.selectedRowsData[0];
        // if (!this.selectedInvoice) this.invoiceDetails = null;
        // else await this.getInvoiceDetails();
    }
    async getInvoiceDetails() {
        // this.invoiceDetails = await this.uow.invoiceLines.where('invoiceId', '==', this.selectedInvoice.id);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },

            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async readyForExport() {
        const selectedRows = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        if (selectedRows.some(invoice => invoice.quickBooksId)) {
            const success = await confirm(
                'One or more of the selected invoices have already been exported. Do you still want to mark them as ready to export?',
                this.appInfo.company
            );
            if (!success) return;
        }

        this.appInfo.pleaseWaitShow();
        const today = new Date();
        selectedRows.forEach(invoice => {
            if (!invoice.readyToExport) invoice.readyToExport = today;
            invoice.quickBooksId = null;
        });
        await this.uow.commit();
        this.appInfo.pleaseWaitHide();
    }
}
