import { Injectable } from '@angular/core';

import { EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
import {
    CreditCard,
    Customer,
    CustomerRate,
    Dumpster,
    DumpsterCategory,
    DumpsterFeeType,
    DumpsterFeeTypeDumpsterLink,
    DumpsterInventory,
    DumpsterLocation,
    EmailMessage,
    FuelRatePercentage,
    Invoice,
    InvoiceLine,
    InvoiceStatus,
    JobDumpsterFee,
    Maintenance,
    Note,
    Payment,
    PaymentCredit,
    PaymentLine,
    Photo,
    PrintQueue,
    PrintQueueInvoiceLink,
    QuickBooksProduct,
    QuickBooksTaskResponse,
    RentalBillingLine,
    SalesPerson,
    ServiceAddress,
    Setting,
    Statement,
    StorageDumpSite,
    User,
    UserRole
} from '../model/entity-model';
import { CustomerRepository } from './customer.repository';
import { DispatchNoteRepository } from './dispatch-note.repository';
import { Entities } from './entities';
import { EntityManagerProvider } from './entity-manager-provider';
import { JobRepository } from './job.repository';
import { RentalRepository } from './rental.repository';
import { Repository } from './repository';
import { UnitOfWork } from './unit-of-work';
import * as moment from 'moment';
import { PhotoService } from '../photo.service';

@Injectable({
    providedIn: 'root'
})
export class CdrUnitOfWork extends UnitOfWork {
    creditCards: Repository<CreditCard>;
    customers: CustomerRepository;
    customerRates: Repository<CustomerRate>;
    emailMessages: Repository<EmailMessage>;
    fuelRatePercentages: Repository<FuelRatePercentage>;
    rentals: RentalRepository;
    salesPersons: Repository<SalesPerson>;
    settings: Repository<Setting>;
    serviceAddresses: Repository<ServiceAddress>;
    statements: Repository<Statement>;
    users: Repository<User>;
    userRoles: Repository<UserRole>;
    maintenances: Repository<Maintenance>;
    notes: Repository<Note>;
    invoices: Repository<Invoice>;
    invoiceLines: Repository<InvoiceLine>;
    payments: Repository<Payment>;
    paymentCredits: Repository<PaymentCredit>;
    paymentLines: Repository<PaymentLine>;
    photos: Repository<Photo>;
    printQueues: Repository<PrintQueue>;
    printQueueInvoiceLinks: Repository<PrintQueueInvoiceLink>;

    rentalBillingLines: Repository<RentalBillingLine>;


    jobs: JobRepository;
    storageDumpSites: Repository<StorageDumpSite>;
    dispatchNotes: DispatchNoteRepository;
    dumpsterCategories: Repository<DumpsterCategory>;
    dumpsterLocations: Repository<DumpsterLocation>;
    dumpsterFeeTypes: Repository<DumpsterFeeType>;
    dumpsterFeeTypeDumpsterLinks: Repository<DumpsterFeeTypeDumpsterLink>;
    dumpsters: Repository<Dumpster>;
    dumpsterInventories: Repository<DumpsterInventory>;
    jobDumpsterFees: Repository<JobDumpsterFee>;

    lookups: any;

    constructor(emProvider: EntityManagerProvider) {
        super(emProvider);

        this.lookups = Entities.lookups;
        emProvider.prepare().then(lookups => {
            this.lookups = lookups;
        });

        this.creditCards = this.createRepository<CreditCard>('CreditCard', 'creditCards');
        this.customers = new CustomerRepository(this.manager);
        this.customerRates = this.createRepository<CustomerRate>('CustomerRate', 'customerRates');
        this.emailMessages = this.createRepository<EmailMessage>('EmailMessage', 'emailMessages');
        this.fuelRatePercentages = this.createRepository<FuelRatePercentage>('FuelRatePercentage', 'fuelRatePercentages');
        this.salesPersons = this.createRepository<SalesPerson>('SalesPerson', 'salesPersons');
        this.settings = this.createRepository<Setting>('Setting', 'settings');
        this.serviceAddresses = this.createRepository<ServiceAddress>('ServiceAddress', 'serviceAddresses');
        this.statements = this.createRepository<Statement>('Statement', 'statements');
        this.users = this.createRepository<User>('User', 'users');
        this.userRoles = this.createRepository<UserRole>('UserRole', 'userRoles');
        this.rentals = new RentalRepository(this.manager);
        this.rentalBillingLines = this.createRepository<RentalBillingLine>('RentalBillingLine', 'rentalBillingLines');
        this.maintenances = this.createRepository<Maintenance>('Maintenance', 'maintenances');
        this.notes = this.createRepository<Note>('Note', 'notes');
        this.invoices = this.createRepository<Invoice>('Invoice', 'invoices');
        this.invoiceLines = this.createRepository<InvoiceLine>('InvoiceLine', 'invoiceLines');
        this.payments = this.createRepository<Payment>('Payment', 'payments');
        this.paymentCredits = this.createRepository<PaymentCredit>('PaymentCredit', 'paymentCredits');
        this.paymentLines = this.createRepository<PaymentLine>('PaymentLine', 'paymentLines');
        this.photos = this.createRepository<Photo>('Photo', 'photos');

        this.printQueues = this.createRepository<PrintQueue>('PrintQueue', 'printQueues');
        this.printQueueInvoiceLinks = this.createRepository<PrintQueueInvoiceLink>('PrintQueueInvoiceLink', 'printQueueInvoiceLinks');

        this.jobs = new JobRepository(this.manager);
        this.storageDumpSites = this.createRepository<StorageDumpSite>('StorageDumpSite', 'storageDumpSites');
        this.dispatchNotes = new DispatchNoteRepository(this.manager);
        this.dumpsterCategories = this.createRepository<DumpsterCategory>('DumpsterCategory', 'dumpsterCategories');
        this.dumpsterLocations = this.createRepository<DumpsterLocation>('DumpsterLocation', 'dumpsterLocations');
        this.dumpsterFeeTypes = this.createRepository<DumpsterFeeType>('DumpsterFeeType', 'dumpsterFeeTypes');
        this.dumpsterFeeTypeDumpsterLinks = this.createRepository<DumpsterFeeTypeDumpsterLink>('DumpsterFeeTypeDumpsterLink', 'dumpsterFeeTypeDumpsterLinks');
        this.dumpsters = this.createRepository<Dumpster>('Dumpster', 'dumpsters');
        this.dumpsterInventories = this.createRepository<DumpsterInventory>('DumpsterInventory', 'dumpsterInventories');
        this.jobDumpsterFees = this.createRepository<JobDumpsterFee>('JobDumpsterFee', 'jobDumpsterFees');
    }

    async getActiveUsers() {
        let predicate = new Predicate('isActive', '==', true);
        let query = EntityQuery.from('Users').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getSettingsByName(name: string) {
        const query = EntityQuery.from('Settings').where('name', '==', name);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as Setting;
    }

    async getSettings() {
        const query = EntityQuery.from('Settings');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getDrivers() {
        let query = EntityQuery.from('Drivers').orderBy('displayOrder');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getActiveInvoices(options?: any) {
        let query = EntityQuery.from('Invoices');
        if (!options || !options.includeDeleted) query = query.where('deleted', '==', null);
        if (options) {
            if (options.expand) query = query.expand(options.expand);
            if (options.customerId) query = query.where('customerId', '==', options.customerId);
            if (options.statusId) query = query.where('statusId', '==', options.statusId);
            if (options.excludeStatusIds) {
                const predicate = new Predicate('statusId', 'in', options.excludeStatusIds).not();
                query = query.where(predicate);
            }
            if (options.createdFrom) query = query.where('createDate', '>=', moment(options.createdFrom).startOf('day').toDate());
            if (options.createdTo) query = query.where('createDate', '<', moment(options.createdTo).add(1, 'days').startOf('day').toDate());
            if (options.statusIds) query = query.where('statusId', 'in', options.statusIds);
            if (options.onlyUnPaid) query = query.where('paid', '==', null);
        }
        const data = await this.manager.executeQuery(query);
        return data.results as Invoice[];
    }

    async getInvoice(id: number | string) {
        const query = EntityQuery.from('invoices')
            .where('id', FilterQueryOp.Equals, id)
            .expand('rental.jobs, status, invoiceLines, customer');
        const data = await this.manager.executeQuery(query);
        return data.results[0] as Invoice;
    }

    async getActiveInvoiceLines(invoiceId: number | string) {
        const predicate = new Predicate('invoiceId', FilterQueryOp.Equals, invoiceId).and('deleted', FilterQueryOp.Equals, null);
        const query = EntityQuery.from('invoiceLines').where(predicate).expand('rentalBillingLines');
        const data = await this.manager.executeQuery(query);
        return data.results as InvoiceLine[];
    }

    async getOpenInvoicePaymentInfos(customerId: number) {
        const query = EntityQuery.from('OpenInvoicePaymentInfos').withParameters({ customerId });
        const data = await this.manager.executeQuery(query);
        return data.results;
    }
    async getInvoicePaymentInfos(paymentId: number) {
        const query = EntityQuery.from('InvoicePaymentInfos').withParameters({ paymentId });
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getInvoiceCommunications() {
        const query = EntityQuery.from('InvoiceCommunications');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getPayments(expand?: string) {
        const predicate = new Predicate('deleted', '==', null);
        let query = EntityQuery.from('Payments').where(predicate);
        if (!!expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results as Payment[];
    }

    async getPaymentLines(invoiceId: any) {
        let predicate = new Predicate('deleted', '==', null).and('payment.deleted', '==', null).and('invoiceId', '==', invoiceId);
        let query = EntityQuery.from('PaymentLines').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as PaymentLine[];
    }

    async getCredits(options?) {
        let query = EntityQuery.from('PaymentCredits').where('deleted', '==', null);
        if (options) {
            if (options.customerId) query = query.where('customerId', '==', options.customerId);
            if (options.unapplied) query = query.where('paymentId', '==', null);
            if (options.expand) query = query.expand(options.expand);
        }
        const data = await this.manager.executeQuery(query);
        return data.results as PaymentCredit[];
    }

    async getActiveServiceAddresses(customerId: number) {
        const predicate = new Predicate('customerId', FilterQueryOp.Equals, customerId).and('deleted', FilterQueryOp.Equals, null);
        const query = EntityQuery.from('serviceAddresses').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as ServiceAddress[];
    }

    async getServiceAddress(id: number, expand: string) {
        let query = EntityQuery.from('serviceAddresses').where('id', FilterQueryOp.Equals, id);
        if (expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as ServiceAddress;
    }

    async getActiveStorageDumpSites(options?: any) {
        let query = EntityQuery.from('storageDumpSites').where('deleted', FilterQueryOp.Equals, null).orderBy('displayOrder');
        if (options) {
            if (options.storage) query = query.where('storage', '==', true);
        }
        const data = await this.manager.executeQuery(query);
        return data.results as StorageDumpSite[];
    }

    async getActiveDumpsterInventories() {
        const query = EntityQuery.from('DumpsterInventories').where('deleted', '==', null).orderBy('dumpsterTypeId, dumpsterNumber');
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterInventory[];
    }

    async getRecentDumpsterJobs() {
        const query = EntityQuery.from('RecentDumpsterJobs');
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getDumpsterInventoryLocations() {
        const query = EntityQuery.from('DumpsterInventoryLocations');
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getAllInventories() {
        let predicate = new Predicate('deleted', '==', null);
        const query = EntityQuery.from('DumpsterInventories').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterInventory[];
    }

    async getInventories(typeId?: number, mode?: string, jobId?: number) {
        let predicate = new Predicate('deleted', '==', null);
        if (!!typeId) predicate = predicate.and('dumpsterTypeId', '==', typeId);
        let query: EntityQuery;
        if (mode === 'delivery') {
            query = EntityQuery.from('AvailableDeliveryDumpsters').withParameters({ typeId: typeId });
        } else if (mode === 'pickup') {
            query = EntityQuery.from('AvailablePickUpDumpsters').withParameters({ typeId: typeId, jobId: jobId });
        } else if (mode === 'storage') {
            query = EntityQuery.from('AvailableStoragePickUpDumpsters');
        } else if (mode === 'storageDelivery') {
            query = EntityQuery.from('AvailableStorageDeliveryUpDumpsters');
        } else {
            query = EntityQuery.from('DumpsterInventories').where(predicate).orderBy('dumpsterTypeId, dumpsterNumber');
        }

        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterInventory[];
    }

    async getStorageInventories(storageId?: number) {
        const query = EntityQuery.from('AvailableStoragePickUpDumpsters').withParameters({ storageId: storageId });
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterInventory[];
    }

    async getAvailablePickUpInventories(typeId: number, jobId: number) {
        // let predicate = new Predicate('deleted', '==', null).and('dumpsterTypeId', '==', typeId);
        let query = EntityQuery.from('AvailablePickUpDumpsters').withParameters({ typeId: typeId, jobId: jobId });
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterInventory[];
    }

    async getCustomerRates(options?: any) {
        let predicate = new Predicate('deleted', '==', null);
        if (options) {
            if (options.customerId) predicate = predicate.and('customerId', '==', options.customerId);
            if (options.rateTypeIds) predicate = predicate.and('rateTypeId', 'in', options.rateTypeIds);
        }
        let query = EntityQuery.from('CustomerRates').where(predicate);
        if(options.orderBy) query = query.orderBy(options.orderBy);
        if(options.expand) query = query.expand(options.expand);
        const data = await this.manager.executeQuery(query);
        return data.results as CustomerRate[];
    }

    async getActiveDumpsterLocations(dumpsterInventoryId: number) {
        const predicate = new Predicate('dumpsterInventoryId', '==', dumpsterInventoryId).and('removed', '==', null);
        const query = EntityQuery.from('DumpsterLocations').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterLocation[];
    }

    async getDumpsterLocations(options?: any) {
        let query = EntityQuery.from('DumpsterLocations');
        if (options) {
            if (options.placedJobId) query = query.where('placedJobId', '==', options.placedJobId).orderBy('placed', true);
            if (options.removedJobId) query = query.where('removedJobId', '==', options.removedJobId).orderBy('removed', true);
            if (options.expand) query = query.expand(options.expand);
        }
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterLocation[];
    }

    async getDumpsterLocationByJobId(jobId: number) {
        const predicate = new Predicate('removedJobId', '==', jobId).and('removed', '!=', null);
        const query = EntityQuery.from('DumpsterLocations').where(predicate).orderByDesc('id').take(1);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as DumpsterLocation;
    }

    async geDumpsterLocationsByJobId(jobId: number) {
        const predicate = new Predicate('removedJobId', '==', jobId).or('placedJobId', '==', jobId);
        const query = EntityQuery.from('DumpsterLocations').expand('dumpsterInventory').where(predicate).orderByDesc('id');
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterLocation[];
    }

    async getCustomersToExport() {
        let predicate = new Predicate('deleted', '==', null).and('quickBooksId', '==', null);
        const query = EntityQuery.from('Customers').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as Customer[];
    }

    async getEntitiesToExport(breezeController: string, expand?: string | string[]) {
        let predicate = new Predicate('deleted', '==', null).and('quickBooksId', '==', null);
        let query = EntityQuery.from(breezeController).where(predicate);
        if (expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getInvoicesToExport(breezeController: string, expand?: string | string[]) {
        let predicate = new Predicate('deleted', '==', null).and('readyToExport', '!=', null);
        const orPredicate = new Predicate('quickBooksId', '==', null).or('quickBooksLastExport', '==', null);
        predicate = predicate.and(orPredicate);
        let query = EntityQuery.from(breezeController).where(predicate);
        if (expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getPaymentsToExport(breezeController: string, expand?: string | string[]) {
        let predicate = new Predicate('deleted', '==', null);
        const orPredicate = new Predicate('quickBooksId', '==', null).or('quickBooksExport', '==', null);
        predicate = predicate.and(orPredicate);
        let query = EntityQuery.from(breezeController).where(predicate);
        if (expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getQBTaskResponses() {
        const query = EntityQuery.from('QuickBooksTaskResponses')
            .orderByDesc('id')
            .expand('quickBooksTask, customer, invoice.customer, payment.customer')
            .take(100);
        const data = await this.manager.executeQuery(query);
        return data.results as QuickBooksTaskResponse[];
    }
    async getQBProducts(onlyVisible: boolean = false) {
        let predicate = new Predicate('deleted', '==', null);
        let query = EntityQuery.from('QuickBooksProducts').orderBy('quickBooksProductService').where(predicate);
        if (onlyVisible) query = query.where('isVisible', '==', true);
        const data = await this.manager.executeQuery(query);
        return data.results as QuickBooksProduct[];
    }

    async getAuditLogs(options?: any) {
        let query = EntityQuery.from('AuditLogs');
        if (!!options) {
            if (options.jobId) query = query.withParameters({ jobId: options.jobId });
        }

        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getOpenPaymentCredits(customerId: number) {
        let predicate = new Predicate('deleted', '==', null).and('customerId', '==', customerId).and('applied', '==', null);
        const query = EntityQuery.from('PaymentCredits').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results as PaymentCredit[];
    }

    async getInventoryInfo() {
        const query = EntityQuery.from('GetInventoryInfo');
        const data = await this.manager.executeQuery(query);
        return data.results[0] as { currentInventoryInfos: any[]; projectedInventoryInfos: any[] };
    }

    async getInventorySubstitutions() {
        const query = EntityQuery.from('InventorySubstitutions');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getInventoryProjectionInfo(dumpsterTypeId: number) {
        const query = EntityQuery.from('GetInventoryProjection').withParameters({ dumpsterTypeId: dumpsterTypeId });
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getDumpsterFeeTypeDumpsterLinks(dumpsterFeeTypeId: number) {
        const query = EntityQuery.from('DumpsterFeeTypeDumpsterLinks').where('dumpsterFeeTypeId', '==', dumpsterFeeTypeId);
        const data = await this.manager.executeQuery(query);
        return data.results as DumpsterFeeTypeDumpsterLink[];
    }

    async getRevenueByType(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate();
        endDate = moment(endDate).startOf('day').toDate();
        const query = EntityQuery.from('RevenueByType').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results as any[];
    }

    async getActiveFuelRatePercentage() {
        const query = EntityQuery.from('FuelRatePercentages').where('endDate', '==', null).orderByDesc('startDate').take(1);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as FuelRatePercentage;
    }

    async getRentalJobsToBillDaily(rentalId: number) {
        const query = EntityQuery.from('GetRentalJobsToBillDaily').withParameters({rentalId: rentalId});
        const data = await this.manager.executeQuery(query);
        return data.results;
    }
    async getBillableDaysByJobIds(rentalId: number) {
        const query = EntityQuery.from('GetBillableDaysByJobIds').withParameters({rentalId: rentalId});
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getRentalBillingLines(rentalId: number) {
        let predicate = new Predicate('deleted', '==', null).and('rentalId', '==', rentalId);
        const query = EntityQuery.from('RentalBillingLines').where(predicate).orderByDesc('endDate');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getPhotos(mode?: number, id?: number, expand?: string) {
        let query = EntityQuery.from('Photos').where('deleted', '==', null).orderByDesc('created');
        if (expand) query = query.expand(expand);
        if (mode === PhotoService.PhotoMode.Rental) query = query.where('rentalId', '==', id);
        const data = await this.manager.executeQuery(query);
        return data.results;
    }
}
