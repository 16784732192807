import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { JobType } from 'src/app/core/model/entity-model';
import { Job } from 'src/app/core/model/job';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { alert } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-rental-job-dialog',
    templateUrl: './rental-job-dialog.component.html',
    styleUrls: ['./rental-job-dialog.component.scss']
})
export class RentalJobDialogComponent extends BaseDialogComponent<any> implements OnInit {
    dialogHeader: string;
    job: Job;
    rentalJobs: Job[];
    selectedRentalJob: Job[];
    rentalJobRequired: boolean = false;
    dumpsterRequired: boolean = false;
    rentalJobLabel: string = 'Picking Up Dumpster';
    dumpsterLabel: string = 'Dumpster';
    dumpsters: Dumpster[];
    showTomorrowButton: boolean = true;
    showPreferredTime: boolean = true;
    isExchange: boolean = false;
    isService: boolean = false;
    isDifferentThanRental: boolean = false;

    constructor(private appInfo: AppInformationService, private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.isExchange = this.job.jobTypeId === JobType.Types.Exchange;
        this.isService = this.job.jobTypeId === JobType.Types.Service;
        (this.job as any).exchangeDumpsterId = this.job.dumpsterId;
        if (!this.job.billAsDumpsterId) this.job.billAsDumpsterId = this.job.rental.dumpsterId;
        if (!(this.job as any).billExchangeAs && this.isExchange) (this.job as any).billExchangeAs = this.job.rental.dumpsterId;
        await this.checkInventory();
    }

    async checkInventory() {
        // only check inventory if a pickUp job will be created
        if (
            this.job.jobTypeId !== JobType.Types.PickUp &&
            this.job.jobTypeId !== JobType.Types.Exchange &&
            this.job.jobTypeId !== JobType.Types.RoundTrip
        )
            return;

        const rentalInventory = await this.uow.rentals.getRentalInventory(this.job.rentalId);
        if (rentalInventory.length === 0) return;

        if (rentalInventory.length > 1)
            await alert(
                `The following are the dumpster sizes on site. Verify that the correct size is selected before saving.<br/>- ${rentalInventory
                    .map(inventory => inventory.dumpsterSize)
                    .join('<br/>- ')}`,
                this.appInfo.appTitle
            );
        else if (
            ['10 yd', '15 yd', '20 yd', '30 yd', '40 yd'].some(size => size === this.job.rental.dumpster.size) &&
            rentalInventory.some(inventory => inventory.dumpsterTypeId !== this.job.rental.dumpsterId)
        )
            await alert('The Dumpster size on site does not match the Rental Dumpster size.', this.appInfo.appTitle);
    }

    save = async () => {
        if (!this.valid()) {
            await alert('Please fix errors before saving', this.appInfo.appTitle);
            return false;
        }

        this.hide({ success: true, value: { job: this.job, selectedRentalJob: this.selectedRentalJob } });
    };

    cancel = async () => {
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('rentalValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('rentalValidationGroup');
            return result.isValid;
        } else return false;
    }

    toggleStartDate() {
        if (this.showTomorrowButton) this.job.startDate = moment(new Date()).add(1, 'days').startOf('date').toDate();
        else this.job.startDate = moment(new Date()).startOf('date').toDate();
        this.showTomorrowButton = !this.showTomorrowButton;
    }

    dumpsterChanged = e => {
        this.isDifferentThanRental =
            (this.job as any).exchangeDumpsterId !== this.job.rental.dumpsterId ||
            (this.job as any).dumpsterId !== this.job.rental.dumpsterId;
    };

    startDateChanged = e => {
        if (!e.value) return;
        this.setPreferredTimeDates();
    };
    startTimeValueChanged = e => {
        if (!e.event || !e.value) return;

        if (!this.job.preferredEndTime) this.job.preferredEndTime = moment(e.value).add(1, 'hours').toDate();
        this.setPreferredTimeDates();
    };
    endTimeValueChanged = e => {
        this.setPreferredTimeDates();
    };
    setPreferredTimeDates() {
        const startDate = this.job.startDate ?? new Date();

        const day = moment(startDate).get('date');
        const month = moment(startDate).get('month');
        const year = moment(startDate).get('year');

        if (this.job.preferredStartTime)
            this.job.preferredStartTime = moment(this.job.preferredStartTime).date(day).month(month).year(year).toDate();
        if (this.job.preferredEndTime)
            this.job.preferredEndTime = moment(this.job.preferredEndTime).date(day).month(month).year(year).toDate();
    }
}
