// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { CustomerRate } from './customer-rate';
import { Invoice } from './invoice';
import { Rental } from './rental';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ServiceAddress extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get displayAddress() {
        return [this.siteName ? this.siteName + ' -' : null, this.address1, this.address2, this.city + ',', this.state, this.zip].join(' ');
    }

    get displayName() {
        return [this.siteName ? this.siteName + ' -' : null, this.address1].join(' ');
    }

    get gridName() {
        return `${this.siteName ? this.siteName : '----'}`;
    }
    get assignedRate() {
        let rate = '';
        if (this.zone) rate = 'Zone';
        if (this.range) rate = 'Range';
        return rate;
    }
    get rangeDisplay() {
        return `${this.range ? this.range : 'Zone ' + this.zone}`;
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  address1: string;
  address2: string;
  city: string;
  country: string;
  county: string;
  createDate: Date;
  createdBy: string;
  customerId: number;
  deleted: Date;
  distance: number;
  extendedRange: boolean;
  latitude: number;
  longitude: number;
  notes: string;
  outerRange: boolean;
  phone: string;
  pricePerTon: number;
  range: string;
  rateId: number;
  regularRange: boolean;
  siteName: string;
  state: string;
  zip: string;
  zone: number;
  customer: Customer;
  invoices: Invoice[];
  rate: CustomerRate;
  rentals: Rental[];
}

