import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { PrepareGuard } from './core/prepare.guard';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' },
    },
    {
        path: 'profile',
        component: ProfileComponent,
    },
    {
        path: '**',
        redirectTo: '',
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forRoot(routes, {}), DxDataGridModule, DxFormModule],
    exports: [RouterModule],
    declarations: [HomeComponent, ProfileComponent]
})
export class AppRoutingModule {}
