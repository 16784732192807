<div class="flex-fill d-flex flex-column content-block dx-card p-3">
    <div class="content-block dx-card responsive-paddings" autofocus *ngIf="true">
        <dx-form [formData]="filter" [colCount]="3" (onEditorEnterKey)="search()" appAutofocusForm="companyName">
            <dxi-item itemType="group" [colSpan]="2" [colCount]="4">
                <dxi-item dataField="companyName"></dxi-item>
                <dxi-item dataField="firstName"></dxi-item>
                <dxi-item dataField="lastName"></dxi-item>
                <dxi-item dataField="billCity" [label]="{ text: 'City' }"></dxi-item>
            </dxi-item>
            <dxi-item [colSpan]="3">
                <div *dxTemplate class="btn-toolbar justify-content-end align-items-center">
                    <div *ngIf="customers" class="mr-3">
                        {{ customers.length }} {{ customers.length === 1 ? 'item found' : 'items found' }}
                    </div>
                    <button type="button" accesskey="s" (click)="search()" class="btn btn-primary mr-1">Search</button>
                    <button type="button" (click)="clear()" class="btn btn-light">Clear</button>
                </div>
            </dxi-item>
        </dx-form>
    </div>
    <div class="flex-fill d-flex flex-column content-block dx-card p-3">
        <div class="d-flex flex-fill flex-column">
            <dx-data-grid
                #dataGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="customers"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column dataField="type.name" caption="Type" width="100"></dxi-column>
                <dxi-column dataField="companyName" caption="Company Name" width="175" cellTemplate="linkTemplate"></dxi-column>
                <dxi-column dataField="displayName" caption="Customer Name" width="175" cellTemplate="linkTemplate"></dxi-column>
                <dxi-column dataField="contactName" caption="Contact Name" width="175" cellTemplate="linkTemplate"></dxi-column>
                <dxi-column dataField="billingType" width="100"></dxi-column>
                <dxi-column dataField="phone" width="125"></dxi-column>
                <dxi-column dataField="email" width="175"></dxi-column>
                <dxi-column dataField="billAddress1" caption="Address 1" width="175"></dxi-column>
                <dxi-column dataField="billAddress2" caption="Address 2" width="125"></dxi-column>
                <dxi-column dataField="billCity" caption="City" width="125"></dxi-column>
                <dxi-column dataField="billState" caption="State" width="100"></dxi-column>
                <dxi-column dataField="billZip" caption="Zip" width="75"></dxi-column>
                <dxi-column dataField="billCountry" caption="Country" width="125"></dxi-column>
                <dxi-column dataField="salesPerson.name" caption="Sales Person" width="125"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'linkTemplate'">
                    <a [routerLink]="[]" (click)="open(cell.data)" *ngIf="cell.data">{{ cell.text }}</a>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="customer-search-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-primary mr-1 mb-2"
                        (click)="open(dataGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isCustomerAdmin"
                    >
                        Open
                    </button>
                    <button class="btn btn-success mr-2 mb-2" (click)="open()" [hidden]="!isCustomerAdmin">Add</button>
                    <button
                        class="btn btn-danger mr-1 mb-2"
                        (click)="delete(dataGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                        [hidden]="!isCustomerAdmin"
                    >
                        Delete
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="customers" class="mr-3">
                        <strong>
                            {{ dataGrid.instance.totalCount() + ' items' }}
                            {{
                                dataGrid.instance.getSelectedRowsData().length > 0
                                    ? dataGrid.instance.getSelectedRowsData().length + ' selected'
                                    : ''
                            }}
                        </strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
