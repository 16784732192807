import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-base-dialog',
    templateUrl: './base-dialog.component.html',
    styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent<T> implements OnInit {
    visible = false;
    isResolved = false;
    resolve: (value?: T) => void;
    reject: (reason: any) => void;

    constructor() {}

    ngOnInit() {}

    show() {
        this.isResolved = false;
        const dialogPromise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.visible = true;
        return dialogPromise;
    }

    hide(result?: T) {
        this.resolve(result);
        this.isResolved = true;
        this.visible = false;
    }

    cancel() {}

    onHiding(e) {
        if (!this.isResolved) {
            this.cancel();
            this.resolve(null);
            this.isResolved = true;
        }
    }
}
