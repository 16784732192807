// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { DumpsterInventory } from './dumpster-inventory';
import { Job } from './job';

/// <code-import> Place custom imports between <code-import> tags
import * as moment from 'moment';
/// </code-import>

export class DumpsterLocation extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get totalNumberOfDays() {
        let days = null;
        if (this.placed && this.removed) {
            let start = this.placed;
            const end = this.removed;
            if (this.lastBilledDate) start = this.lastBilledDate;
            days = moment(this.removed).diff(this.placed, 'days');
        }
        return days;
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  billedDays: number;
  completedBy: string;
  created: Date;
  createdBy: string;
  dumpsterInventoryId: number;
  lastBilledDate: Date;
  placed: Date;
  placedJobId: number;
  removed: Date;
  removedJobId: number;
  totalDays: number;
  dumpsterInventory: DumpsterInventory;
  placedJob: Job;
  removedJob: Job;
}

