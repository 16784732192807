import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Routes, Resolve, Router } from '@angular/router';
import { EntityManagerProvider } from './data/entity-manager-provider';
import { AppInformationService } from './app-information.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PrepareGuard implements CanActivate {
    constructor(
        public entityManagerProvider: EntityManagerProvider,
        private auth: AuthService,
        private router: Router,
        private appInfo: AppInformationService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        try {
            this.appInfo.pleaseWaitShow();
            await this.entityManagerProvider.prepare();
            this.appInfo.pleaseWaitHide();
            return true;
        } catch (e) {
            this.appInfo.pleaseWaitHide();
            console.error('Error connecting to data service: ' + EntityManagerProvider.serviceName, e);
            return false;
        }
    }
}
