import { Injectable } from '@angular/core';
import { EntityManager, Entity, EntityQuery, FetchStrategy, SaveOptions, EntityChangedEventArgs } from 'breeze-client';

import { EntityManagerProvider } from './entity-manager-provider';
import { Repository } from './repository';
import { Subject } from 'rxjs';

export class SavedOrRejectedArgs {
    entities: Entity[];
    rejected: boolean;
}

@Injectable()
export class UnitOfWork {
    private static shelveSets = {};
    private static savedOrRejectedSubject = new Subject<SavedOrRejectedArgs>();

    private _manager: EntityManager;
    private entityChangedSubject: Subject<EntityChangedEventArgs>;

    static deleteShelveSet(key: string): void {
        delete UnitOfWork.shelveSets[key];
    }

    get manager(): EntityManager {
        if (!this._manager) {
            this._manager = this._emProvider.newManager();

            this._manager.entityChanged.subscribe(args => {
                this.entityChangedSubject.next(args);
            });
        }
        return this._manager;
    }

    get entityChanged() {
        return this.entityChangedSubject.asObservable();
    }

    createEntity(entityTypeName: string, config?: any) {
        return this.manager.createEntity(entityTypeName, config);
    }

    clearCached(entityTypeName) {
        const cached = this.manager.getEntities(entityTypeName); // all items in cache
        cached.forEach(entity => {
            this.manager.detachEntity(entity);
        });
    }

    static get savedOrRejected() {
        return UnitOfWork.savedOrRejectedSubject.asObservable();
    }

    constructor(private _emProvider: EntityManagerProvider) {
        this.entityChangedSubject = new Subject<EntityChangedEventArgs>();
    }

    hasChanges(): boolean {
        return this.manager.hasChanges();
    }

    getChanges(): Entity[] {
        return this.manager.getChanges();
    }

    commit(entities?: any[]): Promise<any> {
        const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        return <Promise<any>>(<any>this.manager
            .saveChanges(entities, saveOptions)
            .then(saveResult => {
                UnitOfWork.savedOrRejectedSubject.next({
                    entities: saveResult.entities,
                    rejected: false
                });

                return saveResult.entities;
            })
            .catch(error => {
                console.log(`save failed: ${error}`);
                if (error.entityErrors) console.warn('entityErrors', error.entityErrors);
                throw error;
            }));
    }

    rollback(): void {
        const pendingChanges = this.manager.getChanges();
        this.manager.rejectChanges();
        UnitOfWork.savedOrRejectedSubject.next({
            entities: pendingChanges,
            rejected: true
        });
    }

    clear(): void {
        this._emProvider.reset(this.manager);
    }

    shelve(key: string, clear: boolean = false): void {
        const data = this.manager.exportEntities(null, { asString: false, includeMetadata: false });
        UnitOfWork.shelveSets[key] = data;
        if (clear) {
            this._emProvider.reset(this.manager);
        }
    }

    unshelve(key: string, clear: boolean = true): boolean {
        const data = UnitOfWork.shelveSets[key];
        if (!data) {
            return false;
        }

        if (clear) {
            // Clear the entity manager and don't bother importing lookup data from masterManager.
            this.manager.clear();
        }
        this.manager.importEntities(data);

        // Delete the shelveSet
        delete UnitOfWork.shelveSets[key];
        return true;
    }

    protected createRepository<T>(entityTypeName: string, resourceName: string, isCached: boolean = false, defaultOrderBy?: string) {
        return new Repository<T>(this.manager, entityTypeName, resourceName, isCached, defaultOrderBy);
    }
}
