// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Contact } from './contact';
import { CreditCard } from './credit-card';
import { CustomerRate } from './customer-rate';
import { CustomerType } from './customer-type';
import { Invoice } from './invoice';
import { PaymentCredit } from './payment-credit';
import { Rental } from './rental';
import { SalesPerson } from './sales-person';
import { ServiceAddress } from './service-address';

/// <code-import> Place custom imports between <code-import> tags
import * as moment from 'moment';
import { Note } from './note';
/// </code-import>

export class Customer extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static PreferredCommunicationOptions = ['Email', 'Phone'];
    static PaymentTermOptions = ['Net 30', 'Net 15', 'Due on receipt'];
    static BillingTypeOptions = ['COD', 'Credit-Debit', 'Billing'];

    get displayName() {
        let name = '';
        if (this.typeId == CustomerType.Types.Commercial) name = this.companyName;
        else {
            if (this.companyName) name = this.companyName;
            else if (!this.firstName && !this.lastName) name = '(residential names blank)';
            else name = [this.firstName, this.lastName].join(' ');
        }
        return name;
    }

    get contactName() {
        return [this.firstName, this.lastName].filter(s => s).join(' ');
    }

    get displayNameGP() {
        return this.quickBooksId;
    }

    get displayNotes() {
        let notes: string[] = [];
        this.customerNotes?.forEach(cn =>
            notes.push([`(${moment(cn.created).format('MM/DD/yyyy h:mma')})`, `${cn.createdBy.displayName}:`, cn.text].join('  '))
        );
        return notes?.join('\n') ?? '';
    }

    get billingCommunication() {
        if (this.billingType === 'Billing') return 'Mail';
        else return 'Email';
    }

    customerNotes: Note[];
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  balance: number;
  billAddress1: string;
  billAddress2: string;
  billCity: string;
  billCountry: string;
  billState: string;
  billZip: string;
  billingRule: string;
  billingSameAsService: boolean;
  billingType: string;
  bypassKentCharge: boolean;
  bypassMichiganSurcharge: boolean;
  companyName: string;
  creditCardId: number;
  deleted: Date;
  email: string;
  extensionRate: number;
  firstName: string;
  fuelRate: number;
  lastName: string;
  nameOverride: string;
  notes: string;
  paymentTerms: string;
  phone: string;
  preferredCommunication: string;
  quickBooksId: string;
  quickBooksLastExport: Date;
  salesPersonId: number;
  siteNotes: string;
  standardRentalLength: number;
  typeId: number;
  usePercentageBasedFuelRate: boolean;
  contacts: Contact[];
  creditCard: CreditCard;
  credits: PaymentCredit[];
  invoices: Invoice[];
  rates: CustomerRate[];
  rentals: Rental[];
  salesPerson: SalesPerson;
  serviceAddresses: ServiceAddress[];
  type: CustomerType;
}

