<dx-popup title="User" [width]="400" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="user">
        <dxi-item dataField="firstName"> </dxi-item>
        <dxi-item dataField="lastName"> </dxi-item>
        <dxi-item dataField="userName"> </dxi-item>
        <dxi-item dataField="email"> </dxi-item>
        <dxi-item dataField="windowsUserName"> </dxi-item>
        <dxi-item dataField="isActive" editorType="dxCheckBox"> </dxi-item>
        <dxi-item [label]="{ text: 'Roles' }">
            <div *dxTemplate>
                <dx-list
                    [items]="roles"
                    keyExpr="roleId"
                    [(selectedItemKeys)]="selectedRoleIds"
                    selectionMode="multiple"
                    [showSelectionControls]="true"
                    itemTemplate="listItem"
                    >
                    <div *dxTemplate="let item of 'listItem'">
                        {{ item.name }}
                    </div>
                </dx-list>
            </div>
        </dxi-item>
    </dx-form>
</dx-popup>
