import { Component, OnInit, ViewChild } from '@angular/core';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { alert, confirm } from 'src/app/shared/shared.module';
import { CustomerDetailComponent } from '../customer-detail/customer-detail.component';

@Component({
    selector: 'app-customer-detail-dialog',
    templateUrl: './customer-detail-dialog.component.html',
    styleUrls: ['./customer-detail-dialog.component.scss']
})
export class CustomerDetailDialogComponent extends BaseDialogComponent<any> implements OnInit {
    @ViewChild('customerDetailComponent') customerDetailComponent: CustomerDetailComponent;
    dialogKey: any = 'new';

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService) {
        super();
    }

    ngOnInit() {}

    save = async () => {
        if (!this.customerDetailComponent.valid()) {
            await alert('Please fix errors before saving', this.appInfo.appTitle);
            return;
        }

        await this.uow.commit([this.customerDetailComponent.customer]);
        this.hide({ success: true, value: this.customerDetailComponent.customer.id });
    };

    cancel = async () => {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;

        this.customerDetailComponent.customer.entityAspect.setDeleted();
        this.hide({ success: false });
    };
}
