import { Entity, EntityManager, FetchStrategy, EntityType, EntityQuery, Predicate, FilterQueryOp, QueryResult, EntityState } from 'breeze-client';



export { Entity, Predicate };
export class Repository<T> {
    // tslint:disable: variable-name
    private _resourceNameSet: boolean;
    protected _defaultFetchStrategy: FetchStrategy;
    protected idField: any;

    notDeleted = new Predicate('deleted', '==', null);

    constructor(
        private _manager: EntityManager,
        protected _entityTypeName: string,
        protected _resourceName: string,
        protected _isCachedBundle: boolean = false,
        protected _defaultOrderBy?: string
    ) {
        this._defaultFetchStrategy = _isCachedBundle ? FetchStrategy.FromLocalCache : FetchStrategy.FromServer;

        const metadataStore = this._manager.metadataStore;

        const entityType = <EntityType>metadataStore.getEntityType(this._entityTypeName || '', true);
        if (entityType) {
            if (entityType.keyProperties.length === 1) {
                this.idField = entityType.keyProperties[0].name;
            }
            entityType.setProperties({ defaultResourceName: this.localResourceName });
            metadataStore.setEntityTypeForResourceName(this.localResourceName, entityType);
        }
    }

    protected get manager(): EntityManager {
        if (this._resourceNameSet) return this._manager;
        const metadataStore = this._manager.metadataStore;

        const entityType = <EntityType>metadataStore.getEntityType(this._entityTypeName || '', true);
        if (entityType) {
            entityType.setProperties({ defaultResourceName: this.localResourceName });
            metadataStore.setEntityTypeForResourceName(this.localResourceName, entityType);
        }

        return this._manager;
    }

    protected get localResourceName() {
        return this._isCachedBundle ? this._entityTypeName : this._resourceName;
    }

    withId(id: any): Promise<T> {
        if (!this._entityTypeName) throw new Error('Repository must be created with an entity type specified');

        return <Promise<T>>(<any>this.manager
            .fetchEntityByKey(this._entityTypeName, id, true)
            .then(function(data) {
                return <T>(<any>data.entity);
            })
            .catch(e => {
                if (e.status === 404) {
                    return null;
                }

                throw e;
            }));
    }

    byId(id, expand?: string[] | string): Promise<T> {
        if (!this._entityTypeName) throw new Error('Repository must be created with an entity type specified');

        if (!this.idField) throw new Error('Single Identity field not found.');

        let query = EntityQuery.from(this._resourceName).where(this.idField, FilterQueryOp.Equals, id);
        if (expand) {
            query = query.expand(expand as any);
        }

        return this.executeQuery(query)
            .then((data: any) => {
                return data.length === 1 ? data[0] : undefined;
            })
            .catch(e => {
                throw e;
            });
    }

    byIds(ids, expand?: string): Promise<T[]> {
        if (!this._entityTypeName) throw new Error('Repository must be created with an entity type specified');

        if (!this.idField) throw new Error('Single Identity field not found.');

        let query = EntityQuery.from(this._resourceName).where(this.idField, 'in', ids);
        if (expand) {
            query = query.expand(expand);
        }

        return this.executeQuery(query)
            .then((data: any) => {
                return data;
            })
            .catch(e => {
                throw e;
            });
    }

    protected buildQuery(predicateOrProperty: Predicate | string, operator?: string, value?: any) {
        let orderBy: string;
        let expand: string;
        if (typeof predicateOrProperty === 'string') {
            const property = predicateOrProperty;
            predicateOrProperty = new Predicate(property, operator, value);
        } else {
            orderBy = operator || this._defaultOrderBy;
            expand = value;
        }
        let query = this.baseQuery().where(predicateOrProperty);
        if (expand) query = query.expand(expand);
        if (orderBy) query = query.orderBy(orderBy);

        return query;
    }

    where(predicate: Predicate, orderBy?: string, expand?: string): Promise<T[]>;
    where(property: string, operator: string, value: any, orderBy?: string): Promise<T[]>;
    where(predicateOrProperty: Predicate | string, operator?: string, value?: any, orderBy?: string): Promise<T[]> {
        let query = this.buildQuery(predicateOrProperty, operator, value);
        if (orderBy) query = query.orderBy(orderBy);
        return this.executeQuery(query);
    }

    whereInCache(predicate: Predicate, orderBy?: string, expand?: string): T[];
    whereInCache(property: string, operator: string, value: any, orderBy?: string): T[];
    whereInCache(predicateOrProperty: Predicate | string, operator?: string, value?: any): T[] {
        // whereInCache(predicate: Predicate): T[] {
        // let query = this.baseQuery().where(predicate);
        const query = this.buildQuery(predicateOrProperty, operator, value);

        return this.executeCacheQuery(query) as T[];
    }

    whereActive(orderBy?: string, expand?: string) {
        return this.where(this.notDeleted, orderBy, expand);
    }

    all(orderBy?: string, expand?: string | string[]): Promise<T[]> {
        let query = this.baseQuery();
        orderBy = orderBy || this._defaultOrderBy;
        if (orderBy) {
            query = query.orderBy(orderBy);
        }
        if (expand) {
            if (typeof expand === 'string') {
                query = query.expand(expand);
            } else {
                query = query.expand(expand);
            }
        }

        return this.executeQuery(query);
    }

    allLocal(orderBy?: string): T[] {
        let query = this.baseQuery();
        orderBy = orderBy || this._defaultOrderBy;
        if (orderBy) {
            query = query.orderBy(orderBy);
        }

        return this.executeCacheQuery(query);
    }

    createEntity(initialValues?: Object, entityState?: EntityState) {
        return <T>(<any>this.manager.createEntity(this._entityTypeName, initialValues, entityState));
    }

    async refresh(entity: Entity): Promise<T> {
        const data = await this.executeQuery(EntityQuery.fromEntities(entity));
        return data[0];
    }

    baseQuery(): EntityQuery {
        return EntityQuery.from(this.localResourceName);
    }

    async executeQuery(query: EntityQuery, fetchStrategy?: FetchStrategy): Promise<T[]> {
        const data = await this.executeQueryFull(query, fetchStrategy);
        return <T[]>(<any>data.results);
    }

    executeQueryFull(query: EntityQuery, fetchStrategy?: FetchStrategy): Promise<QueryResult> {
        const q = query.using(fetchStrategy || this._defaultFetchStrategy);
        return <Promise<QueryResult>>(<any>this.manager.executeQuery(q).catch(e => {
            if (e.status === 404) {
                return [];
            }

            // Something else happened, rethrow the exception
            throw e;
        }));
    }

    protected executeCacheQuery(query: EntityQuery): T[] {
        return <T[]>(<any>this.manager.executeQueryLocally(query));
    }
}
