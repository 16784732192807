<div class="flex-fill d-flex flex-column">
    <h1 class="header-label">Storage and Dump Sites</h1>
    <div class="flex-fill content-block dx-card responsive-paddings">
        <div class="d-flex flex-fill">
            <dx-data-grid
                #overviewGrid
                keyExpr="id"
                [dataSource]="storageDumpSites"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column dataField="name" width="150"></dxi-column>
                <dxi-column dataField="dump" width="75"></dxi-column>
                <dxi-column dataField="storage" width="75"></dxi-column>
                <dxi-column dataField="note" width="250"></dxi-column>
                <dxi-column dataField="address1" width="200"></dxi-column>
                <dxi-column dataField="address2" width="200"></dxi-column>
                <dxi-column dataField="city" width="100"></dxi-column>
                <dxi-column dataField="state" width="75"></dxi-column>
                <dxi-column dataField="zip" width="100"></dxi-column>
                <dxi-column dataField="country" width="75"></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage-dump-site-overview-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button class="btn btn-success mr-2 mb-2" (click)="open()">Add</button>
                    <button
                        class="btn btn-primary mr-1 mb-2"
                        (click)="open(overviewGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        Edit
                    </button>
                    <button
                        class="btn btn-danger mb-2"
                        (click)="delete(overviewGrid.instance.getSelectedRowsData())"
                        [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                    >
                        Delete
                    </button>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
