<div class="header-label">Rentals</div>
<div class="flex-fill d-flex content-block dx-card p-3">
    <div class="col-7 flex-fill d-flex flex-column">
        <dx-data-grid
            #rentalsGrid
            keyExpr="id"
            class="flex-fill d-flex flex-column"
            height="100px"
            [dataSource]="rentals"
            [showBorders]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="onRentalRowDoubleClick($event)"
            (onRowPrepared)="onRowPrepared($event)"
        >
            <dxi-column dataField="id" caption="Rental" width="80" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="customer.displayName" caption="Customer" width="100" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="siteAddressHTML" caption="Site Address" width="200" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="inventoryHtml" caption="Inventory" width="150" cellTemplate="htmlTemplate"></dxi-column>
            <dxi-column dataField="daysOnSite" caption="Max Days" width="150"></dxi-column>
            <dxi-column dataField="startDate" dataType="date" format="M/d/yyyy" width="100"></dxi-column>
            <dxi-column dataField="endDate" dataType="date" format="M/d/yyyy" width="100"></dxi-column>
            <dxi-column
                caption="Status"
                cellTemplate="statusTemplate"
                [calculateCellValue]="statusCellValue"
                [calculateSortValue]="statusSortValue"
                [allowSorting]="true"
                [allowFiltering]="true"
                width="100"
            ></dxi-column>
            <!-- <dxi-column dataField="preferredTime" dataType="datetime" format="shortTime" width="150"></dxi-column> -->
            <!-- <dxi-column dataField="preferredWindow" width="150"></dxi-column> -->

            <div *dxTemplate="let cell of 'htmlTemplate'">
                <span [innerHTML]="cell.text"></span>
            </div>

            <div *dxTemplate="let cell of 'linkTemplate'">
                <a *ngIf="cell" [routerLink]="[]" [innerHtml]="cell.text" (click)="open(cell.data, cell.column.dataField)"></a>
            </div>

            <div *dxTemplate="let item of 'statusTemplate'" class="d-flex">
                <i *ngIf="item" class="badge danger" text="Status" style="color: darkred"></i>
            </div>

            <div *dxTemplate="let cell of 'statusTemplate'">
                <div class="{{ 'ml-1 badge ' + getStatusClass(cell.data) }}">
                    {{ getStatus(cell.data) }}
                </div>
            </div>

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="rentals-grid"></dxo-state-storing>

            <div *dxTemplate="let data of 'toolbarButtons'">
                <button class="btn btn-sm btn-success mr-1 mb-2" (click)="openRental()" [hidden]="!isRentalAdmin">Add</button>
                <button
                    class="btn btn-sm btn-danger mb-2"
                    (click)="deleteRental(rentalsGrid.instance.getSelectedRowsData())"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length === 0"
                    [hidden]="!isRentalAdmin"
                >
                    Delete
                </button>
                <button
                    class="btn btn-sm btn-secondary ml-4 mr-1 mb-2"
                    (click)="completeRental(rentalsGrid.instance.getSelectedRowsData())"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length === 0"
                    [hidden]="!isRentalAdmin"
                >
                    Complete
                </button>
                <button
                    class="btn btn-sm btn-success mr-1 mb-2"
                    (click)="add(rentalsGrid.instance.getSelectedRowsData()[0], 'Delivery')"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isRentalAdmin"
                >
                    Add Delivery
                </button>
                <button
                    class="btn btn-sm btn-success mr-1 mb-2"
                    (click)="add(rentalsGrid.instance.getSelectedRowsData()[0], 'PickUp')"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isRentalAdmin"
                >
                    Add Pickup
                </button>

                <button
                    class="btn btn-sm btn-success mr-1 mb-2"
                    (click)="add(rentalsGrid.instance.getSelectedRowsData()[0], 'Exchange')"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isRentalAdmin"
                >
                    Add Exchange
                </button>
                <button
                    class="btn btn-sm btn-success mr-1 mb-2"
                    (click)="add(rentalsGrid.instance.getSelectedRowsData()[0], 'RoundTrip')"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isRentalAdmin"
                >
                    Add Round Trip
                </button>
                <button
                    class="btn btn-sm btn-success mb-2"
                    (click)="add(rentalsGrid.instance.getSelectedRowsData()[0], 'Service')"
                    [disabled]="rentalsGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isRentalAdmin"
                >
                    Add Service
                </button>
            </div>

            <div *dxTemplate="let data of 'toolbarText'">
                <span *ngIf="rentals" class="mr-3">
                    <strong>{{ rentalsGrid.instance.totalCount() + ' items' }}</strong>
                </span>
            </div>
        </dx-data-grid>
    </div>
    <div class="col-sm-5 d-flex flex-fill flex-column">
        <app-dispatch-notes-overview style="display: contents; padding-top: 1rem" readOnly="true"></app-dispatch-notes-overview>
    </div>
</div>
<!-- <div class="flex-fill d-flex flex-column">
</div> -->
