import { Directive, AfterViewInit, ElementRef, DoCheck, Input } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';

@Directive({ selector: '[appAutofocusForm]' })
export class FormAutofocusDirective implements AfterViewInit, DoCheck {
    private lastVisible = false;
    private initialised = false;
    @Input('appAutofocusForm') dataField: string;

    constructor(private el: ElementRef, private form: DxFormComponent) {}

    ngAfterViewInit() {
        this.initialised = true;
        this.ngDoCheck();
    }

    ngDoCheck() {
        if (!this.initialised) {
            return;
        }
        const visible = !!this.el.nativeElement.offsetParent;
        if (visible && !this.lastVisible) {
            this.form.instance.focus();
            const editor = this.form.instance.getEditor(this.dataField);
            if (editor) {
                setTimeout(() => {
                    const inputs: any = editor.element().getElementsByClassName('dx-texteditor-input');
                    if (inputs.item(0)) inputs.item(0).select();
                }, 400);
            }
        }
        this.lastVisible = visible;
    }
}
