import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReportService {
    static serviceName: string;
    constructor() {
        ReportService.serviceName = `${environment.serviceName}/report`;
    }

    printInvoiceReport(invoiceIds: number[]) {
        const idsParam = invoiceIds.map(id => `ids=${id}`).join('&');
        const url = `${ReportService.serviceName}/invoice?${idsParam}`;
        window.open(url, '_blank');
    }

    printPaymentReceiptReport(invoiceIds: number[]) {
        const idsParam = invoiceIds.map(id => `ids=${id}`).join('&');
        const url = `${ReportService.serviceName}/PaymentReceipt?${idsParam}`;
        window.open(url, '_blank');
    }

    printStatementReport(statementId: number) {
        const url = `${ReportService.serviceName}/Statement?id=${statementId}`;
        window.open(url, '_blank');
    }
}
