import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Options as dxDataGridOptions, default as dxDataGrid } from 'devextreme/ui/data_grid';
import { Options as dxTreeListOptions, default as dxTreeList } from 'devextreme/ui/tree_list';
import { Options as dxFormOptions, default as dxForm } from 'devextreme/ui/form';
import { Options as dxListOptions, default as dxList } from 'devextreme/ui/list';
import { Options as dxSelectBoxOptions, default as dxSelectBox } from 'devextreme/ui/select_box';
import { Options as dxTagBoxOptions, default as dxTagBox } from 'devextreme/ui/tag_box';
import { Options as dxTextBoxOptions, default as dxTextBox } from 'devextreme/ui/text_box';
import { Options as dxTextAreaOptions, default as dxTextArea } from 'devextreme/ui/text_area';
import { Options as dxNumberBoxOptions, default as dxNumberBox } from 'devextreme/ui/number_box';
import { Options as dxDateBoxOptions, default as dxDateBox } from 'devextreme/ui/date_box';
import { Options as dxTabPanelOptions, default as dxTabPanel } from 'devextreme/ui/tab_panel';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { DialogService } from './dialog.service';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { FormAutofocusDirective } from './autofocus-form.directive';
import { alert, confirm } from 'devextreme/ui/dialog';
import { RouterModule } from '@angular/router';
import { defaultToolbarPreparing } from './defaults';
import { TextFieldModule } from '@angular/cdk/text-field';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { DispatchNotesDetailComponent } from './dispatch-notes-detail/dispatch-notes-detail.component';
import { DispatchNotesDialogComponent } from './dispatch-notes-dialog/dispatch-notes-dialog.component';
import { AddressLookupComponent } from './components/address-lookup/address-lookup.component';
import { InvoiceOverviewComponent } from 'src/app/invoice/invoice-overview/invoice-overview.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { AuditDialogComponent } from './audit-dialog/audit-dialog.component';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { PhotoDialogComponent } from './photo-dialog/photo-dialog.component';

export {
    alert,
    confirm,
    DialogService,
    SearchDialogComponent,
    defaultToolbarPreparing
};

@NgModule({
    declarations: [
        InvoiceOverviewComponent,
        BaseDialogComponent,
        SearchDialogComponent,
        InputDialogComponent,
        DispatchNotesDetailComponent,
        DispatchNotesDialogComponent,
        FormAutofocusDirective,
        AddressLookupComponent,
        EmailDialogComponent,
        AuditDialogComponent,
        PhotoDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        DevExtremeModule,
        TextFieldModule
    ],
    exports: [
        InvoiceOverviewComponent,
        DispatchNotesDetailComponent,
        DispatchNotesDialogComponent,
        CommonModule,
        FormsModule,
        DevExtremeModule,
        ReactiveFormsModule,
        FormAutofocusDirective,
        AddressLookupComponent,
        AuditDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    constructor() {
        const treeListOptions: dxTreeListOptions = {
            focusedRowEnabled: true,
            columnResizingMode: 'widget',
            showBorders: true,
            allowColumnResizing: true,
            allowColumnReordering: true,
            columnAutoWidth: true,
            searchPanel: { placeholder: 'Find...', width: 100 },
            loadPanel: { enabled: false },
            stateStoring: { savingTimeout: 500 }
        };
        dxTreeList.defaultOptions({ options: treeListOptions as any });

        const dataGridOptions: dxDataGridOptions = {
            columnAutoWidth: false,
            columnResizingMode: 'widget',
            allowColumnReordering: true,
            allowColumnResizing: true,
            columnHidingEnabled: true,
            hoverStateEnabled: false,
            loadPanel: { enabled: false },
            wordWrapEnabled: true,
            paging: { enabled: false, pageSize: 100 },
            scrolling: { mode: 'virtual', useNative: false, showScrollbar: 'always' },
            searchPanel: { placeholder: 'Find...', width: 125 },
            selection: { mode: 'none', showCheckBoxesMode: 'onLongTap' },
            stateStoring: { type: 'localStorage', savingTimeout: 500 },
            groupPanel: { emptyPanelText: 'drag here to group' },

            onToolbarPreparing: (e) => {
                const toolbarItems = e.toolbarOptions.items;
                const searchPanel = toolbarItems.filter((item) => item.name === 'searchPanel')[0];
                if (searchPanel) {
                    searchPanel.location = 'after';
                }
                if (!e.component.option('stateStoring.enabled')) return;
                e.toolbarOptions.items.push({
                    widget: 'dxButton',
                    options: {
                        text: 'Filter Row',
                        icon: 'filter',
                        elementAttr: { title: 'Reset Grid Layout' },
                        onClick: () => {
                            const visible = e.component.option('filterRow.visible') as boolean;
                            e.component.option('filterRow.visible', !visible);
                        }
                    },
                    location: 'after',
                    locateInMenu: 'always'
                });
                e.toolbarOptions.items.push({
                    widget: 'dxButton',
                    options: {
                        text: 'Reset Layout',
                        icon: 'revert',
                        elementAttr: { title: 'Reset Grid Layout' },
                        onClick: () => {
                            e.component.state({});
                        }
                    },
                    location: 'after',
                    locateInMenu: 'always'
                });
            },
            onExporting: (e) => {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Main sheet');
                exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function(options) {
                        options.excelCell.font = { name: 'Arial', size: 12 };
                        options.excelCell.alignment = { horizontal: 'left' };
                    }
                }).then(function() {
                    workbook.xlsx.writeBuffer()
                        .then(function(buffer: BlobPart) {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                        });
                });
            }
        };
        dxDataGrid.defaultOptions({ options: dataGridOptions as any });

        const formOptions: dxFormOptions = {
            labelLocation: 'top',
            showColonAfterLabel: false
        };
        dxForm.defaultOptions({ options: formOptions as any });

        const selectboxOptions: dxSelectBoxOptions = {
            searchEnabled: true,
            searchTimeout: 100,
            showClearButton: false,
            inputAttr: { autoComplete: false },
            valueChangeEvent: 'input'

        };
        dxSelectBox.defaultOptions({ options: selectboxOptions as any });

        const textboxOptions: dxTextBoxOptions = {
            inputAttr: { autoComplete: false },
            spellcheck: true,
            valueChangeEvent: 'input'
        };
        dxTextBox.defaultOptions({ options: textboxOptions as any });

        const textareaOptions: dxTextAreaOptions = {
            valueChangeEvent: 'input'
        };
        dxTextArea.defaultOptions({ options: textareaOptions as any });

        const numberboxOptions: dxNumberBoxOptions = {
            showSpinButtons: false,
            valueChangeEvent: 'input'
        };
        dxNumberBox.defaultOptions({ options: numberboxOptions as any });

        const dateboxOptions: dxDateBoxOptions = {
            // valueChangeEvent: 'input'
        };
        dxDateBox.defaultOptions({ options: dateboxOptions as any });

        const tabPanelOptions: dxTabPanelOptions = {
            swipeEnabled: false
        };
        dxTabPanel.defaultOptions({ options: tabPanelOptions as any });}
}
