import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import validationEngine from 'devextreme/ui/validation_engine';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { CustomerType } from 'src/app/core/model/customer-type';
import { DumpsterInventory } from 'src/app/core/model/dumpster-inventory';
import { DumpsterLocation } from 'src/app/core/model/dumpster-location';
import { JobType } from 'src/app/core/model/job-type';
import { ServiceAddress } from 'src/app/core/model/service-address';
import { StorageDumpSite } from 'src/app/core/model/storage-dump-site';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-dumpster-location-dialog',
    templateUrl: './dumpster-location-dialog.component.html',
    styleUrls: ['./dumpster-location-dialog.component.scss']
})
export class DumpsterLocationDialogComponent extends BaseDialogComponent<any> implements OnInit {
    dumpsterInventory: DumpsterInventory;
    existingLocations: DumpsterLocation[];

    dumpsterLocation: DumpsterLocation;
    customers: any[];
    customersDS: DataSource;
    storageSites: StorageDumpSite[];
    storageSiteDS: DataSource;

    selectedCustomer: any;
    customerServiceAddresses: ServiceAddress[];
    selectedServiceAddress: ServiceAddress;
    selectedStorageSite: StorageDumpSite;

    constructor(private uow: CdrUnitOfWork, private auth: AuthService, private appInfo: AppInformationService) {
        super();
    }

    async ngOnInit() {
        this.isAddressSelected = this.isAddressSelected.bind(this);

        this.appInfo.pleaseWaitShow();
        await this.getStorageSites();
        await this.getCustomers();
        this.appInfo.pleaseWaitHide();
    }

    async getExistingLocations() {
        this.existingLocations = await this.uow.getActiveDumpsterLocations(this.dumpsterInventory.id);
    }

    async getCustomers() {
        this.customers = (await this.uow.customers.getActiveCustomers(null, 'id, typeId, companyName, firstName, lastName')) as any[];
        this.customers.forEach(customer => {
            customer.displayName =
                customer.typeId === CustomerType.Types.Commercial
                    ? customer.companyName
                    : [customer.firstName, customer.lastName].join(' ');
        });

        this.customersDS = new DataSource({
            store: this.customers as any[],
            paginate: true
        });
    }

    async getStorageSites() {
        this.storageSites = await this.uow.getActiveStorageDumpSites({ storage: true });

        this.storageSiteDS = new DataSource({
            store: this.storageSites as any[],
            paginate: true
        });
    }

    save = async () => {
        if (!this.valid()) return;

        this.appInfo.pleaseWaitShow();
        let placedJobId;
        if (this.selectedServiceAddress) {
            const lastServiceAddressJob = await this.uow.jobs.lastServiceAddressJob(this.selectedServiceAddress.id);
            if (!lastServiceAddressJob) {
                const success = await confirm(
                    'Cannot assign dumpster location to customer. Selected customer address has no related jobs. ' +
                        'Would you like to add this address as a note to the dumpster instead?',
                    this.appInfo.appTitle
                );
                if (success) {
                    this.dumpsterInventory.notes = `${
                        this.dumpsterInventory.notes ? this.dumpsterInventory.notes + '\n' : ''
                    }No job found. Dumpster stored at ${this.selectedServiceAddress.displayAddress}`;
                }
            } else {
                placedJobId = lastServiceAddressJob.id;
            }
        } else if (this.selectedStorageSite) {
            let lastStorageSiteJob = await this.uow.jobs.lastStorageDumpSiteJob(this.selectedStorageSite.id);
            if (!lastStorageSiteJob)
                lastStorageSiteJob = this.uow.jobs.createEntity({
                    jobTypeId: JobType.Types.Note,
                    created: new Date(),
                    createdBy: this.auth.userValue.userName,
                    completed: new Date(),
                    startDate: new Date(),
                    endDate: new Date(),
                    address1: this.selectedStorageSite.address1,
                    address2: this.selectedStorageSite.address2,
                    city: this.selectedStorageSite.city,
                    inventoryId: this.dumpsterInventory.id,
                    storageDumpSiteId: this.selectedStorageSite.id
                });
            placedJobId = lastStorageSiteJob.id;
        }

        if (placedJobId) {
            this.dumpsterLocation = this.uow.dumpsterLocations.createEntity({
                dumpsterInventoryId: this.dumpsterInventory.id,
                placedJobId: placedJobId,
                placed: new Date(),
                created: new Date(),
                createdBy: this.auth.userValue.userName
            });

            await this.getExistingLocations();
            this.existingLocations.forEach(location => {
                location.removed = new Date();
                location.removedJobId = placedJobId;
            });
        }

        await this.uow.commit();
        this.hide({ success: true, value: this.dumpsterLocation });
    };

    cancel = () => {
        this.uow.rollback();
        this.hide({ success: false });
    };

    async customerChanged(e) {
        this.customerServiceAddresses = [];
        if (e && e.value) {
            this.customerServiceAddresses = await this.uow.getActiveServiceAddresses(e.value.id);
            this.selectedStorageSite = null;
        }
    }

    storageSiteChanged(e) {
        if (e && e.value) {
            this.selectedCustomer = null;
            this.selectedServiceAddress = null;
            this.customerServiceAddresses = [];
        }
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }

    isAddressSelected(e) {
        return this.selectedServiceAddress || this.selectedStorageSite;
    }
}
