import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PendingChangesGuard, PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { JobDialogComponent } from './job-dialog/job-dialog.component';
import { RentalDetailComponent } from './rental-detail/rental-detail.component';
import { RentalFormDialogComponent } from './rental-form-dialog/rental-form-dialog.component';
import { RentalJobDialogComponent } from './rental-job-dialog/rental-job-dialog.component';
import { RentalOverviewComponent } from './rental-overview/rental-overview.component';
import { DispatchNoteModule } from '../dispatch-notes/dispatch-notes.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        DispatchNoteModule,
        RouterModule.forChild([
            {
                path: 'rentals',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: RentalOverviewComponent,
                        data: { title: 'Rental Overview' },
                    },
                    {
                        path: ':id',
                        component: RentalDetailComponent,
                        data: { title: 'Rental' },
                        canDeactivate: [PendingChangesGuard]
                    },
                ]
            }
        ])
    ],
    declarations: [RentalOverviewComponent, RentalDetailComponent, JobDialogComponent, RentalFormDialogComponent, RentalJobDialogComponent]
})
export class RentalModule {}
