import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import * as moment from 'moment';
import { EmailMessage, Statement } from 'src/app/core/model/entity-model';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { alert } from 'src/app/shared/shared.module';
import { AppInformationService, AuthService, CdrUnitOfWork, ReportService } from '../../core/core.module';

@Component({
    selector: 'app-statement-dialog',
    templateUrl: './statement-dialog.component.html',
    styleUrls: ['./statement-dialog.component.scss']
})
export class StatementDialogComponent
    extends BaseDialogComponent<{ success: boolean; value?: { statement: Statement; emailMessage?: EmailMessage } }>
    implements OnInit
{
    customerId: number;
    statement: Statement;
    emailMessage: EmailMessage;
    processing: boolean;

    constructor(
        private uow: CdrUnitOfWork,
        private auth: AuthService,
        private reportService: ReportService,
        private appInfo: AppInformationService
    ) {
        super();
    }

    async ngOnInit() {
        this.statement = this.uow.statements.createEntity({
            customerId: this.customerId,
            startDate: moment(new Date()).add(-30, 'days').startOf('day').toDate(),
            endDate: moment(new Date()).startOf('day').toDate(),
            created: new Date(),
            createdBy: this.auth.userValue.userName
        });

        // entity is created on save
        const emailMessage = new EmailMessage();
        emailMessage.subject = (await this.uow.getSettingsByName('StatementEmailSubject'))?.stringValue ?? '';
        emailMessage.body = (await this.uow.getSettingsByName('StatementEmailBody'))?.stringValue ?? '';
        emailMessage.created = new Date();
        emailMessage.createdBy = this.auth.userValue.userName;
        this.emailMessage = emailMessage;

        const customer = await this.uow.customers.byId(this.customerId);
        this.emailMessage.to = customer.email;
    }

    async save(saveEmailMessage: boolean = false) {
        const valid = this.valid();
        if (!valid) return false;

        if (saveEmailMessage) {
            if (!this.emailMessage.to) {
                await alert('A recipient email address is required for sending an email', this.appInfo.appTitle);
                return;
            }

            this.emailMessage = this.uow.emailMessages.createEntity({
                subject: this.emailMessage.subject,
                body: this.emailMessage.body,
                to: this.emailMessage.to,
                from: this.emailMessage.from,
                bcc: this.emailMessage.bcc,
                cc: this.emailMessage.cc,
                created: this.emailMessage.created,
                createdBy: this.emailMessage.createdBy,
                statementId: this.statement.id
            });
        }
        await this.uow.commit();
        return true;
    }

    print = async () => {
        if (this.processing) return;
        this.processing = true;

        const success = await this.save();
        if (!success) {
            this.processing = false;
            return;
        }

        this.hide({ success: true, value: { statement: this.statement } });
    };

    email = async () => {
        if (this.processing) return;
        this.processing = true;

        const success = await this.save(true);
        if (!success) {
            this.processing = false;
            return;
        }

        this.hide({ success: true, value: { statement: this.statement, emailMessage: this.emailMessage } });
    };

    cancel = async () => {
        if (this.processing) return;
        this.processing = true;

        this.uow.rollback();
        this.hide({ success: false });
    };

    async openReport(e) {
        if (this.processing) return;

        const success = await this.save();
        if (!success) return;

        await this.reportService.printStatementReport(this.statement.id);
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
