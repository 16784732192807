import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService, CdrUnitOfWork, SessionInformationService, UtilityService } from 'src/app/core/core.module';

@Component({
    selector: 'app-revenue-forecast',
    templateUrl: './revenue-forecast.component.html',
    styleUrls: ['./revenue-forecast.component.scss']
})
export class RevenueForecastComponent implements OnInit {
    filter = { startDate: new Date(), endDate: new Date() };
    showPreviousWeek: boolean;
    revenue: any[] = [];
    tonsDumped: { jobs: any[]; categories: any[] };
    jobCounts: { jobCounts: any[]; totalCount: number };

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private sessionInfo: SessionInformationService,
        private utility: UtilityService
    ) {}

    async ngOnInit() {
        const filter = this.sessionInfo.getItem('revenue-forecast-filter');
        if (filter) this.filter = filter;
        else this.setFilter();
        await this.getData();
    }

    async getData() {
        if (!this.filter.startDate || !this.filter.endDate) {
            this.revenue = null;
            this.tonsDumped = null;
            this.jobCounts = null;
            return;
        }

        this.appInfo.pleaseWaitShow();
        this.revenue = await this.uow.jobs.getEstimatedRevenue(this.filter.startDate, this.filter.endDate);
        this.tonsDumped = await this.uow.jobs.getTonsDumped(this.filter.startDate, this.filter.endDate);
        this.jobCounts = await this.uow.jobs.getJobCounts(this.filter.startDate, this.filter.endDate);
        this.sessionInfo.setItem('revenue-forecast-filter', this.filter);
        this.appInfo.pleaseWaitHide();
    }

    async toggleShowPreviousWeek() {
        this.showPreviousWeek = !this.showPreviousWeek;
        this.setFilter();
        await this.getData();
    }

    setFilter() {
        const start = new Date();
        // 0 = Sun, 1 = Mon, etc.
        const day = start.getDay();
        // -6 if day is Sun; otherwise +1
        let daysPastMonday = start.getDate() - day + (day === 0 ? -6 : 1);
        if (this.showPreviousWeek) daysPastMonday -= 7;
        start.setDate(daysPastMonday);
        const end = moment(start).add(6, 'days').toDate();

        this.filter.startDate = start;
        this.filter.endDate = end;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'refresh',
                    text: 'Reset Filters',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            }
        );
    }

    async createRevenueLines(selectedRows: any) {
        if (!selectedRows) return;
        const jobIds = selectedRows.map(x => {
            return x.id;
        })
        console.log(jobIds);

        this.appInfo.pleaseWaitShow();
        for (let index = 0; index < jobIds.length; index++) {
            const jobId = jobIds[index];
            await this.utility.generateRevenueLines(jobId);
        }

        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
