import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-driver-job-dialog',
    templateUrl: './driver-job-dialog.component.html',
    styleUrls: ['./driver-job-dialog.component.scss']
})
export class DriverJobDialogComponent extends BaseDialogComponent<any> implements OnInit {
    job: Job;
    jobTypes: JobType[];

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService) {
        super();
    }

    ngOnInit() {
        this.jobTypes = this.uow.lookups.jobTypes as JobType[];
    }

    complete = async () => {
        this.close(true);
    };

    save = async () => {
        this.close(false);
    };

    close(complete: boolean = false) {
        this.hide({ success: true, value: { job: this.job, completed: complete } });
    }

    cancel = async () => {
        this.hide({ success: false });
    };
}
