import { Component, OnInit } from '@angular/core';
import { AppInformationService, AuthService } from 'src/app/core/core.module';
import { DialogService } from 'src/app/shared/dialog.service';
import { DispatchNotesDialogComponent } from 'src/app/shared/dispatch-notes-dialog/dispatch-notes-dialog.component';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    showClaims = false;
    user$ = this.auth.user$;
    account$ = this.auth.account$;

    constructor(public appInfo: AppInformationService, private auth: AuthService, private dialogService: DialogService) {}

    async ngOnInit() {}

    login() {
        this.auth.login();
    }

    logout() {
        this.auth.logout();
    }

    async openRentalForm() {
        const dialogRef = this.dialogService.open(DispatchNotesDialogComponent);
        const results = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);
    }
}
