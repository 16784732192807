<div class="header-label" *ngIf="invoice">{{ 'Invoice #' + invoice?.invoiceNumber }}</div>
<div class="flex-fill d-flex flex-column" *ngIf="invoice">
    <div class="dx-card responsive-paddings">
        <div class="row">
            <div class="col-7">
                <button (click)="save()" class="btn btn-success mr-2" *ngIf="hasChanges()">Save</button>
                <button (click)="cancel()" class="btn btn-danger" *ngIf="hasChanges()">Cancel</button>
                <button (click)="close()" class="btn btn-primary" *ngIf="!hasChanges()">Close</button>
                <button *ngIf="!hasChanges() && invoice?.statusId === 1" class="btn btn-danger ml-5 mr-2" (click)="deleteInvoice()">
                    Delete Invoice
                </button>
                <button *ngIf="!hasChanges() && invoice?.statusId !== 1" class="btn btn-danger ml-5 mr-2" (click)="voidInvoice()">
                    Void Invoice
                </button>
                <button *ngIf="!hasChanges()" (click)="printInvoice()" class="btn btn-primary ml-5 mr-2">Print Invoice</button>
                <button
                    (click)="sendForReview(invoice)"
                    class="btn btn-primary"
                    *ngIf="!hasChanges() && !readOnly && invoice.statusId !== 5"
                >
                    Send For Review
                </button>
                <button
                    (click)="changeStatusAndNext()"
                    class="btn btn-primary mr-2"
                    *ngIf="!hasChanges() && !readOnly && invoice.statusId === 5"
                >
                    Add to Queue & Next
                </button>
                <button
                    (click)="changeStatusAndNext(true)"
                    class="btn btn-primary"
                    *ngIf="!hasChanges() && !readOnly && invoice.statusId === 5"
                >
                    Reject & Next
                </button>
            </div>
            <div class="col-5 d-flex align-items-center ml-auto">
                <div class="d-flex ml-auto mr-1 mt-2">
                    <div class="d-flex align-items-center mr-5">
                        <label class="mt-2 mr-2">Invoice Date</label>
                        <dx-date-box [(value)]="invoice.invoiceDate" [readOnly]="readOnly">
                            <dx-validator validationGroup="invoiceValidationGroup">
                                <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
                            </dx-validator>
                        </dx-date-box>
                    </div>
                    <div class="d-flex align-items-center">
                        <label class="mr-2">Status</label>
                        <h4 class="mr-2">
                            <strong>{{ invoice.status?.name }}</strong>
                        </h4>
                        <button *ngIf="readOnly" class="btn btn-sm btn-danger ml-2" (click)="editInvoice()">Edit Invoice</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" class="p-2">
            <dx-form *ngIf="invoice" [formData]="invoice" colCount="4" validationGroup="invoiceValidationGroup">
                <dxi-item itemType="group" caption="Site Info">
                    <dxi-item *ngIf="!rentalId && key === 'new'" [label]="{ text: 'Customer' }">
                        <div *dxTemplate>
                            <dx-select-box
                                [(value)]="invoice.customerId"
                                [dataSource]="customersDS"
                                valueExpr="id"
                                displayExpr="displayName"
                                searchEnabled="true"
                                showClearButton="true"
                                (onValueChanged)="onCustomerChanged($event)"
                            ></dx-select-box>
                        </div>
                    </dxi-item>
                    <dxi-item *ngIf="!rentalId && key === 'new'" [label]="{ text: 'Address' }">
                        <div *dxTemplate>
                            <dx-select-box
                                [(value)]="invoice.serviceAddressId"
                                [dataSource]="customerLocations"
                                valueExpr="id"
                                displayExpr="displayAddress"
                                (onValueChanged)="onSiteLocationChanged($event)"
                            ></dx-select-box>
                        </div>
                    </dxi-item>
                    <dxi-item>
                        <div *dxTemplate>
                            <h3 class="mt-0 mb-1">{{ invoice?.customer?.displayName }}</h3>
                            <div>{{ invoice?.serviceAddress1 }}</div>
                            <div>{{ invoice?.serviceAddress2 }}</div>
                            <div>
                                {{ invoice?.serviceCity ? invoice.serviceCity + ',' : '' }}
                                {{ invoice?.serviceState }}
                                {{ invoice?.serviceZip }}
                                {{ invoice?.serviceCountry }}
                            </div>
                            <div *ngIf="hasHaulingRates">Distance: {{ invoice?.rental?.serviceAddress?.distance }}</div>
                            <div>Range: {{ invoice?.rental?.serviceAddress?.range }}</div>
                            <div class="mt-3">
                                <strong>Rental Period: {{ rentalPeriod }}</strong>
                            </div>
                        </div>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Payment Info">
                    <dxi-item
                        dataField="paymentMethod"
                        [label]="{ text: 'Method' }"
                        editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: paymentMethods, readOnly: readOnly, onValueChanged: paymentMethodChanged }"
                    >
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="3">
                        <dxi-item
                            dataField="creditCardId"
                            [label]="{ text: 'Credit Card' }"
                            editorType="dxSelectBox"
                            [editorOptions]="{
                                items: creditCards,
                                displayExpr: 'name',
                                valueExpr: 'id',
                                readOnly: readOnly,
                                onValueChanged: creditCardChanged
                            }"
                            [visible]="invoice.paymentMethod === 'Credit-Debit'"
                        ></dxi-item>
                        <dxi-item
                            dataField="creditCardPercentage"
                            [label]="{ text: 'Percentage' }"
                            editorType="dxNumberBox"
                            [editorOptions]="{ readOnly: true }"
                            [visible]="invoice.paymentMethod === 'Credit-Debit'"
                        ></dxi-item>
                        <dxi-item
                            dataField="creditCardFee"
                            [label]="{ text: 'Processing Fee' }"
                            [editorOptions]="{ format: { type: 'currency', precision: 4 }, readOnly: true }"
                            [visible]="invoice.paymentMethod === 'Credit-Debit'"
                        >
                        </dxi-item>
                    </dxi-item>
                    <dxi-item
                        dataField="terms"
                        [label]="{ text: 'Terms' }"
                        editorType="dxSelectBox"
                        [editorOptions]="{
                            dataSource: terms,
                            valueExpr: 'name',
                            displayExpr: 'name',
                            onSelectionChanged: onTermsChanged,
                            readOnly: readOnly
                        }"
                    >
                    </dxi-item>
                </dxi-item>

                <dxi-item itemType="group" caption="Total">
                    <!-- <dxi-item
                        dataField="totalWithTaxMinusFee"
                        [label]="{ text: 'Subtotal' }"
                        [editorOptions]="{ format: { type: 'currency', precision: 2 }, readOnly: true }"
                        [visible]="invoice.paymentMethod === 'Credit-Debit'"
                    >
                    </dxi-item>
                    <dxi-item
                        dataField="creditCardFee"
                        [label]="{ text: 'Processing Fee' }"
                        [editorOptions]="{ format: { type: 'currency', precision: 4 }, readOnly: true }"
                        [visible]="invoice.paymentMethod === 'Credit-Debit'"
                    >
                    </dxi-item> -->
                    <dxi-item
                        dataField="totalWithTax"
                        [label]="{ text: 'Total' }"
                        [editorOptions]="{ format: { type: 'currency', precision: 2 }, readOnly: true }"
                    >
                    </dxi-item>
                    <dxi-item dataField="unpaidBalance" [editorOptions]="{ format: { type: 'currency', precision: 2 }, readOnly: true }">
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Great Plains Info">
                    <dxi-item dataField="quickBooksId" [label]="{ text: 'System' }" [editorOptions]="{ readOnly: true }"> </dxi-item>
                    <dxi-item dataField="quickBooksLastExport" [label]="{ text: 'Last Exported' }" [editorOptions]="{ readOnly: true }">
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </div>
    </div>
    <div class="d-flex flex-fill flex-row dx-card responsive-paddings">
        <div class="d-flex col-sm-9">
            <dx-data-grid
                #invoiceLineGrid
                keyExpr="id"
                [dataSource]="invoiceLines"
                [showBorders]="true"
                [showRowLines]="true"
                [rowAlternationEnabled]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column
                    dataField="lineNumber"
                    caption="No."
                    [visible]="true"
                    width="80"
                    [sortIndex]="1"
                    [sortOrder]="'asc'"
                ></dxi-column>
                <dxi-column dataField="description" width="325"></dxi-column>
                <dxi-column dataField="service" width="165"></dxi-column>
                <dxi-column dataField="weight" width="100"></dxi-column>
                <dxi-column dataField="quantity" width="100"></dxi-column>
                <dxi-column dataField="rate" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="quickBooksProduct.quickBooksProductService" caption="Account" width="125"></dxi-column>
                <dxi-column dataField="note"></dxi-column>

                <dxo-selection [mode]="'multiple'"></dxo-selection>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-lines-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-secondary mr-1 mb-2"
                        (click)="move(invoiceLineGrid.instance.getSelectedRowsData()[0], 'up')"
                        [disabled]="readOnly || invoiceLineGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                    </button>
                    <button
                        class="btn btn-sm btn-secondary mr-2 mb-2"
                        (click)="move(invoiceLineGrid.instance.getSelectedRowsData()[0], 'down')"
                        [disabled]="readOnly || invoiceLineGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-sm btn-success mr-1 mb-2" (click)="addInvoiceLine(null, true)" [disabled]="readOnly">
                        Add Line
                    </button>
                    <button
                        class="btn btn-sm btn-danger mr-4 mb-2"
                        (click)="removeInvoiceLine(invoiceLineGrid.instance.getSelectedRowsData())"
                        [disabled]="readOnly"
                    >
                        Remove
                    </button>

                    <button class="btn btn-sm btn-success mr-2 mb-2" (click)="addKentFee()" [disabled]="readOnly || !invoiceLines">
                        Add Kent Surcharge
                    </button>
                    <button class="btn btn-sm btn-success mr-2 mb-2" (click)="addFuelCharge()" [disabled]="readOnly || !invoiceLines">
                        Add Fuel Charge
                    </button>
                </div>
            </dx-data-grid>
        </div>
        <div class="d-flex col-sm-3">
            <dx-data-grid
                #dumpsterFeesGrid
                keyExpr="id"
                [dataSource]="jobDumpsterFees"
                [showBorders]="true"
                [showRowLines]="true"
                [rowAlternationEnabled]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="jobId" width="55"></dxi-column>
                <dxi-column dataField="dumpsterFeeType.name" caption="Type" width="150"></dxi-column>
                <dxi-column dataField="quantity" width="65"></dxi-column>
                <dxi-column dataField="invoiceLines[0].id" caption="Invoice Line Id" width="100"></dxi-column>
                <dxi-column dataField="note"></dxi-column>

                <dxo-selection mode="single"></dxo-selection>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="dumpster-fees-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-success mr-1 mb-2"
                        (click)="addDumpsterFeeLine(dumpsterFeesGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="
                            readOnly ||
                            dumpsterFeesGrid.instance.getSelectedRowsData().length !== 1 ||
                            dumpsterFeesGrid.instance.getSelectedRowsData()[0].invoiceLines[0]
                        "
                    >
                        Add To Invoice
                    </button>
                    <button
                        class="btn btn-sm btn-primary mr-1 mb-2"
                        (click)="editDumpsterFeeNote(dumpsterFeesGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="readOnly || dumpsterFeesGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        Edit Note
                    </button>
                    <!-- <button class="btn btn-sm btn-success mr-1 mb-2" (click)="addInvoiceLine(null, true)" [disabled]="readOnly">
                        Add Line
                    </button> -->
                    <!-- <button
                        class="btn btn-sm btn-danger mr-4 mb-2"
                        (click)="removeInvoiceLine(dumpsterFeesGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="readOnly || dumpsterFeesGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        Remove
                    </button> -->
                </div>
            </dx-data-grid>
        </div>
    </div>
    <div class="dx-card responsive-paddings">
        <dx-form [formData]="invoice" colCount="2">
            <dxi-item
                dataField="note"
                editorType="dxTextArea"
                [label]="{ text: 'Invoice Notes' }"
                [editorOptions]="{ height: 100, readOnly: readOnly }"
            >
            </dxi-item>
            <dxi-item
                dataField="privateNote"
                editorType="dxTextArea"
                [label]="{ text: 'Private Notes' }"
                [editorOptions]="{ height: 100, readOnly: readOnly }"
            >
            </dxi-item>
        </dx-form>
    </div>
</div>
