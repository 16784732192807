// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { DumpsterFeeType } from './dumpster-fee-type';
import { InvoiceLine } from './invoice-line';
import { Job } from './job';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class JobDumpsterFee extends BaseEntity  {

  /// <code> Place custom code between <code> tags

  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  created: Date;
  createdBy: string;
  deleted: Date;
  deletedBy: string;
  dumpsterFeeTypeId: number;
  jobId: number;
  note: string;
  quantity: number;
  dumpsterFeeType: DumpsterFeeType;
  invoiceLines: InvoiceLine[];
  job: Job;
}

