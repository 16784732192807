<div class="flex-fill d-flex flex-column">
    <div class="flex-fill content-block dx-card responsive-paddings">
        <div class="d-flex flex-fill">
            <dx-data-grid
                #overviewGrid
                keyExpr="userId"
                [dataSource]="users"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column dataField="userName" width="150"></dxi-column>
                <dxi-column dataField="firstName" width="150"></dxi-column>
                <dxi-column dataField="lastName" width="150"></dxi-column>
                <dxi-column dataField="isActive" width="100"></dxi-column>
                <dxi-column dataField="email" width="225"></dxi-column>
                <dxi-column dataField="displayRoles" caption="Roles" width="225"></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="user-overview-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        type="button"
                        class="btn btn-primary mr-1 mb-2"
                        (click)="open(overviewGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                    >
                        Edit
                    </button>
                    <button type="button" class="btn btn-success mr-2 mb-2" (click)="open()">Add</button>
                </div>
            </dx-data-grid>
        </div>
        <div class="autocomplete-container" id="autocomplete-container"></div>
    </div>
</div>
