// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class InvoiceStatus extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static Statuses = {
    New: 1,
    InProcess: 2,
    Complete: 3,
    Posted: 4,
    Review: 5,
    Reviewed: 6,
    Queue: 7,
    Rejected: 8,
    Paid: 9,
    Emailed: 10,
    Printed: 11,
    PartiallyPaid: 12,
    WaitingToEmail: 13,
    Voided: 14
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  deleted: Date;
  displayOrder: number;
  name: string;
}

