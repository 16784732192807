import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { JobNoteDialogComponent } from './job-note-dialog/job-note-dialog.component';
import { ScheduleOverviewComponent } from './schedule-overview/schedule-overview.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'schedule',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: ScheduleOverviewComponent,
                        data: { title: 'Dispatch Overview' },
                    },
                ]
            }
        ])
    ],
    declarations: [ScheduleOverviewComponent, JobNoteDialogComponent]
})
export class ScheduleModule {}
