import { Component } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService } from 'src/app/core/app-information.service';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { SessionInformationService } from 'src/app/core/session-information.service';

@Component({
    selector: 'app-revenue-by-type',
    templateUrl: './revenue-by-type.component.html',
    styleUrls: ['./revenue-by-type.component.scss']
})
export class RevenueByTypeComponent {
    filter = { startDate: new Date(), endDate: new Date() };
    showPreviousWeek: boolean;
    revenueByType: any[];

    constructor(private appInfo: AppInformationService, private uow: CdrUnitOfWork, private sessionInfo: SessionInformationService) {}

    async ngOnInit() {
        const filter = this.sessionInfo.getItem('revenue-by-type-filter');
        if (filter) this.filter = filter;
        else this.setFilter();
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        this.revenueByType = await this.uow.getRevenueByType(this.filter.startDate, this.filter.endDate);
        this.revenueByType.forEach(r => r.completed = moment(r.completed).toDate());
        this.sessionInfo.setItem('revenue-by-type-filter', this.filter);
        this.appInfo.pleaseWaitHide();
    }

    async toggleShowPreviousWeek() {
        this.showPreviousWeek = !this.showPreviousWeek;
        this.setFilter();
        await this.getData();
    }

    setFilter() {
        const start = new Date();
        // 0 = Sun, 1 = Mon, etc.
        const day = start.getDay();
        // -6 if day is Sun; otherwise +1
        let daysPastMonday = start.getDate() - day + (day === 0 ? -6 : 1);
        if (this.showPreviousWeek) daysPastMonday -= 7;
        start.setDate(daysPastMonday);
        const end = moment(start).add(6, 'days').toDate();

        this.filter.startDate = start;
        this.filter.endDate = end;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
             {
                 template: 'toolbarHeaders',
                 location: 'before'
             },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }
}
