<div class="header-label">{{ serviceAddress?.displayName }}</div>
<div class="flex-fill d-flex flex-column">
    <div class="flex-fill content-block dx-card p-3">
        <dx-form [formData]="serviceAddress" class="col-sm-6" style="max-width: 500px" [readOnly]="true" *ngIf="serviceAddress">
            <dxi-item itemType="group" caption="Site Information">
                <dxi-item dataField="customer.displayName" [label]="{ text: 'Customer' }"></dxi-item>
                <dxi-item dataField="customer.type.name" [label]="{ text: 'Type' }"></dxi-item>
                <dxi-item dataField="displayAddress" [label]="{ text: 'Site Address' }"></dxi-item>
            </dxi-item>
        </dx-form>
        <div class="flex-fill" *ngIf="jobs">
            <dx-data-grid
                #jobGrid
                keyExpr="id"
                class="h-100"
                [dataSource]="jobs"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="id" caption="Job #" width="100"></dxi-column>
                <dxi-column dataField="rental.customer.displayName" caption="Customer" width="225"></dxi-column>
                <dxi-column dataField="jobType.name" caption="Job Type" width="150"></dxi-column>
                <dxi-column dataField="driver.displayName" caption="Driver" width="150"></dxi-column>
                <dxi-column dataField="dumpster.size" caption="Dumpster" width="75"></dxi-column>
                <dxi-column dataField="weight" width="100"></dxi-column>
                <dxi-column dataField="startDate" dataType="date" format="M/d/yyyy" width="150"></dxi-column>
                <dxi-column dataField="endDate" dataType="date" format="M/d/yyyy" width="150"></dxi-column>
                <dxi-column dataField="completed" dataType="date" format="M/d/yyyy" width="150"></dxi-column>
                <dxi-column dataField="driverNotes" caption="Notes"></dxi-column>

                <div *dxTemplate="let data of 'gridTitle'">
                    <h4>Jobs</h4>
                </div>
                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            </dx-data-grid>
        </div>
    </div>
</div>
