import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-ar-total-dialog',
    templateUrl: './ar-total-dialog.component.html',
    styleUrls: ['./ar-total-dialog.component.scss']
})
export class ARTotalDialogComponent extends BaseDialogComponent<any> implements OnInit {
    arTotal: any;

    constructor() {
        super();
    }

    async ngOnInit() {
        console.log(this.arTotal);
        this.arTotal.Invoices.forEach(invoice => {
            if (invoice.DueDate) invoice.due = moment(invoice.DueDate).toDate();
        });
    }

    close = (): void => {
        this.hide({ success: true });
    };
}
