<dx-popup
    title="Audit History"
    [width]="800"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Close', type: 'success', onClick: close }"></dxi-toolbar-item>
    <div *ngIf="auditLogs">
        <div class="d-flex flex-fill">
            <dx-data-grid
                #auditLogGrid
                [dataSource]="auditLogs"
                keyExpr="id"
                class="d-flex flex-fill"
                height="300"
                (onSelectionChanged)="selectionChange($event)"
            >
                <dxi-column dataField="when" caption="Date" [dataType]="'datetime'" width="150"></dxi-column>
                <dxi-column dataField="entityTypeName" caption="Table" width="150"></dxi-column>
                <dxi-column dataField="action" width="150"></dxi-column>
                <dxi-column dataField="userName" caption="User"></dxi-column>

                <dxo-selection mode="single"></dxo-selection>
            </dx-data-grid>
        </div>
        <div class="d-flex flex-fill mt-3">
            <dx-data-grid #auditLogChangesGrid [dataSource]="auditLogData?.Changes" class="d-flex flex-fill" height="300">
                <dxi-column dataField="ColumnName" caption="Field" width="150"></dxi-column>
                <dxi-column dataField="OriginalValue" caption="Old Value"></dxi-column>
                <dxi-column dataField="NewValue" caption="New Value"></dxi-column>

                <dxo-selection mode="single"></dxo-selection>
            </dx-data-grid>
        </div>
    </div>
</dx-popup>
