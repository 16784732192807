<dx-form [formData]="dispatchNote" *ngIf="dispatchNote" colCount="2" [readOnly]="readOnly">
    <dxi-item itemType="group">
        <dxi-item dataField="companyName" [label]="{ text: 'Customer Name/Company Name' }"> </dxi-item>
        <dxi-item dataField="siteName"> </dxi-item>
        <dxi-item
            dataField="jobType"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: jobTypes, displayExpr: 'name', valueExpr: 'id' }"
        >
        </dxi-item>
        <dxi-item [label]="{ text: 'Dumpster Size' }">
            <div *dxTemplate>
                <dx-radio-group
                    [items]="dumpsters"
                    [(value)]="dispatchNote.dumpsterType"
                    valueExpr="id"
                    displayExpr="size"
                    layout="horizontal"
                    [readOnly]="readOnly"
                >
                </dx-radio-group>
            </div>
        </dxi-item>
        <dxi-item dataField="rentalDate" editorType="dxDateBox"> </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="rentalTime"> </dxi-item>
            <dxi-item>
                <div *dxTemplate>
                    <dx-radio-group
                        [(value)]="selectedHourSegment"
                        [items]="['AM', 'PM']"
                        (onValueChanged)="rentalHourSegmentChanged($event)"
                        layout="horizontal"
                        [readOnly]="readOnly"
                        class="mt-4"
                    >
                    </dx-radio-group>
                </div>
            </dxi-item>
        </dxi-item>
        <dxi-item
            dataField="preferredCommunication"
            [label]="{ text: 'Preferred Communication Method' }"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: ['Mail', 'Email'] }"
        >
        </dxi-item>
        <dxi-item dataField="paymentTerms" editorType="dxSelectBox" [editorOptions]="{ dataSource: ['COD', 'CC'] }"> </dxi-item>
    </dxi-item>
    <dxi-item itemType="group">
        <dxi-item
            dataField="billingSameAsService"
            [label]="{ text: 'Service Address Same As Billing' }"
            editorType="dxCheckBox"
            colSpan="2"
        >
        </dxi-item>


        <dxi-item [label]="{ text: 'Address Lookup' }" *ngIf="dispatchNote">
            <div *dxTemplate>
                <app-address-lookup (onLocationChanged)="locationChanged($event)"></app-address-lookup>
            </div>
        </dxi-item>

        <dxi-item dataField="address1"> </dxi-item>
        <dxi-item dataField="address2"> </dxi-item>
        <dxi-item dataField="city"> </dxi-item>
        <dxi-item itemType="group" colCount="2">
            <dxi-item dataField="state"> </dxi-item>
            <dxi-item dataField="zip"> </dxi-item>
        </dxi-item>
        <dxi-item dataField="phone" [label]="{ text: 'Phone #' }"> </dxi-item>
        <dxi-item dataField="email"></dxi-item>
        <dxi-item dataField="notes" [label]="{ text: 'Customer Notes' }" editorType="dxTextArea" [editorOptions]="{ height: '75px' }">
        </dxi-item>
    </dxi-item>
</dx-form>
