import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DriverJobDialogComponent } from './driver-job-dialog/driver-job-dialog.component';
import { DriverScheduleComponent } from './driver-schedule/driver-schedule.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'drivers',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'schedule', pathMatch: 'full' },
                    {
                        path: 'schedule',
                        component: DriverScheduleComponent,
                        data: { title: 'Driver Schedule' }
                    }
                ]
            }
        ])
    ],
    declarations: [DriverScheduleComponent, DriverJobDialogComponent]
})
export class DriverModule {}
