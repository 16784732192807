import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { CustomerType, Job, ServiceAddress, User } from 'src/app/core/model/entity-model';
import { JobType } from 'src/app/core/model/job-type';

@Component({
    selector: 'app-site-detail',
    templateUrl: './site-detail.component.html',
    styleUrls: ['./site-detail.component.scss']
})
export class SiteDetailComponent implements OnInit {
    key: number;
    serviceAddress: ServiceAddress;
    jobs: Job[];

    customerTypes: CustomerType[];
    jobTypes: JobType[];
    drivers: User[];
    dumpsters: Dumpster[];

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
    ) {}

    async ngOnInit() {
        this.customerTypes = this.uow.lookups.customerTypes;
        this.jobTypes = this.uow.lookups.jobTypes;
        this.drivers = this.uow.lookups.users;
        this.dumpsters = this.uow.lookups.dumpsters;

        this.key = this.route.snapshot.params.id;

        await this.getData();
    }

    async getData() {
        this.serviceAddress = await this.uow.getServiceAddress(this.key, 'customer, rentals.jobs');
        this.jobs = _(this.serviceAddress.rentals).flatMap(rental => rental.jobs).value();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'gridTitle'
        });
    }
}
