// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags
import * as moment from 'moment';
/// </code-import>

export class DispatchNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  address1: string;
  address2: string;
  billingSameAsService: boolean;
  city: string;
  companyName: string;
  completed: Date;
  created: Date;
  createdBy: string;
  deleted: Date;
  dumpsterType: number;
  email: string;
  jobType: number;
  modified: Date;
  notes: string;
  paymentTerms: string;
  phone: string;
  preferredCommunication: string;
  rentalAM: boolean;
  rentalDate: Date;
  rentalPM: boolean;
  rentalTime: string;
  siteName: string;
  state: string;
  zip: string;
}

