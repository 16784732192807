// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { DumpsterFeeTypeDumpsterLink } from './dumpster-fee-type-dumpster-link';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class DumpsterFeeType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get displayLinks() {
    if (!this.dumpsterFeeTypeDumpsterLinks) return '';

    return this.dumpsterFeeTypeDumpsterLinks
        .map((link) => link.dumpster?.size)
        .join(' -- ');
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  deleted: Date;
  displayOrder: number;
  name: string;
  price: number;
  visibleForDropOff: boolean;
  visibleForPickup: boolean;
  dumpsterFeeTypeDumpsterLinks: DumpsterFeeTypeDumpsterLink[];
}

