import { Component, inject } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Photo } from 'src/app/core/model/photo';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
    selector: 'app-photo-dialog',
    templateUrl: './photo-dialog.component.html',
    styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent extends BaseDialogComponent<any> {
    uow = inject(CdrUnitOfWork);
    appInfo = inject(AppInformationService);

    photo: Photo;

    save = async () => {
        const success = this.valid();
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        await this.uow.commit([this.photo]);
        this.appInfo.pleaseWaitHide();
        this.hide({ success: true });
    };

    cancel = () => {
        this.photo.entityAspect.rejectChanges();
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('photoDialogValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('photoDialogValidationGroup');
            return result.isValid;
        } else return false;
    }
}
