import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { AppInformationService } from 'src/app/core/app-information.service';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { CustomerRate } from 'src/app/core/model/customer-rate';
import { RateType } from 'src/app/core/model/rate-type';
import { ServiceAddress } from 'src/app/core/model/service-address';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { DialogService, alert, confirm } from 'src/app/shared/shared.module';
import { RateDialogComponent } from '../rate-dialog/rate-dialog.component';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-service-address-dialog',
    templateUrl: './service-address-dialog.component.html',
    styleUrls: ['./service-address-dialog.component.scss']
})
export class ServiceAddressDialogComponent extends BaseDialogComponent<any> implements OnInit {
    serviceAddress: ServiceAddress;
    mode: 'range' | 'zone' = 'range';
    ranges = ['Regular', 'Extended', 'Outer'];
    zones = [1, 2, 3, 4];
    customerRates: CustomerRate[];
    customerHaulingOrZoneRates: CustomerRate[];
    selectedCustomerRate: CustomerRate;
    rateTypeDisplay: string;
    baseRateDisplay: string;
    pricePerTonDisplay: string;

    constructor(
        private appInfo: AppInformationService,
        private http: HttpClient,
        private uow: CdrUnitOfWork,
        private dialogService: DialogService
    ) {
        super();
    }

    async ngOnInit() {
        this.serviceAddress.range = this.serviceAddress.range ?? 'Regular';
        this.serviceAddress.zone = this.serviceAddress.zone ?? 1;
        this.customerRates = await this.uow.getCustomerRates({
            customerId: this.serviceAddress.customerId,
        });
        this.customerHaulingOrZoneRates = await this.uow.getCustomerRates({
            customerId: this.serviceAddress.customerId,
            rateTypeIds: [RateType.RateTypes.Hauling, RateType.RateTypes.Zone]
        });
        this.mode = this.customerHaulingOrZoneRates.some(rate => rate.rateTypeId === RateType.RateTypes.Zone) ? 'zone' : 'range';
        this.setPricePerTon(this.mode === 'range' ? this.serviceAddress.range : this.serviceAddress.zone);
    }

    save = async () => {
        const valid = this.valid();
        if (!valid) return;

        // clear hidden fields
        if (this.mode === 'range') this.serviceAddress.zone = null;
        else this.serviceAddress.range = null;

        if (this.serviceAddress.county) this.serviceAddress.county = this.serviceAddress.county.replace(' County', '');

        this.hide({ success: true, value: this.serviceAddress });
    };

    cancel = async () => {
        this.hide({ success: false });
    };

    async locationChanged(addressLookup) {
        if (!addressLookup) return;

        if (
            this.serviceAddress.address1 ||
            this.serviceAddress.address2 ||
            this.serviceAddress.city ||
            this.serviceAddress.state ||
            this.serviceAddress.zip ||
            this.serviceAddress.latitude ||
            this.serviceAddress.longitude
        ) {
            const result = await confirm(`Are you sure you want to override existing address data?`, `${this.appInfo.company}`);
            if (!result) {
                return;
            }
        }
        this.serviceAddress.address1 = !!addressLookup.addressLabel ? addressLookup.addressLabel : addressLookup.placeLabel;
        this.serviceAddress.address2 = addressLookup.county;
        this.serviceAddress.city = addressLookup.city;
        this.serviceAddress.state = addressLookup.stateCode;
        this.serviceAddress.zip = addressLookup.postalCode;
        this.serviceAddress.latitude = addressLookup.latitude;
        this.serviceAddress.longitude = addressLookup.longitude;
        this.serviceAddress.county = addressLookup.county.replace(' County', '');

        await this.setDistance();
    }

    async setDistance() {
        if (!this.serviceAddress.latitude || !this.serviceAddress.longitude) {
            await alert('Cannot set distance if latitude or longitude are not filled in', `${this.appInfo.company}`);
            return;
        }

        this.appInfo.pleaseWaitShow();
        const originCoordinates = '42.9129, -85.6794';
        const destinationCoordinates = `${this.serviceAddress.latitude},${this.serviceAddress.longitude}`;

        //USING RADAR API
        const RADAR_API_KEY = 'prj_live_sk_f46a1bf52a124b6b2eece299e2acc174dee88d58';
        const url = `https://api.radar.io/v1/route/distance?origin=${originCoordinates}&destination=${destinationCoordinates}&modes=car&units=imperial`;
        const headerOptions = {
            headers: new HttpHeaders({ Authorization: `${RADAR_API_KEY}` })
        };
        try {
            const distanceObject = await firstValueFrom(this.http.get(url, headerOptions));

            if (!!distanceObject) {
                const distance = (distanceObject as any).routes.car.distance.value / 5280;
                this.serviceAddress.distance = distance;
            }
        } catch (e) {
            await alert(`Error calculating distance. ${e.error.meta.message}`, `${this.appInfo.company}`);
        } finally {
            this.appInfo.pleaseWaitHide();
        }
    }

    radioButtonChanged(e) {
        this.setPricePerTon(e.value);
    }
    setPricePerTon(rangeOrZone: string | number) {
        this.selectedCustomerRate = this.customerHaulingOrZoneRates.find(rate =>
            this.mode === 'range' ? rate.range === rangeOrZone : rate.zone === rangeOrZone
        );
        if (this.selectedCustomerRate) {
            this.serviceAddress.pricePerTon = this.selectedCustomerRate.pricePerTon;
            this.rateTypeDisplay = '';
            this.baseRateDisplay = '';
            this.pricePerTonDisplay = `$${this.selectedCustomerRate.pricePerTon.toFixed(2)}`;
        } else {
            const rate = this.customerRates.find(rate =>
                this.mode === 'range' ? rate.range === rangeOrZone : rate.zone === rangeOrZone
            );
            if (rate && rate.rateTypeId === RateType.RateTypes.Dumpster) {
                this.rateTypeDisplay = 'Dumpster Rates (set per job)';
                this.baseRateDisplay = '';
                this.pricePerTonDisplay = '';
            } else {
                this.rateTypeDisplay = 'No matching rate found';
                this.baseRateDisplay = 'No matching rate found';
                this.pricePerTonDisplay = 'No matching rate found';
            }
        }
    }

    async addCustomerRate() {
        const dialogRef = this.dialogService.open(RateDialogComponent);
        dialogRef.instance.customerId = this.serviceAddress.customerId;
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success && result.value) {
            this.customerHaulingOrZoneRates.push(result.value);
            this.setPricePerTon(this.mode === 'range' ? this.serviceAddress.range : this.serviceAddress.zone);
        }
        this.dialogService.close(dialogRef);
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('serviceAddressValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('serviceAddressValidationGroup');
            return result.isValid;
        } else return false;
    }
}
