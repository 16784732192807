import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppInformationService, CdrUnitOfWork } from './core.module';
import { Invoice, InvoiceStatus, Payment, PaymentCredit, Rental, ServiceAddress } from './model/entity-model';
import * as moment from 'moment';
import { alert, confirm } from 'devextreme/ui/dialog';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    constructor(private auth: AuthService, private appInfo: AppInformationService, private uow: CdrUnitOfWork) {}

    async receivePayment(invoice: Invoice): Promise<boolean> {
        if (!invoice) return;

        let creditPayment;
        const credits = await this.checkForCredits(invoice);

        if (credits?.length > 0) {
            creditPayment = await this.applyCredits(invoice, credits[0]);
        }
        await this.uow.commit();

        if (!credits || credits.length === 0 || credits[0].amount < invoice.total) {
            return false;
        } else {
            await alert(`Payment Credits applied. Payment Id: ${creditPayment.id} has been updated.`, this.appInfo.appTitle);
            return true;
        }
    }

    async checkForCredits(invoice: Invoice): Promise<PaymentCredit[]> {
        const credits = await this.uow.getOpenPaymentCredits(invoice.customerId);
        if (!!credits && credits.length > 0) {
            const creditAmount = _.sum(
                credits.map(x => {
                    return x.amount;
                })
            );
            const result = await confirm(
                `A Payment Credit exists for Customer.  Amount: $${credits[0].amount}.  Do you want to apply it to the selected invoice(s)?`,
                this.appInfo.appTitle
            );
            if (!result) return null;
        }
        return credits;
    }

    async applyCredits(invoice: Invoice, credit: PaymentCredit): Promise<Payment> {
        const invoiceAmount = invoice.unpaidBalance;
        const creditPayment = await this.uow.payments.byId(credit.paymentId, 'paymentLines');
        const creditAmount = credit.amount;
        //create a new payment line for the invoice using the credit
        const paymentLine = this.uow.paymentLines.createEntity({
            paymentId: creditPayment.id,
            invoiceId: invoice.id,
            lineNumber: Payment.getNextLineNumber(creditPayment.paymentLines),
            description: `Inv-${invoice.invoiceNumber} - ${invoice.serviceAddress1}`,
            created: new Date(),
            createdBy: this.auth.userValue.userName,
            amount: invoiceAmount >= creditAmount ? creditAmount : invoiceAmount,
            notes: `Credit Id: ${credit.id} --- Applied ($${creditAmount})`
        });
        invoice.note = `Credit Id: ${credit.id} --- ($${creditAmount}) --- Applied ${moment(new Date()).format('MM/DD/yyyy')}`;

        credit.applied = new Date();
        credit.appliedBy = this.auth.userValue.userName;
        creditPayment.quickBooksExport = null;

        //reduce the amount of the overpayment line
        const overPaymentLine = creditPayment.paymentLines.find(x => {
            if (x.description === 'Invoice OverPayment Record') return x;
        });

        if (!!overPaymentLine) {
            overPaymentLine.amount -= invoiceAmount;
        }

        if (invoiceAmount <= creditAmount) {
            if (invoiceAmount < creditAmount) {
                //add another credit for the difference
                this.uow.paymentCredits.createEntity({
                    customerId: invoice.customerId,
                    paymentId: creditPayment.id,
                    amount: creditAmount - invoiceAmount,
                    created: new Date(),
                    createdBy: this.auth.userValue.userName
                });
            }
            invoice.statusId = InvoiceStatus.Statuses.Paid;
            invoice.paid = new Date();
        } else {
            invoice.statusId = InvoiceStatus.Statuses.PartiallyPaid;
        }
        return creditPayment;
    }
}
