import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { CreditCard } from 'src/app/core/model/credit-card';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-credit-card-dialog',
    templateUrl: './credit-card-dialog.component.html',
    styleUrls: ['./credit-card-dialog.component.scss']
})
export class CreditCardDialogComponent extends BaseDialogComponent<{ success: boolean; value?: CreditCard }> implements OnInit {
    creditCard: CreditCard;

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        if (!this.creditCard) this.creditCard = this.uow.creditCards.createEntity();
    }

    save = async () => {
        const success = this.valid();
        if (!success) return;

        await this.uow.commit();
        this.hide({ success: true, value: this.creditCard });
    };

    cancel = async () => {
        this.uow.rollback();
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
