import { Component, OnInit } from '@angular/core';
import { AppInformationService, CdrUnitOfWork, SessionInformationService, UtilityService } from 'src/app/core/core.module';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceLine } from 'src/app/core/model/invoice-line';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';
import { DialogService } from 'src/app/shared/dialog.service';
import { ARTotalDialogComponent } from '../ar-total-dialog/ar-total-dialog.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-ar-aging',
    templateUrl: './ar-aging.component.html',
    styleUrls: ['./ar-aging.component.scss']
})
export class ARAgingComponent implements OnInit {
    arAging: any[];
    arTotals: any[];


    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private sessionInfo: SessionInformationService,
        private utilityService: UtilityService,
        private dialogService: DialogService
    ) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        this.arTotals = await this.utilityService.getARTotals();
        this.arAging = await this.utilityService.getARAging();
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                template: 'toolbarHeaders',
                location: 'before'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async openARTotal(arTotal: any) {
        const dialogRef = this.dialogService.open(ARTotalDialogComponent);
        dialogRef.instance.arTotal = arTotal;
        const results = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);
    }
}
