import { DxDataGridComponent } from 'devextreme-angular';
import { SessionInformationService } from './session-information.service';

export class GridUtilityOptions {
    static SearchTextOptions = {
        doNotSave: 0,
        saveToSessionStorage: 1,
        saveToLocalStorage: 2
    };

    searchTextOption: number = GridUtilityOptions.SearchTextOptions.saveToLocalStorage;
}

export class GridUtility {
    initialState: any;
    gridUtilityOptions: GridUtilityOptions | null = null;

    constructor(
        public grid: DxDataGridComponent,
        storageKey: string,
        private sessionInfo: SessionInformationService,
        private options: GridUtilityOptions | null = null
    ) {
        grid.stateStoring = grid.stateStoring || {};
        grid.stateStoring.enabled = true;
        grid.stateStoring.storageKey = storageKey;
        grid.stateStoring.type = 'custom';
        grid.stateStoring.customSave = this.gridStateSave;
        grid.stateStoring.customLoad = this.gridStateLoad;
        this.gridUtilityOptions = options;
    }

    gridStateSave = (state: any) => {
        if (!this.grid.stateStoring.storageKey) return;

        const scrollView = this.grid.instance.getScrollable();
        if (scrollView) state.scrollPosition = scrollView.scrollOffset();
        this.sessionInfo.setItem(this.grid.stateStoring.storageKey, state, true);
        this.sessionInfo.setItem(this.grid.stateStoring.storageKey, state, false);
    };

    gridStateLoad = () => {
        if (!this.grid.stateStoring.storageKey) return;

        this.initialState = this.sessionInfo.getItem(this.grid.stateStoring.storageKey, true);
        if (this.initialState && this.grid.activeStateEnabled) this.initialState.selectedRowKeys = undefined;
        if (!!this.gridUtilityOptions) {
            if (this.gridUtilityOptions.searchTextOption == GridUtilityOptions.SearchTextOptions.saveToSessionStorage) {
                var initialStateLocal = this.sessionInfo.getItem(this.grid.stateStoring.storageKey, false);
                if (initialStateLocal && this.initialState) {
                    this.initialState.searchText = initialStateLocal.searchText;
                } else if (this.initialState) {
                    this.initialState.searchText = null;
                }
            } else if (this.initialState && this.gridUtilityOptions.searchTextOption == GridUtilityOptions.SearchTextOptions.doNotSave) {
                this.initialState.searchText = null;
            }
        }
        return this.initialState;
    };

    setScrollPosition() {
        setTimeout(() => {
            const scrollView = this.grid.instance.getScrollable();
            if (!scrollView) {
                console.warn('scrollView is undefined', this.grid);
                return;
            }

            const state = this.grid.instance.state();
            if (state.scrollPosition && scrollView) scrollView.scrollTo(state.scrollPosition);

            if (!this.grid.activeStateEnabled) this.grid.selectedRowKeys = state && state.selectedRowKeys;

            const onScroll = scrollView.option('onScroll');
            scrollView.option('onScroll', e => {
                this.gridStateSave(this.grid.instance.state());
                if (onScroll) onScroll(e);
            });
        }, 500);
    }

    saveGridKey(key) {
        if (this.grid.activeStateEnabled) this.grid.focusedRowKey = key;
        else this.grid.selectedRowKeys = [key];

        const state = this.grid.instance.state();
        this.gridStateSave(state);
    }
}
