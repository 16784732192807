import { Component, NgModule, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthService } from 'src/app/core/core.module';

@Component({
    selector: 'app-user-panel',
    templateUrl: 'user-panel.component.html',
    styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {
    user$ = this.auth.user$;

    @Input()
    menuItems: any;
    @Input()
    menuMode: string;

    constructor(private auth: AuthService) {}

    async ngOnInit() {}
}

@NgModule({
    imports: [DxListModule, DxContextMenuModule, CommonModule],
    declarations: [UserPanelComponent],
    exports: [UserPanelComponent]
})
export class UserPanelModule {}
