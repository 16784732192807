import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuickBooksService {
    serviceUrl: string;

    constructor(private http: HttpClient) {
        this.serviceUrl = environment.serviceName + '/quickbooks';
    }

    async isAuthorized() {
        const result = await firstValueFrom(this.http.post(`${this.serviceUrl}/isAuthorized`, {}));
        return result as boolean;
    }

    async authorize() {
        const result = await firstValueFrom(this.http.post(`${this.serviceUrl}/authorize`, {}));
        return result as any;
    }

    async disconnect() {
        const result = await firstValueFrom(this.http.post(`${this.serviceUrl}/disconnect`, {}));
        return result as string;
    }

    async authorizeCallback(code: string, realmId: number, state: string) {
        const result = await firstValueFrom(this.http.post(`${this.serviceUrl}/authorizeCallback`, { code, realmId, state }));
        return result as string;
    }

    async exportCustomer(code: string, realmId: number, state: string, accessToken: string) {
        const results = await firstValueFrom(this.http.post(`${this.serviceUrl}/exportCustomers`, { code, realmId, state, accessToken }));
        return results;
    }
    async exportToQuickBooksByIds(
        mode: string,
        code: string,
        realmId: number,
        state: string,
        accessToken: string,
        refreshToken: string,
        ids: number[]
    ) {
        const results = await firstValueFrom(
            this.http.post(`${this.serviceUrl}/exportToQuickBooksByIds`, { mode, code, realmId, state, accessToken, refreshToken, ids })
        );
        return results;
    }

    async exportInvoices(code: string, realmId: number, state: string, accessToken: string) {
        const results = await firstValueFrom(this.http.post(`${this.serviceUrl}/exportInvoices`, { code, realmId, state, accessToken }));
        return results;
    }

    async exportPayments(code: string, realmId: number, state: string, accessToken: string) {
        const results = await firstValueFrom(this.http.post(`${this.serviceUrl}/exportPayments`, { code, realmId, state, accessToken }));
        return results;
    }
}
