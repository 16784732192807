<div class="d-flex flex-column flex-fill dx-card">
    <div class="d-flex flex-row justify-content-center" style="max-height: 40px">
        <div class="ar-block" *ngFor="let arTotal of arTotals" (click)="openARTotal(arTotal)">
            <div class="ar-header">{{ arTotal.Name }}</div>
            <div class="ar-amount">{{ arTotal.Amount | currency }}</div>
        </div>
    </div>
    <div class="d-flex flex-row flex-fill p-2">
        <div class="d-flex flex-column flex-fill p-1">
            <dx-data-grid
                #invoiceGrid
                class="flex-fill d-flex flex-column"
                height="650px"
                [dataSource]="arAging"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="CustomerId" caption="Id" width="75"></dxi-column>
                <dxi-column dataField="CompanyName" caption="Customer" width="365" cellTemplate="customerLinkTemplate"></dxi-column>
                <dxi-column dataField="CreatedPreDec16" [format]="{ type: 'currency', precision: 2 }" width="150"></dxi-column>
                <dxi-column dataField="CurrentDue" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column dataField="Net30" caption="1-30" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column dataField="Net60" caption="31-60" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column dataField="Net90" caption="61-90" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column dataField="Net90Plus" caption="90+" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column dataField="Total" [format]="{ type: 'currency', precision: 2 }" width="115"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/customers/' + cell.data.CustomerId" [innerHtml]="cell.text"></a>
                </div>

                <div *dxTemplate="let data of 'toolbarHeaders'">
                    <h4 class="m-0">Invoices</h4>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="invoiceGrid" class="mr-3">
                        <strong>{{ invoiceGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>

                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-selection [mode]="'single'"></dxo-selection>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ar-aging-grid"></dxo-state-storing>

                <dxo-summary>
                    <dxi-total-item
                        column="CurrentDue"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="CreatedPreDec16"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="Net30"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="Net60"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="Net90"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="Total"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="Net90Plus"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
</div>
