import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import { CdrUnitOfWork } from './core.module';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        private uow: CdrUnitOfWork
    ) {}

    async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
        const canDeactivate = component.canDeactivate();
        if (canDeactivate) return true;

        const success = await confirm('Do you want to continue? Press No to go back and save, or Yes to lose these changes.', 'WARNING: You have unsaved changes');
        if (success && this.uow.hasChanges()) this.uow.rollback();
        return success;
    }
}
