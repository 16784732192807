import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService, CdrUnitOfWork, ReportService, SessionInformationService } from 'src/app/core/core.module';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';

@Component({
    selector: 'app-invoice-review',
    templateUrl: './invoice-review.component.html',
    styleUrls: ['./invoice-review.component.scss']
})
export class InvoiceReviewComponent implements OnInit {
    invoices: Invoice[];
    queueInvoices: Invoice[];
    invoiceStatuses: InvoiceStatus[];
    isInvoiceAdmin = false;

    @ViewChild('overviewGrid') overviewGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private reportService: ReportService,
        private sessionInfo: SessionInformationService,
        public auth: AuthService,

    ) {}

    async ngOnInit() {
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        this.isInvoiceAdmin = this.auth.hasRole('Invoice:Admin');
        await this.getData();
    }

    async getData() {
        this.invoices = await this.uow.getActiveInvoices({ statusId: InvoiceStatus.Statuses.Review, expand: 'customer' });
        this.queueInvoices = await this.uow.getActiveInvoices({ statusId: InvoiceStatus.Statuses.Queue, expand: 'customer' });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    onRowDoubleClick(e) {
        this.open(e.data?.id);
    }

    bulkApprove(rows: any[]) {
        if (!rows) return;

        const invoiceIds = rows.map(row => row.data.id);
        this.sessionInfo.setItem('bulk-approve-invoice-ids', invoiceIds);
        this.open(invoiceIds[0]);
    }

    open(id?: number) {
        this.router.navigate([`/invoices/${id ?? 'new'}`]);
    }

    openReport(invoices: Invoice[]) {
        const ids = invoices.map(invoice => invoice.id);
        this.reportService.printInvoiceReport(ids);
    }
}
