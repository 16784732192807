import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { default as validationEngine } from 'devextreme/ui/validation_engine';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, RentalService } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { DispatchNote } from 'src/app/core/model/dispatch-note';
import { Dumpster } from 'src/app/core/model/dumpster';
import { Job, JobType, Photo, ServiceAddress } from 'src/app/core/model/entity-model';
import { Rental } from 'src/app/core/model/rental';
import { PhotoService } from 'src/app/core/photo.service';
import { CustomerDetailDialogComponent } from 'src/app/customer/customer-detail-dialog/customer-detail-dialog.component';
import { ServiceAddressDialogComponent } from 'src/app/customer/service-address-dialog/service-address-dialog.component';
import { DispatchNotesDetailComponent } from 'src/app/shared/dispatch-notes-detail/dispatch-notes-detail.component';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { PhotoDialogComponent } from 'src/app/shared/photo-dialog/photo-dialog.component';
import { alert, confirm, DialogService } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-rental-detail',
    templateUrl: './rental-detail.component.html',
    styleUrls: ['./rental-detail.component.scss']
})
export class RentalDetailComponent implements OnInit {
    rental: Rental;
    jobs: Job[];
    key: string | number;
    customer: Customer;
    customers: Customer[];
    customersDS: DataSource;
    photos: Photo[];
    uploadUrl: string;
    uploadHeader: any;
    imageExtensions = PhotoService.ImageExtensions;

    dumpsters: Dumpster[];
    preferredWindowOptions = Rental.PreferredWindowOptions;
    dispatchNote: DispatchNote;
    canAddSite = false;
    canSaveAndStart = false;
    canUpdateCustomer = false;
    canChangeSiteAddress = false;

    @ViewChild('dispatchNotesDetailComponent') dispatchNotesDetailComponent: DispatchNotesDetailComponent;
    @ViewChild('jobGrid') jobGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private appInfo: AppInformationService,
        private auth: AuthService,
        private dialogService: DialogService,
        private rentalService: RentalService,
        private photoService: PhotoService
    ) {}

    canDeactivate() {
        return !this.hasChanges();
    }

    async ngOnInit() {
        this.dumpsters = this.uow.lookups.dumpsters;
        this.key = this.route.snapshot.params.id;
        const dispatchNoteId = this.route.snapshot.queryParams.dispatchNoteId;

        if (this.key === 'new') {
            this.canUpdateCustomer = true;
            this.canChangeSiteAddress = true;
            await this.getCustomers();
            this.rental = this.uow.rentals.createEntity();
            this.rental.startDate = new Date();
            if (!dispatchNoteId) {
                this.rental.startDate = moment(new Date()).startOf('day').toDate();
                this.rental.price = 0.0;
            } else {
                this.dispatchNote = await this.uow.dispatchNotes.byId(dispatchNoteId);
                this.autofillRental(this.dispatchNote);
            }
            this.canSaveAndStart = true;
        } else {
            await this.getRental();
            this.customer = this.rental.customer;
            this.canSaveAndStart = false;
            if (!!this.customer) this.canAddSite = true;
        }

        await this.getPhotos();

        if (dispatchNoteId) {
        }

        this.uploadUrl = this.photoService.getUploadUrl(this.rental.id, PhotoService.PhotoMode.Rental);
        this.uploadHeader = await this.photoService.getUploadHeader();
    }

    async getRental() {
        this.rental = await this.uow.rentals.byId(this.key, 'customer, serviceAddress, jobs.dumpster, jobs.fuelRatePercentage');
        const jobs = this.rental.jobs.filter(x => {
            return !x.deleted;
        });
        for (let index = 0; index < jobs.length; index++) {
            const job = jobs[index];
            if (job.completed) {
                const locations = await this.uow.geDumpsterLocationsByJobId(job.id);
                if (locations && locations.length > 0) {
                    const type = job.dumpster.size.substring(0, 2);
                    const dumpsterNumber = locations[0].dumpsterInventory.dumpsterNumber.toString().padStart(3, '0');
                    ((job as any).dumpsterUsed as string) = `${type}-${dumpsterNumber}`;
                }
            }
        }
        this.jobs = jobs;
    }

    async getCustomers() {
        this.customers = await this.uow.customers.getActiveCustomers('serviceAddresses');

        this.customersDS = new DataSource({
            store: this.customers as any[],
            paginate: true
        });
    }

    async getPhotos() {
        this.photos = await this.uow.getPhotos(PhotoService.PhotoMode.Rental, this.rental.id);
    }

    hasChanges() {
        return this.uow.hasChanges();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarButtons'
        });
    }

    customerChanged = e => {
        this.customer = this.customers.find(c => c.id == e.value);
        this.rental.serviceAddress = null;
        if (!this.customer) this.canAddSite = false;
        else {
            this.canChangeSiteAddress = true;
            this.canAddSite = true;
        }
    };

    dumpsterChanged(e) {
        this.rental.price = this.dumpsters.find(x => x.id === e.value) ? this.dumpsters.find(x => x.id === e.value).basePrice : 0;
    }

    autofillRental(dispatchNote: DispatchNote) {
        if (dispatchNote.companyName) {
            const customer = this.customers.find(customer => {
                return customer.companyName?.toLowerCase() === dispatchNote.companyName?.toLowerCase();
            });
            if (customer) this.rental.customerId = customer.id;
        }

        if (dispatchNote.dumpsterType) {
            const dumpster = this.uow.lookups.dumpsters.find(dumpster => {
                return dumpster.id === dispatchNote.dumpsterType;
            });
            if (dumpster) this.rental.dumpsterId = dumpster.id;
        }
        if (!!dispatchNote.rentalDate) this.rental.startDate = dispatchNote.rentalDate;
        this.rental.notes = dispatchNote.notes;
    }

    async saveAndStart() {
        const success = await this.validate();
        if (!success) return;
        await this.uow.commit();

        if (!this.rental.dumpsterId) {
            await alert(`Dumpster not selected`, `${this.appInfo.company}`);
            return;
        }

        let scheduleDelivery = false;
        let schedulePickup = false;
        if (!!this.rental.startDate) {
            scheduleDelivery = await confirm(`Start Date set, do you want to create the Delivery?`, `${this.appInfo.company}`);
        }
        if (this.rental.endDate) {
            schedulePickup = await confirm(`End Date set, do you want to create the Pickup?`, `${this.appInfo.company}`);
        }
        if (scheduleDelivery) {
            this.createJob(JobType.Types.Delivery, this.rental.startDate);
        }
        if (schedulePickup) {
            this.createJob(JobType.Types.PickUp, this.rental.endDate);
        }
        await this.uow.commit();
        this.close();
    }

    createJob(jobTypeId: number, jobDate: Date): Job {
        return this.rentalService.createJob(this.rental, this.rental.serviceAddress, jobDate, jobTypeId);
    }

    async deleteJob() {
        const selectedRows = this.jobGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        this.appInfo.pleaseWaitShow();
        selectedRows.forEach(row => {
            row.deleted = new Date();
        });
        await this.uow.commit();
        await this.getRental();
        this.appInfo.pleaseWaitHide();
    }

    async save() {
        const success = await this.validate();
        if (!success) return;
        await this.uow.commit();

        if (this.key === 'new') {
            this.key = this.rental.id;
            this.canUpdateCustomer = false;
            this.canChangeSiteAddress = false;
            this.uploadUrl = this.photoService.getUploadUrl(this.rental.id, PhotoService.PhotoMode.Rental);
            this.router.navigate([`../${this.rental.id}`], { relativeTo: this.route, replaceUrl: true });
        }
    }

    async validate() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            if (!result.isValid) {
                await alert('Please fix errors before saving', this.appInfo.appTitle);
                return false;
            }
        }
        return true;
    }

    async cancel() {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;
        this.uow.rollback();
        if (!this.key || this.key === 'new') this.close();
    }

    close() {
        try {
            this.location.back();
        } catch {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    async addCustomer(customer?: Customer) {
        const dialogRef = this.dialogService.open(CustomerDetailDialogComponent);
        dialogRef.instance.dialogKey = customer ? customer.id : 'new';
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();
            await this.getCustomers();
            this.rental.customerId = results.value;
        }
        this.dialogService.close(dialogRef);
        this.appInfo.pleaseWaitHide();
    }

    async addSite(site?: ServiceAddress) {
        if (!site) {
            site = this.uow.serviceAddresses.createEntity();
            site.customerId = this.customer.id;
        }

        const dialogRef = this.dialogService.open(ServiceAddressDialogComponent);
        dialogRef.instance.serviceAddress = site;
        const result = await dialogRef.instance.show();
        if (result.success) {
            this.appInfo.pleaseWaitShow();
            await this.uow.commit([site]);
            await this.getCustomers();
            this.rental.serviceAddressId = site.id;

            this.canChangeSiteAddress = false;
        } else site.entityAspect.setDeleted();
        this.dialogService.close(dialogRef);
        this.appInfo.pleaseWaitHide();
    }

    uploadStarted(e: any) {
        this.appInfo.pleaseWaitShow();
    }

    uploaded = async (e: any) => {
        var response = this.photoService.parseUploadResponse(e);
        if (!response.success) alert(`Unable to upload the file(s). ${response.message}`, 'Upload Error');
        else await this.getPhotos();
        this.appInfo.pleaseWaitHide();
    };

    uploadError(e: any) {
        alert('An error occurred while attempting to upload the file(s)', 'Upload Error');
        this.appInfo.pleaseWaitHide();
    }

    openPhoto(photo: Photo) {
        if (!photo) return;
        window.open(photo.filename, '_blank');
    }

    async editPhoto(photo: Photo) {
        if (!photo) return;
        const dialogRef = this.dialogService.open(PhotoDialogComponent);
        dialogRef.instance.photo = photo;
        await dialogRef.instance.show();
        this.dialogService.close(dialogRef);
    }

    removePhoto = async (photo: Photo) => {
        if (!photo) return;

        const success = await confirm('Are you sure you want to delete this photo?', this.appInfo.company)
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        photo.deleted = new Date();
        photo.deletedBy = this.auth.userValue.userName;
        await this.uow.commit([photo]);
        this.photos.splice(this.photos.indexOf(photo), 1);
        this.appInfo.pleaseWaitHide();
    };
}
