import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import notify from 'devextreme/ui/notify';

declare function require(moduleName: string): any;
export const { version: appVersion } = require('../../../package.json');

@Injectable({ providedIn: 'root' })
export class AppInformationService {
    static platform: string;
    static webAssetContainer = 'web-assets';
    static allowedImageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.gif'];

    private pleaseWait = false;

    public get appTitle() {
        return 'CDR Disposal';
    }
    company = 'CDR Disposal';
    isLoggedIn = false;
    title: string;

    applicationInfo: any;
    appVersion: string;
    serviceName: string;

    partInfos: any[];
    openInvoiceRoute: 'parts' | 'purchaseorder';

    parameters: any = {};

    savedData: { parts: any[]; orderPoints: any[] } = {
        parts: undefined,
        orderPoints: undefined
    };

    constructor(private titleService: Title) {
        this.appVersion = appVersion;
        this.serviceName = environment.serviceName;
    }

    setTitle(title: string) {
        let pageTitle = 'CDR';
        if (title) pageTitle += ' - ' + title;
        this.titleService.setTitle(pageTitle);
        this.title = title;
    }

    getPleaseWait() {
        return this.pleaseWait;
    }

    async pleaseWaitShow() {
        await Promise.resolve(null);
        this.pleaseWait = true;
    }

    async pleaseWaitHide() {
        await Promise.resolve(null);
        this.pleaseWait = false;
    }

    notifyToast(message: string, type: 'info' | 'warning' | 'error' | 'success' = 'success', displayTime = 5000) {
        notify({
            position: { my: 'center top', at: 'center top', offset: '0 20', of: 'body' },
            message,
            closeOnClick: true,
            displayTime,
            width: '400px',
            type
        });
    }
}
