import { Component, inject } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService, CdrUnitOfWork, SessionInformationService, UtilityService } from 'src/app/core/core.module';

@Component({
    selector: 'app-customer-totals',
    templateUrl: './customer-totals.component.html',
    styleUrls: ['./customer-totals.component.scss']
})
export class CustomerTotalsComponent {
    uow = inject(CdrUnitOfWork);
    appInfo = inject(AppInformationService);
    sessionInfo = inject(SessionInformationService);
    utility = inject(UtilityService);

    filter = { startDate: new Date(), endDate: new Date() };
    showPreviousWeek: boolean;
    feeRevenue: { id: number; name: string; first: Date; last: Date; count: number; total: number }[];
    customerTotals: { customer: string; invoiceCount: number; total: number }[];

    async ngOnInit() {
        const filter = this.sessionInfo.getItem('revenue-forecast-filter');
        if (filter) this.filter = filter;
        else this.setFilter();
        await this.getData();
    }

    async getData() {
        if (!this.filter.startDate || !this.filter.endDate) {
            this.feeRevenue = null;
            this.customerTotals = null;
            return;
        }

        this.appInfo.pleaseWaitShow();
        this.feeRevenue = await this.uow.jobs.getFeeRevenue(this.filter.startDate, this.filter.endDate);
        this.customerTotals = await this.uow.customers.getCustomerTotals(this.filter.startDate, this.filter.endDate);
        this.sessionInfo.setItem('revenue-forecast-filter', this.filter);
        this.appInfo.pleaseWaitHide();
    }

    async toggleShowPreviousWeek() {
        this.showPreviousWeek = !this.showPreviousWeek;
        this.setFilter();
        await this.getData();
    }

    setFilter() {
        const start = new Date();
        // 0 = Sun, 1 = Mon, etc.
        const day = start.getDay();
        // -6 if day is Sun; otherwise +1
        let daysPastMonday = start.getDate() - day + (day === 0 ? -6 : 1);
        if (this.showPreviousWeek) daysPastMonday -= 7;
        start.setDate(daysPastMonday);
        const end = moment(start).add(6, 'days').toDate();

        this.filter.startDate = start;
        this.filter.endDate = end;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: 'refresh',
                    text: 'Reset Filters',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            }
        );
    }
}
