<div class="user-panel">
    <div class="user-info">
        <div class="image-container">
            <!-- <div class="user-image"></div> -->
            <i class="dx-icon-user"></i>
        </div>
        <div class="user-name" *ngIf="user$ | async as user">{{ user.firstName }}</div>
    </div>
    <dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        target=".user-button"
        showEvent="dxclick"
        width="170px"
        [position]="{ my: 'top center', at: 'bottom center' }"
        cssClass="user-menu"
    >
    </dx-context-menu>
    <dx-list *ngIf="menuMode === 'list'" class="dx-toolbar-menu-action" [items]="menuItems"> </dx-list>
</div>
