import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { default as validationEngine } from 'devextreme/ui/validation_engine';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, ReportService, UtilityService } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { CustomerRate, InvoiceStatus, Payment, PaymentCredit, PaymentLine, PaymentMethod } from 'src/app/core/model/entity-model';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceLine } from 'src/app/core/model/invoice-line';
import { ServiceAddress } from 'src/app/core/model/service-address';
import { DialogService, alert, confirm } from 'src/app/shared/shared.module';
import { InvoiceLineDialogComponent } from '../invoice-line-dialog/invoice-line-dialog.component';
import { EmailDialogComponent } from 'src/app/shared/email-dialog/email-dialog.component';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
    styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {
    key: number | string;
    customerKey: number | string;
    selectedInvoiceKey: number;
    invoicePaymentInfos: any[];
    readonly: boolean;

    payment: Payment;
    paymentLines: PaymentLine[] = [];
    customer: Customer;
    selectedInvoicePaymentInfo: any;
    amountReceived: number;
    balance: number;
    credits: PaymentCredit[];
    creditAmount: number;
    processing: boolean = false;
    paymentMethods: PaymentMethod[];
    @ViewChild('invoiceLineGrid') invoiceLineGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private appInfo: AppInformationService,
        private dialogService: DialogService,
        private authService: AuthService,
        private reportService: ReportService,
        private utilityService: UtilityService
    ) {}

    canDeactivate() {
        return !this.hasChanges();
    }

    async ngOnInit() {
        this.paymentMethods = this.uow.lookups.paymentMethods;

        // need to subscribe to refresh page when navigating to related payments
        this.route.params.subscribe(async (params: any) => {
            this.key = params.id;
            this.customerKey = Number(params.customerId);
            this.selectedInvoiceKey = Number(params.selectedInvoiceId);

            if (this.key === 'new') {
                await this.getCustomer(this.customerKey);
                await this.getCredits(this.customerKey);

                this.payment = this.uow.payments.createEntity({
                    customerId: this.customerKey,
                    created: new Date(),
                    paymentDate: new Date(),
                    paymentMethodId: 1,
                    createdBy: this.authService.userValue.userName
                }) as Payment;

                await this.getInvoiceInfos();
                this.createOpenInvoiceLines();
                this.setInvoiceAmounts();
                this.getSelectedInvoice(this.selectedInvoiceKey);
            } else {
                this.readonly = true;
                await this.getData();
                await this.getCredits(this.payment.customerId);
            }
            this.amountReceived = this.payment.paymentAmount;
        });
    }

    async getData() {
        this.payment = await this.uow.payments.byId(this.key, ['customer', 'paymentLines']);
        this.customer = this.payment.customer;
        this.paymentLines = this.payment.paymentLines;
        await this.getInvoiceInfos();
        this.setInvoiceAmounts();
    }

    async getCustomer(id: number) {
        this.customer = await this.uow.customers.byId(id);
    }
    getSelectedInvoice(id: number) {
        this.selectedInvoicePaymentInfo = this.invoicePaymentInfos.find(invoice => invoice.id == id);
    }
    async getCredits(customerId: number) {
        const credits = await this.uow.getCredits({ customerId, unapplied: true });
        this.creditAmount = _.sum(
            credits.map(x => {
                return x.amount;
            })
        );
    }
    async getInvoiceInfos() {
        if (this.key === 'new') this.invoicePaymentInfos = await this.uow.getOpenInvoicePaymentInfos(this.customer.id);
        else this.invoicePaymentInfos = await this.uow.getInvoicePaymentInfos(this.payment.id);

        this.balance = _.sum(
            this.invoicePaymentInfos.map(x => {
                return x.total;
            })
        );
    }

    setInvoiceAmounts() {
        this.paymentLines.forEach(paymentLine => {
            const paymentInfo = this.invoicePaymentInfos.find(invoice => invoice.id === paymentLine.invoiceId);
            if (!paymentInfo) return;

            paymentLine.invoiceDueDate = paymentInfo.dueDate;
            paymentLine.invoiceAmount = paymentInfo.total;
            paymentLine.paidAmount = paymentInfo.paidAmount;
            paymentLine.startingBalance = paymentInfo.remainingBalance;
            this.updateLineRemainingBalance(paymentLine.id);
            paymentLine.relatedPaymentIds = paymentInfo.relatedPaymentIds;
        });
    }

    createOpenInvoiceLines() {
        this.invoicePaymentInfos.forEach(invoice => {
            const paymentLine = this.uow.paymentLines.createEntity({
                paymentId: this.payment.id,
                invoiceId: invoice.id,
                lineNumber: Payment.getNextLineNumber(this.paymentLines),
                description: `Inv-${invoice.invoiceNumber} - ${invoice.serviceAddress1}`,
                amount: this.selectedInvoiceKey === invoice.id ? invoice.remainingBalance : 0,
                created: new Date(),
                createdBy: this.authService.userValue.userName
            });
            this.paymentLines.push(paymentLine);
        });
    }

    creditCheck(e) {
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    rowPrepare(e) {
        if (e.rowType === 'data') {
            if (e.data.amount > 0) {
                if (this.key === 'new') {
                    if (e.data.amount < e.data.startingBalance) e.rowElement.className = e.rowElement.className + ' partial-payment';
                    else if (e.data.amount > e.data.startingBalance) e.rowElement.className = e.rowElement.className + ' over-payment';
                    else e.rowElement.className = e.rowElement.className + ' full-payment';
                } else {
                    if (e.data.startingBalance !== 0) e.rowElement.className = e.rowElement.className + ' partial-payment';
                    else e.rowElement.className = e.rowElement.className + ' full-payment';
                }
            }
        }
    }

    rowUpdated(e) {
        if (this.readonly) return;

        this.updateLineRemainingBalance(e.data.id);
        this.updateAmountReceived();
    }

    rowDoubleClick(e) {
        if (this.readonly || !e.data) return;

        if (e.data.amount === 0) e.data.amount = e.data.startingBalance;
        else e.data.amount = 0;

        this.updateLineRemainingBalance(e.data.id);
        this.updateAmountReceived();
    }

    updateLineRemainingBalance(lineId: number) {
        const line = this.paymentLines.find(line => line.id === lineId);
        line.remainingBalance = this.key === 'new' ? line.startingBalance - line.amount : line.startingBalance;
    }

    updateAmountReceived() {
        this.amountReceived = _.sum(
            this.paymentLines.map(paymentLine => {
                return paymentLine.amount;
            })
        );
    }

    async validateLines(): Promise<boolean> {
        if (
            this.paymentLines.filter(x => {
                if (x.amount < 0) return x;
            }).length > 0
        ) {
            await alert('Payment Lines CANNOT be negative.', this.appInfo.appTitle);
            return false;
        }

        return true;
    }

    async save() {
        if (!this.valid()) {
            await alert('Please fix errors before saving', this.appInfo.appTitle);
            return false;
        }
        const lineCheck = await this.validateLines();
        if (!lineCheck) {
            return;
        }

        if (this.key === 'new') {
            const startingBalanceSum = _.sum(
                this.paymentLines.map(x => {
                    if (x.amount > 0) return x.startingBalance;
                })
            );
            if (startingBalanceSum !== this.amountReceived  && this.amountReceived > startingBalanceSum) {
                const creditConfirm = await confirm(
                    `A payment credit will be generated for $${this.amountReceived - startingBalanceSum}.  Continue?`,
                    this.appInfo.appTitle
                );
                if (!creditConfirm) return;
            }

            this.paymentLines.forEach(line => {
                if (!line.amount) {
                    line.entityAspect.setDeleted();
                } else {
                    const openInvoice = this.invoicePaymentInfos.find(invoice => invoice.id === line.invoiceId);
                    if (!openInvoice) return;

                    if (line.amount > line.startingBalance) {
                        line.overPaymentAmount = line.amount - line.startingBalance;
                        line.amount = line.startingBalance;


                    }
                    if (line.amount >= line.startingBalance) {
                        openInvoice.invoice.paid = new Date();
                        openInvoice.invoice.statusId = InvoiceStatus.Statuses.Paid;
                    }
                    if (line.amount < line.startingBalance) {
                        openInvoice.invoice.statusId = InvoiceStatus.Statuses.PartiallyPaid;
                    }
                }
            });
            //consolidate the credits to a single credit
            const creditAmount = _.sum(
                this.paymentLines.map(x => {
                    return x.overPaymentAmount;
                })
            );
            if (creditAmount > 0) {
                this.uow.paymentCredits.createEntity({
                    customerId: this.customer.id,
                    paymentId: this.payment.id,
                    amount: creditAmount,
                    created: new Date(),
                    createdBy: this.authService.userValue.userName
                });
                //creates extra payment line for single point of deductions when applying credits later
                const paymentLine = this.uow.paymentLines.createEntity({
                    paymentId: this.payment.id,
                    invoiceId: this.selectedInvoicePaymentInfo.id,
                    lineNumber: Payment.getNextLineNumber(this.paymentLines),
                    description: `Invoice OverPayment Record`,
                    amount: creditAmount,
                    created: new Date(),
                    createdBy: this.authService.userValue.userName
                });
                this.paymentLines.push(paymentLine);
            }

            await this.uow.commit();
            this.key = this.payment.id;
            this.router.navigate([`/payments/${this.payment.id}`], { relativeTo: this.route, replaceUrl: true });
        }

        await this.uow.commit();
        await this.utilityService.updateCustomerBalance(this.customer.id);
        return true;
    }

    async cancel() {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;
        this.uow.rollback();
        this.setInvoiceAmounts(); // if a line was edited, rolling back clears invoice properties on the line
        if (!this.key || this.key === 'new') this.close();
    }

    close() {
        try {
            this.location.back();
        } catch {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    hasChanges() {
        return this.uow.hasChanges();
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('invoiceValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('invoiceValidationGroup');
            return result.isValid;
        } else return false;
    }

    updateDisplayOrder(invoiceLines: InvoiceLine[]) {
        _.orderBy(this.paymentLines, 'lineNumber').forEach((invoiceLine, index) => {
            if (invoiceLine.lineNumber !== index + 1) invoiceLine.lineNumber = index + 1;
        });
    }

    sort(invoiceLines: InvoiceLine[]) {
        invoiceLines.sort((a, b) => (a.lineNumber || 9999) - (b.lineNumber || 9999));
    }

    printPaymentReceipt() {
        this.reportService.printPaymentReceiptReport([this.payment.id]);
    }

    async sendForReview(invoice: Invoice) {
        if (!invoice) return;

        const success = await confirm(`Are you sure you want to mark the selected invoice for review?`, this.appInfo.appTitle);
        if (!success) return;

        invoice.statusId = InvoiceStatus.Statuses.Review;
        await this.uow.commit();
    }

    async saveAndSend() {
        const success = await this.save();
        if (!success) return;

        const dialogRef = this.dialogService.open(EmailDialogComponent);
        dialogRef.instance.entityIds = [this.payment.id];
        dialogRef.instance.mode = 'Payment';
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            const emailMessageIds = result.value.emailMessages.map(emailMessage => emailMessage.id);
            const success = await this.utilityService.sendEmail(emailMessageIds);
            if (success) await alert('Email sent', this.appInfo.appTitle);
            else await alert('Error sending email', this.appInfo.appTitle);
        }
    }

    async openPayment(paymentId) {
        this.router.navigate([`/payments/${paymentId}`]);
    }
}
