import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { Job } from 'src/app/core/model/job';
import { User } from 'src/app/core/model/user';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-job-note-dialog',
    templateUrl: './job-note-dialog.component.html',
    styleUrls: ['./job-note-dialog.component.scss']
})
export class JobNoteDialogComponent extends BaseDialogComponent<any> implements OnInit {
    job: Job;
    drivers: User[];

    constructor() {
        super();
    }

    ngOnInit() {}


    save = async () => {
        this.hide({ success: true, value: this.job });
    };

    cancel = async () => {
        this.hide({ success: false });
    };

    validate() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        }
        return true;
    }
}
