import { Component } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { Maintenance } from 'src/app/core/model/maintenance';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-maintenance-dialog',
    templateUrl: './maintenance-dialog.component.html',
    styleUrls: ['./maintenance-dialog.component.scss']
})
export class MaintenanceDialogComponent extends BaseDialogComponent<{ success: boolean; maintenance?: Maintenance }> {
    maintenance: Maintenance;

    save = () => {
        const success = this.valid();
        if (!success) return;

        this.hide({ success: true, maintenance: this.maintenance });
    };

    cancel = () => {
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
