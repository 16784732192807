import { Component, OnInit } from '@angular/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
    selector: 'app-audit-dialog',
    templateUrl: './audit-dialog.component.html',
    styleUrls: ['./audit-dialog.component.scss']
})
export class AuditDialogComponent extends BaseDialogComponent<any> implements OnInit {
    auditLogs: any[];
    auditLogData: any;

    constructor() {
        super();
    }

    async ngOnInit() {
        if (!!this.auditLogs && this.auditLogs.length > 0) this.auditLogData = JSON.parse(this.auditLogs[0].data);
    }
    selectionChange(e) {
        this.auditLogData = JSON.parse(e.selectedRowsData[0].data);
    }

    close = (): void => {
        this.hide({ success: true });
    }
}
