<div class="flex-fill d-flex flex-row">
    <div class="flex-shrink-0 sidebar" *ngIf="!showAsDialog">
        <div class="toolbar m-3">
            <dx-button *ngIf="!hasChanges()" text="Close" type="default" accessKey="c" (onClick)="close()"></dx-button>
            <dx-button *ngIf="hasChanges()" text="Save" type="success" accessKey="s" (onClick)="save()"></dx-button>
            <dx-button *ngIf="hasChanges()" text="Cancel" type="danger" (onClick)="cancel()"></dx-button>
        </div>
        <div class="border-top m-3"></div>
        <div class="toolbar m-3">
            <button [disabled]="hasChanges()" class="btn btn-small btn-primary" (click)="openStatement()">Generate Statement</button>
        </div>
    </div>
    <div class="flex-fill" style="width: 1px">
        <div [class]="!showAsDialog ? 'content-block dx-card responsive-paddings' : ''" autofocus *ngIf="customer">
            <dx-form [formData]="customer" [validationGroup]="'customerValidationGroup'" *ngIf="customer" appAutofocusForm>
                <dxi-item itemType="tabbed">
                    <dxi-tab [title]="'Customer'" class="p-0">
                        <dxi-item itemType="group" [colCount]="2">
                            <dxi-item
                                dataField="typeId"
                                [label]="{ text: 'Customer Type' }"
                                [editorType]="'dxSelectBox'"
                                [editorOptions]="{ dataSource: customerTypes, displayExpr: 'name', valueExpr: 'id' }"
                            >
                                <dxi-validation-rule type="range" [min]="1" message="Required"> </dxi-validation-rule>
                                <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
                            </dxi-item>
                            <dxi-item
                                dataField="salesPersonId"
                                [label]="{ text: 'Sales Person' }"
                                editorType="dxSelectBox"
                                [editorOptions]="{ dataSource: salesPersons, displayExpr: 'name', valueExpr: 'id' }"
                            ></dxi-item>

                            <dxi-item dataField="companyName"></dxi-item>
                            <dxi-item dataField="quickBooksId" [label]="{ text: 'GreatPlains Id' }"></dxi-item>
                            <dxi-item dataField="firstName"></dxi-item>
                            <dxi-item dataField="nameOverride" [label]="{ text: 'Scheduling Name Override' }"></dxi-item>
                            <dxi-item dataField="lastName"></dxi-item>
                            <dxi-item itemType="empty"></dxi-item>
                            <dxi-item
                                dataField="preferredCommunication"
                                [label]="{ text: 'Preferred Communication' }"
                                [editorType]="'dxSelectBox'"
                                [editorOptions]="{ dataSource: communicationOptions }"
                            >
                            </dxi-item>
                            <dxi-item dataField="phone"></dxi-item>
                            <dxi-item dataField="email"></dxi-item>
                        </dxi-item>
                        <dxi-item>
                            <div *dxTemplate>
                                <button class="btn btn-primary" (click)="addNote()" [disabled]="key === 'new'">Add Note</button>
                            </div>
                        </dxi-item>
                        <dxi-item itemType="group" [colCount]="2">
                            <dxi-item
                                dataField="displayNotes"
                                [label]="{ text: 'Customer Notes' }"
                                editorType="dxTextArea"
                                [editorOptions]="{ readOnly: true, height: '225px' }"
                            >
                            </dxi-item>
                            <dxi-item
                                dataField="siteNotes"
                                [label]="{ text: 'Site Notes' }"
                                editorType="dxTextArea"
                                [editorOptions]="{ readOnly: true, height: '225px' }"
                            >
                            </dxi-item>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab [title]="'Billing Info'">
                        <dxi-item itemType="group" [colCount]="3">
                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item
                                    dataField="billingType"
                                    [label]="{ text: 'Billing Type' }"
                                    [editorType]="'dxSelectBox'"
                                    [editorOptions]="{ dataSource: billingTypeOptions }"
                                >
                                </dxi-item>
                                <dxi-item
                                    dataField="creditCardId"
                                    [label]="{ text: 'Credit Card' }"
                                    [editorType]="'dxSelectBox'"
                                    [editorOptions]="{ dataSource: creditCards, displayExpr: 'name', valueExpr: 'id' }"
                                >
                                </dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item dataField="standardRentalLength" [label]="{ text: 'Standard Rental Length' }"> </dxi-item>
                                <dxi-item
                                    dataField="extensionRate"
                                    editorType="dxNumberBox"
                                    [editorOptions]="{ format: { type: 'currency', precision: 2 } }"
                                ></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item
                                    dataField="paymentTerms"
                                    [label]="{ text: 'Payment Terms' }"
                                    [editorType]="'dxSelectBox'"
                                    [editorOptions]="{ dataSource: paymentTermOptions }"
                                >
                                </dxi-item>
                                <dxi-item
                                    dataField="billingRule"
                                    [label]="{ text: 'Billing Rules (*if blank, bill as customer type)' }"
                                    [editorType]="'dxSelectBox'"
                                    [editorOptions]="{ items: billingRules }"
                                >
                                </dxi-item>
                            </dxi-item>
                            <!-- <dxi-item itemType="empty"></dxi-item> -->

                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item
                                    dataField="billingSameAsService"
                                    cssClass="show-labels"
                                    [label]="{ text: 'Billing Address Same As Service', location: 'left' }"
                                    [editorType]="'dxCheckBox'"
                                >
                                </dxi-item>
                                <dxi-item
                                    dataField="bypassKentCharge"
                                    cssClass="show-labels"
                                    [label]="{ text: 'Bypass Kent Charge', location: 'left' }"
                                    [editorType]="'dxCheckBox'"
                                >
                                </dxi-item>
                                <dxi-item dataField="billAddress1" [label]="{ text: 'Address 1' }"></dxi-item>
                                <dxi-item dataField="billAddress2" [label]="{ text: 'Address 2' }"></dxi-item>
                                <dxi-item dataField="billCity" [label]="{ text: 'City' }"></dxi-item>
                                <dxi-item dataField="billState" [label]="{ text: 'State' }"></dxi-item>
                                <dxi-item dataField="billZip" [label]="{ text: 'Zip' }"></dxi-item>
                                <dxi-item dataField="billCountry" [label]="{ text: 'Country' }"></dxi-item>
                            </dxi-item>

                            <dxi-item itemType="group" [colCount]="2">
                                <dxi-item
                                    dataField="usePercentageBasedFuelRate"
                                    cssClass="show-labels"
                                    [label]="{ text: 'Use Percentage Based Fuel Rate', location: 'left' }"
                                    [editorType]="'dxCheckBox'"
                                ></dxi-item>

                                <dxi-item
                                    dataField="fuelRate"
                                    editorType="dxNumberBox"
                                    [editorOptions]="{ format: { type: 'currency', precision: 2 } }"
                                ></dxi-item>
                            </dxi-item>
                            <dxi-item
                                dataField="balance"
                                editorType="dxNumberBox"
                                [editorOptions]="{ format: { type: 'currency', precision: 2 }, disabled: true }"
                            ></dxi-item>
                        </dxi-item>
                    </dxi-tab>
                    <dxi-tab [title]="'Service Address'">
                        <app-service-address-overview [customerId]="customer.id" [readonly]="key === 'new'"></app-service-address-overview>
                    </dxi-tab>
                    <dxi-tab [title]="'Rates'">
                        <div class="d-flex flex-fill flex-column">
                            <dx-data-grid
                                #ratesGrid
                                keyExpr="id"
                                class="d-flex flex-fill flex-column"
                                [dataSource]="rates"
                                [showBorders]="true"
                                style="min-height: 650px"
                                (onToolbarPreparing)="onToolbarPreparing($event)"
                                (onRowDblClick)="rateDoubleClick($event)"
                            >
                                <dxi-column dataField="rateType.name" caption="Type" width="100"></dxi-column>
                                <dxi-column
                                    dataField="dumpsterSize"
                                    caption="Size"
                                    width="100"
                                    [sortIndex]="1"
                                    [sortOrder]="'asc'"
                                ></dxi-column>
                                <dxi-column dataField="rate" width="100" [format]="{ type: 'currency', precision: 2 }"></dxi-column>
                                <dxi-column
                                    dataField="pricePerTon"
                                    caption="Price/Ton"
                                    width="100"
                                    [format]="{ type: 'currency', precision: 2 }"
                                ></dxi-column>
                                <dxi-column dataField="weightLimit" width="100"></dxi-column>
                                <dxi-column
                                    dataField="rangeDisplay"
                                    caption="Range"
                                    width="100"
                                    [sortIndex]="2"
                                    [sortOrder]="'desc'"
                                ></dxi-column>
                                <!-- <dxi-column dataField="zone" width="100" [sortIndex]="3" [sortOrder]="'desc'"></dxi-column> -->
                                <dxi-column dataField="baseMileage" caption="Base Mileage" width="125"></dxi-column>
                                <dxi-column
                                    dataField="mileageOffsetRate"
                                    caption="$/mi extra"
                                    width="125"
                                    [format]="{ type: 'currency', precision: 2 }"
                                ></dxi-column>
                                <dxi-column dataField="mileageOffset" caption="Additional Miles" width="125"></dxi-column>
                                <dxi-column></dxi-column>

                                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                                <dxo-selection [mode]="'single'"></dxo-selection>
                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>

                                <div *dxTemplate="let data of 'toolbarButtons'">
                                    <span *ngIf="key !== 'new'">
                                        <button class="btn btn-success" (click)="addRate()">Add</button>
                                        <button
                                            class="btn btn-primary ml-2"
                                            (click)="addRate(ratesGrid.instance.getSelectedRowsData()[0])"
                                            [disabled]="ratesGrid.instance.getSelectedRowsData().length !== 1"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            class="btn btn-danger ml-2"
                                            (click)="deleteRate(ratesGrid.instance.getSelectedRowsData())"
                                            [disabled]="ratesGrid.instance.getSelectedRowsData().length !== 1"
                                        >
                                            Delete
                                        </button>
                                    </span>
                                </div>
                                <div *dxTemplate="let data of 'toolbarText'">
                                    <span *ngIf="rates" class="mr-3">
                                        <strong>{{ ratesGrid.instance.totalCount() + ' items' }}</strong>
                                    </span>
                                </div>
                            </dx-data-grid>
                        </div>
                    </dxi-tab>
                    <dxi-tab [title]="'Rentals'">
                        <div class="d-flex flex-fill">
                            <dx-data-grid
                                #searchGrid
                                keyExpr="id"
                                class="d-flex flex-fill flex-column"
                                [dataSource]="rentals"
                                [height]="'650px'"
                                [showBorders]="true"
                                (onToolbarPreparing)="onToolbarPreparing($event)"
                            >
                                <dxi-column dataField="id" width="80"></dxi-column>
                                <dxi-column dataField="dumpster.size" caption="Dumpster" width="100"></dxi-column>
                                <dxi-column dataField="displayAddress" caption="Site Address" width="400"></dxi-column>
                                <dxi-column dataField="startDate" dataType="date" format="M/d/yyyy" width="100"></dxi-column>
                                <dxi-column dataField="endDate" dataType="date" format="M/d/yyyy" width="100"></dxi-column>
                                <dxi-column dataField="preferredTime" dataType="datetime" format="shortTime" width="150"></dxi-column>
                                <dxi-column dataField="preferredWindow" width="150"></dxi-column>
                                <dxi-column dataField="completed" width="150"></dxi-column>
                                <dxi-column></dxi-column>

                                <div *dxTemplate="let data of 'toolbarButtons'">
                                    <button
                                        class="btn btn-sm btn-secondary mb-2"
                                        (click)="unCompleteRental(searchGrid.instance.getSelectedRowsData()[0])"
                                        [disabled]="
                                            searchGrid.instance.getSelectedRowsData().length !== 1 ||
                                            !searchGrid.instance.getSelectedRowsData()[0].completed
                                        "
                                    >
                                        UnComplete
                                    </button>
                                </div>
                                <div *dxTemplate="let data of 'toolbarText'">
                                    <span *ngIf="customer.rentals" class="mr-3">
                                        <strong>{{ searchGrid.instance.totalCount() + ' items' }}</strong>
                                    </span>
                                </div>

                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                <dxo-selection [mode]="'single'"></dxo-selection>
                                <dxo-filter-row [visible]="true"></dxo-filter-row>
                            </dx-data-grid>
                        </div>
                    </dxi-tab>
                    <dxi-tab [title]="'Invoices'">
                        <div class="d-flex flex-column flex-fill p-1">
                            <dx-data-grid
                                #invoicesGrid
                                keyExpr="id"
                                class="flex-fill d-flex flex-column"
                                height="650px"
                                [dataSource]="invoices"
                                [showBorders]="true"
                                (onToolbarPreparing)="onToolbarPreparing($event)"
                                (onRowDblClick)="invoiceDoubleClick($event)"
                                [columnAutoWidth]="false"
                            >
                                <dxi-column dataField="id" caption="Id" width="75" cellTemplate="invoiceLinkTemplate"></dxi-column>
                                <dxi-column
                                    dataField="invoiceNumber"
                                    caption="Invoice #"
                                    width="75"
                                    cellTemplate="invoiceLinkTemplate"
                                ></dxi-column>
                                <dxi-column
                                    dataField="rentalIdDisplay"
                                    caption="Rental"
                                    width="75"
                                    cellTemplate="rentalLinkTemplate"
                                ></dxi-column>
                                <dxi-column dataField="status.name" caption="Status" width="100"></dxi-column>
                                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="125"></dxi-column>
                                <dxi-column dataField="createDate" caption="Created" width="100"></dxi-column>
                                <dxi-column dataField="sent" format="shortDate" caption="Sent" width="100"></dxi-column>
                                <dxi-column dataField="paid" width="100"></dxi-column>
                                <dxi-column dataField="deleted" width="100"></dxi-column>
                                <dxi-column dataField="isDeleted" width="75"></dxi-column>
                                <dxi-column dataField="displayServiceAddress" caption="Service Address"></dxi-column>

                                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                                    <a *ngIf="cell" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                                </div>

                                <div *dxTemplate="let cell of 'invoiceLinkTemplate'">
                                    <a *ngIf="cell" [routerLink]="'/invoices/' + cell.data.id" [innerHtml]="cell.text"></a>
                                </div>

                                <dxo-summary>
                                    <dxi-total-item column="total" summaryType="sum" valueFormat="currency"> </dxi-total-item>
                                </dxo-summary>

                                <dxo-selection [mode]="'multiple'"></dxo-selection>
                                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                                <dxo-search-panel [visible]="true"></dxo-search-panel>
                                <dxo-filter-row [visible]="true"></dxo-filter-row>
                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                <dxo-sorting [mode]="'multiple'"></dxo-sorting>
                                <dxo-state-storing
                                    [enabled]="true"
                                    type="localStorage"
                                    storageKey="customer-invoice-overview-grid"
                                ></dxo-state-storing>
                                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                                <div *dxTemplate="let data of 'toolbarText'">
                                    <span *ngIf="invoices" class="mr-3">
                                        <strong>{{ invoicesGrid.instance.totalCount() + ' items' }}</strong>
                                    </span>
                                </div>
                            </dx-data-grid>
                        </div>
                    </dxi-tab>
                </dxi-item>
            </dx-form>
        </div>
    </div>
</div>
