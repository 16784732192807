<div *ngIf="substitutions" class="flex-fill d-flex flex-column dx-card">
    <div class="flex-fill d-flex flex-column dx-card">
        <div class="d-flex flex-column flex-fill responsive-paddings">
            <dx-data-grid
                #substitutionsDataGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                [dataSource]="substitutions"
                height="100px"
                [showBorders]="true"
                [showRowLines]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="id" caption="Job #" width="70" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="rentalId" caption="Rental #" width="70" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="companyName" width="240"></dxi-column>
                <dxi-column dataField="name" caption="Customer Name" width="120"></dxi-column>
                <dxi-column dataField="action" width="85"></dxi-column>
                <dxi-column dataField="size" width="75"></dxi-column>
                <dxi-column dataField="billAs" width="75"></dxi-column>
                <dxi-column dataField="completed" width="100"></dxi-column>
                <dxi-column dataField="siteName" width="190"></dxi-column>
                <dxi-column dataField="address1" width="215"></dxi-column>
                <dxi-column dataField="address2" width="160"></dxi-column>
                <dxi-column dataField="city" width="125"></dxi-column>
                <dxi-column dataField="state" width="50"></dxi-column>
                <dxi-column dataField="zip" width="60"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="substitutions-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="substitutions" class="mr-3">
                        <strong>{{ substitutionsDataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
