<div class="flex-fill d-flex dx-card">
    <div *ngIf="!showAsComponent" class="d-flex flex-fill flex-column col-sm-6">
        <div class="d-flex flex-column flex-fill p-3">
            <h4>Payments</h4>
            <dx-data-grid
                #paymentsGrid
                [keyExpr]="'id'"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="payments"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onPaymentDoubleClick($event)"
                [columnAutoWidth]="true"
            >
                <dxi-column dataField="id" width="75"></dxi-column>
                <dxi-column
                    dataField="customer.companyName"
                    caption="Customer"
                    width="150"
                    cellTemplate="customerLinkTemplate"
                ></dxi-column>
                <dxi-column dataField="paymentDate" caption="Date" width="75"></dxi-column>
                <dxi-column dataField="paymentMethod.name" caption="Method" width="75"></dxi-column>
                <dxi-column
                    dataField="paymentAmount"
                    caption="Amount"
                    [format]="{ type: 'currency', precision: 2 }"
                    width="75"
                ></dxi-column>
                <dxi-column dataField="referenceNumber" caption="Ref#" width="75"></dxi-column>
                <dxi-column dataField="quickBooksId" caption="QB ID" width="75"></dxi-column>
                <dxi-column dataField="quickBooksExport" caption="QB Export" width="75"></dxi-column>

                <dxi-column dataField="created" dataType="date" width="100"></dxi-column>
                <dxi-column dataField="createdBy" width="100"></dxi-column>

                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell && isPaymentAdmin" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isPaymentAdmin" [innerHtml]="cell.text"></div>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="payments-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-primary mr-1 mb-2"
                        (click)="openPayment(paymentsGrid.instance.getSelectedRowsData()[0]?.id)"
                        [disabled]="paymentsGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isPaymentAdmin"
                    >
                        Open
                    </button>
                    <button
                        class="btn btn-danger mr-3 mb-2"
                        (click)="delete(paymentsGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="paymentsGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isPaymentAdmin"
                    >
                        Delete
                    </button>
                    <button
                        class="btn btn-secondary mr-1 mb-2"
                        (click)="payWithoutQuickBooks(paymentsGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="
                            paymentsGrid.instance.getSelectedRowsData().length !== 1 ||
                            paymentsGrid.instance.getSelectedRowsData()[0].quickBooksId ||
                            paymentsGrid.instance.getSelectedRowsData()[0].quickBooksExport
                        "
                        [hidden]="!isPaymentAdmin"
                    >
                        Pay w/o QuickBooks
                    </button>
                    <button
                        class="btn btn-secondary mr-1 mb-2"
                        (click)="printPaymentReceipt(paymentsGrid.instance.getSelectedRowsData())"
                        [disabled]="paymentsGrid.instance.getSelectedRowsData().length === 0"
                        [hidden]="!isPaymentAdmin"
                    >
                        Print
                    </button>
                    <button
                        class="btn btn-secondary mb-2"
                        (click)="emailPaymentReceipt(paymentsGrid.instance.getSelectedRowsData()[0])"
                        [disabled]="paymentsGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isPaymentAdmin"
                    >
                        Email
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="payments" class="mr-3">
                        <strong>{{ paymentsGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
    <div [class]="(showAsComponent ? '' : '') + ' d-flex flex-column flex-fill p-2  col-sm-6'">
        <h4 *ngIf="!showAsComponent">Open Invoices</h4>
        <div class="d-flex flex-column flex-fill p-1">
            <dx-data-grid
                #overviewGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="invoices"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onRowDoubleClick($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="id" caption="Id" width="75" cellTemplate="invoiceLinkTemplate"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="75" cellTemplate="invoiceLinkTemplate"></dxi-column>
                <dxi-column dataField="rentalIdDisplay" caption="Rental" width="75" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="status.name" caption="Status" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column
                    dataField="customer.displayName"
                    caption="Customer"
                    width="175"
                    cellTemplate="customerLinkTemplate"
                ></dxi-column>
                <dxi-column dataField="displayBillAddress" caption="Billing Address"></dxi-column>

                <div *dxTemplate="let cell of 'invoiceLinkTemplate'">
                    <a *ngIf="cell && isPaymentAdmin" [routerLink]="'/invoices/' + cell.data.id" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isPaymentAdmin" [innerHtml]="cell.text"></div>
                </div>

                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <a *ngIf="cell && isPaymentAdmin" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isPaymentAdmin" [innerHtml]="cell.text"></div>
                </div>

                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell && isPaymentAdmin" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                    <div *ngIf="cell && !isPaymentAdmin" [innerHtml]="cell.text"></div>
                </div>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <span *ngIf="!showAsComponent">
                        <button
                            class="btn btn-success mr-1 mb-2"
                            (click)="receivePayment()"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                            [hidden]="!isPaymentAdmin"
                        >
                            Receive Payment
                        </button>
                    </span>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="invoices" class="mr-3">
                        <strong>{{ overviewGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>

                <dxo-selection [mode]="'single'"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="payment-invoice-overview-grid"></dxo-state-storing>
            </dx-data-grid>
        </div>
    </div>
</div>
