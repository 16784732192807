import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { DumpsterInventory } from 'src/app/core/model/dumpster-inventory';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-dumpster-inventory-dialog',
    templateUrl: './dumpster-inventory-dialog.component.html',
    styleUrls: ['./dumpster-inventory-dialog.component.scss']
})
export class DumpsterInventoryDialogComponent extends BaseDialogComponent<any> implements OnInit {
    dumpsterInventory: DumpsterInventory;
    dumpsterInventories: DumpsterInventory[];
    dumpsterTypes: Dumpster[];

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        if (!this.dumpsterInventory) {
            this.dumpsterInventory = this.uow.dumpsterInventories.createEntity({
                created: new Date()
            });
        }

        this.isNumberUnique = this.isNumberUnique.bind(this);

        this.dumpsterInventories = await this.uow.getActiveDumpsterInventories();
        this.dumpsterTypes = this.uow.lookups.dumpsters;
    }

    save = async () => {
        if (!this.valid()) return;

        await this.uow.commit();
        this.hide({ success: true, value: this.dumpsterInventory });
    };

    cancel = async () => {
        this.uow.rollback();
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }

    isNumberUnique(e) {
        if (!e.value || !this.dumpsterInventory.dumpsterTypeId) return true;
        return !this.dumpsterInventories.some(
            inventory => inventory.dumpsterTypeId === this.dumpsterInventory.dumpsterTypeId && inventory.dumpsterNumber === e.value
        );
    }
}
