<div class="header-label">Jobs</div>
<div class="flex-fill d-flex flex-column content-block dx-card p-3">
    <div class="row d-flex flex-fill flex-row">
        <div class="col-sm-7 d-flex flex-column">
            <dx-data-grid
                #dataGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="jobs"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="id" caption="Job #" width="100" [sortIndex]="1" [sortOrder]="'desc'"></dxi-column>
                <dxi-column dataField="rental.customer.displayName" caption="Customer" width="225"></dxi-column>
                <dxi-column dataField="jobType.name" caption="Job Type" width="150"></dxi-column>
                <dxi-column dataField="driver.displayName" caption="Driver" width="150"></dxi-column>
                <dxi-column dataField="dumpster.size" caption="Dumpster" width="75"></dxi-column>
                <dxi-column dataField="inventory.dumpsterBarcode" caption="Barcode" width="75"></dxi-column>
                <dxi-column dataField="weight" width="100"></dxi-column>
                <dxi-column dataField="created" width="150"></dxi-column>
                <dxi-column dataField="started" format="shortDateShortTime" width="150"></dxi-column>
                <dxi-column dataField="completed" width="150"></dxi-column>
                <dxi-column dataField="billAsDumpster.size" caption="Bill As" width="150"></dxi-column>
                <dxi-column dataField="displayAddress" caption="Service Address" width="250"></dxi-column>
                <dxi-column dataField="driverNotes" width="350"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="job-overview-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button class="btn btn-sm btn-warning mb-2" (click)="toggleShowUnassigned()">
                        {{ showUnassigned ? 'Show Assigned' : 'Show Only Unassigned' }}
                    </button>
                    <button class="btn btn-sm btn-warning ml-3 mb-2" (click)="toggleShowCompleted()">
                        {{ showUnCompleted ? 'Show All' : 'Show UnCompleted' }}
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="jobs" class="mr-3">
                        <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
        <div class="col-sm-5 d-flex flex-column">
            <dx-data-grid
                #auditDataGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="unAuditedJobs"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="id" caption="Job #" width="100" [sortIndex]="1" [sortOrder]="'desc'"></dxi-column>
                <dxi-column dataField="rental.customer.displayName" caption="Customer" width="225"></dxi-column>
                <dxi-column dataField="jobType.name" caption="Job Type" width="150"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="150" cellTemplate="invoiceTemplate"></dxi-column>
                <dxi-column dataField="dumpster.size" caption="Dumpster" width="75"></dxi-column>
                <dxi-column dataField="inventory.dumpsterBarcode" caption="Barcode" width="75"></dxi-column>
                <dxi-column dataField="created" width="150"></dxi-column>
                <dxi-column dataField="started" format="shortDateShortTime" width="150"></dxi-column>
                <dxi-column dataField="completed" width="150"></dxi-column>
                <dxi-column dataField="billAsDumpster.size" caption="Bill As" width="150"></dxi-column>
                <dxi-column dataField="displayAddress" caption="Service Address" width="250"></dxi-column>
                <dxi-column dataField="driverNotes" width="350"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'invoiceTemplate'">
                    <a [routerLink]="'/invoices/' + cell.data.invoiceId">{{ cell.data.invoiceNumber }}</a>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="job-overview-audit-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-success mb-2"
                        (click)="markAsAudited(auditDataGrid.instance.getSelectedRowsData())"
                        [disabled]="auditDataGrid.instance.getSelectedRowsData().length === 0"
                    >
                        Audited
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="jobs" class="mr-3">
                        <strong>{{ auditDataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
