<dx-popup title="Credit" [width]="400" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="credit">
        <dxi-item
            dataField="customerId"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: customersDS, displayExpr: 'displayName', valueExpr: 'id' }"
            [label]="{ text: 'Customer' }"
        >
        </dxi-item>
        <dxi-item dataField="amount" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'currency', precision: 2 } }"> </dxi-item>
        <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="{ height: '150px' }"> </dxi-item>
    </dx-form>
</dx-popup>
