// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { Invoice } from './invoice';
import { Payment } from './payment';
import { QuickBooksTask } from './quick-books-task';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class QuickBooksTaskResponse extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get type() {
    return `${this.customerId ? 'Customer' : this.invoiceId ? 'Invoice' : this.paymentId ? 'Payment' : '[undefined]'}`
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  created: Date;
  customerId: number;
  invoiceId: number;
  message: string;
  paymentId: number;
  quickBooksTaskId: number;
  status: string;
  customer: Customer;
  invoice: Invoice;
  payment: Payment;
  quickBooksTask: QuickBooksTask;
}

