import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { PaymentCredit } from 'src/app/core/model/payment-credit';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-credit-dialog',
    templateUrl: './credit-dialog.component.html',
    styleUrls: ['./credit-dialog.component.scss']
})
export class CreditDialogComponent extends BaseDialogComponent<any> implements OnInit {
    customers: Customer[];
    customersDS: DataSource;
    credit: PaymentCredit;

    constructor(
        private uow: CdrUnitOfWork,
        private auth: AuthService,
        private appInfo: AppInformationService,
    ) {
        super();
    }

    async ngOnInit() {
        this.appInfo.pleaseWaitShow();
        if (!this.credit) {
            this.credit = this.uow.paymentCredits.createEntity({
                created: new Date(),
                createdBy: this.auth.userValue.userName
            });
        }

        this.customers = await this.uow.customers.getActiveCustomers();
        this.customersDS = new DataSource({
            store: this.customers,
            paginate: true
        });
        this.appInfo.pleaseWaitHide();
    }

    save = async () => {
        await this.uow.commit();
        this.hide({ success: true, value: this.credit });
    };

    cancel = async () => {
        this.uow.rollback();
        this.hide({ success: false });
    };
}
