<div class="flex-fill d-flex flex-column">
    <div class="flex-fill content-block">
        <div class="d-flex flex-fill flex-column">
            <dx-data-grid
                #overviewGrid
                keyExpr="id"
                class="d-flex flex-fill flex-column"
                [height]="'650px'"
                [dataSource]="serviceAddresses"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column dataField="gridName" caption="Site Name" width="150"></dxi-column>
                <dxi-column dataField="phone" width="95"></dxi-column>
                <dxi-column dataField="range" width="95"></dxi-column>
                <dxi-column dataField="address1" width="250"></dxi-column>
                <dxi-column dataField="address2" width="150"></dxi-column>
                <dxi-column dataField="city" width="200"></dxi-column>
                <dxi-column dataField="state" width="100"></dxi-column>
                <dxi-column dataField="zip" width="150"></dxi-column>
                <dxi-column dataField="county" width="100"></dxi-column>
                <dxi-column dataField="distance" caption="Job Dist." width="100"></dxi-column>
                <dxi-column dataField="latitude" width="100"></dxi-column>
                <dxi-column dataField="longitude" width="100"></dxi-column>
                <dxi-column dataField="notes" width="250"></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="service-address-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <span *ngIf="!readonly">
                        <button class="btn btn-success mr-2 mb-2" (click)="open()">Add</button>
                        <button
                            class="btn btn-primary mr-2 mb-2"
                            (click)="open(overviewGrid.instance.getSelectedRowsData()[0])"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                        >
                            Open
                        </button>
                        <button
                            class="btn btn-danger mr-4 mb-2"
                            (click)="delete(overviewGrid.instance.getSelectedRowsData()[0])"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                        >
                            Delete
                        </button>
                        <button
                            class="btn btn-success mb-2"
                            (click)="getDirections(overviewGrid.instance.getSelectedRowsData()[0])"
                            [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                        >
                            Get Directions
                        </button>
                    </span>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="serviceAddresses" class="mr-3">
                        <strong>{{ overviewGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
