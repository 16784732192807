// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Dumpster } from './dumpster';
import { DumpsterFeeType } from './dumpster-fee-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class DumpsterFeeTypeDumpsterLink extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  dumpsterFeeTypeId: number;
  dumpsterId: number;
  dumpster: Dumpster;
  dumpsterFeeType: DumpsterFeeType;
}

