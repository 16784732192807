<dx-popup *ngIf="statement && emailMessage"
    title="Statement"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Print', type: 'success', onClick: print }"
        [disabled]="processing"
    ></dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Email', type: 'success', onClick: email }"
        [disabled]="processing"
    ></dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }"
        [disabled]="processing"
    ></dxi-toolbar-item>
    <dx-form  [formData]="statement" [colCount]="2" validationGroup="mainValidationGroup">
        <dxi-item dataField="startDate">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="empty"></dxi-item>
        <dxi-item dataField="endDate">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item itemType="empty"></dxi-item>
        <dxi-item>
            <div *dxTemplate>
                <button class="btn btn-small btn-primary" (click)="openReport($event)">View Statement</button>
            </div>
        </dxi-item>
    </dx-form>
    <dx-form [formData]="emailMessage" class="mt-4" validationGroup="mainValidationGroup">
        <dxi-item dataField="to"> </dxi-item>
        <dxi-item dataField="cc"> </dxi-item>
        <dxi-item dataField="bcc"> </dxi-item>
        <dxi-item dataField="subject"> </dxi-item>
        <dxi-item dataField="body" editorType="dxTextArea" [editorOptions]="{ height: 350 }"> </dxi-item>
    </dx-form>
</dx-popup>
