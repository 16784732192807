// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { InvoiceLine } from './invoice-line';
import { Rental } from './rental';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RentalBillingLine extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  created: Date;
  createdBy: string;
  deleted: Date;
  deletedBy: string;
  endDate: Date;
  invoiceLineId: number;
  quantity: number;
  rentalId: number;
  startDate: Date;
  invoiceLine: InvoiceLine;
  rental: Rental;
}

