// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { EmailMessage } from './email-message';
import { PaymentCredit } from './payment-credit';
import { PaymentLine } from './payment-line';
import { PaymentMethod } from './payment-method';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class Payment extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getNextLineNumber(lines: any[]) {
        if (lines.length === 0) return 1;

        let lineNumber: any;
        lineNumber = _.maxBy(lines, 'lineNumber').lineNumber + 1;
        return lineNumber;
    }

    get paymentAmount() {
        let amount = 0;
        if (this.paymentLines && this.paymentLines.length > 0)
            amount = _.sum(
                this.paymentLines.map(x => {
                    return x.amount;
                })
            );
        return amount;
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  created: Date;
  createdBy: string;
  customerId: number;
  deleted: Date;
  deletedBy: string;
  notes: string;
  paymentDate: Date;
  paymentMethodId: number;
  quickBooksExport: Date;
  quickBooksId: string;
  referenceNumber: string;
  credits: PaymentCredit[];
  customer: Customer;
  emailMessages: EmailMessage[];
  paymentLines: PaymentLine[];
  paymentMethod: PaymentMethod;
}

