<dx-popup
    title="Dumpster Maintenance"
    [width]="400"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="maintenance" class="mb-4" validationGroup="mainValidationGroup">
        <dxi-item dataField="dueDate" editorType="dxDateBox"></dxi-item>
        <dxi-item dataField="note" editorType="dxTextArea" [editorOptions]="{ height: '125px' }" [isRequired]="true"></dxi-item>
        <dxi-item dataField="completed" editorType="dxDateBox"></dxi-item>
    </dx-form>
</dx-popup>
