import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { AuthService, CdrUnitOfWork, ReportService } from '../../core/core.module';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
    selector: 'app-email-dialog',
    templateUrl: './email-dialog.component.html',
    styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent extends BaseDialogComponent<any> implements OnInit {
    emailMessageId: number;
    entityIds: number[];
    entities: any[];
    formData: any = {};
    mode: 'Invoice' | 'Payment';
    dialogHeader: string;
    reportName: string;
    isSingleEmail: boolean;
    processing: boolean;
    readOnly: boolean;
    sendToMode: boolean;

    constructor(private uow: CdrUnitOfWork, private auth: AuthService, private reportService: ReportService) {
        super();
    }

    async ngOnInit() {
        this.dialogHeader = `Email ${this.mode}`;
        this.reportName = `View ${this.mode}`;

        if (!this.emailMessageId) {
            this.isSingleEmail = this.entityIds.length === 1;
            let emailSubject;
            let emailBody;
            switch (this.mode) {
                case 'Invoice':
                    this.entities = await this.uow.invoices.byIds(this.entityIds, 'customer');
                    if (this.isSingleEmail) this.formData.to = this.entities[0].customer.email;
                    emailSubject = (await this.uow.getSettingsByName('EmailSubject'))?.stringValue ?? '';
                    emailBody = (await this.uow.getSettingsByName('EmailBody'))?.stringValue ?? '';
                    break;
                case 'Payment':
                    this.entities = await this.uow.payments.byIds(this.entityIds, 'customer');
                    if (this.isSingleEmail) this.formData.to = this.entities[0].customer.email;
                    emailSubject = (await this.uow.getSettingsByName('PaymentEmailSubject'))?.stringValue ?? '';
                    emailBody = (await this.uow.getSettingsByName('PaymentEmailBody'))?.stringValue ?? '';
                    break;
            }
            this.formData.subject = emailSubject;
            this.formData.body = emailBody;
        } else {
            this.readOnly = true;
            this.isSingleEmail = true;
            const emailMessage = await this.uow.emailMessages.byId(this.emailMessageId);
            this.formData.to = emailMessage.to;
            this.formData.cc = emailMessage.cc;
            this.formData.bcc = emailMessage.bcc;
            this.formData.subject = emailMessage.subject;
            this.formData.body = emailMessage.body;
        }
    }

    save = async () => {
        if (this.processing) return;
        this.processing = true;

        const valid = this.valid();
        if (!valid) {
            this.processing = false;
            return;
        }

        const emailMessages = [];
        const skippedEntities = [];
        this.entities.forEach(entity => {
            const emailMessage = this.uow.emailMessages.createEntity({
                created: new Date(),
                createdBy: this.auth.userValue.userName,
                cc: this.formData.cc,
                bcc: this.formData.bcc,
                subject: this.formData.subject,
                body: this.formData.body
            });

            if (this.isSingleEmail || this.sendToMode) emailMessage.to = this.formData.to;

            switch (this.mode) {
                case 'Invoice':
                    emailMessage.invoiceId = entity.id;
                    if (!this.isSingleEmail && !this.sendToMode) {
                        if (entity.customer.email) emailMessage.to = entity.customer.email;
                        else {
                            emailMessage.to = 'ryan.busscher@gmail.com';
                            skippedEntities.push(entity);
                        }
                    }
                    break;
                case 'Payment':
                    emailMessage.paymentId = entity.id;
                    break;
            }

            emailMessages.push(emailMessage);
        });

        await this.uow.commit();
        this.hide({ success: true, value: { emailMessages, skippedEntities } });
    };

    cancel = async () => {
        if (this.processing) return;
        this.processing = true;

        this.uow.rollback();
        this.hide({ success: false });
    };

    openReport() {
        switch (this.mode) {
            case 'Invoice':
                this.reportService.printInvoiceReport([this.entityIds[0]]);
                break;
            case 'Payment':
                this.reportService.printPaymentReceiptReport([this.entityIds[0]]);
                break;
        }
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
