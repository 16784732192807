<dx-popup
    [title]="dialogHeader"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Save', type: 'success', onClick: save }"
        [disabled]="processing"
        *ngIf="!readOnly"
    ></dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }"
        [disabled]="processing"
        *ngIf="!readOnly"
    ></dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Close', type: 'default', onClick: cancel }"
        [disabled]="processing"
        *ngIf="readOnly"
    ></dxi-toolbar-item>
    <dx-form *ngIf="formData" [formData]="formData" [readOnly]="readOnly" validationGroup="mainValidationGroup">
        <dxi-item dataField="to" *ngIf="isSingleEmail || sendToMode">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="cc"> </dxi-item>
        <dxi-item dataField="bcc"> </dxi-item>
        <dxi-item dataField="subject"> </dxi-item>
        <dxi-item dataField="body" editorType="dxTextArea" [editorOptions]="{ height: 350 }"> </dxi-item>
        <dxi-item [label]="{ text: 'Report' }" *ngIf="(mode === 'Invoice' || mode === 'Payment') && isSingleEmail">
            <div *dxTemplate>
                <a [routerLink]="[]" (click)="openReport()">{{ reportName }}</a>
            </div>
        </dxi-item>
    </dx-form>
</dx-popup>
