<div class="content-block">
    <div class="dx-card responsive-paddings px-4">
        <div class="logos-container">
            <img src="assets/images/cdr-logo.jpg" title="CDR Disposal logo" style="height: 150px" />
        </div>

        <h2>Welcome to <b>CDR Disposal</b>!</h2>
        <p><button (click)="login()" *ngIf="!(account$ | async)">Login</button></p>

        <div *ngIf="account$ | async as account">
            <div style="font-size: 1.2em; cursor: pointer">
                <div *ngIf="!(user$ | async)" (click)="showClaims = !showClaims">The account "{{account.username}}" is not setup as a user for CDR</div>
                <div *ngIf="user$ | async as user" (click)="showClaims = !showClaims">{{ user?.firstName }} {{ user?.lastName }}</div>
            </div>
            <div class="mx-3" *ngIf="showClaims">
                <!-- <div>{{ account | json }}</div> -->
                <div>{{ account?.name }}</div>
                <div>{{ account?.localAccountId }}</div>
                <div>{{ account?.username }}</div>
            </div>
            <p><button (click)="logout()" class="mt-4">Logout</button></p>
            <!-- <button (click)="openRentalForm()" class="mt-4">Rental Form</button> -->
        </div>
        <p class="mt-4">version {{ appInfo?.appVersion }}</p>
    </div>
</div>
