import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { DispatchNote } from 'src/app/core/model/dispatch-note';
import { Dumpster } from 'src/app/core/model/dumpster';
import { JobType } from 'src/app/core/model/job-type';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { DispatchNotesDetailComponent } from '../dispatch-notes-detail/dispatch-notes-detail.component';

@Component({
    selector: 'app-dispatch-notes-dialog',
    templateUrl: './dispatch-notes-dialog.component.html',
    styleUrls: ['./dispatch-notes-dialog.component.scss']
})
export class DispatchNotesDialogComponent extends BaseDialogComponent<any> implements OnInit {
    @ViewChild('dispatchNotesDetailComponent') dispatchNotesDetailComponent: DispatchNotesDetailComponent

    constructor(private uow: CdrUnitOfWork, private auth: AuthService) {
        super();
    }

    ngOnInit() {
    }

    start = async () => {
        await this.close(true);
    };

    save = async () => {
        await this.close();
    };

    async close(start: boolean = false) {
        const dispatchNote = this.dispatchNotesDetailComponent.dispatchNote;
        await this.uow.commit();
        this.hide({ success: true, value: { dispatchNote: dispatchNote, start: start} });
    }


    cancel = async () => {
        this.hide({ success: false });
    };
}
