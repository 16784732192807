import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { AppInformationService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';

@Component({
    selector: 'app-inventory-overview',
    templateUrl: './inventory-overview.component.html',
    styleUrls: ['./inventory-overview.component.scss']
})
export class InventoryOverviewComponent implements OnInit {
    currentInventoryInfos: any[];
    inventoryProjections: any[];
    inventoryUsage: any;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService, private utility: UtilityService) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        const inventoryInfo = await this.uow.getInventoryInfo();
        this.inventoryUsage = await this.utility.getDumpsterUsage();

        this.currentInventoryInfos = inventoryInfo.currentInventoryInfos;
        this.currentInventoryInfos.forEach(info => {
            let locationsHTML = [];
            info.locations.forEach(location => locationsHTML.push(`${location.storageName} --- ${location.quantity}`));
            info.locationsHTML = locationsHTML.join('<br/>');
            info.usageAverage = info.onSiteCount / info.totalCount;
        });
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async onSelectionChanged(e) {
        if (!e.selectedRowsData) return;
        this.appInfo.pleaseWaitShow();

        const inventoryProjections = await this.uow.getInventoryProjectionInfo(e.selectedRowsData[0].typeId);
        inventoryProjections.forEach(x => {
            x.start = moment(x.startDate).toDate();
        });
        this.inventoryProjections = inventoryProjections
        this.appInfo.pleaseWaitHide();
    }

    rowDoubleClick(e) {}
}
