<ng-container *ngIf="true || isAuthorized(); else notAuthorized">
    <app-top-nav appTitle="{{ appInfo.appTitle }}" [title]="appInfo.title">
        <router-outlet></router-outlet>
    </app-top-nav>
</ng-container>

<ng-template #notAuthorized>
    <div class="content main-content">
        <app-single-card>
            <router-outlet></router-outlet>
        </app-single-card>
    </div>
</ng-template>

<dx-load-panel
    #loadPanel
    message="Please wait..."
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: 'window' }"
    [visible]="pleaseWait()"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="false"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
