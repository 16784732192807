import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { StorageDumpSite } from 'src/app/core/model/storage-dump-site';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-storage-dump-site-detail',
    templateUrl: './storage-dump-site-detail.component.html',
    styleUrls: ['./storage-dump-site-detail.component.scss']
})
export class StorageDumpSiteDetailComponent extends BaseDialogComponent<any> implements OnInit {
    storageDumpSite: StorageDumpSite;
    success: boolean = false;

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService, private authService: AuthService) {
        super();
    }

    async ngOnInit() {
        if (!this.storageDumpSite) this.storageDumpSite = this.createStorageDumpSite();
    }

    createStorageDumpSite() {
        return this.uow.storageDumpSites.createEntity({
            created: moment(new Date()).toDate(),
            createdBy: this.authService.userValue.userName
        });
    }

    save = async () => {
        await this.uow.commit();
        this.success = true;
        this.hide({ success: this.success, storageDumpSite: this.storageDumpSite });
    };

    saveAndNew = async () => {
        await this.uow.commit();
        this.success = true;
        this.storageDumpSite = this.createStorageDumpSite();
    };

    cancel = async () => {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;
        this.uow.rollback();
        this.close();
    };

    close = () => {
        this.hide({ success: this.success });
    };

    hasChanges() {
        return this.uow.hasChanges();
    }
}
