import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppInformationService } from './app-information.service';
import { SessionInformationService } from './session-information.service';
import * as moment from 'moment/moment';
import { firstValueFrom } from 'rxjs';
import { Maintenance } from './model/maintenance';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    static serviceName: string;

    static localToUtc(datetime: Date) {
        if (!datetime) return datetime;

        return moment(datetime).subtract(moment(datetime).utcOffset(), 'minutes').toDate();
    }

    static utcToLocal(datetime: Date) {
        if (!datetime) return datetime;
        else {
            return moment(datetime).add(moment(datetime).utcOffset(), 'minutes').toDate();
        }
    }

    static makeReadOnly(e) {
        e.element.querySelector('.dx-texteditor-input').setAttribute('readonly', 'readonly');
    }

    constructor(private http: HttpClient, private appInfo: AppInformationService, private sessionInfo: SessionInformationService) {
        UtilityService.serviceName = environment.serviceName;
    }

    async getNextNumber(name: string) {
        const result = await firstValueFrom(this.http.post(`${UtilityService.serviceName}/utility/getNextIntNumber`, { name }));
        return result;
    }

    async getApplicationInfo() {
        return {};
    }

    async getGeo(text: string) {
        const result = await firstValueFrom(this.http.post(`${UtilityService.serviceName}/utility/testGeoAPIfy`, { text: text }));
        return result;
    }

    async updateUncompletedJobs(serviceAddressId: number) {
        const result = await firstValueFrom(
            this.http.post(`${UtilityService.serviceName}/utility/updateUncompletedJobs`, { serviceAddressId })
        );
        return result;
    }

    async uncompleteJob(jobId: number) {
        const result = await firstValueFrom(this.http.post(`${UtilityService.serviceName}/utility/uncompleteJob`, { jobId }));
        return result;
    }

    async addEmailToQueue(ids: number[]) {
        const result = await firstValueFrom(this.http.post(`${UtilityService.serviceName}/utility/addEmailToQueue`, { ids }));
        return result;
    }

    async sendEmail(ids: number[]) {
        const result = await firstValueFrom(this.http.post(`${UtilityService.serviceName}/utility/sendEmail`, { ids }));
        return result as boolean;
    }

    async getARTotals() {
        const result = await firstValueFrom<any>(this.http.get(`${UtilityService.serviceName}/utility/aRTotals`));
        return result as any[];
    }

    async getARAging() {
        const result = await firstValueFrom<any>(this.http.get(`${UtilityService.serviceName}/utility/aRAging`));
        return result as any[];
    }
    async getDumpsterUsage() {
        const result = await firstValueFrom<any>(this.http.get(`${UtilityService.serviceName}/utility/dumpsterUsage`));
        return result as any[];
    }

    async updateCustomerBalance(customerId: number) {
        const result = await firstValueFrom<any>(
            this.http.post(`${UtilityService.serviceName}/utility/updateCustomerBalance`, { customerId: customerId })
        );
        return result as boolean;
    }

    async generateRevenueLines(jobId: number) {
        const result = await firstValueFrom<any>(
            this.http.post(`${UtilityService.serviceName}/utility/generateRevenueLines`, { jobId: jobId })
        );
        return result as boolean;
    }

    async postToGreatPlains(ids: number[]) {
        const result = await firstValueFrom(
            this.http.post<{ success: boolean; message?: string }>(`${UtilityService.serviceName}/utility/postToGreatPlains`, { ids })
        );
        return result;
    }

    getMaintenanceLinks(maintenances: Maintenance[]) {
        return _(maintenances)
            .orderBy(m => [m.completed, m.dueDate], ['asc', 'desc'])
            .map(m => {
                return {
                    maintenance: m,
                    html: [
                        '-',
                        m.completed
                            ? `Completed: ${moment(m.completed).format('MM/DD/YYYY')} -`
                            : m.dueDate
                            ? `Due: ${moment(m.dueDate).format('MM/DD/YYYY')} -`
                            : '',
                        m.note
                    ].join(' ')
                };
            })
            .valueOf();
    }

    async completeJob(jobId: number, inventoryId: number, destinationPickId: number, destinationDropId: number, weight: number) {
        const result = await firstValueFrom(
            this.http.post<{ success: boolean; message?: string }>(`${UtilityService.serviceName}/job/completeJob`, {
                jobId,
                inventoryId,
                destinationPickId,
                destinationDropId,
                weight
            })
        );
        return result;
    }
}
