import { Component, OnInit } from '@angular/core';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { InvoiceLine } from 'src/app/core/model/invoice-line';
import { QuickBooksProduct } from 'src/app/core/model/quick-books-product';
import { Rental } from 'src/app/core/model/rental';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-invoice-line-dialog',
    templateUrl: './invoice-line-dialog.component.html',
    styleUrls: ['./invoice-line-dialog.component.scss']
})
export class InvoiceLineDialogComponent extends BaseDialogComponent<any> implements OnInit {
    customerId: number;
    invoiceLine: InvoiceLine;
    dumpsters: Dumpster[];
    rentals: Rental[];
    quickBooksProducts: QuickBooksProduct[];

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.dumpsters = this.uow.lookups.dumpsters as Dumpster[];
        this.rentals = await this.uow.rentals.getActiveRentals({ customerId: this.customerId });
        this.quickBooksProducts = await this.uow.getQBProducts(true);
    }

    onRentalChanged(e) {
        const rental = this.rentals.find(rental => rental.id === e.value);
        this.invoiceLine.service = rental?.dumpster.size ?? null;
        this.invoiceLine.rate = rental?.price ?? null;
    }

    save = async () => {
        this.hide({ success: true, value: this.invoiceLine });
    };

    cancel = async () => {
        this.hide({ success: false });
    };
}
