// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RateType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static RateTypes = {
    Dumpster: 1,
    Hauling: 2,
    Shop: 3,
    Zone: 4,
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  name: string;
}

