// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Invoice } from './invoice';
import { Payment } from './payment';
import { Statement } from './statement';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class EmailMessage extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  bcc: string;
  body: string;
  cc: string;
  created: Date;
  createdBy: string;
  error: string;
  from: string;
  invoiceId: number;
  paymentId: number;
  sent: Date;
  statementId: number;
  subject: string;
  to: string;
  invoice: Invoice;
  payment: Payment;
  statement: Statement;
}

