<div class="flex-fill d-flex flex-column w-100">
    <div class="date-select-card">
        <div class="d-flex flex-row align-items-center">
            <label class="mr-3">Schedule Date</label>
            <dx-date-box style="max-width: '150px'" [(value)]="scheduleDate" (onValueChanged)="onDateChanged()"></dx-date-box>
            <button (click)="changeDate(-1)" class="btn btn-sm btn-secondary ml-4 mr-2" style="min-width: 100px">Previous</button>
            <button (click)="changeDate(1)" class="btn btn-sm btn-secondary mr-2" style="min-width: 100px">Next</button>
            <button class="btn btn-sm btn-success mr-4" (click)="refresh()">Refresh Data</button>
            <button class="btn btn-sm btn-primary mr-2" (click)="addJobNote()" [hidden]="!isScheduleAdmin">Add Note</button>
        </div>
    </div>
    <div id="kanban" class="flex-fill d-flex flex-row">
        <div *ngIf="storageDumpSitesList" class="d-flex flex-column">
            <div class="list-title dx-theme-text-color">{{ 'Storage & Dump Sites' }}</div>
            <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
                <dx-sortable
                    class="sortable-cards"
                    group="cardsGroup"
                    [data]="storageDumpSitesList"
                    (onDragStart)="onstorageDumpSitesDragStart($event)"
                    (onReorder)="onstorageDumpSitesDrop($event)"
                    (onAdd)="onstorageDumpSitesDrop($event)"
                >
                    <div
                        class="card dx-card dx-theme-text-color dx-theme-background-color text-wrap"
                        *ngFor="let site of storageDumpSitesList.storageDumpSites"
                    >
                        <div class="card-priority" [ngClass]="site.storage ? 'priority-5' : 'priority-2'"></div>
                        <div>{{ site.name }}</div>
                        <div class="card-assignee card-subject">{{ site.displayAddress }}</div>
                        <div *ngIf="site.storage" class="card-assignee card-subject" style="color: red; font-weight: bold">
                            <span [innerHTML]="site.locationsHTML"></span>
                        </div>
                    </div>
                </dx-sortable>
            </dx-scroll-view>
        </div>
        <div *ngIf="unAssignedJobs" class="d-flex flex-column">
            <div class="list-title dx-theme-text-color">{{ 'UnAssigned' }}</div>
            <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
                <dx-sortable
                    class="sortable-cards"
                    group="cardsGroup"
                    [data]="unAssignedJobs"
                    (onDragStart)="onTaskDragStart($event)"
                    (onReorder)="onTaskDrop($event)"
                    (onAdd)="onTaskDrop($event)"
                >
                    <div
                        id="unassigned-job-card"
                        class="card dx-card dx-theme-text-color dx-theme-background-color text-wrap"
                        *ngFor="let job of unAssignedJobs.assignedJobs"
                        (contextmenu)="onContextMenu(job.id)"
                    >
                        <div class="card-priority" [ngClass]="'priority-' + job.Task_Priority"></div>
                        <div *ngIf="job.rentalId" class="card-assignee">{{ job.rental.customer.displayName }}</div>
                        <div class="card-subject" *ngIf="job.jobTypeId !== 6 && job.jobTypeId !== 7">
                            {{ job.address1 }}
                        </div>
                        <div class="card-subject">
                            {{ job.displayId }}: {{ job.dumpsterId ? job.dumpster.size + ' -' : '' }} {{ job.scheduleJobTypeName }}
                        </div>
                        <div *ngIf="!job.rentalId" class="card-subject">{{ job.displayAddress }}</div>
                    </div>
                </dx-sortable>
            </dx-scroll-view>
        </div>

        <dx-scroll-view class="scrollable-board" direction="horizontal" showScrollbar="always">
            <dx-sortable
                class="sortable-lists"
                [data]="driverColumns"
                itemOrientation="horizontal"
                handle=".list-title"
                (onDragStart)="onListDragStart($event)"
                (onReorder)="onListReorder($event)"
            >
                <div class="list" *ngFor="let driver of driverColumns">
                    <div class="list-title dx-theme-text-color">{{ driver.name }}</div>
                    <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
                        <dx-sortable
                            class="sortable-cards"
                            group="cardsGroup"
                            [data]="driver"
                            (onDragStart)="onTaskDragStart($event)"
                            (onReorder)="onTaskDrop($event)"
                            (onAdd)="onTaskDrop($event)"
                        >
                            <div
                                id="job-card"
                                [class]="
                                    'flex-row card dx-card dx-theme-text-color dx-theme-background-color text-wrap' +
                                    (assignedJob.completed
                                        ? ' card-completedJob'
                                        : assignedJob._nonCDRDumpster
                                        ? ' card-other-salesperson'
                                        : '')
                                "
                                *ngFor="let assignedJob of driver.assignedJobs"
                                (contextmenu)="onContextMenu(assignedJob.id)"
                            >
                                <div class="flex-fill">
                                    <div
                                        *ngIf="!assignedJob.completed"
                                        class="card-priority"
                                        [ngClass]="'priority-' + assignedJob.jobTypeId"
                                    ></div>
                                    <div class="card-assignee">{{ assignedJob.rental?.customer?.displayName }}</div>
                                    <div
                                        *ngIf="
                                            !assignedJob.completed &&
                                            assignedJob.jobTypeId !== 6 &&
                                            assignedJob.jobTypeId !== 7 &&
                                            assignedJob.preferredStartTime
                                        "
                                        class="card-subject"
                                    >
                                        {{ assignedJob.preferredStartTime | date : 'shortTime'
                                        }}{{
                                            assignedJob.preferredEndTime ? '-' + (assignedJob.preferredEndTime | date : 'shortTime') : ''
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            !assignedJob.completed &&
                                            assignedJob.jobTypeId !== 6 &&
                                            assignedJob.jobTypeId !== 7 &&
                                            assignedJob.address1
                                        "
                                        class="card-subject"
                                    >
                                        <a [routerLink]="[]" (click)="openMaps(assignedJob)"
                                            >{{ assignedJob.address1 }} {{ assignedJob.city }}</a
                                        >
                                    </div>
                                    <div *ngIf="assignedJob.rentalId || assignedJob.jobTypeId === 7" class="card-subject">
                                        <!-- J{{ assignedJob.id }}: {{ assignedJob.dumpsterId ? assignedJob.dumpster.size + ' -' : '' }}
                                                {{ assignedJob.jobType.name }} {{assignedJob.jobTypeId === 3 || assignedJob.jobTypeId === 4 ? assignedJob.action?.name : ''}} -->
                                        {{ assignedJob.scheduleJobTypeName }}
                                    </div>
                                    <div *ngIf="!assignedJob.rentalId" class="card-subject">
                                        {{ assignedJob.siteName }}
                                    </div>
                                </div>
                                <div class="d-flex flex-fill align-items-center" style="min-width: 10px; max-width: 10px">
                                    <i
                                        *ngIf="!assignedJob.completed && assignedJob.started"
                                        class="fa fa-clock-o mr-1"
                                        style="color: #028d00"
                                        aria-hidden="true"
                                    ></i>
                                    <i
                                        *ngIf="!assignedJob.completed && !!assignedJob.notes"
                                        class="fa fa-exclamation"
                                        style="color: red"
                                        aria-hidden="true"
                                    ></i>
                                    <i
                                        *ngIf="assignedJob.completed"
                                        class="fa fa-check-circle fa-2"
                                        style="color: #028d00"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </div>
                        </dx-sortable>
                    </dx-scroll-view>
                </div>
            </dx-sortable>
        </dx-scroll-view>
    </div>
</div>
<dx-context-menu target="#job-card" [dataSource]="jobContextMenuItems" (onItemClick)="onContextMenuItemClick($event)"> </dx-context-menu>
<dx-context-menu target="#unassigned-job-card" [dataSource]="unassignedJobContextMenuItems" (onItemClick)="onContextMenuItemClick($event)">
</dx-context-menu>
