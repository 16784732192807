<dx-popup
    title="Customer"
    [width]="1200"
    [height]="800"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Save', type: 'success', accessKey: 's', onClick: save }"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }"
        *ngIf="true"
    >
    </dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'">
        <app-customer-detail #customerDetailComponent [showAsDialog]="true" [dialogKey]="dialogKey"> </app-customer-detail>
    </div>
</dx-popup>
