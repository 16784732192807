import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';
import { DumpsterLocation } from 'src/app/core/model/dumpster-location';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { Rental } from 'src/app/core/model/rental';
import { ServiceAddressDialogComponent } from 'src/app/customer/service-address-dialog/service-address-dialog.component';
import { AuditDialogComponent } from 'src/app/shared/audit-dialog/audit-dialog.component';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { alert, confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-job-dialog',
    templateUrl: './job-dialog.component.html',
    styleUrls: ['./job-dialog.component.scss']
})
export class JobDialogComponent extends BaseDialogComponent<any> implements OnInit {
    job: Job;
    jobTypes: JobType[];
    rentals: Rental[];
    canChangeDumpster = false;
    dumpsters: any;
    isDifferentThanRental: boolean = false;
    latestDumpsterInfo: any = {};
    dumpsterLocations: any[] = [];
    completeJobMode: boolean = false;
    inventories: any;
    storageDropInventories: any;
    storagePickInventories: any;

    weightRequired: boolean = false;
    idRequired: boolean = false;
    isStorageDestination: boolean = false;

    inventoryId: number;
    destinationPickId: number;
    destinationDropId: number;

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private auth: AuthService,
        private dialogService: DialogService,
        private utilityService: UtilityService
    ) {
        super();
    }

    async ngOnInit() {
        this.jobTypes = this.uow.lookups.jobTypes as JobType[];
        this.dumpsters = this.uow.lookups.dumpsters;
        this.canChangeDumpster = !this.job.completed && this.auth.hasRole('Admin');
        await this.getDumpsterLocations();

        if (this.completeJobMode) {
            this.weightRequired = this.job.actionId === JobType.Actions.PickUp && !!this.job.started;
            this.idRequired =
                this.job.dumpster &&
                this.job.dumpster.size !== 'Customer Owned' &&
                this.job.jobTypeId !== JobType.Types.Destination &&
                this.job.jobTypeId !== JobType.Types.Note &&
                this.job.jobTypeId !== JobType.Types.Service &&
                !this.job.completed;
            this.isStorageDestination = this.job.jobTypeId === JobType.Types.Destination && this.job.storageDumpSite.storage;
            await this.getInventoryInformation();
        }
    }

    async getInventoryInformation() {
        if (this.isStorageDestination) {
            this.storagePickInventories = await this.uow.getStorageInventories(this.job.storageDumpSiteId);
            this.storageDropInventories = await this.uow.getInventories(null, 'storageDelivery');
        }

        if (this.idRequired) {
            this.inventories = await this.uow.getInventories(
                this.job.dumpsterId,
                this.job.actionId === JobType.Actions.Delivery ? 'delivery' : 'pickup',
                this.job.id
            );
        }
    }

    async getDumpsterLocations() {
        if (this.job.actionId) {
            let dumpsterLocationOption;
            const isDelivery = this.job.actionId === JobType.Actions.Delivery;
            if (isDelivery) dumpsterLocationOption = { placedJobId: this.job.id, expand: 'dumpsterInventory' };
            else dumpsterLocationOption = { removedJobId: this.job.id, expand: 'dumpsterInventory' };
            const dumpsterLocations = await this.uow.getDumpsterLocations(dumpsterLocationOption);
            if (dumpsterLocations.length > 0) {
                const latestDumpster = dumpsterLocations.splice(0, 1)[0];
                this.latestDumpsterInfo.barcode = latestDumpster.dumpsterInventory.dumpsterBarcode;
                this.latestDumpsterInfo.byUser = isDelivery ? latestDumpster.createdBy : latestDumpster.completedBy;
                this.latestDumpsterInfo.date = (isDelivery ? latestDumpster.placed : latestDumpster.removed).toLocaleString();
                dumpsterLocations.forEach(location => {
                    const barcode = `${location.dumpsterInventory.dumpsterBarcode}`;
                    const userName = isDelivery ? location.createdBy : location.completedBy;
                    const dateTime = (isDelivery ? location.placed : location.removed).toLocaleString();
                    this.dumpsterLocations.push({ text: `${barcode} - ${userName} - ${dateTime}` });
                });
            }
        }
    }

    save = async () => {
        this.hide({ success: true, value: this.job });
    };

    cancel = async () => {
        this.hide({ success: false });
    };
    dumpsterChanged = e => {
        this.isDifferentThanRental = this.job.dumpsterId !== this.job.rental.dumpsterId;
    };

    async completeJob() {
        this.appInfo.pleaseWaitShow();
        const result = await this.utilityService.completeJob(
            this.job.id,
            this.inventoryId,
            this.destinationPickId,
            this.destinationDropId,
            this.job.weight
        );
        this.appInfo.pleaseWaitHide();
        if (!result.success) {
            await alert(`${result.message}`, `${this.appInfo.company}`);
        } else {
            await alert(`Job Completed!`, `${this.appInfo.company}`);
            this.utilityService.generateRevenueLines(this.job.id);
            this.hide({ success: true, value: this.job });
        }
    }

    async openServiceAddress(serviceAddressId: number) {
        if (!serviceAddressId) return;
        const serviceAddress = await this.uow.serviceAddresses.byId(serviceAddressId);

        const dialogRef = this.dialogService.open(ServiceAddressDialogComponent);
        dialogRef.instance.serviceAddress = serviceAddress;
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();

            this.job.siteName = serviceAddress.siteName;
            this.job.address1 = serviceAddress.address1;
            this.job.address2 = serviceAddress.address2;
            this.job.city = serviceAddress.city;
            this.job.state = serviceAddress.state;
            this.job.zip = serviceAddress.zip;
            this.job.country = serviceAddress.country;
            this.job.latitude = serviceAddress.latitude;
            this.job.longitude = serviceAddress.longitude;
            await this.uow.commit();

            const success = await confirm(
                'Do you want to update all uncompleted jobs that use this service address as well?',
                `${this.appInfo.company}`
            );
            if (success) await this.utilityService.updateUncompletedJobs(serviceAddressId);

            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    startDateChanged = e => {
        if (!e.value) return;
        this.setPreferredTimeDates();
    };
    startTimeValueChanged = e => {
        if (!e.event || !e.value) return;

        if (!this.job.preferredEndTime) this.job.preferredEndTime = moment(e.value).add(1, 'hours').toDate();
        this.setPreferredTimeDates();
    };
    endTimeValueChanged = e => {
        this.setPreferredTimeDates();
    };
    setPreferredTimeDates() {
        const startDate = this.job.startDate ?? new Date();

        const day = moment(startDate).get('date');
        const month = moment(startDate).get('month');
        const year = moment(startDate).get('year');

        if (this.job.preferredStartTime)
            this.job.preferredStartTime = moment(this.job.preferredStartTime).date(day).month(month).year(year).toDate();
        if (this.job.preferredEndTime)
            this.job.preferredEndTime = moment(this.job.preferredEndTime).date(day).month(month).year(year).toDate();
    }

    openAuditLog = async () => {
        const auditLogs = await this.uow.getAuditLogs({ jobId: this.job.id });
        const dialogRef = this.dialogService.open(AuditDialogComponent);
        dialogRef.instance.auditLogs = auditLogs;
        const results = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);
    };
}
