// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { DumpsterInventory } from './dumpster-inventory';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Maintenance extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  completed: Date;
  created: Date;
  createdBy: string;
  deleted: Date;
  deletedBy: string;
  dueDate: Date;
  dumpsterInventoryId: number;
  note: string;
  dumpsterInventory: DumpsterInventory;
}

