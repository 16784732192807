// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { CreditCard } from './credit-card';
import { Customer } from './customer';
import { EmailMessage } from './email-message';
import { InvoiceLine } from './invoice-line';
import { InvoiceStatus } from './invoice-status';
import { PaymentLine } from './payment-line';
import { Rental } from './rental';
import { ServiceAddress } from './service-address';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class Invoice extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getNextLineNumber(lines: any[]) {
        if (lines.length === 0) return 1;

        let lineNumber: any;
        lineNumber = _.maxBy(lines, 'lineNumber').lineNumber + 1;
        return lineNumber;
    }

    get displayBillAddress() {
        const addressArray = [
            this.billToContactName,
            this.billToAddress1,
            this.billToAddress2,
            this.billToCity,
            this.billToState,
            this.billToCountry,
            this.billToZip
        ];
        return addressArray.join(' ');
    }
    get displayServiceAddress() {
        const addressArray = [
            this.serviceAddress1,
            this.serviceAddress2,
            this.serviceCity,
            this.serviceState,
            this.serviceCountry,
            this.serviceZip
        ];
        return addressArray.join(' ');
    }
    get rentalIdDisplay() {
        return `${this.rentalId ? 'R' + this.rentalId : '----'}`;
    }

    // disabled including credit card fee in totals. Replace with commented out getters to add back
    get creditCardFee() {
        if (this.paymentMethod !== 'Credit-Debit' || !this.creditCardPercentage) return 0;

        return this.totalWithTax * this.creditCardPercentage;
    }

    // get creditCardFee() {
    //     if (this.paymentMethod !== 'Credit-Debit' || !this.creditCardPercentage) return 0;

    //     return this.totalWithTax - this.totalWithTax / (1 + this.creditCardPercentage);
    // }

    // get totalWithTaxMinusFee() {
    //     return this.totalWithTax - this.creditCardFee;
    // }

    get totalWithTax() {
        // total includes credit card fee
        return this.total + this.tax;
    }

    get sent() {
        return this.emailed ? this.emailed : this.printed ? this.printed : null;
    }

    get unpaidBalance() {
        return this.totalWithTax - this.payments;
    }

    get isPaid() {
        return !!this.paid;
    }

    get isDeleted() {
        return !!this.deleted;
    }

    payments: number = 0;
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  balance: number;
  batchNumber: number;
  billToAddress1: string;
  billToAddress2: string;
  billToCity: string;
  billToContactName: string;
  billToCountry: string;
  billToEmail: string;
  billToPhone: string;
  billToState: string;
  billToZip: string;
  code: string;
  createDate: Date;
  createdBy: string;
  creditCardId: number;
  creditCardPercentage: number;
  customerId: number;
  deleted: Date;
  dueDate: Date;
  emailed: Date;
  freight: number;
  invoiceDate: Date;
  invoiceNumber: number;
  note: string;
  pONumber: string;
  paid: Date;
  paymentMethod: string;
  printed: Date;
  privateNote: string;
  quickBooksId: string;
  quickBooksLastExport: Date;
  readyToExport: Date;
  rentalId: number;
  serviceAddress1: string;
  serviceAddress2: string;
  serviceAddressId: number;
  serviceCity: string;
  serviceCountry: string;
  serviceState: string;
  serviceZip: string;
  statusId: number;
  tax: number;
  terms: string;
  total: number;
  creditCard: CreditCard;
  customer: Customer;
  emailMessages: EmailMessage[];
  invoiceLines: InvoiceLine[];
  paymentLines: PaymentLine[];
  rental: Rental;
  serviceAddress: ServiceAddress;
  status: InvoiceStatus;
}

