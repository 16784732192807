<dx-popup title="Job" [width]="300" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="dumpsterInventory" validationGroup="mainValidationGroup" style="min-height: 125px">
        <dxi-item
            dataField="dumpsterTypeId"
            [label]="{ text: 'Dumpster Type' }"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: dumpsterTypes, displayExpr: 'size', valueExpr: 'id' }"
        >
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
            <dxi-validation-rule type="range" [min]="1" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="dumpsterNumber" editorType="dxNumberBox">
            <dxi-validation-rule type="custom" [validationCallback]="isNumberUnique" message="Dumpster Number Already Used" [reevaluate]="true"> </dxi-validation-rule>
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
    </dx-form>
</dx-popup>
