import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { AppInformationService, AuthService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { DumpsterInventory } from 'src/app/core/model/dumpster-inventory';
import { DialogService } from 'src/app/shared/dialog.service';
import { DumpsterInventoryDialogComponent } from '../dumpster-inventory-dialog/dumpster-inventory-dialog.component';
import * as moment from 'moment';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { DumpsterLocationDialogComponent } from '../dumpster-location-dialog/dumpster-location-dialog.component';
import { MaintenanceDialogComponent } from '../maintenance-dialog/maintenance-dialog.component';
import { Maintenance } from 'src/app/core/model/maintenance';

@Component({
    selector: 'app-dumpster-inventory-overview',
    templateUrl: './dumpster-inventory-overview.component.html',
    styleUrls: ['./dumpster-inventory-overview.component.scss']
})
export class DumpsterInventoryOverviewComponent implements OnInit {
    dumpsterInventories: any[];
    recentJobs: any[];
    dumpsterInventoryLocations: any;
    dumpsterTypes: Dumpster[];
    isDumpsterAdmin = false;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private dialogService: DialogService,
        private auth: AuthService,
        private utility: UtilityService
    ) {}

    async ngOnInit() {
        this.dumpsterTypes = await this.uow.lookups.dumpsters;
        this.isDumpsterAdmin = this.auth.hasRole('Dumpster:Admin');

        await this.getData();
    }

    async getData() {
        this.dumpsterInventoryLocations = await this.uow.getDumpsterInventoryLocations();
        this.dumpsterInventoryLocations.forEach(x => {
            x.createdDate = moment(x.created).toDate();
            if (x.recentJobs.length > 0) x.recentJobsHtml = x.recentJobs.join('</br>');
            if (x.maintenances.length > 0) x.maintenanceLinks = this.utility.getMaintenanceLinks(x.maintenances);
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    rowDoubleClick(e) {
        if (!this.isDumpsterAdmin) return;
        this.open(e.data);
    }

    async open(dumpsterInventory?: DumpsterInventory) {
        if (!this.isDumpsterAdmin) return;
        const dialogRef = this.dialogService.open(DumpsterInventoryDialogComponent);
        dialogRef.instance.dumpsterInventory = dumpsterInventory;
        const result = await dialogRef.instance.show();
        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async delete(selectedRow: any) {
        if (!selectedRow) return;

        const result = await confirm('Are you sure you want to delete the selected dumpster?', this.appInfo.appTitle);
        if (!result) return;

        this.appInfo.pleaseWaitShow();
        const dumpsterInventory = await this.uow.dumpsterInventories.byId(selectedRow.id);
        dumpsterInventory.deleted = new Date();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async createMaintenance(dumpsterInventoryLocation: any) {
        if (!dumpsterInventoryLocation) return;
        const dumpsterInventoryId = dumpsterInventoryLocation.id;

        const maintenance = this.uow.maintenances.createEntity({
            dumpsterInventoryId: dumpsterInventoryId,
            created: new Date(),
            createdBy: this.auth.userValue.userName
        });
        await this.editMaintenance(maintenance);
    }

    async editMaintenance(maintenance: Maintenance) {
        if (!maintenance) return;

        const dialogRef = this.dialogService.open(MaintenanceDialogComponent);
        dialogRef.instance.maintenance = maintenance;
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        } else this.uow.rollback();
        this.dialogService.close(dialogRef);
    }

    async addNote(dumpsterInventoryLocation: any) {
        if (!dumpsterInventoryLocation) return;

        let dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = `Note for ${dumpsterInventoryLocation.dumpsterBarcode}`;
        dialogRef.instance.mode = 'textarea';
        dialogRef.instance.dialogValue = dumpsterInventoryLocation.notes;
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();
            const dumpsterInventory = await this.uow.dumpsterInventories.byId(dumpsterInventoryLocation.id);
            dumpsterInventory.notes = results.value;
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async assignLocation(dumpsterInventoryLocation: any) {
        this.appInfo.pleaseWaitShow();
        const dumpsterInventory = await this.uow.dumpsterInventories.byId(dumpsterInventoryLocation.id);
        const existingLocation = await this.uow.getActiveDumpsterLocations(dumpsterInventory.id);
        if (existingLocation.length > 0) {
            this.appInfo.pleaseWaitHide();
            const success = await confirm(
                'This dumpster already has a location. Do you still want to set a new location?',
                this.appInfo.appTitle
            );
            if (!success) {
                return;
            }
        }

        let dialogRef = this.dialogService.open(DumpsterLocationDialogComponent);
        dialogRef.instance.dumpsterInventory = dumpsterInventory;
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async releaseDumpster() {
        const selectedRows = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedRows || selectedRows.length === 0) return;

        this.appInfo.pleaseWaitShow();
        const array = selectedRows;
        for (let index = 0; index < array.length; index++) {
            const row = array[index];
            if (row.currentDumpsterLocationId) {
                const location = await this.uow.dumpsterLocations.byId(row.currentDumpsterLocationId);
                location.removed = new Date();
            }
        }
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
