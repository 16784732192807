<div class="d-flex flex-column flex-fill dx-card">
    <div class="d-flex mt-3 ml-3">
        <dx-form [formData]="filter" style="max-width: 150px">
            <dxi-item dataField="startDate" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="endDate" editorType="dxDateBox"></dxi-item>
        </dx-form>
        <div class="d-flex flex-column ml-3 mt-3">
            <button class="btn btn-secondary" (click)="toggleShowPreviousWeek()">
                {{ showPreviousWeek ? 'This Week' : 'Previous Week' }}
            </button>
            <button class="btn btn-primary mt-2" (click)="getData()">Search</button>
        </div>
        <div *ngIf="!!tonsDumped" class="tons-dumped-table">
            <table class="table">
                <thead>
                    <h4 style="text-align: center;">Dumpster Tons By Type Summary</h4>
                    <tr>
                        <th>Type</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let category of tonsDumped.categories">
                        <td class="col-xs-2 table-little-padding">{{ category.name }}</td>
                        <td class="col-xs-1 table-little-padding total-right-align">{{ category.tons | number }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="flex-row flex-fill d-flex">
        <div class="d-flex flex-fill flex-column col-sm-5 pt-3 pb-2 px-2">
            <h4 class="mx-2 my-0">Revenue</h4>
            <dx-data-grid
                #revenueGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="350px"
                [dataSource]="revenue"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="id" caption="JobId" width="50"></dxi-column>
                <dxi-column dataField="rentalId" caption="RentalId" width="50"></dxi-column>
                <dxi-column dataField="customer" width="200"></dxi-column>
                <dxi-column dataField="action" width="100"></dxi-column>
                <dxi-column dataField="dumpster" width="100"></dxi-column>
                <dxi-column dataField="completed" dataType="date" format="M/d/yyyy" width="90"></dxi-column>
                <dxi-column
                    dataField="estimatedRevenue"
                    caption="Est.Rev."
                    [format]="{ type: 'currency', precision: 2 }"
                    width="90"
                ></dxi-column>
                <dxi-column dataField="weight" width="90"></dxi-column>
                <dxi-column dataField="salesPerson" width="90"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-summary>
                    <dxi-total-item column="estimatedRevenue" summaryType="sum" displayFormat="{0}" [valueFormat]="{ precision: 2 }">
                    </dxi-total-item>
                </dxo-summary>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="report-revenue-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-danger mr-1 mb-2"
                        (click)="createRevenueLines(revenueGrid.instance.getSelectedRowsData())"
                        [disabled]="revenueGrid.instance.getSelectedRowsData().length === 0"
                    >
                        Create Revenue Lines
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="revenue" class="mr-3">
                        <strong>{{ revenueGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
        <div class="d-flex flex-fill flex-column col-sm-5 pt-3 pb-2 px-2">
            <!-- <h4 *ngFor="let category of tonsDumped?.categories" class="mx-2 my-0">{{ category.name }}: {{ category.tons }}</h4> -->
            <h4 class="mx-2 my-0">Tons Dumped</h4>
            <dx-data-grid
                #tonsGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="350px"
                [dataSource]="tonsDumped?.jobs"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="completed" dataType="date" format="M/d/yyyy" width="90"></dxi-column>
                <dxi-column dataField="id" caption="Job #" width="50"></dxi-column>
                <dxi-column dataField="tons" width="100"></dxi-column>
                <dxi-column dataField="jobType" width="90"></dxi-column>
                <dxi-column dataField="action" width="90"></dxi-column>
                <dxi-column dataField="driver" width="125"></dxi-column>
                <dxi-column dataField="dumpster" width="75"></dxi-column>
                <dxi-column dataField="billAs" width="75"></dxi-column>
                <dxi-column dataField="customer" width="200"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-summary>
                    <dxi-total-item column="tons" summaryType="sum" [valueFormat]="{ precision: 2 }"> </dxi-total-item>
                </dxo-summary>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="report-tons-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="tonsDumped?.jobs" class="mr-3">
                        <strong>{{ tonsGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
        <div class="d-flex flex-fill flex-column col-sm-2 pt-3 pb-2 px-2">
            <h4 class="mx-2 my-0">Job Count: {{ jobCounts?.totalCount }}</h4>
            <dx-data-grid
                #jobsGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="350px"
                [dataSource]="jobCounts?.jobCounts"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="jobType" width="100"></dxi-column>
                <dxi-column dataField="action" width="100"></dxi-column>
                <dxi-column dataField="count" width="90"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-summary>
                    <dxi-total-item column="count" summaryType="sum"> </dxi-total-item>
                </dxo-summary>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="report-jobs-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="jobCounts?.jobCounts" class="mr-3">
                        <strong>{{ jobsGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
