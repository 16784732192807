import { Component } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import * as _ from 'lodash';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { DumpsterFeeType } from 'src/app/core/model/dumpster-fee-type';
import { DumpsterFeeTypeDumpsterLink } from 'src/app/core/model/dumpster-fee-type-dumpster-link';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-dumpster-fee-type-dialog',
    templateUrl: './dumpster-fee-type-dialog.component.html',
    styleUrls: ['./dumpster-fee-type-dialog.component.scss']
})
export class DumpsterFeeTypeDialogComponent extends BaseDialogComponent<any> {
    dumpsterFeeType: DumpsterFeeType;
    dumpsterFeeTypeDumpsterLinks: DumpsterFeeTypeDumpsterLink[] = [];
    dumpsters: Dumpster[];
    selectedDumpsterIds: number[] = [];
    currentDumpsterIds: number[] = [];

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.dumpsters = await this.uow.dumpsters.all();

        if (!this.dumpsterFeeType) {
            this.dumpsterFeeType = this.uow.dumpsterFeeTypes.createEntity({
                // price default is 0. Set to null so number box doesn't show 0
                price: null
            });
        } else {
            this.dumpsterFeeTypeDumpsterLinks = await this.uow.getDumpsterFeeTypeDumpsterLinks(this.dumpsterFeeType.id);
        }

        this.currentDumpsterIds = this.dumpsterFeeTypeDumpsterLinks.map(link => link.dumpsterId);
        this.selectedDumpsterIds = this.currentDumpsterIds.slice();
    }

    save = async () => {
        const success = this.valid();
        if (!success) return;

        const addedIds = _.difference(this.selectedDumpsterIds, this.currentDumpsterIds);
        const removedIds = _.difference(this.currentDumpsterIds, this.selectedDumpsterIds);
        addedIds.forEach(dumpsterId => {
            this.uow.dumpsterFeeTypeDumpsterLinks.createEntity({
                dumpsterFeeTypeId: this.dumpsterFeeType.id,
                dumpsterId: dumpsterId
            });
        });
        removedIds.forEach(dumpsterId => {
            const dumpsterFeeTypeDumpsterLink = this.dumpsterFeeTypeDumpsterLinks.find(link => link.dumpsterId === dumpsterId);
            dumpsterFeeTypeDumpsterLink.entityAspect.setDeleted();
        });

        await this.uow.commit();
        this.hide({ success: true, value: this.dumpsterFeeType });
    };

    cancel = async () => {
        this.uow.rollback();
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
