import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { HeaderModule } from 'src/app/shared/components';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
    menuOpened = true;

    @Input()
    appTitle: string;

    @Input()
    title: string;

    constructor() {}

    ngOnInit() {}
}

@NgModule({
    imports: [HeaderModule, CommonModule],
    exports: [TopNavComponent],
    declarations: [TopNavComponent]
})
export class TopNavModule {}
