import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SessionInformationService } from './session-information.service';
import { CdrUnitOfWork } from './data/cdr-unit-of-work';
import { PrepareGuard } from './prepare.guard';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { AuthService } from './auth.service';
import { PendingChangesGuard, ComponentCanDeactivate } from './pending-changes.guard';
import { AppInformationService } from './app-information.service';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { apiConfig, b2cPolicies } from './b2c-config';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalRedirectComponent,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { ToastErrorHandler } from './toast-error-handler.service';
import { ReportService } from './report.service';
import { RentalService } from './rental.service';
import { InvoiceService } from './invoice.service';

export {
    AppInformationService,
    SessionInformationService,
    AuthService,
    PrepareGuard,
    PendingChangesGuard,
    ComponentCanDeactivate,
    CdrUnitOfWork,
    UtilityService,
    RentalService,
    ReportService,
    InvoiceService
};

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: 'd07ed094-c5ee-46c8-a7b0-24eb62f56b08',
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            redirectUri: location.origin,
            postLogoutRedirectUri: location.origin + '/home',
            knownAuthorities: [b2cPolicies.authorityDomain],
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...apiConfig.scopes]
        }
    };
}

@NgModule({
    imports: [CommonModule, FormsModule, HttpClientModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        ReportService,
        { provide: ErrorHandler, useClass: ToastErrorHandler },
    ],
    bootstrap: [MsalRedirectComponent]
})
export class CoreModule {}
