import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { StorageDumpSiteDetailComponent } from './storage-dump-site-detail/storage-dump-site-detail.component';
import { StorageDumpSiteOverview } from './storage-dump-site-overview/storage-dump-site-overview.component';
import { TableEditComponent } from './table-edit/table-edit.component';
import { UserDetailDialogComponent } from './user-detail-dialog/user-detail-dialog.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { DumpsterDetailDialogComponent } from './dumpster-detail-dialog/dumpster-detail-dialog.component';
import { DumpsterFeeTypeDialogComponent } from './dumpster-fee-type-dialog/dumpster-fee-type-dialog.component';
import { CreditCardDialogComponent } from './credit-card-dialog/credit-card-dialog.component';
import { CustomerRatesComponent } from './customer-rates/customer-rates.component';
import { PhotoOverviewComponent } from './photo-overview/photo-overview.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'admin',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    {
                        path: 'users',
                        children: [
                            {
                                path: 'overview',
                                component: UserOverviewComponent,
                                data: { title: 'User Overview' }
                            }
                        ]
                    },
                    {
                        path: 'storage-dump-sites',
                        children: [
                            {
                                path: '',
                                redirectTo: 'overview',
                                pathMatch: 'full'
                            },
                            {
                                path: 'overview',
                                component: StorageDumpSiteOverview,
                                data: { title: 'Storage Dump Site Overview' }
                            },
                            {
                                path: ':id',
                                component: StorageDumpSiteDetailComponent,
                                data: { title: 'Storage Dump Site Detail' }
                            }
                        ]
                    },
                    {
                        path: 'lookup-tables',
                        component: TableEditComponent,
                        data: { title: 'Lookup Tables' }
                    },
                    {
                        path: 'customer-rates',
                        component: CustomerRatesComponent,
                        data: { title: 'Customer Rates' }
                    },
                    {
                        path: 'photo-overview',
                        component: PhotoOverviewComponent,
                        data: { title: 'Photo Overview' }
                    }
                ]
            }
        ])
    ],
    declarations: [
        UserOverviewComponent,
        UserDetailDialogComponent,
        StorageDumpSiteOverview,
        StorageDumpSiteDetailComponent,
        TableEditComponent,
        DumpsterDetailDialogComponent,
        DumpsterFeeTypeDialogComponent,
        CustomerRatesComponent,
        CreditCardDialogComponent,
        PhotoOverviewComponent
    ]
})
export class AdminModule {}
