<div class="flex-fill d-flex flex-column dx-card">
    <div class="d-flex flex-column flex-fill responsive-paddings">
        <dx-data-grid
            #dataGrid
            keyExpr="id"
            class="flex-fill d-flex flex-column"
            height="100px"
            [dataSource]="dumpsterInventoryLocations"
            [showBorders]="true"
            [showRowLines]="true"
            [rowAlternationEnabled]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="rowDoubleClick($event)"
        >
            <dxi-column dataField="size" caption="Type" width="80"></dxi-column>
            <dxi-column dataField="dumpsterNumber" caption="No." width="50"></dxi-column>
            <dxi-column dataField="dumpsterBarcode" caption="Barcode" width="150"></dxi-column>
            <dxi-column dataField="createdDate" caption="Created" width="100"></dxi-column>
            <dxi-column dataField="currentJob" caption="CurrentJob" width="400"></dxi-column>
            <dxi-column dataField="recentJobsHtml" caption="Recent Jobs" width="400" cellTemplate="htmlTemplate"></dxi-column>
            <dxi-column dataField="notes" width="250"></dxi-column>
            <dxi-column dataField="maintenanceLinks" caption="Maintenance" width="250" cellTemplate="maintenanceLinkTemplate"></dxi-column>
            <dxi-column></dxi-column>

            <div *dxTemplate="let cell of 'maintenanceLinkTemplate'">
                <div *ngFor="let maintenanceLink of cell.data.maintenanceLinks">
                    <a [routerLink]="[]" (click)="editMaintenance(maintenanceLink.maintenance)">{{ maintenanceLink.html }}</a>
                </div>
            </div>

            <div *dxTemplate="let cell of 'htmlTemplate'">
                <span [innerHTML]="cell.text"></span>
            </div>

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="dumpster-inventory-grid"></dxo-state-storing>
            <div *dxTemplate="let data of 'toolbarButtons'">
                <button
                    class="btn btn-primary mr-1 mb-2"
                    (click)="open(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isDumpsterAdmin"
                >
                    Open
                </button>
                <button class="btn btn-success mr-2 mb-2" (click)="open()" [hidden]="!isDumpsterAdmin">Add</button>
                <button
                    class="btn btn-danger mr-4 mb-2"
                    (click)="delete(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isDumpsterAdmin"
                >
                    Delete
                </button>
                <button
                    class="btn btn-primary mr-2 mb-2"
                    (click)="createMaintenance(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isDumpsterAdmin"
                >
                    Log Maintenance
                </button>
                <button
                    class="btn btn-primary mr-2 mb-2"
                    (click)="assignLocation(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isDumpsterAdmin"
                >
                    Assign Location
                </button>
                <button
                    class="btn btn-primary mr-2 mb-2"
                    (click)="addNote(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    [hidden]="!isDumpsterAdmin"
                >
                    Add Note
                </button>
                <button
                    class="btn btn-success mb-2"
                    (click)="releaseDumpster()"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                    [hidden]="!isDumpsterAdmin"
                >
                    Release
                </button>
            </div>
            <div *dxTemplate="let data of 'toolbarText'">
                <span *ngIf="dumpsterInventoryLocations" class="mr-3">
                    <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                </span>
            </div>
        </dx-data-grid>
    </div>
</div>
