// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { UserRole } from './user-role';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class User extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get displayName() {
    return [this.firstName, this.lastName].join(' ');
  }

  get displayRoles() {
    return _(this.userRoles).orderBy('role.displayOrder').map(role => role.role.name).join(' --- ');
  }
  /// </code>

  // Generated code. Do not place code below this line.
  userId: number;
  displayOrder: number;
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  userName: string;
  windowsUserName: string;
  userRoles: UserRole[];
}

