import { EntityManager, EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
import * as moment from 'moment';
import { Job } from '../model/entity-model';
import { Repository } from './repository';
;

export class JobRepository extends Repository<Job> {
    constructor(manager: EntityManager) {
        super(manager, 'Job', 'jobs');
    }

    async getActiveJobs(options: any) {
        let predicate = new Predicate('deleted', FilterQueryOp.Equals, null);
        if (options.showUnassigned) predicate = predicate.and('driverId', '==', null);
        if (options.showUnCompleted) predicate = predicate.and('completed', '==', null);
        if (options.showUnAudited) predicate = predicate.and('audited', '==', null);
        let query = EntityQuery.from('Jobs').where(predicate).orderBy('id');
        if (options.expand) query = query.expand(options.expand);
        const data = await this.manager.executeQuery(query);
        return data.results as Job[];
    }

    async getActiveJobsByRentalId(rentalId: number) {
        const predicate = new Predicate('deleted', '==', null).and('rentalId', '==', rentalId);
        const query = EntityQuery.from('Jobs').where(predicate).expand('invoiceLines');
        const data = await this.manager.executeQuery(query);
        return data.results as Job[];
    }


    async getNonCDRDumpsterJobs(date: Date) {
        const start = moment(date).startOf('day').toDate()
        let query = EntityQuery.from('GetNonCDRDumpsterJobs').withParameters({ startDate: start });
        const data = await this.manager.executeQuery(query);
        return data.results as number[];
    }

    async getJobsByDate(date: Date, hideAllButLastCompleted: boolean, options: any) {
        const start = moment(date).startOf('day').toDate()
        let query = EntityQuery.from('JobsByDate').where('deleted', FilterQueryOp.Equals, null).withParameters({ startDate: start, hideAllButLastCompleted });
        if (options) {
            if (options.driverId) query = query.where('driverId', FilterQueryOp.Equals, options.driverId);
            if (options.orderBy) query = query.orderBy(options.orderBy);
            if (options.expand) query = query.expand(options.expand);
        }
        const data = await this.manager.executeQuery(query);
        return data.results as Job[];
    }

    async getResidentialJobsForInvoicing() {
        let query = EntityQuery.from('GetResidentialJobsForInvoicing');
        const data = await this.manager.executeQuery(query);
        return data.results as Job[];
    }

    async lastServiceAddressJob(serviceAddressId: number) {
        const predicate = new Predicate('deleted', '==', null)
            .and('rental.deleted', '==', null)
            .and('rental.serviceAddressId', '==', serviceAddressId);
        const query = EntityQuery.from('Jobs').where(predicate).orderBy('created', true).take(1);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as Job;
    }

    async lastStorageDumpSiteJob(storageDumpSiteId: number) {
        const predicate = new Predicate('storageDumpSiteId', '==', storageDumpSiteId).and('deleted', '==', null);
        const query = EntityQuery.from('Jobs').where(predicate).orderBy('created', true).take(1);
        const data = await this.manager.executeQuery(query);
        return data.results[0] as Job;
    }

    async getTonsDumped(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate()
        endDate = moment(endDate).startOf('day').toDate()
        const query = EntityQuery.from('TonsDumped').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results[0] as { jobs: any[]; categories: any[] };
    }

    async getFeeRevenue(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate()
        endDate = moment(endDate).startOf('day').toDate()
        const query = EntityQuery.from('FeeRevenue').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results as { id: number, name: string, first: Date, last: Date, count: number, total: number }[];
    }

    async getJobCounts(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate()
        endDate = moment(endDate).startOf('day').toDate()
        const query = EntityQuery.from('JobCounts').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results[0] as { jobCounts: any[]; totalCount: number };
    }

    async getEstimatedRevenue(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate()
        endDate = moment(endDate).startOf('day').toDate()
        const query = EntityQuery.from('GetEstimatedRevenue').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getJobDumpsterFees(jobId: number) {
        const predicate = new Predicate('deleted', '==', null).and('jobId', '==', jobId);
        let query = EntityQuery.from('JobDumpsterFees').expand('dumpsterFeeType, invoiceLines').where(predicate);
        const data = await this.manager.executeQuery(query);
        return data.results;
    }
}
