import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Role } from 'src/app/core/model/role';
import { User } from 'src/app/core/model/user';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-user-detail-dialog',
    templateUrl: './user-detail-dialog.component.html',
    styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent extends BaseDialogComponent<any> implements OnInit {
    user: User;
    roles: Role[];
    selectedRoleIds: number[] = [];
    currentRoleIds: number[] = [];

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    ngOnInit() {
        this.roles = this.uow.lookups.roles;

        this.currentRoleIds = this.user.userRoles.map(userRoles => {
            return userRoles.roleId;
        });
        this.selectedRoleIds = this.currentRoleIds.slice();
    }

    save = async () => {
        const addedRoleIds = _.difference(this.selectedRoleIds, this.currentRoleIds);
        const removedRoleIds = _.difference(this.currentRoleIds, this.selectedRoleIds);

        this.hide({ success: true, value: { user: this.user, addedRoleIds: addedRoleIds, removedRoleIds: removedRoleIds } });
    };

    cancel = async () => {
        this.hide({ success: false });
    };
}
