import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { default as validationEngine } from 'devextreme/ui/validation_engine';
import { AppInformationService, AuthService, CdrUnitOfWork, ReportService, UtilityService } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { CustomerRate } from 'src/app/core/model/customer-rate';
import { CustomerType } from 'src/app/core/model/customer-type';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { alert, confirm, DialogService } from 'src/app/shared/shared.module';
import { RateDialogComponent } from '../rate-dialog/rate-dialog.component';
import { Rental } from 'src/app/core/model/rental';
import { Invoice } from 'src/app/core/model/invoice';
import { StatementDialogComponent } from '../statement-dialog/statement-dialog.component';
import { CreditCard } from 'src/app/core/model/credit-card';
import { SalesPerson } from 'src/app/core/model/sales-person';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
    customer: Customer;
    rentals: Rental[];
    invoices: Invoice[];
    customerTypes: CustomerType[];
    communicationOptions = Customer.PreferredCommunicationOptions;
    paymentTermOptions = Customer.PaymentTermOptions;
    billingTypeOptions = Customer.BillingTypeOptions;
    key: number | string;
    rates: CustomerRate[];
    billingRules = ['Bill After Delivery', 'Bill After Pickup', 'Bill as Customer Type'];
    creditCards: CreditCard[];
    salesPersons: SalesPerson[];

    @Input() showAsDialog: boolean = false;
    @Input() dialogKey: number | string;

    @ViewChild('ratesGrid') ratesGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private appInfo: AppInformationService,
        private dialogService: DialogService,
        private authService: AuthService,
        private reportService: ReportService,
        private utility: UtilityService
    ) {}

    canDeactivate() {
        return !this.hasChanges();
    }

    async ngOnInit() {
        this.customerTypes = this.uow.lookups.customerTypes as CustomerType[];
        this.creditCards = await this.uow.creditCards.whereActive('displayOrder, name');
        this.salesPersons = await this.uow.salesPersons.whereActive('displayOrder, name');
        this.key = this.route.snapshot.params.id;
        if (!this.key && this.dialogKey) this.key = this.dialogKey;

        if (this.key === 'new') {
            this.customer = this.uow.customers.createEntity({
                salesPersonId: SalesPerson.Options.CDR
            });
        } else {
            await this.getData();
            await this.getNotes();
        }
    }

    async getData() {
        this.customer = await this.uow.customers.byId(this.key, 'rates');
        this.rates = this.customer.rates.filter(x => {
            return !x.deleted;
        });
        await this.getRentals();
        await this.getInvoices();
    }

    async getRentals() {
        this.rentals = await this.uow.rentals.getActiveRentals({ customerId: this.customer.id });
    }

    async getInvoices() {
        this.invoices = await this.uow.getActiveInvoices({
            customerId: this.customer.id,
            includeDeleted: true
        });
    }

    invoiceDoubleClick(e) {
        if (!e.data.id) return;
        this.router.navigate([`../invoices/${e.data.id}`]);
    }

    async getNotes() {
        this.customer.customerNotes = await this.uow.customers.customerNotes(this.key);
    }

    async save() {
        if (!this.valid()) {
            await alert('Please fix errors before saving', this.appInfo.appTitle);
            return false;
        }

        if (this.customer.typeId === CustomerType.Types.Commercial && !this.customer.companyName) {
            const success = await confirm(
                'Company Name is blank for a Commercial customer. Do you want to continue?',
                this.appInfo.company
            );
            if (!success) return;
        }

        this.appInfo.pleaseWaitShow();
        await this.uow.commit();

        if (this.key === 'new') {
            this.key = this.customer.id;
            this.router.navigate([`../${this.customer.id}`], { relativeTo: this.route, replaceUrl: true });
        }
        this.appInfo.pleaseWaitHide();
        return true;
    }

    async cancel() {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;
        this.uow.rollback();
        if (!this.key || this.key === 'new') this.close();
    }

    close() {
        try {
            this.location.back();
        } catch {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    hasChanges() {
        return this.uow.hasChanges();
    }

    async addNote() {
        const dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = 'Customer Note';
        dialogRef.instance.dialogPrompt = 'Add Customer Note';
        dialogRef.instance.mode = 'textarea';
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success && result.value) {
            const note = this.uow.notes.createEntity({
                entityId: this.customer.id,
                entityTypeName: 'Customer',
                text: result.value,
                createdById: this.authService.userValue.id,
                created: new Date()
            });
            await this.uow.commit([note]);
            await this.getNotes();
        }
        this.dialogService.close(dialogRef);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async rateDoubleClick(e) {
        if (this.key === 'new') return;
        await this.addRate(e.data);
    }

    async addRate(selectedRow?: any) {
        const dialogRef = this.dialogService.open(RateDialogComponent);
        dialogRef.instance.customerId = this.customer.id;
        dialogRef.instance.rate = !!selectedRow ? selectedRow : null;
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success && result.value) {
            await this.getData();
        }
        this.dialogService.close(dialogRef);
    }
    async deleteRate(selectedRates: CustomerRate[]) {
        const success = await confirm('Are you sure you want to delete the selected rate?', this.appInfo.appTitle);
        if (!success) return false;

        selectedRates.forEach(row => {
            row.deleted = new Date();
            row.deletedBy = this.authService.userValue.userName;
        });
        await this.uow.commit(selectedRates);
        await this.getData();
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('customerValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('customerValidationGroup');
            return result.isValid;
        } else return false;
    }

    async unCompleteRental(rental: Rental) {
        if (!rental) return;

        const success = await confirm('Are you sure you want to un-complete the selected rental?', this.appInfo.appTitle);
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        rental.completed = null;
        await this.uow.commit([rental]);
        await this.getRentals();
        this.appInfo.pleaseWaitHide();
    }

    async openStatement() {
        if (this.key === 'new' || this.hasChanges()) return;

        const dialogRef = this.dialogService.open(StatementDialogComponent);
        dialogRef.instance.customerId = this.customer.id;
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            if (result.value.emailMessage) {
                result.value.statement.emailed = new Date();
                await this.uow.commit();
                const success = await this.utility.sendEmail([result.value.emailMessage.id]);
                if (success) await alert('Email sent', this.appInfo.appTitle);
                else await alert('Error sending email', this.appInfo.appTitle);
            } else await this.reportService.printStatementReport(result.value.statement.id);
            this.appInfo.pleaseWaitHide();
        }
    }
}
