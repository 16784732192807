<dx-popup
    title="Credit Card"
    [width]="300"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="creditCard" class="mb-4" validationGroup="mainValidationGroup">
        <dxi-item dataField="name">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="feePercentage" editorType="dxNumberBox" [editorOptions]="{ format: '0.0000' }">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
            <dxi-validation-rule type="range" [min]="0.01" [max]="0.99" message="Must be between 0.00 and 1.00"> </dxi-validation-rule>
        </dxi-item>
    </dx-form>
</dx-popup>
