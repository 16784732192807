// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { DumpsterCategory } from './dumpster-category';
import { Rental } from './rental';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Dumpster extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  basePrice: number;
  categoryId: number;
  chargeKentFee: boolean;
  chargeMichiganFee: boolean;
  extendedPrice: number;
  size: string;
  trackInventory: boolean;
  weightLimit: number;
  category: DumpsterCategory;
  rentals: Rental[];
}

