<div class="d-flex flex-column flex-fill dx-card">
    <div class="d-flex mt-3 ml-3">
        <dx-form [formData]="filter" style="max-width: 150px">
            <dxi-item dataField="startDate" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="endDate" editorType="dxDateBox"></dxi-item>
        </dx-form>
        <div class="d-flex flex-column ml-3 mt-3">
            <button class="btn btn-secondary" (click)="toggleShowPreviousWeek()">
                {{ showPreviousWeek ? 'This Week' : 'Previous Week' }}
            </button>
            <button class="btn btn-primary mt-2" (click)="getData()">Search</button>
        </div>
    </div>
    <div class="d-flex flex-row flex-fill p-2">
        <div class="d-flex flex-column flex-fill p-1">
            <dx-data-grid
                #dataGrid
                class="flex-fill d-flex flex-column"
                height="650px"
                [dataSource]="revenueByType"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="completed" format="shortDate" width="125"></dxi-column>
                <dxi-column dataField="revenueType" width="100"></dxi-column>
                <dxi-column dataField="amount" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="customer" cellTemplate="customerLinkTemplate" width="250"></dxi-column>
                <dxi-column dataField="rentalId" cellTemplate="rentalLinkTemplate" caption="Rental #" width="75"></dxi-column>
                <dxi-column dataField="jobId" caption="Job #" width="75"></dxi-column>
                <dxi-column dataField="jobType" width="100"></dxi-column>
                <dxi-column dataField="dumpsterSize" caption="Dumpster" width="125"></dxi-column>
                <dxi-column dataField="billAsDumpsterSize" caption="Bill As Dumpster" width="125"></dxi-column>
                <dxi-column dataField="weight" width="100"></dxi-column>
                <dxi-column dataField="action" width="75"></dxi-column>
                <dxi-column dataField="driver" width="150"></dxi-column>
                <dxi-column dataField="salesPerson" width="90"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                </div>
                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                </div>

                 <div *dxTemplate="let data of 'toolbarHeaders'">
                    <h4 class="m-0">Revenue by Type</h4>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="dataGrid" class="mr-3">
                        <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>

                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-selection [mode]="'multiple'"></dxo-selection>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="revenue-by-type-grid"></dxo-state-storing>

                <dxo-summary>
                    <dxi-total-item
                        column="amount"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="weight"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
                    >
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
</div>
