<div class="flex-fill d-flex flex-row">
    <div class="flex-shrink-0 sidebar">
        <div class="toolbar m-3">
            <dx-button text="Close" type="default" accessKey="c" (onClick)="close()" [visible]="!hasChanges()"></dx-button>
            <dx-button text="Save" type="success" accessKey="s" (onClick)="save()" [visible]="hasChanges()"></dx-button>
            <dx-button text="Cancel" type="danger" (onClick)="cancel()" [visible]="hasChanges()"></dx-button>
        </div>
        <div class="toolbar m-3">
            <dx-button text="Save & Start" type="success" (onClick)="saveAndStart()" [visible]="canSaveAndStart"></dx-button>
        </div>
        <div class="m-3"></div>
        <div class="border-top m-3"></div>
    </div>
    <div class="flex-fill dx-card responsive-paddings">
        <div *ngIf="rental" class="flex-fill content-block" autofocus>
            <dx-form *ngIf="rental" [formData]="rental" validationGroup="mainValidationGroup" appAutofocusForm [colCount]="3">
                <dxi-item
                    *ngIf="canUpdateCustomer"
                    dataField="customerId"
                    [label]="{ text: 'Customer' }"
                    [editorType]="'dxSelectBox'"
                    [editorOptions]="{
                        dataSource: customersDS,
                        valueExpr: 'id',
                        displayExpr: 'displayName',
                        onValueChanged: customerChanged
                    }"
                >
                    <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
                    <dxi-validation-rule type="range" [min]="1" message="Required"> </dxi-validation-rule>
                </dxi-item>
                <dxi-item [colSpan]="2">
                    <div *dxTemplate>
                        <button *ngIf="canUpdateCustomer" class="btn btn-sm btn-primary mt-2" (click)="addCustomer()">Add Customer</button>
                        <button *ngIf="!canUpdateCustomer" class="btn btn-sm btn-success mt-2" (click)="addCustomer(rental.customer)">
                            Edit Customer
                        </button>

                        <button
                            *ngIf="canChangeSiteAddress"
                            class="btn btn-sm btn-primary ml-2 mt-2"
                            (click)="addSite()"
                            [disabled]="!canAddSite"
                        >
                            Add Site Address
                        </button>
                        <button
                            *ngIf="!canChangeSiteAddress"
                            class="btn btn-sm btn-success ml-2 mt-2"
                            (click)="addSite(rental.serviceAddress)"
                            [disabled]="!canAddSite"
                        >
                            Edit Site Address
                        </button>
                    </div>
                </dxi-item>
            </dx-form>

            <div class="row mt-2">
                <dx-form [formData]="customer" [readOnly]="true" class="col-4" validationGroup="mainValidationGroup">
                    <dxi-item itemType="group" caption="Customer Information" [colCount]="2">
                        <dxi-item *ngIf="customer?.typeId === 1" dataField="companyName" [colSpan]="2"></dxi-item>
                        <dxi-item dataField="firstName"></dxi-item>
                        <dxi-item dataField="lastName"></dxi-item>
                        <dxi-item
                            dataField="notes"
                            [label]="{ text: 'Customer Notes' }"
                            editorType="dxTextArea"
                            [colSpan]="2"
                            [editorOptions]="{ height: 100 }"
                        >
                        </dxi-item>
                    </dxi-item>
                    <dxi-item itemType="group" caption="Photos" [visible]="key !== 'new'">
                        <dxi-item>
                            <div *dxTemplate>
                                <dx-file-uploader
                                    name="fileUpload"
                                    selectButtonText="Select Photo(s)"
                                    labelText="or drop a photo here"
                                    uploadMode="instantly"
                                    accept="image/*"
                                    [allowedFileExtensions]="imageExtensions"
                                    [showFileList]="false"
                                    [multiple]="true"
                                    [uploadUrl]="uploadUrl"
                                    [uploadHeaders]="uploadHeader"
                                    (onUploadStarted)="uploadStarted($event)"
                                    (onUploadError)="uploadError($event)"
                                    (onUploaded)="uploaded($event)"
                                >
                                </dx-file-uploader>
                            </div>
                        </dxi-item>
                        <dxi-item>
                            <ul class="file-list">
                                <li *ngFor="let photo of photos">
                                    <a title="remove" class="mr-2" (click)="removePhoto(photo)">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                    <a title="edit" class="mr-2" (click)="editPhoto(photo)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    <a title="view" (click)="openPhoto(photo)">{{ photo.name }}</a>
                                </li>
                            </ul>
                        </dxi-item>
                    </dxi-item>
                </dx-form>

                <dx-form class="col-4" validationGroup="mainValidationGroup">
                    <dxi-item itemType="group" caption="Site Information" [colCount]="2">
                        <dxi-item [label]="{ text: 'Site' }" [colSpan]="2">
                            <div *dxTemplate>
                                <dx-select-box
                                    [(value)]="rental.serviceAddressId"
                                    [items]="customer?.serviceAddresses"
                                    valueExpr="id"
                                    displayExpr="displayName"
                                    searchEnabled="true"
                                    showClearButton="true"
                                    [readOnly]="!canChangeSiteAddress"
                                ></dx-select-box>
                            </div>
                            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
                            <dxi-validation-rule type="range" [min]="1" message="Required"> </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item [label]="{ text: 'Name' }" [colSpan]="2">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.siteName" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Address' }" [colSpan]="2">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.address1" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'City' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.city" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'State' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.state" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Zip' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.zip" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'County' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.county" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Latitude' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.latitude" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Longitude' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.longitude" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Range' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.range" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Price/Ton' }">
                            <div *dxTemplate>
                                <dx-text-box [value]="rental?.serviceAddress?.pricePerTon" [readOnly]="true"></dx-text-box>
                            </div>
                        </dxi-item>
                        <dxi-item [label]="{ text: 'Notes' }" [colSpan]="2">
                            <div *dxTemplate>
                                <dx-text-area [value]="rental?.serviceAddress?.notes" [height]="'75px'" [readOnly]="true"></dx-text-area>
                            </div>
                        </dxi-item>
                    </dxi-item>
                </dx-form>

                <dx-form [formData]="rental" class="col-4" [validationGroup]="'mainValidationGroup'">
                    <dxi-item itemType="group" caption="Rental Information" [colCount]="4">
                        <dxi-item dataField="startDate" editorType="dxDateBox">
                            <dxi-validation-rule type="required"> </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="endDate" editorType="dxDateBox"></dxi-item>
                        <dxi-item dataField="pONumber" [label]="{ text: 'PO Number' }"></dxi-item>
                        <dxi-item itemType="empty"></dxi-item>

                        <dxi-item [label]="{ text: 'Dumpster' }">
                            <div *dxTemplate>
                                <dx-select-box
                                    [(value)]="rental.dumpsterId"
                                    [items]="dumpsters"
                                    valueExpr="id"
                                    displayExpr="size"
                                    (onValueChanged)="dumpsterChanged($event)"
                                ></dx-select-box>
                            </div>
                            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
                            <dxi-validation-rule type="range" [min]="1" message="Required"> </dxi-validation-rule>
                        </dxi-item>
                        <dxi-item dataField="price" [editorOptions]="{ format: { type: 'currency', precision: 2 }, step: 0 }"></dxi-item>

                        <dxi-item dataField="preferredTime" editorType="dxDateBox" [editorOptions]="{ type: 'time' }"></dxi-item>
                        <dxi-item
                            dataField="preferredWindow"
                            [label]="{ text: 'Preferred Window' }"
                            [editorType]="'dxSelectBox'"
                            [editorOptions]="{ dataSource: preferredWindowOptions }"
                        >
                        </dxi-item>

                        <dxi-item
                            dataField="billDaily"
                            cssClass="show-labels"
                            [label]="{ text: 'Bill Daily', location: 'left' }"
                            [editorType]="'dxCheckBox'"
                        >
                        </dxi-item>
                        <dxi-item *ngIf="rental.billDaily" dataField="dailyRate" [editorOptions]="{ format: { type: 'currency', precision: 2 }, step: 0 }"></dxi-item>
                        <dxi-item *ngIf="!rental.billDaily" itemType="empty"></dxi-item>
                        <dxi-item itemType="empty"></dxi-item>
                        <dxi-item itemType="empty"></dxi-item>

                        <dxi-item
                            dataField="driverInstructions"
                            editorType="dxTextArea"
                            [colSpan]="4"
                            [editorOptions]="{ height: 100 }"
                        ></dxi-item>

                        <dxi-item dataField="notes" editorType="dxTextArea" [colSpan]="4" [editorOptions]="{ height: 100 }"></dxi-item>
                        <dxi-item
                            dataField="invoiceNotes"
                            editorType="dxTextArea"
                            [colSpan]="4"
                            [editorOptions]="{ height: 100 }"
                        ></dxi-item>
                    </dxi-item>
                </dx-form>
            </div>

            <div *ngIf="jobs" class="d-flex">
                <dx-data-grid
                    #jobGrid
                    keyExpr="id"
                    class="h-100 mt-2"
                    [dataSource]="jobs"
                    [showBorders]="true"
                    [wordWrapEnabled]="false"
                    (onToolbarPreparing)="onToolbarPreparing($event)"
                >
                    <dxi-column dataField="id" caption="Job #" width="70" [sortIndex]="1" [sortOrder]="'desc'"></dxi-column>
                    <dxi-column dataField="rental.customer.displayName" caption="Customer" width="180"></dxi-column>
                    <dxi-column dataField="jobType.name" caption="Job Type" width="85"></dxi-column>
                    <dxi-column dataField="action.name" caption="Action" width="85"></dxi-column>
                    <dxi-column dataField="driver.displayName" caption="Driver" width="150"></dxi-column>
                    <dxi-column dataField="dumpster.size" caption="Dumpster" width="75"></dxi-column>
                    <dxi-column dataField="weight" width="75"></dxi-column>
                    <dxi-column dataField="startDate" caption="Date" width="100"></dxi-column>
                    <dxi-column dataField="started" format="shortDateShortTime" width="135"></dxi-column>
                    <dxi-column dataField="completed" format="shortDateShortTime" width="135"></dxi-column>
                    <dxi-column dataField="dumpsterUsed" caption="Dumpster #" width="95"></dxi-column>

                    <dxi-column dataField="displayAddress" caption="Service Address" width="250"></dxi-column>
                    <dxi-column dataField="fuelRatePercentage.rate" caption="Fuel Rate Percentage" width="100"></dxi-column>
                    <dxi-column dataField="driverNotes"></dxi-column>

                    <dxo-selection mode="multiple"></dxo-selection>
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                    <dxo-state-storing
                        [enabled]="true"
                        type="localStorage"
                        storageKey="rental-detail-job-overview-grid"
                    ></dxo-state-storing>

                    <div *dxTemplate="let data of 'toolbarButtons'">
                        <button
                            class="btn btn-danger mb-2"
                            (click)="deleteJob()"
                            [disabled]="jobGrid.instance.getSelectedRowsData().length === 0"
                        >
                            Delete
                        </button>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
</div>
