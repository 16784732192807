<div class="flex-fill d-flex flex-column content-block dx-card p-3">
    <div class="d-flex flex-fill flex-column">
        <dx-data-grid
            #dataGrid
            keyExpr="id"
            class="flex-fill d-flex flex-column"
            height="100px"
            [dataSource]="customerRates"
            [showBorders]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="rowDoubleClick($event)"
        >
            <dxi-column dataField="id" width="100" alignment='left'></dxi-column>
            <dxi-column dataField="customerId" width="100" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="customer.displayName" caption="Customer Name" width="175" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="customer.type.name" caption="Customer Type" width="125"></dxi-column>
            <dxi-column dataField="rateType.name" caption="Rate Type" width="100"></dxi-column>
            <dxi-column dataField="dumpsterId" width="100"></dxi-column>
            <dxi-column dataField="dumpster.size" width="100" caption="Dumpster Size"></dxi-column>
            <dxi-column dataField="rate" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
            <dxi-column dataField="baseMileage" width="100"></dxi-column>
            <dxi-column dataField="mileageOffset" width="100"></dxi-column>
            <dxi-column dataField="mileageOffsetRate" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
            <dxi-column dataField="displayOrder" width="100"></dxi-column>
            <dxi-column dataField="extendedRange" width="100"></dxi-column>
            <dxi-column dataField="outerRange" width="100"></dxi-column>
            <dxi-column dataField="regularRange" width="100"></dxi-column>
            <dxi-column dataField="weightLimit" width="100"></dxi-column>
            <dxi-column dataField="range" width="100"></dxi-column>
            <dxi-column dataField="pricePerTon" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
            <dxi-column dataField="zone" width="100"></dxi-column>
            <dxi-column></dxi-column>

            <div *dxTemplate="let cell of 'linkTemplate'">
                <a [routerLink]="[]" (click)="open(cell.data.customer)" *ngIf="cell.data">{{ cell.text }}</a>
            </div>

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="customer-rates-search-grid"></dxo-state-storing>
            <div *dxTemplate="let data of 'toolbarButtons'">
                <button
                    class="btn btn-primary mr-1 mb-2"
                    (click)="open(dataGrid.instance.getSelectedRowsData()[0].customer)"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Open
                </button>
            </div>
            <div *dxTemplate="let data of 'toolbarText'">
                <span class="mr-3"><strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong></span>
            </div>
        </dx-data-grid>
    </div>
</div>