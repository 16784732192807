<dx-popup
    [title]="(invoiceLine.miscItem ? 'Misc ' : '') + 'Invoice Line'"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="invoiceLine">
        <dxi-item [label]="{ text: 'Rental' }">
            <div *dxTemplate>
                <dx-select-box
                    *ngIf="!invoiceLine.miscItem"
                    [(value)]="invoiceLine.rentalId"
                    [items]="rentals"
                    displayExpr="displayName"
                    valueExpr="id"
                    (onValueChanged)="onRentalChanged($event)"
                ></dx-select-box>
            </div>
        </dxi-item>
        <dxi-item dataField="service"></dxi-item>
        <dxi-item dataField="rate" [editorOptions]="{ format: { type: 'currency', precision: 2 } }"> </dxi-item>
        <dxi-item dataField="weight" editorType="dxNumberBox"></dxi-item>
        <dxi-item dataField="quantity" editorType="dxNumberBox"></dxi-item>
        <dxi-item
            dataField="quickBooksProductId"
            [label]="{ text: 'Product Account' }"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: quickBooksProducts, displayExpr: 'quickBooksProductService', valueExpr: 'id' }"
        >
        </dxi-item>
        <dxi-item dataField="description" editorType="dxTextArea" [editorOptions]="{ height: '125px' }"> </dxi-item>
        <dxi-item dataField="note" editorType="dxTextArea" [editorOptions]="{ height: '125px' }"> </dxi-item>
    </dx-form>
</dx-popup>
