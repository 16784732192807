// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { Payment } from './payment';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PaymentCredit extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  amount: number;
  applied: Date;
  appliedBy: string;
  created: Date;
  createdBy: string;
  customerId: number;
  deleted: Date;
  deletedBy: string;
  notes: string;
  paymentId: number;
  customer: Customer;
  payment: Payment;
}

