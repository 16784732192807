<div *ngIf="inventoryUsage" class="flex-fill d-flex flex-column dx-card">
    <div class="d-flex flex-row justify-content-center inventory-usage">
        Dumpsters On Site: {{ inventoryUsage.totalUsage }} of {{ inventoryUsage.totalDumpsters }} =>
        {{ inventoryUsage.usage | number : '1.2-2' }}%
    </div>

    <div class="flex-fill d-flex flex-row dx-card">
        <div class="d-flex flex-column flex-fill responsive-paddings">
            <dx-data-grid
                #dataGrid
                keyExpr="typeId"
                class="flex-fill d-flex flex-column"
                height="100px"
                [dataSource]="currentInventoryInfos"
                [showBorders]="true"
                [showRowLines]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                (onRowDblClick)="rowDoubleClick($event)"
            >
                <dxi-column dataField="name" width="100"></dxi-column>
                <dxi-column dataField="availableCount" caption="Available" width="100"></dxi-column>
                <dxi-column dataField="onSiteCount" caption="On Site" width="100"></dxi-column>
                <dxi-column dataField="totalCount" caption="Total" width="100"></dxi-column>
                <dxi-column
                    dataField="usageAverage"
                    caption="Usage Avg"
                    [format]="{ type: 'percent', precision: 2 }"
                    width="100"
                ></dxi-column>
                <dxi-column dataField="locationsHTML" caption="Locations" width="350" cellTemplate="htmlTemplate"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'htmlTemplate'">
                    <span [innerHTML]="cell.text"></span>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="current-inventory-infos-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="currentInventoryInfos" class="mr-3">
                        <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
        <div class="d-flex flex-column flex-fill responsive-paddings">
            <dx-data-grid
                #inventoryProjectionsdataGrid
                keyExpr="startDate"
                class="flex-fill d-flex flex-column"
                [dataSource]="inventoryProjections"
                height="100px"
                [showBorders]="true"
                [showRowLines]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
            >
                <dxi-column dataField="start" caption="Date" format="longDate" width="230"></dxi-column>
                <dxi-column dataField="additions" caption="Additions" width="100"></dxi-column>
                <dxi-column dataField="subtractions" caption="Subtractions" width="100"></dxi-column>
                <dxi-column dataField="estimatedAvailable" caption="Est. Available" width="100"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'htmlTemplate'">
                    <span [innerHTML]="cell.text"></span>
                </div>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="current-inventory-proj-infos-grid"></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="inventoryProjections" class="mr-3">
                        <strong>{{ inventoryProjectionsdataGrid.instance.totalCount() + ' days' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
