<dx-popup
    title="Assign New Location"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [colCount]="2" style="min-height: 125px" validationGroup="mainValidationGroup">
        <dxi-item itemType="group">
            <dxi-item [label]="{ text: 'Storage Site' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="selectedStorageSite"
                        [dataSource]="storageSiteDS"
                        displayExpr="name"
                        (onValueChanged)="storageSiteChanged($event)"
                    ></dx-select-box>
                </div>
                <dxi-validation-rule
                    type="custom"
                    [validationCallback]="isAddressSelected"
                    message="Storage Site or Service Address Is Required"
                    [reevaluate]="true"
                >
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group">
            <dxi-item [label]="{ text: 'Customer' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="selectedCustomer"
                        [dataSource]="customersDS"
                        displayExpr="displayName"
                        (onValueChanged)="customerChanged($event)"
                    ></dx-select-box>
                </div>
            </dxi-item>
            <dxi-item [label]="{ text: 'Service Address' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="selectedServiceAddress"
                        [items]="customerServiceAddresses"
                        displayExpr="displayName"
                    ></dx-select-box>
                </div>
                <dxi-validation-rule
                    type="custom"
                    [validationCallback]="isAddressSelected"
                    message="Storage Site or Service Address Is Required"
                    [reevaluate]="true"
                >
                </dxi-validation-rule>
            </dxi-item>
        </dxi-item>
    </dx-form>
</dx-popup>
