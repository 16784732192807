import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PendingChangesGuard, PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';

import { GreatPlainsOverviewComponent } from './great-plains-overview/great-plains-overview.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([

            {
                path: 'great-plains',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: GreatPlainsOverviewComponent,
                        data: { title: 'Great Plains Overview' }
                    },
                ]
            },
        ])
    ],
    declarations: [
        GreatPlainsOverviewComponent,
    ]
})
export class GreatPlainsModule {}
