// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Dumpster } from './dumpster';
import { DumpsterLocation } from './dumpster-location';
import { Maintenance } from './maintenance';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class DumpsterInventory extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get dumpsterBarcode() {
    return `${this.dumpsterType.size.substring(0,2)}-${this.dumpsterNumber ? this.dumpsterNumber.toString().padStart(3,'0') : ''}`
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  created: Date;
  deleted: Date;
  dumpsterNumber: number;
  dumpsterTypeId: number;
  notes: string;
  placedOnSite: Date;
  removedFromSite: Date;
  dumpsterLocations: DumpsterLocation[];
  dumpsterType: Dumpster;
  maintenances: Maintenance[];
}

