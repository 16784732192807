<div class="header-label">Lookup Tables</div>
<div class="flex-fill d-flex flex-column dx-card responsive-paddings">
    <div class="d-flex flex-row">
        <div class="m-1">
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Fuel Rate</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="fuelRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('FuelRate', fuelRate, 'decimal')">Set</button>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Fuel Percentage</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="fuelPercentage"></dx-number-box>
                <button class="btn btn-danger mr-2" (click)="setFuelPercentage()">Set</button>
                <label *ngIf="activeFuelRatePercentage" class="mr-2" style="text-align: end; min-width: 130px">
                    Last Updated: {{ activeFuelRatePercentage.startDate | date }}
                </label>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Kent County Rate</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="kentCountyRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('KentCountyRate', kentCountyRate, 'decimal')">Set</button>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Overage Rate</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="overageRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('OverageRate', overageRate, 'decimal')">Set</button>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Extended Overage Rate</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="extendedOverageRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('ExtendedOverageRate', extendedOverageRate, 'decimal')">Set</button>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Service Rate</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="serviceRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('ServiceRate', serviceRate, 'decimal')">Set</button>
            </div>
            <div class="d-flex align-items-center m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Extension Rate (per day)</label>
                <dx-number-box class="mr-2" style="max-width: 175px" [(value)]="extensionRate"></dx-number-box>
                <button class="btn btn-danger" (click)="setSetting('ExtensionRate', extensionRate, 'decimal')">Set</button>
            </div>
        </div>
        <div class="flex-fill m-1" style="max-width: 600px">
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 120px">Invoice Email Subject</label>
                <dx-text-box class="mr-2 flex-fill" [(value)]="emailSubject"></dx-text-box>
                <button class="btn btn-danger" (click)="setSetting('EmailSubject', emailSubject)">Set</button>
            </div>
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 120px">Invoice Email Body</label>
                <dx-text-area class="mr-2 flex-fill" height="100px" [(value)]="emailBody"></dx-text-area>
                <button class="btn btn-danger" (click)="setSetting('EmailBody', emailBody)">Set</button>
            </div>
        </div>
        <div class="flex-fill m-1" style="max-width: 600px">
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 120px">Payment Email Subject</label>
                <dx-text-box class="mr-2 flex-fill" [(value)]="paymentEmailSubject"></dx-text-box>
                <button class="btn btn-danger" (click)="setSetting('PaymentEmailSubject', paymentEmailSubject)">Set</button>
            </div>
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 120px">Payment Email Body</label>
                <dx-text-area class="mr-2 flex-fill" height="100px" [(value)]="paymentEmailBody"></dx-text-area>
                <button class="btn btn-danger" (click)="setSetting('PaymentEmailBody', paymentEmailBody)">Set</button>
            </div>
        </div>
        <div class="flex-fill m-1" style="max-width: 600px">
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Statement Email Subject</label>
                <dx-text-box class="mr-2 flex-fill" [(value)]="statementEmailSubject"></dx-text-box>
                <button class="btn btn-danger" (click)="setSetting('StatementEmailSubject', statementEmailSubject)">Set</button>
            </div>
            <div class="d-flex flex-fill align-items-baseline m-1">
                <label class="mr-2" style="text-align: end; min-width: 130px">Statement Email Body</label>
                <dx-text-area class="mr-2 flex-fill" height="100px" [(value)]="statementEmailBody"></dx-text-area>
                <button class="btn btn-danger" (click)="setSetting('StatementEmailBody', statementEmailBody)">Set</button>
            </div>
        </div>
    </div>

    <dx-form class="d-flex my-2" *ngIf="filter" [formData]="filter">
        <dxi-item [label]="{ text: 'Table' }" *ngIf="filter">
            <div *dxTemplate>
                <dx-select-box
                    [(value)]="filter.table"
                    [items]="tables"
                    [searchEnabled]="true"
                    (onValueChanged)="selectionChanged($event)"
                ></dx-select-box>
            </div>
        </dxi-item>
    </dx-form>

    <div class="d-flex flex-fill flex-column" *ngIf="entities">
        <dx-data-grid
            #dataGrid
            keyExpr="id"
            class="d-flex flex-fill flex-column"
            height="100px"
            [dataSource]="entities"
            [showBorders]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="rowDoubleClick($event)"
        >
            <dxi-column *ngIf="filter.table === 'Credit Cards'" dataField="name" width="350"></dxi-column>
            <dxi-column *ngIf="filter.table === 'Credit Cards'" dataField="feePercentage" width="150"></dxi-column>

            <dxi-column *ngIf="filter.table === 'Dumpsters'" dataField="size" width="350"></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'Dumpsters'"
                dataField="basePrice"
                [format]="{ type: 'currency', precision: 2 }"
                width="150"
            ></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'Dumpsters'"
                dataField="extendedPrice"
                [format]="{ type: 'currency', precision: 2 }"
                width="150"
            ></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'Dumpsters'"
                dataField="weightLimit"
                [format]="{ type: 'fixedPoint', precision: 2 }"
                width="150"
            ></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpsters'" dataField="category.name" caption="Category" width="175"></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpsters'" dataField="trackInventory" width="150"></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpsters'" dataField="chargeKentFee" width="150"></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpsters'" dataField="chargeMichiganFee" width="150"></dxi-column>

            <dxi-column *ngIf="filter.table === 'Dumpster Categories' || filter.table === 'Sales People'" dataField="name" width="350"></dxi-column>

            <dxi-column *ngIf="filter.table === 'Dumpster Fee Types'" dataField="name" width="150"></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'Dumpster Fee Types'"
                dataField="price"
                [format]="{ type: 'currency', precision: 2 }"
                width="150"
            ></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpster Fee Types'" dataField="visibleForPickup" width="150"></dxi-column>
            <dxi-column *ngIf="filter.table === 'Dumpster Fee Types'" dataField="visibleForDropOff" width="150"></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'Dumpster Fee Types'"
                dataField="displayLinks"
                caption="Associated Dumpsters"
                width="350"
            ></dxi-column>

            <dxi-column *ngIf="filter.table === 'QuickBooks Products'" dataField="quickBooksProductService" width="250"></dxi-column>
            <dxi-column *ngIf="filter.table === 'QuickBooks Products'" dataField="quickBooksAccount" width="150"></dxi-column>
            <dxi-column *ngIf="filter.table === 'QuickBooks Products'" dataField="quickBooksId" width="125"></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'QuickBooks Products'"
                dataField="quickBooksLastSync"
                format="shortDateShortTime"
                width="175"
            ></dxi-column>
            <dxi-column *ngIf="filter.table === 'QuickBooks Products'" dataField="azureType" caption="Type" width="150"></dxi-column>
            <dxi-column *ngIf="filter.table === 'QuickBooks Products'" dataField="isVisible" caption="Visible" width="100"></dxi-column>
            <dxi-column
                *ngIf="filter.table === 'QuickBooks Products'"
                dataField="sETBillingCode"
                caption="SET Code"
                width="100"
            ></dxi-column>
            <dxi-column></dxi-column>

            <div *dxTemplate="let data of 'toolbarButtons'">
                <button
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    (click)="openSelection(dataGrid.instance.getSelectedRowsData()[0])"
                    class="btn btn-primary ml-2"
                >
                    {{ filter.table === 'QuickBooks Products' ? 'Edit SET Code' : 'Edit' }}
                </button>
                <button
                    [hidden]="filter.table !== 'QuickBooks Products'"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                    (click)="toggleVisibility(dataGrid.instance.getSelectedRowsData())"
                    class="btn btn-primary ml-2"
                >
                    Toggle Visibility
                </button>
                <button [hidden]="filter.table === 'QuickBooks Products'" (click)="openSelection()" class="btn btn-success ml-1">
                    Add
                </button>
                <button
                    [hidden]="filter.table === 'Dumpsters' || filter.table === 'QuickBooks Products'"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                    (click)="delete(dataGrid.instance.getSelectedRowsData()[0])"
                    class="ml-2 btn btn-danger"
                >
                    Delete
                </button>
            </div>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-row-dragging
                [allowReordering]="filter.table !== 'Dumpsters'"
                [onReorder]="onReorder"
                [showDragIcons]="false"
            ></dxo-row-dragging>
        </dx-data-grid>
    </div>
</div>
