import { Component, OnInit } from '@angular/core';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { StorageDumpSite } from 'src/app/core/model/storage-dump-site';
import { confirm } from 'devextreme/ui/dialog';
import * as moment from 'moment';
import { DialogService } from 'src/app/shared/dialog.service';
import { StorageDumpSiteDetailComponent } from '../storage-dump-site-detail/storage-dump-site-detail.component';

@Component({
    selector: 'app-storage-dump-site-overview',
    templateUrl: './storage-dump-site-overview.component.html',
    styleUrls: ['./storage-dump-site-overview.component.scss']
})
export class StorageDumpSiteOverview implements OnInit {
    storageDumpSites: StorageDumpSite[];

    constructor(
        private uow: CdrUnitOfWork,
        private dialogService: DialogService,
        private appInfo: AppInformationService
    ) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.storageDumpSites = await this.uow.getActiveStorageDumpSites();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    rowDoubleClick(e) {
        this.open(e.data);
    }

    async open(storageDumpSite?: StorageDumpSite) {
        const dialogRef = this.dialogService.open(StorageDumpSiteDetailComponent);
        dialogRef.instance.storageDumpSite = storageDumpSite;
        const results = await dialogRef.instance.show();
        if (results.success) {
            await this.getData();
        }
        this.dialogService.close(dialogRef);
    }

    async delete(storageDumpSites: StorageDumpSite[]) {
        if (
            !(await confirm(
                `Are you sure you want to delete the selected storage dump site${storageDumpSites.length === 1 ? '' : 's'}?`,
                this.appInfo.appTitle
            ))
        )
            return;

        storageDumpSites.forEach(storageDumpSite => {
            storageDumpSite.deleted = moment(new Date()).toDate();
        });
        await this.uow.commit();
        await this.getData();
    }
}
