import { EntityManager, EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
import { Customer, Note } from '../model/entity-model';
import { Repository } from './repository';
import * as moment from 'moment';
export class CustomerRepository extends Repository<Customer> {
    constructor(manager: EntityManager) {
        super(manager, 'Customer', 'customers');
    }

    async getActiveCustomers(expand?: string, select?: string) {
        let query = this.baseQuery().where('deleted', '==', null).orderBy('firstName');
        if (!!expand) query = query.expand(expand);
        if (select) query = query.select(select);
        const data = await this.manager.executeQuery(query);
        return data.results as Customer[];
    }

    async search(filter: any, expand: string | string[], take?: number) {
        let query = this.baseQuery().expand(expand).where('deleted', '==', null).orderByDesc('id');
        if (take) query = query.take(take);
        if (filter.companyName) query = query.where('companyName', FilterQueryOp.Contains, filter.companyName);
        if (filter.firstName) query = query.where('firstName', FilterQueryOp.Contains, filter.firstName);
        if (filter.lastName) query = query.where('lastName', FilterQueryOp.Contains, filter.lastName);
        if (filter.billCity) query = query.where('billCity', FilterQueryOp.Contains, filter.billCity);
        const data = await this.manager.executeQuery(query);
        return data.results as Customer[];
    }

    async customerNotes(customerId: any) {
        const predicate = new Predicate('entityTypeName', '==', 'Customer').and('entityId', '==', customerId).and('deleted', '==', null);
        const query = EntityQuery.from('Notes').where(predicate).expand('createdBy').orderByDesc('created');
        const data = await this.manager.executeQuery(query);
        return data.results as Note[];
    }

    async getActiveRentalCustomers() {
        const query = EntityQuery.from('ActiveRentalCustomers');
        const data = await this.manager.executeQuery(query);
        return data.results as Customer[];
    }

    async getCustomerTotals(startDate: Date, endDate: Date) {
        startDate = moment(startDate).startOf('day').toDate()
        endDate = moment(endDate).startOf('day').toDate()
        const query = EntityQuery.from('CustomerTotals').withParameters({ startDate, endDate });
        const data = await this.manager.executeQuery(query);
        return data.results as { customer: string, invoiceCount: number, total: number }[];
    }
}
