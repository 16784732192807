import { Component, OnInit } from '@angular/core';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { User } from 'src/app/core/model/user';

@Component({
    selector: 'app-job-overview',
    templateUrl: './job-overview.component.html',
    styleUrls: ['./job-overview.component.scss']
})
export class JobOverviewComponent implements OnInit {
    jobs: Job[];
    unAuditedJobs: Job[];
    jobTypes: JobType[];
    drivers: User[];
    dumpsters: Dumpster[];
    showUnassigned: boolean = false;
    showUnCompleted: boolean = true;

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService) {}

    async ngOnInit() {
        await this.getData();
        this.jobTypes = this.uow.lookups.jobTypes;
        this.drivers = this.uow.lookups.users;
        this.dumpsters = this.uow.lookups.dumpsters;
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        await this.getJobs();
        await this.getUnAuditedJobs();
        this.appInfo.pleaseWaitHide();
    }

    async getJobs() {
        this.jobs = await this.uow.jobs.getActiveJobs({
            showUnassigned: this.showUnassigned,
            showUnCompleted: this.showUnCompleted,
            expand: 'rental.customer, inventory'
        });
    }

    async getUnAuditedJobs() {
        this.unAuditedJobs = await this.uow.jobs.getActiveJobs({
            showUnAudited: true,
            expand: 'rental.customer, inventory, invoiceLines.invoice'
        });
        this.unAuditedJobs
            .filter(job => job.invoiceLines.length > 0)
            .forEach((job: any) => {
                const invoice = job.invoiceLines[0].invoice;
                job.invoiceId = invoice.id;
                job.invoiceNumber = invoice.invoiceNumber;
            });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async toggleShowUnassigned() {
        this.showUnassigned = !this.showUnassigned;
        this.appInfo.pleaseWaitShow();
        await this.getJobs();
        this.appInfo.pleaseWaitHide();
    }
    async toggleShowCompleted() {
        this.showUnCompleted = !this.showUnCompleted;
        this.appInfo.pleaseWaitShow();
        await this.getJobs();
        this.appInfo.pleaseWaitHide();
    }

    async markAsAudited(jobs: Job[]) {
        if (!jobs) return;

        this.appInfo.pleaseWaitShow();
        jobs.forEach(job => (job.audited = new Date()));
        await this.uow.commit();
        await this.getUnAuditedJobs();
        this.appInfo.pleaseWaitHide();
    }
}
