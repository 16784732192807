import { Component, OnInit } from '@angular/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { DumpsterCategory } from 'src/app/core/model/dumpster-category';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-dumpster-detail-dialog',
    templateUrl: './dumpster-detail-dialog.component.html',
    styleUrls: ['./dumpster-detail-dialog.component.scss']
})
export class DumpsterDetailDialogComponent extends BaseDialogComponent<any> implements OnInit {
    dumpster: Dumpster;
    dumpsterCategories: DumpsterCategory[];

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.dumpsterCategories = await this.uow.dumpsterCategories.whereActive('displayOrder');
        if (!this.dumpster) this.dumpster = this.uow.dumpsters.createEntity();
    }

    save = async () => {
        const success = this.valid();
        if (!success) return;

        await this.uow.commit();
        this.hide({ success: true, value: this.dumpster });
    };

    cancel = async () => {
        this.uow.rollback();
        this.hide({ success: false });
    };

    valid() {
        const groupConfig = validationEngine.getGroupConfig('mainValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('mainValidationGroup');
            return result.isValid;
        } else return false;
    }
}
