import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { User } from 'src/app/core/model/user';
import { DialogService } from 'src/app/shared/dialog.service';
import { DriverJobDialogComponent } from '../driver-job-dialog/driver-job-dialog.component';

@Component({
    selector: 'app-driver-schedule',
    templateUrl: './driver-schedule.component.html',
    styleUrls: ['./driver-schedule.component.scss']
})
export class DriverScheduleComponent implements OnInit {
    drivers: User[];
    jobs: Job[];
    dumpsters: Dumpster[];
    jobTypes: JobType[];
    scheduleDate: Date;
    selectedDriverId: number;

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private dialogService: DialogService
    ) {}

    async ngOnInit() {
        this.dumpsters = this.uow.lookups.dumpsters;
        this.jobTypes = this.uow.lookups.jobTypes;
        this.scheduleDate = moment(new Date()).startOf('day').toDate();
        this.drivers = this.uow.lookups.drivers;
    }

    async getData() {
        if (this.selectedDriverId)
            this.jobs = await this.uow.jobs.getJobsByDate(this.scheduleDate, false, {
                driverId: this.selectedDriverId,
            });
    }

    close() {
        try {
            this.location.back();
        } catch {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    async dateChange(offset: number) {
        this.scheduleDate = moment(this.scheduleDate).add(offset, 'days').toDate();
        await this.getData();
    }

    async driverChanged(e) {
        await this.getData();
    }

    async openJob(job: Job) {
        const dialogRef = this.dialogService.open(DriverJobDialogComponent);
        dialogRef.instance.job = job;
        const results = await dialogRef.instance.show();

        if (results.success) {
            if (results.value.completed) job.completed = moment(new Date()).toDate();
            await this.uow.commit();
        }
        this.dialogService.close(dialogRef);
    }
}
