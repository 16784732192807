<dx-popup title="Job" [width]="400" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="job" validationGroup="mainValidationGroup">
        <dxi-item
            dataField="driverId"
            [label]="{ text: 'Driver' }"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: drivers, valueExpr: 'userId', displayExpr: 'displayName' }"
        >
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="driverNotes" editorType="dxTextArea" [editorOptions]="{ height: '150px' }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
    </dx-form>
</dx-popup>
