<div class="flex-fill d-flex flex-column dx-card">
    <div class="d-flex flex-column flex-fill responsive-paddings">
        <dx-data-grid
            #dataGrid
            keyExpr="id"
            class="flex-fill d-flex flex-column"
            height="100px"
            [dataSource]="credits"
            [showBorders]="true"
            [showRowLines]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="rowDoubleClick($event)"
        >
            <dxi-column dataField="customer.displayName" caption="Customer" width="225"></dxi-column>
            <dxi-column dataField="amount" [format]="{ type: 'currency', precision: 2 }" width="150"></dxi-column>
            <dxi-column dataField="created" width="100"></dxi-column>
            <dxi-column dataField="createdBy" width="100"></dxi-column>
            <dxi-column dataField="applied" width="100"></dxi-column>
            <dxi-column dataField="appliedBy" width="100"></dxi-column>
            <dxi-column dataField="paymentId" caption="Applied To Payment" cellTemplate="paymentTemplate" width="110"></dxi-column>
            <dxi-column dataField="notes"></dxi-column>

            <div *dxTemplate="let cell of 'paymentTemplate'">
                <a [routerLink]="'/payments/' + cell.data.paymentId" *ngIf="cell.data">{{ cell.text }}</a>
            </div>

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="credit-grid"></dxo-state-storing>
            <div *dxTemplate="let data of 'toolbarButtons'">
                <button
                    type="button"
                    class="btn btn-primary mr-1 mb-2"
                    (click)="open(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Open
                </button>
                <button class="btn btn-success mr-2 mb-2" (click)="open()">Add</button>
                <button
                    class="btn btn-danger mr-4 mb-2"
                    (click)="delete(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Delete
                </button>
            </div>
        </dx-data-grid>
    </div>
</div>
