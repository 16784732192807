<dx-autocomplete
    #autoComplete
    placeholder="Type address to search...."
    [(value)]="addressLookup"
    [minSearchLength]="3"
    [searchTimeout]="500"
    [dataSource]="clientsStore"
    (onItemClick)="onClick($event)"
    (onEnterKey)="onClick($event)"
    (onValueChanged)="onValueChanged($event)"
>
</dx-autocomplete>
<!-- <dx-autocomplete
    #autoComplete
    placeholder="Type address to search...."
    [(value)]="addressLookup"
    [items]="locations"
    (onItemClick)="onClick($event)"
    (onEnterKey)="onClick($event)"
    (onValueChanged)="onValueChanged($event)"
>
</dx-autocomplete> -->
