import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';
import { Dumpster } from 'src/app/core/model/dumpster';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { Rental } from 'src/app/core/model/rental';
import { JobDialogComponent } from 'src/app/rentals/job-dialog/job-dialog.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { JobNoteDialogComponent } from '../job-note-dialog/job-note-dialog.component';

@Component({
    selector: 'app-schedule-overview',
    templateUrl: './schedule-overview.component.html',
    styleUrls: ['./schedule-overview.component.scss']
})
export class ScheduleOverviewComponent implements OnInit {
    today: Date;
    scheduleDate: Date;
    drivers: any;
    interval: any;
    hideAllButLastCompleted: boolean = false;

    jobs: any;
    assignedJobs: any;
    unAssignedJobs: any;
    jobTypes: JobType[];
    dumpsters: Dumpster[];
    storageDumpSitesList: any;
    driverColumns: any[];

    jobContextMenuItems = [];
    unassignedJobContextMenuItems = [];

    contextMenuJobId: number;
    isScheduleAdmin = false;

    constructor(
        private uow: CdrUnitOfWork,
        private auth: AuthService,
        private appInfo: AppInformationService,
        private dialogService: DialogService,
        private utility: UtilityService
    ) {}

    async ngOnInit() {
        this.isScheduleAdmin = this.auth.hasRole('Schedule:Admin');

        if (!this.isScheduleAdmin) {
            this.jobContextMenuItems = [{ index: 1, text: 'Open' }];
            this.unassignedJobContextMenuItems = [{ index: 1, text: 'Open' }];
        } else {
            this.jobContextMenuItems = [
                { index: 1, text: 'Open' },
                { index: 2, text: 'Add Note' },
                { index: 3, text: 'UnAssign' },
                { index: 4, text: 'Delete' }
            ];
            this.unassignedJobContextMenuItems = [
                { index: 1, text: 'Open' },
                { index: 2, text: 'Add Note' },
                { index: 4, text: 'Delete' }
            ];
        }
        if (this.auth.hasRole('Schedule:CanComplete')) {
            this.jobContextMenuItems.push({ index: 5, text: 'Complete' });
            this.unassignedJobContextMenuItems.push({ index: 5, text: 'Complete' });
        }

        if (this.auth.hasRole('Schedule:CanUnComplete')) {
            this.jobContextMenuItems.push({ index: 6, text: 'UnComplete' });
            this.unassignedJobContextMenuItems.push({ index: 6, text: 'UnComplete' });
        }

        this.today = moment(new Date()).startOf('day').toDate();
        this.scheduleDate = this.today;
        this.jobTypes = this.uow.lookups.jobTypes as JobType[];
        this.dumpsters = this.uow.lookups.dumpsters as Dumpster[];
        this.drivers = await this.uow.getDrivers();
        const inventories = await this.uow.getAllInventories();

        this.driverColumns = [];
        this.drivers.forEach(driver => {
            this.driverColumns.push({
                id: driver.userId,
                displayOrder: driver.displayOrder,
                name: driver.firstName + ' ' + driver.lastName,
                assignedJobs: [],
                storageDumpSitesList: []
            });
        });
        await this.refresh();
        this.interval = setInterval(async () => {
            await this.getData();
        }, 300000);
    }

    async ngOnDestroy() {
        clearInterval(this.interval);
    }

    async refresh() {
        this.appInfo.pleaseWaitShow();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async getData() {
        const jobs = await this.uow.jobs.getJobsByDate(this.scheduleDate, this.hideAllButLastCompleted, {
            orderBy: 'driverId, displayOrder'
        });

        const otherDumpsterJobs = await this.uow.jobs.getNonCDRDumpsterJobs(this.scheduleDate);
        otherDumpsterJobs.forEach(otherDumpsterJob => {
            const found = _.find(jobs, { id: otherDumpsterJob });
            if (found) found._nonCDRDumpster = true;
        });

        this.jobs = jobs;
        const unAssignedJobs = this.jobs.filter(job => {
            return !job.driverId && !job.completed;
        });
        this.unAssignedJobs = {
            id: -1,
            name: 'unAssignedJobs',
            assignedJobs: unAssignedJobs
        };

        const storageDumpSites = await this.uow.getActiveStorageDumpSites();

        const inventoryInfo = await this.uow.getInventoryInfo();
        storageDumpSites.forEach((site: any) => {
            let siteArray = [];
            inventoryInfo.currentInventoryInfos.forEach(info => {
                info.locations.forEach(location => {
                    if (location.storageId === site.id) {
                        siteArray.push(`${info.name} --- ${location.quantity}`);
                    }
                });
            });
            site.locationsHTML = siteArray.join('<br/>');
        });

        this.storageDumpSitesList = {
            id: -1,
            name: 'storageDumpSites',
            storageDumpSites: storageDumpSites
        };

        this.driverColumns.forEach((driver: any) => {
            driver.assignedJobs = this.jobs.filter(x => x.driverId === driver.id);
        });
    }

    async toggleHideCompleted() {
        this.hideAllButLastCompleted = !this.hideAllButLastCompleted;
        await this.getData();
    }

    changeDate(offset: number) {
        this.scheduleDate = moment(this.scheduleDate).add(offset, 'days').toDate();
    }

    async onDateChanged() {
        await this.getData();
    }

    onListDragStart(e) {
        if (!this.isScheduleAdmin) return;
        e.itemData = e.fromData[e.fromIndex];
    }

    async onListReorder(e) {
        if (!this.isScheduleAdmin) return;
        this.driverColumns.splice(e.fromIndex, 1)[0];
        this.driverColumns.splice(e.toIndex, 0, e.itemData);
        this.updateDisplayOrder(this.driverColumns);
        this.sortArray(this.driverColumns);
        await this.uow.commit();

        this.driverColumns.forEach(column => {
            const driver = _.find(this.drivers, { userId: column.id });
            driver.displayOrder = column.displayOrder;
        });
        await this.uow.commit();
    }
    sortArray(rows: any[]) {
        rows.sort((a, b) => (a.displayOrder || 9999) - (b.displayOrder || 9999));
    }

    updateDisplayOrder(rows: any[]) {
        rows.forEach((row, index) => {
            if (row.displayOrder !== index + 1) row.displayOrder = index + 1;
        });
    }
    onstorageDumpSitesDragStart(e) {
        if (!this.isScheduleAdmin) return;
        e.itemData = e.fromData.storageDumpSites[e.fromIndex];
    }

    async onstorageDumpSitesDrop(e) {
        if (!this.isScheduleAdmin) return;
        if (e.fromData.name !== 'storageDumpSites') return;
        (e.fromData.storageDumpSites as []).splice(e.fromIndex, 1);
        (e.toData.storageDumpSites as any[]).splice(e.toIndex, 0, e.itemData);
        this.updateDisplayOrder(this.storageDumpSitesList.storageDumpSites);
        this.sortArray(this.storageDumpSitesList.storageDumpSites);
        await this.uow.commit();
    }

    onTaskDragStart(e) {
        if (!this.isScheduleAdmin) return;
        e.itemData = e.fromData.assignedJobs[e.fromIndex];
    }

    onTaskDrop(e) {
        if (!this.isScheduleAdmin) return;
        if (e.fromData.name === 'storageDumpSites') {
            if (e.toData.name === 'unAssignedJobs') return;
            const job = this.uow.jobs.createEntity({
                created: moment(new Date()).toDate(),
                createdBy: this.auth.userValue.userName,
                driverId: e.toData.id,
                jobTypeId: JobType.Types.Destination,
                startDate: this.scheduleDate,
                siteName: e.itemData.name,
                address1: e.itemData.address1,
                address2: e.itemData.address2,
                city: e.itemData.city,
                state: e.itemData.state,
                zip: e.itemData.zip,
                storageDumpSiteId: e.itemData.id
            });
            e.toData.assignedJobs.splice(e.toIndex, 0, job);
        } else {
            (e.fromData.assignedJobs as []).splice(e.fromIndex, 1);

            // if moving destination to unassigned jobs, delete it instead of adding it to the list
            if (e.toData.id === -1 && e.itemData.jobTypeId === JobType.Types.Destination) e.itemData.deleted = new Date();
            else e.toData.assignedJobs.splice(e.toIndex, 0, e.itemData);

            if (e.toData.id === -1) {
                e.itemData.driverId = null;
            } else e.itemData.driverId = e.toData.id;
        }
        this.updateDisplayOrder(e.toData.assignedJobs);
        this.sortArray(e.toData.assignedJobs);
        this.uow.commit();
    }

    onContextMenu(id) {
        this.contextMenuJobId = id;
    }

    async onContextMenuItemClick(e: any) {
        const job = await this.uow.jobs.byId(this.contextMenuJobId, 'rental, fuelRatePercentage');
        switch (e.itemData.index) {
            case 1:
                await this.openJob(job);
                break;
            case 2:
                await this.addNote(job);
                break;
            case 3:
                if (job.jobTypeId === JobType.Types.Destination) job.deleted = new Date();
                else job.driverId = null;
                await this.uow.commit();
                break;
            case 4:
                await this.deleteJob(job);
                break;
            case 5:
                await this.completeJob(job);
                break;
            case 6:
                await this.unCompleteJob(job);
                break;
        }
        await this.getData();
    }

    async addJobNote() {
        let job = this.uow.jobs.createEntity({
            jobTypeId: JobType.Types.Note,
            created: new Date(),
            createdBy: this.auth.userValue.userName,
            startDate: this.scheduleDate
        });

        const dialogRef = this.dialogService.open(JobNoteDialogComponent);
        dialogRef.instance.job = job;
        dialogRef.instance.drivers = this.drivers;
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success) {
            this.appInfo.pleaseWaitShow();
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        } else this.uow.rollback();
        this.dialogService.close(dialogRef);
    }

    async addNote(job: Job) {
        const dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = 'Job Note';
        dialogRef.instance.dialogPrompt = 'Add Job Note';
        dialogRef.instance.dialogValue = job.notes;
        dialogRef.instance.mode = 'textarea';
        const result = await dialogRef.instance.show();
        this.dialogService.close(dialogRef);

        if (result.success && result.value) {
            job.notes = result.value;
            this.appInfo.pleaseWaitShow();
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async openJob(job: Job, canComplete?: boolean) {
        const dialogRef = this.dialogService.open(JobDialogComponent);
        dialogRef.instance.rentals = [job.rental];
        dialogRef.instance.completeJobMode = canComplete;
        dialogRef.instance.job = job;
        const results = await dialogRef.instance.show();
        if (results.success) {
            this.appInfo.pleaseWaitShow();
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async deleteJob(job: Job) {
        const result = await confirm('Are you sure you want to delete the selected job?', this.appInfo.appTitle);
        if (!result) return;

        job.deleted = new Date();

        this.appInfo.pleaseWaitShow();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async completeJob(job: Job) {
        const result = await confirm('Are you sure you want to complete the selected job?', this.appInfo.appTitle);
        if (!result) return;
        this.openJob(job, true);
        
        // job.completed = new Date();

        // this.appInfo.pleaseWaitShow();
        // await this.uow.commit();
        // await this.getData();
        // this.appInfo.pleaseWaitHide();
    }

    async unCompleteJob(job: Job) {
        const result = await confirm('Are you sure you want to un-complete the selected job?', this.appInfo.appTitle);
        if (!result) return;

        this.appInfo.pleaseWaitShow();
        await this.utility.uncompleteJob(job.id);
        job.completed = null;
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async deleteRental(rental: Rental) {
        if (!(await confirm('Are you sure you want to delete the selected rental?', this.appInfo.appTitle))) return;

        rental.deleted = moment(new Date()).toDate();

        this.appInfo.pleaseWaitShow();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    getTasks() {}

    openMaps(job: Job) {
        const address = (job.latitude && job.longitude ? [job.latitude, job.longitude] : job.fullAddress.trim().split(' ')).join('+');
        try {
            const url = `https://maps.apple.com/?q=${address}`;
            window.open(url, '_blank');
        } catch (error) {
            alert(error);
        }
    }
}
