import { Component, OnInit } from '@angular/core';
import { AppInformationService } from 'src/app/core/app-information.service';
import { AuthService } from 'src/app/core/auth.service';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { CustomerRate } from 'src/app/core/model/customer-rate';
import { RateType } from 'src/app/core/model/rate-type';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-rate-dialog',
    templateUrl: './rate-dialog.component.html',
    styleUrls: ['./rate-dialog.component.scss']
})
export class RateDialogComponent extends BaseDialogComponent<any> implements OnInit {
    rate: CustomerRate;
    customerId: number;

    dumpsters: any;
    rateTypes: RateType[];
    ranges = ['Regular', 'Extended', 'Outer'];
    zones = [1, 2, 3, 4];

    constructor(private appInfo: AppInformationService, private auth: AuthService, private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.rateTypes = this.uow.lookups.rateTypes;
        this.dumpsters = this.uow.lookups.dumpsters;

        if (!this.rate) {
            this.rate = this.uow.customerRates.createEntity({
                created: new Date(),
                createdBy: this.auth.userValue.userName,
                customerId: this.customerId
            });
        }

        this.rate.range = this.rate.range ?? 'Regular';
        this.rate.zone = this.rate.zone ?? 1;
    }

    save = async () => {
        // clear hidden fields, required fields are set to zero
        if (this.rate.rateTypeId !== RateType.RateTypes.Dumpster) this.rate.dumpsterId = null;
        if (this.rate.rateTypeId === RateType.RateTypes.Zone) this.rate.range = null;
        if (this.rate.rateTypeId !== RateType.RateTypes.Zone) this.rate.zone = null;
        if (this.rate.rateTypeId !== RateType.RateTypes.Hauling && this.rate.rateTypeId !== RateType.RateTypes.Zone)
            this.rate.pricePerTon = 0;
        if (this.rate.rateTypeId !== RateType.RateTypes.Hauling) {
            this.rate.baseMileage = 0;
            this.rate.mileageOffset = 0;
            this.rate.mileageOffsetRate = 0;
        }

        await this.uow.commit([this.rate]);
        this.hide({ success: true, value: this.rate });
    };

    cancel = async () => {
        this.rate.entityAspect.rejectChanges();
        this.hide({ success: false });
    };
}
