<dx-popup title="Storage/Dump Site" [width]="600" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        *ngIf="hasChanges()"
        [options]="{ text: 'Save And New', type: 'success', onClick: saveAndNew }"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" *ngIf="hasChanges()" [options]="{ text: 'Save', type: 'success', onClick: save }">
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        *ngIf="hasChanges()"
        [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        location="after"
        *ngIf="!hasChanges()"
        [options]="{ text: 'Close', type: 'success', onClick: close }"
    >
    </dxi-toolbar-item>
    <dx-form [formData]="storageDumpSite">
        <dxi-item itemType="group" caption="Site Info">
            <dxi-item dataField="name"></dxi-item>
            <dxi-item itemType="group" colCount="2">
                <dxi-item dataField="dump"></dxi-item>
                <dxi-item dataField="storage"></dxi-item>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Address">
            <dxi-item dataField="address1"></dxi-item>
            <dxi-item dataField="address2"></dxi-item>
            <dxi-item dataField="city"></dxi-item>
            <dxi-item dataField="state"></dxi-item>
            <dxi-item dataField="zip"></dxi-item>
        </dxi-item>
        <dxi-item dataField="note" editorType="dxTextArea" [label]="{ text: 'Notes' }" [editorOptions]="{ height: 100 }"></dxi-item>
    </dx-form>
</dx-popup>
