<dx-popup
    [title]="'Invoices for ' + arTotal.Name"
    [width]="1000"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Close', type: 'success', onClick: close }"></dxi-toolbar-item>
    <div class="d-flex flex-fill">
        <dx-data-grid #dataGrid [dataSource]="arTotal.Invoices" keyExpr="Id" class="d-flex flex-fill" height="400">
                <dxi-column dataField="Id" width="75"></dxi-column>
                <dxi-column dataField="InvoiceNumber" caption="Invoice #" width="75"></dxi-column>
                <dxi-column dataField="Rental" width="75"></dxi-column>
                <dxi-column dataField="Status" width="75"></dxi-column>
                <dxi-column dataField="Total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="Balance" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="due"></dxi-column>
                <dxi-column dataField="Customer"></dxi-column>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-selection mode="single"></dxo-selection>
        </dx-data-grid>
    </div>
</dx-popup>
