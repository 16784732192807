import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ARAgingComponent } from './ar-aging/ar-aging.component';
import { ARTotalDialogComponent } from './ar-total-dialog/ar-total-dialog.component';
import { RevenueForecastComponent } from './revenue-forecast/revenue-forecast.component';
import { RevenueByTypeComponent } from './revenue-by-type/revenue-by-type.component';
import { CustomerTotalsComponent } from './customer-totals/customer-totals.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'reporting',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'araging',
                        pathMatch: 'full'
                    },
                    {
                        path: 'araging',
                        component: ARAgingComponent,
                        data: { title: 'Job Overview' }
                    },
                    {
                        path: 'revenue-forecast',
                        component: RevenueForecastComponent,
                        data: { title: 'Revenue Forecast' }
                    },
                    {
                        path: 'revenue-by-type',
                        component: RevenueByTypeComponent,
                        data: { title: 'Revenue by Type' }
                    },
                    {
                        path: 'customer-totals',
                        component: CustomerTotalsComponent,
                        data: { title: 'Customer Totals' }
                    }
                ]
            }
        ])
    ],
    declarations: [ARAgingComponent, ARTotalDialogComponent, RevenueForecastComponent, RevenueByTypeComponent, CustomerTotalsComponent]
})
export class ReportingModule {}
