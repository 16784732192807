// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class JobType extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static Types = {
    Delivery: 1,
    PickUp: 2,
    Exchange: 3,
    RoundTrip: 4,
    Service: 5,
    Destination: 6,
    Note: 7
  }

  static Actions = {
    Delivery: 1,
    PickUp: 2,
  }

  static getJobTypIdeByName(name: string) {
    return JobType.Types[name] as number;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  deleted: Date;
  displayOrder: number;
  name: string;
}

