import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { PaymentCredit } from 'src/app/core/model/payment-credit';
import { DialogService } from 'src/app/shared/dialog.service';
import { CreditDialogComponent } from '../credit-dialog/credit-dialog.component';

@Component({
    selector: 'app-credit-overview',
    templateUrl: './credit-overview.component.html',
    styleUrls: ['./credit-overview.component.scss']
})
export class CreditOverviewComponent implements OnInit {
    credits: PaymentCredit[];

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private auth: AuthService,
        private dialogService: DialogService
    ) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.credits = await this.uow.getCredits({ expand: 'customer' });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    rowDoubleClick(e) {
        this.open(e.data);
    }

    async open(credit?: PaymentCredit) {
        const dialogRef = this.dialogService.open(CreditDialogComponent);
        dialogRef.instance.credit = credit;
        const result = await dialogRef.instance.show();
        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
        this.dialogService.close(dialogRef);
    }

    async delete(credit: PaymentCredit) {
        if (!credit) return;

        const result = await confirm('Are you sure you want to delete the selected credit?', this.appInfo.appTitle);
        if (!result) return;

        this.appInfo.pleaseWaitShow();
        credit.deleted = new Date();
        credit.deletedBy = this.auth.userValue.userName;
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
