import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { AppInformationService } from 'src/app/core/core.module';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-address-lookup',
    templateUrl: 'address-lookup.component.html',
    styleUrls: ['address-lookup.component.scss']
})
export class AddressLookupComponent implements OnInit {
    // locations: string[];
    addressLookup: any;
    locationObjects: any[] = [];
    clientsStore: CustomStore;

    @Output() onLocationChanged = new EventEmitter();

    constructor(private http: HttpClient, private appInfo: AppInformationService) {
        const that = this;
        this.clientsStore = new CustomStore({
            key: 'Value',
            useDefaultSearch: true,
            load(loadOptions: any) {
                const searchText: string = loadOptions.filter ? loadOptions.filter[0][2] : '';
                const BIAS_LAT = `42.753347`;
                const BIAS_LONG = `-85.665798`;
                const BIAS_KEY = `bias=proximity:${BIAS_LONG},${BIAS_LAT}`;
                const EXPECTED_TYPE = null;
                const NEAR_KEY = `&near=${BIAS_LAT},${BIAS_LONG}`;

                //USING RADAR API
                const RADAR_API_KEY = 'prj_live_sk_f46a1bf52a124b6b2eece299e2acc174dee88d58';
                const urlRadar = `https://api.radar.io/v1/search/autocomplete?query=${encodeURIComponent(searchText)}&country=us${
                    NEAR_KEY ? NEAR_KEY : ''
                }`;
                const headerOptions = {
                    headers: new HttpHeaders({ Authorization: `${RADAR_API_KEY}` })
                };
                return firstValueFrom(http
                    .get(urlRadar, headerOptions))
                    .then((data: any) => ({
                        data: data.addresses.map(address => {
                            that.locationObjects.push(address);
                            return address.formattedAddress;
                        })
                    }))
                    .catch(error => {
                        throw 'Data Loading Error';
                    });
            }
        });
    }

    async ngOnInit() {

    }

    async onClick(e) {
        if (!e.itemData) return;
        const location = _.find(this.locationObjects, {formattedAddress: e.itemData}) as AddressLookup;
        this.onLocationChanged.emit(location);
    }

    async onValueChanged(e) {

    }
}



type AddressLookup = {
    addressLabel: string,
    city: string,
    country: string,
    countryCode: string,
    countryFlag: string,
    county: string,
    distance: number,
    formattedAddress: string,
    geometry: {
        coordinates: number[],
        type: string;
    },
    latitude: number,
    layer: string,
    longitude: number,
    number: string,
    postalCode: string,
    state: string,
    stateCode: string,
    street: string
}
