import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { AppInformationService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';

@Component({
    selector: 'app-inventory-substitutions',
    templateUrl: './inventory-substitutions.component.html',
    styleUrls: ['./inventory-substitutions.component.scss']
})
export class InventorySubstitutionsComponent implements OnInit {
    substitutions: any[];

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService, private utility: UtilityService) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        const substitutions = await this.uow.getInventorySubstitutions();
        substitutions.forEach(job => (job.completed = moment(job.completed).toDate()));
        this.substitutions = substitutions;
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }
}
