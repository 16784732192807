<dx-popup title="Job" [width]="600" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item
        *ngIf="!job.completed"
        widget="dxButton"
        location="after"
        [options]="{ text: 'complete', type: 'success', onClick: complete }"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="job">
        <dxi-item *ngIf="job.jobTypeId !== 1" dataField="weight" class="pb-3"> </dxi-item>
        <dxi-item itemType="group" caption="Job Info">
            <dxi-item dataField="rental.customer.displayName" [label]="{ text: 'Customer' }" [editorOptions]="{ readOnly: true }">
            </dxi-item>
            <dxi-item dataField="jobType.name" [label]="{ text: 'Job Type' }" [editorOptions]="{ readOnly: true }"> </dxi-item>
            <dxi-item dataField="displayAddress" [label]="{ text: 'Service Address' }" [editorOptions]="{ readOnly: true }"> </dxi-item>
            <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ type: 'datetime', readOnly: true }"> </dxi-item>
            <dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ type: 'datetime', readOnly: true }"> </dxi-item>
            <dxi-item
                dataField="rental.driverInstructions"
                [label]="{ text: 'Driver Instructions' }"
                editorType="dxTextArea"
                [editorOptions]="{ height: '75px', readOnly: true }"
            >
            </dxi-item>
        </dxi-item>
    </dx-form>
</dx-popup>
