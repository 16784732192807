import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { ScreenService } from './shared/services';
import { AppInformationService } from './core/app-information.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AuthService } from './core/core.module';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    isIframe: boolean;
    private readonly _destroying$ = new Subject<void>();
    @HostBinding('class') get getClass() {
        return Object.keys(this.screen.sizes)
            .filter(cl => this.screen.sizes[cl])
            .join(' ');
    }

    constructor(
        private auth: AuthService,
        private screen: ScreenService,
        public appInfo: AppInformationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {}

    async ngOnInit() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const nav = this.router.getCurrentNavigation();
                if (!nav || !nav.extras.state || !nav.extras.state.navigateOnSaveNew) this.appInfo.setTitle(event['title']);
            });

        this.isIframe = window !== window.parent && !window.opener;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.updateAccount();
            });

            await firstValueFrom(this.msalService.handleRedirectObservable());
    }

    async updateAccount() {
        this.auth.checkAccount();

        if (this.auth.isAuthenticated) {
            await firstValueFrom(this.auth.getUser());
        }
    }

    isAuthorized() {
        return this.auth.isAuthenticated;
    }

    pleaseWait() {
        return this.appInfo.getPleaseWait();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
