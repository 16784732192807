<dx-popup
    title="Service Address"
    [width]="600"
    [height]="'95%'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-scroll-view>
        <dx-form [formData]="serviceAddress" [colCount]="2" validationGroup="serviceAddressValidationGroup">
            <dxi-item [label]="{ text: 'Address Lookup' }" [colSpan]="2">
                <div *dxTemplate>
                    <app-address-lookup (onLocationChanged)="locationChanged($event)"></app-address-lookup>
                </div>
            </dxi-item>

            <dxi-item [label]="{ text: 'Range' }" [colSpan]="2" *ngIf="mode === 'range'">
                <div *dxTemplate>
                    <dx-radio-group
                        [dataSource]="ranges"
                        [(value)]="serviceAddress.range"
                        layout="horizontal"
                        (onValueChanged)="radioButtonChanged($event)"
                    >
                    </dx-radio-group>
                </div>
                <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
            </dxi-item>
            <dxi-item [label]="{ text: 'Zone' }" [colSpan]="2" *ngIf="mode === 'zone'">
                <div *dxTemplate>
                    <dx-radio-group
                        [dataSource]="zones"
                        [(value)]="serviceAddress.zone"
                        layout="horizontal"
                        (onValueChanged)="radioButtonChanged($event)"
                    >
                    </dx-radio-group>
                </div>
                <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="siteName" [label]="{ text: 'Name' }" [colSpan]="2"></dxi-item>
            <dxi-item dataField="address1" [colSpan]="2"></dxi-item>
            <dxi-item dataField="address2" [colSpan]="2"></dxi-item>
            <dxi-item dataField="city"></dxi-item>
            <dxi-item dataField="state"></dxi-item>
            <dxi-item dataField="zip"></dxi-item>
            <dxi-item dataField="county"></dxi-item>
            <dxi-item dataField="latitude"></dxi-item>
            <dxi-item dataField="longitude"></dxi-item>
            <dxi-item dataField="phone" [colSpan]="2"></dxi-item>
            <dxi-item [label]="{ text: 'Site Notes' }" [colSpan]="2">
                <div *dxTemplate>
                    <dx-text-area [(value)]="serviceAddress.notes" [height]="'100px'"></dx-text-area>
                </div>
            </dxi-item>
            <dxi-item dataField="distance" [disabled]="true"></dxi-item>
            <dxi-item>
                <div *dxTemplate>
                    <button class="btn btn-primary mt-2" (click)="setDistance()">Set</button>
                </div>
            </dxi-item>

            <dxi-item itemType="group" caption="Matching Rate" [colCount]="2" [colSpan]="2">
                <dxi-item [label]="{ text: 'Rate' }">
                    <div *dxTemplate>
                        <dx-text-box [(value)]="rateTypeDisplay" disabled="true"></dx-text-box>
                    </div>
                </dxi-item>
                <dxi-item itemType="empty"></dxi-item>
                <dxi-item [label]="{ text: 'Base Rate' }">
                    <div *dxTemplate>
                        <dx-text-box [(value)]="baseRateDisplay" disabled="true"></dx-text-box>
                    </div>
                </dxi-item>
                <dxi-item itemType="empty"></dxi-item>
                <dxi-item [label]="{ text: 'Price/Ton Rate' }">
                    <div *dxTemplate>
                        <dx-text-box [(value)]="pricePerTonDisplay" disabled="true"></dx-text-box>
                    </div>
                </dxi-item>
                <dxi-item>
                    <div *dxTemplate>
                        <button class="btn btn-sm btn-success mt-2" (click)="addCustomerRate()" [disabled]="selectedCustomerRate">
                            Add Rate
                        </button>
                    </div>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </dx-scroll-view>
</dx-popup>
