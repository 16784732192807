<dx-popup
    [title]="dialogHeader"
    [width]="400"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="job" validationGroup="rentalValidationGroup">
        <dxi-item *ngIf="rentalJobs" [label]="{ text: rentalJobLabel }" class="pb-3">
            <div *dxTemplate>
                <dx-select-box [(value)]="selectedRentalJob" [dataSource]="rentalJobs" displayExpr="dumpster.size">
                    <dx-validator *ngIf="rentalJobRequired" validationGroup="rentalValidationGroup">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item
                *ngIf="dumpsters && isExchange"
                class="pb-3"
                dataField="exchangeDumpsterId"
                [label]="{ text: 'Pickup Dumpster' }"
                editorType="dxSelectBox"
                [editorOptions]="{
                    dataSource: dumpsters,
                    valueExpr: 'id',
                    displayExpr: 'size',
                    onValueChanged: dumpsterChanged
                }"
            >
                <dxi-validation-rule *ngIf="dumpsterRequired" type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item
                *ngIf="dumpsters && isExchange"
                [visible]="isDifferentThanRental && isExchange"
                class="pb-3"
                dataField="billExchangeAs"
                [label]="{ text: 'Bill As' }"
                editorType="dxSelectBox"
                [editorOptions]="{
                    dataSource: dumpsters,
                    valueExpr: 'id',
                    displayExpr: 'size'
                }"
            >
                <dxi-validation-rule *ngIf="dumpsterRequired" type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item *ngIf="dumpsters && isExchange && !isDifferentThanRental && !isService" itemType="empty"> </dxi-item>
            <dxi-item
                *ngIf="dumpsters && !isService"
                class="pb-3"
                dataField="dumpsterId"
                [label]="{ text: dumpsterLabel }"
                editorType="dxSelectBox"
                [editorOptions]="{
                    dataSource: dumpsters,
                    valueExpr: 'id',
                    displayExpr: 'size',
                    onValueChanged: dumpsterChanged
                }"
            >
                <dxi-validation-rule *ngIf="dumpsterRequired" type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item
                *ngIf="dumpsters"
                [visible]="isDifferentThanRental"
                class="pb-3"
                dataField="billAsDumpsterId"
                [label]="{ text: 'Bill As' }"
                editorType="dxSelectBox"
                [editorOptions]="{
                    dataSource: dumpsters,
                    valueExpr: 'id',
                    displayExpr: 'size'
                }"
            >
                <dxi-validation-rule *ngIf="dumpsterRequired" type="required"></dxi-validation-rule>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" class="align-items-center" colCount="3">
            <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ onValueChanged: startDateChanged }" colSpan="2">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item class="flex-fill">
                <div *dxTemplate class="d-flex">
                    <button class="btn-small btn-secondary mx-auto mt-3" (click)="toggleStartDate()">
                        {{ showTomorrowButton ? 'Tomorrow' : 'Today' }}
                    </button>
                </div>
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" colCount="2" *ngIf="showPreferredTime">
            <dxi-item
                dataField="preferredStartTime"
                editorType="dxDateBox"
                [label]="{ text: 'Preferred Start' }"
                [editorOptions]="{ type: 'time', pickerType: 'list', onValueChanged: startTimeValueChanged }"
            ></dxi-item>
            <dxi-item
                dataField="preferredEndTime"
                editorType="dxDateBox"
                [label]="{ text: 'Preferred End' }"
                [editorOptions]="{ type: 'time', pickerType: 'list', onValueChanged: endTimeValueChanged }"
            ></dxi-item>
        </dxi-item>
        <dxi-item dataField="notes" editorType="dxTextArea" [label]="{ text: 'Site Notes' }" [editorOptions]="{ height: '100px' }">
        </dxi-item>
        <dxi-item dataField="driverNotes" editorType="dxTextArea" [editorOptions]="{ height: '100px' }"> </dxi-item>
    </dx-form>
</dx-popup>
