import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { Dumpster, QuickBooksProduct, Rental, RentalBillingLine } from 'src/app/core/model/entity-model';
import { BaseDialogComponent } from 'src/app/shared/base-dialog/base-dialog.component';

@Component({
    selector: 'app-rental-billing-line-dialog',
    templateUrl: './rental-billing-line-dialog.component.html',
    styleUrls: ['./rental-billing-line-dialog.component.scss']
})
export class RentalBillingLineDialogComponent extends BaseDialogComponent<any> implements OnInit {
    customerId: number;
    rentalBillingLine: RentalBillingLine;
    dumpsters: Dumpster[];
    rentals: Rental[];
    quickBooksProducts: QuickBooksProduct[];
    rate: number;

    constructor(private uow: CdrUnitOfWork) {
        super();
    }

    async ngOnInit() {
        this.dumpsters = this.uow.lookups.dumpsters as Dumpster[];
        this.rentals = await this.uow.rentals.getActiveRentals({ customerId: this.customerId });
        this.quickBooksProducts = await this.uow.getQBProducts(true);
    }

    onRentalChanged(e) {
        const rental = this.rentals.find(rental => rental.id === e.value);
        // this.rentalBillingLine.service = rental?.dumpster.size ?? null;
        // this.rentalBillingLine.rate = rental?.price ?? null;
    }

    save = async () => {
        this.rentalBillingLine.quantity = moment(this.rentalBillingLine.endDate).diff(this.rentalBillingLine.startDate, 'days') + 1;
        this.hide({ success: true, values: { rentalBillingLine: this.rentalBillingLine, rate: this.rate } });
    };

    cancel = async () => {
        this.hide({ success: false });
    };
}
