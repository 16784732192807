<dx-popup
    title="Customer Rate"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form *ngIf="rate" [formData]="rate">
        <dxi-item [label]="{ text: 'Type' }">
            <div *dxTemplate>
                <dx-select-box [(value)]="rate.rateTypeId" [items]="rateTypes" valueExpr="id" displayExpr="name"></dx-select-box>
            </div>
        </dxi-item>
        <dxi-item *ngIf="rate.rateTypeId === 1" [label]="{ text: 'Size' }">
            <div *dxTemplate>
                <dx-select-box [(value)]="rate.dumpsterId" [items]="dumpsters" valueExpr="id" displayExpr="size"></dx-select-box>
            </div>
        </dxi-item>

        <dxi-item  *ngIf="rate.rateTypeId !== 4" [label]="{ text: 'Range' }">
            <div *dxTemplate>
                <dx-radio-group [dataSource]="ranges" [(value)]="rate.range" layout="horizontal">
                </dx-radio-group>
            </div>
        </dxi-item>
        <dxi-item *ngIf="rate.rateTypeId === 4" [label]="{ text: 'Zones' }">
            <div *dxTemplate>
                <dx-radio-group [dataSource]="zones" [(value)]="rate.zone" layout="horizontal">
                </dx-radio-group>
            </div>
        </dxi-item>

        <dxi-item dataField="rate" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'currency', precision: 2 } }"></dxi-item>
        <dxi-item *ngIf="rate.rateTypeId === 2 || rate.rateTypeId === 4" dataField="pricePerTon" [label]="{ text: 'Price/Ton' }" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'currency', precision: 2 } }"></dxi-item>
        <dxi-item dataField="weightLimit" editorType="dxNumberBox"></dxi-item>

        <dxi-item *ngIf="rate.rateTypeId === 2" dataField="baseMileage" editorType="dxNumberBox"></dxi-item>
        <dxi-item *ngIf="rate.rateTypeId === 2" dataField="mileageOffset" editorType="dxNumberBox"></dxi-item>
        <dxi-item
            *ngIf="rate.rateTypeId === 2"
            dataField="mileageOffsetRate"
            editorType="dxNumberBox"
            [editorOptions]="{ format: { type: 'currency', precision: 2 } }"
        ></dxi-item>
        <!-- <dxi-item [label]="{ text: 'Rate Notes' }">
            <div *dxTemplate>
                <dx-text-area [(value)]="rate.notes" [height]="'150px'"></dx-text-area>
            </div>
        </dxi-item> -->
    </dx-form>
</dx-popup>
