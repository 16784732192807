<header>
    <dx-toolbar class="header-toolbar">
        <dxi-item
            *ngIf="menuToggleEnabled"
            location="before"
            widget="dxButton"
            cssClass="menu-button"
            [options]="{
                icon: 'menu',
                stylingMode: 'text',
                onClick: toggleMenu
            }"
        >
        </dxi-item>
        <dxi-item location="before" cssClass="header-title" *ngIf="appTitle" [text]="appTitle">
            <div *dxTemplate class="header-title" (click)="goHome()">{{ appTitle }}</div>
        </dxi-item>
        <dxi-item location="before" [visible]="user$ | async">
            <div *dxTemplate>
                <!-- <h2 style="padding-left: 40px;">{{ title }}</h2> -->
                <dx-menu
                    #menu
                    [items]="mainMenuItems"
                    displayExpr="text"
                    [showFirstSubmenuMode]="{
                        name: 'onClick',
                        delay: { show: 0, hide: 300 }
                    }"
                    orientation="horizontal"
                    submenuDirection="auto"
                    [hideSubmenuOnMouseLeave]="false"
                    class="main-menu ml-3"
                    itemTemplate="items"
                >
                    <div *dxTemplate="let item of 'items'" class="dx-menu-item-has-text">
                        <a [routerLink]="[item.url]" class="dx-menu-item-text" routerLinkActive="active" *ngIf="!item.items">{{ item.text }}</a>
                        <span class="dx-menu-item-text" [ngClass]="{ active: isActiveItem(item.url) }" *ngIf="item.items">{{ item.text }}</span>
                    </div>
                </dx-menu>
            </div>
        </dxi-item>
        <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
            <div *dxTemplate="let data of 'item'">
                <div class="d-inline-block" *ngIf="false">
                    <dx-button class="ml-3" stylingMode="text">
                        <div *dxTemplate="let data of 'content'" class="d-flex">
                            <i class="fa fa-bell" style="font-size: 20px"></i>
                            <span class="badge badge-danger align-self-start"></span>
                        </div>
                    </dx-button>
                </div>

                <dx-button class="user-button authorization ml-3" x-width="170px" height="100%" stylingMode="text">
                    <div *dxTemplate="let data of 'content'">
                        <app-user-panel [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
                    </div>
                </dx-button>
            </div>
        </dxi-item>
        <div *dxTemplate="let data of 'menuItem'">
            <app-user-panel [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
        </div>
        <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
            v{{appInfo.appVersion}}
        </dxi-item>
    </dx-toolbar>
</header>
