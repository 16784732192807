import { Component, OnInit, ViewChild } from '@angular/core';
import { alert } from 'devextreme/ui/dialog';
import { AppInformationService } from 'src/app/core/core.module';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent extends BaseDialogComponent<any> implements OnInit {
    dialogHeader: string;
    dialogPrompt: string;
    dialogMessage: string;
    dialogValue: any;
    dialogValueArray: any[];
    dialogWidth: number = 600;
    checkNumeric: boolean;
    checkInteger: boolean;
    minValue: number = null;
    mode = 'text';
    saveAndNewVisible = false;
    autoSaveIfNotEmpty = false;
    autoSaveInterval: any;
    previousValue: any;

    @ViewChild('inputComponent') inputComponent: any;

    constructor(private appInfo: AppInformationService) {
        super();
    }

    ngOnInit() {}

    checkInput() {
        if (this.dialogValue !== undefined && this.dialogValue.length > 0 && this.dialogValue !== this.previousValue) {
            this.previousValue = this.dialogValue;
        } else if (this.dialogValue !== undefined && this.dialogValue.length > 0 && this.dialogValue === this.previousValue) {
            clearInterval(this.autoSaveInterval);
            this.save();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.inputComponent.instance.focus();
        });
        if (this.autoSaveIfNotEmpty) {
            this.autoSaveInterval = setInterval(() => {
                this.checkInput();
            }, 500);
        }
    }

    onInput(e) {}

    enterPressed = () => {
        if (this.mode !== 'textarea') this.save();
    }

    onSave = (e) => {
        this.save(false);
    }

    onSaveAndNew = (e) => {
        this.save(true);
    }

    save(andNew?: boolean) {
        if (this.checkNumeric && isNaN(this.dialogValue)) {
            alert('Value needs to be a number', `${this.appInfo.appTitle}`);
            return;
        }
        if (this.checkInteger && (isNaN(this.dialogValue) || Number(this.dialogValue) !== Math.round(Number(this.dialogValue)))) {
            alert('Value needs to be an integer', `${this.appInfo.appTitle}`);
            return;
        }
        if (this.minValue != null && Number(this.dialogValue) < Number(this.minValue)) {
            alert(`Value needs to be greater than or equal to ${this.minValue}`, `${this.appInfo.appTitle}`);
            return;
        }
        if (andNew) {
            //
        } else {
            this.hide({ success: true, value: this.dialogValue });
        }
    };

    cancel = () => {
        this.hide({ success: false });
    };
}
