import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AppInformationService, CdrUnitOfWork, ReportService, SessionInformationService, UtilityService } from 'src/app/core/core.module';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';
import { PaymentMethod } from 'src/app/core/model/payment-method';
import { QuickBooksTaskResponse } from 'src/app/core/model/quick-books-task-response';
import { alert, confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-great-plains-overview',
    templateUrl: './great-plains-overview.component.html',
    styleUrls: ['./great-plains-overview.component.scss']
})
export class GreatPlainsOverviewComponent implements OnInit {
    data: any[];
    filter = {
        selectedEntityType: 'Invoice'
    };
    // types = ['Customer', 'Invoice', 'Payment'];
    types = ['Invoice'];
    invoiceStatuses: InvoiceStatus[];
    paymentMethods: PaymentMethod[];
    currentUserData: any;
    responses: QuickBooksTaskResponse[];
    processing: boolean = false;
    canSeeQB: boolean = false;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private appInfo: AppInformationService,
        private sessionInfo: SessionInformationService,
        private utility: UtilityService,
        private reportService: ReportService
    ) {}

    async ngOnInit() {
        this.processing = true;
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        this.paymentMethods = this.uow.lookups.paymentMethods;

        const filter = this.sessionInfo.getItem('quickbooks-filter');
        if (filter) this.filter = filter;
        else this.filter.selectedEntityType = 'Invoice';
        await this.getData();
        await this.getResponseData();

        this.canSeeQB = true;
        this.processing = false;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            }
        );
    }

    async getData() {
        if (this.filter.selectedEntityType === 'Invoice')
            this.data = await this.uow.getInvoicesToExport('Invoices', ['customer', 'invoiceLines']);
        this.appInfo.pleaseWaitHide();
    }

    async getResponseData() {
        this.appInfo.pleaseWaitShow();
        this.responses = await this.uow.getQBTaskResponses();
        this.appInfo.pleaseWaitHide();
    }

    async refresh() {
        this.appInfo.pleaseWaitShow();
        await this.getData();
        await this.getResponseData();
        this.appInfo.pleaseWaitHide();
    }

    async export(all?: boolean) {
        let selectedRows: any;

        if (all) {
            const confirmResult = await confirm(`Are you sure you want to Export ALL?`, this.appInfo.company);
            if (!confirmResult) return;
            selectedRows = this.data;
        } else {
            selectedRows = this.dataGrid.instance.getSelectedRowsData();
            if (!selectedRows) return;
        }


        this.appInfo.pleaseWaitShow();
        const batchNumber = await this.utility.getNextNumber('InvoiceBatchNumber');
        const ids = selectedRows.map(x => {
            return x.id;
        });
        const response = await this.utility.postToGreatPlains(ids);
        if (!response.success) {
            await alert(`Error posting invoices to Great Plains.${response.message}`, this.appInfo.company);
            this.appInfo.pleaseWaitHide();
        }

        selectedRows.forEach(row => {
            row.batchNumber = batchNumber;
        });
        await this.uow.commit();
        await this.getData();
        await this.getResponseData();
        this.appInfo.pleaseWaitHide();
    }

    open(data: any, dataField: string) {
        switch (dataField) {
            case 'customerId':
            case 'customerDisplayName':
                const customerId =
                    data.type === 'Customer'
                        ? data.customerId
                        : data.type === 'Payment'
                        ? data.payment.customerId
                        : data.type === 'Invoice'
                        ? data.invoice.customerId
                        : null;
                if (customerId) this.router.navigate([`/customers/${customerId}`]);
                break;
            case 'invoiceId':
            case 'invoice.invoiceNumber':
                this.router.navigate([`/invoices/${data.invoiceId}`]);
                break;
            case 'paymentId':
            case 'payment.referenceNumber':
                this.router.navigate([`/payments/${data.paymentId}`]);
                break;
        }
    }
    openFromQueue(data: any, dataField: string) {
        switch (dataField) {
            case 'id':
                if (this.filter.selectedEntityType === 'Customer') this.router.navigate([`/customers/${data.id}`]);
                else if (this.filter.selectedEntityType === 'Invoice') this.router.navigate([`/invoices/${data.id}`]);
                else if (this.filter.selectedEntityType === 'Payment') this.router.navigate([`/payments/${data.id}`]);
                break;
            case 'displayName':
                this.router.navigate([`/customers/${data.id}`]);
                break;
            case 'customer.displayName':
                this.router.navigate([`/customers/${data.customer.id}`]);
                break;
            case 'invoiceNumber':
                this.router.navigate([`/invoices/${data.id}`]);
                break;

            case 'referenceNumber':
                this.router.navigate([`/payments/${data.id}`]);
                break;
        }
    }

    printInvoices(invoices: Invoice[]) {
        const invoiceIds = invoices.map(invoice => invoice.id);
        this.reportService.printInvoiceReport(invoiceIds);
    }
}
