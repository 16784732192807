// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Invoice } from './invoice';
import { Payment } from './payment';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
/// </code-import>

export class PaymentLine extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  invoiceDueDate: Date;
  invoiceAmount: number = 0;
  paidAmount: number = 0;
  startingBalance: number = 0;
  remainingBalance: number = 0;
  relatedPaymentIds: number[] = [];
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  amount: number;
  created: Date;
  createdBy: string;
  deleted: Date;
  deletedBy: string;
  description: string;
  invoiceId: number;
  lineNumber: number;
  notes: string;
  overPaymentAmount: number;
  paymentId: number;
  invoice: Invoice;
  payment: Payment;
}

