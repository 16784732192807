// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Job } from './job';
import { RevenueType } from './revenue-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class RevenueLine extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  amount: number;
  deleted: Date;
  jobId: number;
  revenueTypeId: number;
  job: Job;
  revenueType: RevenueType;
}

