import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';

@Component({
    selector: 'app-invoice-communications',
    templateUrl: './invoice-communications.component.html',
    styleUrls: ['./invoice-communications.component.scss']
})
export class InvoiceCommunicationsComponent implements OnInit {
    communications: any[];

    constructor(private uow: CdrUnitOfWork, private router: Router) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.communications = await this.uow.getInvoiceCommunications();
        this.communications.forEach(communication => {
            communication.created = UtilityService.utcToLocal(communication.created);
            communication.sent = UtilityService.utcToLocal(communication.sent);
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    onRowDoubleClick(e) {
        if (!e.data) return;
        this.router.navigate([`/invoices/${e.data.invoiceId}`]);
    }
}
