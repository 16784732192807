import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PendingChangesGuard, PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DumpsterInventoryOverviewComponent } from './dumpster-inventory-overview/dumpster-inventory-overview.component';
import { DumpsterInventoryDialogComponent } from './dumpster-inventory-dialog/dumpster-inventory-dialog.component';
import { DumpsterLocationDialogComponent } from './dumpster-location-dialog/dumpster-location-dialog.component';
import { InventoryOverviewComponent } from './inventory-overview/inventory-overview.component';
import { InventorySubstitutionsComponent } from './inventory-substitutions/inventory-substitutions.component';
import { MaintenanceDialogComponent } from './maintenance-dialog/maintenance-dialog.component';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'dumpsters',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: DumpsterInventoryOverviewComponent,
                        data: { title: 'Dumpster Overview' }
                    },
                ]
            },
            {
                path: 'inventory',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: InventoryOverviewComponent,
                        data: { title: 'Inventory Overview' }
                    },
                    {
                        path: 'substitutions',
                        component: InventorySubstitutionsComponent,
                        data: { title: 'Inventory Substitutions' }
                    },
                ]
            }
        ])
    ],
    declarations: [
        DumpsterInventoryOverviewComponent,
        DumpsterInventoryDialogComponent,
        DumpsterLocationDialogComponent,
        InventoryOverviewComponent,
        InventorySubstitutionsComponent,
        MaintenanceDialogComponent
    ]
})
export class DumpsterModule {}
