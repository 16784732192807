import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { DispatchNotesOverviewComponent } from './dispatch-notes-overview/dispatch-notes-overview.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'dispatch-notes',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'overview',
                        pathMatch: 'full'
                    },
                    {
                        path: 'overview',
                        component: DispatchNotesOverviewComponent,
                        data: { title: 'Dispatch Notes Overview' }
                    },
                ]
            }
        ])
    ],
    declarations: [DispatchNotesOverviewComponent],
    exports: [
        DispatchNotesOverviewComponent
    ]
})
export class DispatchNoteModule {}
