<dx-popup
    [title]="'Rental Billing Line'"
    [width]="600"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="rentalBillingLine">
        <dxi-item [label]="{ text: 'Rental' }">
            <div *dxTemplate>
                <dx-select-box
                    [value]="rentalBillingLine.rentalId"
                    [items]="rentals"
                    displayExpr="displayName"
                    valueExpr="id"
                    disabled="true"
                ></dx-select-box>
            </div>
        </dxi-item>
        <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ type: 'date', width: '150px' }"> </dxi-item>
        <dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ type: 'date', width: '150px' }"> </dxi-item>
        <dxi-item [label]="{ text: 'Rate' }">
            <div *dxTemplate>
                <dx-number-box [(value)]="rate" [format]="{ type: 'currency', precision: 2 }" width="150px"></dx-number-box>
            </div>
        </dxi-item>

        <dxi-item dataField="note" editorType="dxTextArea" [editorOptions]="{ height: '125px' }"> </dxi-item>
    </dx-form>
</dx-popup>
