<div class="flex-fill d-flex flex-row">
    <div class="d-flex flex-column flex-shrink-0 sidebar">
        <div class="d-flex justify-content-center border-bottom">
            <div class="m-3">Great Plains</div>
        </div>
        <dx-form
            *ngIf="filter"
            [formData]="filter"
            class="d-flex flex-column align-items-center mt-3"
            (onEditorEnterKey)="refresh()"
            appAutofocusForm="companyName"
        >
            <dxi-item
                dataField="selectedEntityType"
                editorType="dxSelectBox"
                [label]="{ text: 'Type' }"
                [editorOptions]="{ items: types, disabled: processing }"
            ></dxi-item>
            <dxi-item>
                <div *dxTemplate>
                    <button class="btn btn-success d-flex m-auto" (click)="refresh()">Refresh Data</button>
                </div>
            </dxi-item>
        </dx-form>
    </div>
    <div class="d-flex flex-fill flex-column dx-card">
        <div class="d-flex flex-fill flex-column p-3">
            <div class="d-flex flex-column flex-fill">
                <dx-data-grid
                    #dataGrid
                    [keyExpr]="'id'"
                    class="flex-fill d-flex flex-column"
                    height="100px"
                    [dataSource]="data"
                    [showBorders]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)"
                    [columnAutoWidth]="true"
                >
                    <dxi-column
                        dataField="id"
                        caption="{{ filter.selectedEntityType }} Id"
                        cellTemplate="linkTemplate"
                        width="100"
                    ></dxi-column>
                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="invoiceNumber"
                        caption="Inv#"
                        cellTemplate="linkTemplate"
                        width="70"
                    ></dxi-column>

                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="customer.displayName"
                        caption="Customer"
                        cellTemplate="linkTemplate"
                        width="250"
                    ></dxi-column>
                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="customer.quickBooksId"
                        caption="SET GPId"
                        width="150"
                    ></dxi-column>
                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="batchNumber"
                        caption="Batch #"
                        width="150"
                    ></dxi-column>
                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="total"
                        [format]="{ type: 'currency', precision: 2 }"
                        caption="Amount"
                        width="70"
                    ></dxi-column>
                    <dxi-column
                        [visible]="filter.selectedEntityType === 'Invoice'"
                        dataField="invoiceDate"
                        caption="Invoice Date"
                        width="70"
                    ></dxi-column>
                    <dxi-column></dxi-column>

                    <div *dxTemplate="let cell of 'linkTemplate'">
                        <a
                            *ngIf="cell"
                            [routerLink]="[]"
                            [innerHtml]="cell.text"
                            (click)="openFromQueue(cell.data, cell.column.dataField)"
                        ></a>
                    </div>

                    <dxo-selection [mode]="'multiple'"></dxo-selection>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="gp-entity-grid"></dxo-state-storing>

                    <div *dxTemplate="let data of 'toolbarButtons'">
                        <button
                            class="btn btn-success mr-1 mb-2"
                            (click)="export()"
                            [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                        >
                            Export Selected
                        </button>
                        <button class="btn btn-success mr-1 mb-2" (click)="export(true)">Export All</button>
                        <button
                            class="btn btn-success mr-1 mb-2"
                            (click)="printInvoices(dataGrid.instance.getSelectedRowsData())"
                            [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                        >
                            Print
                        </button>
                    </div>

                    <div *dxTemplate="let data of 'toolbarText'">
                        <span *ngIf="data" class="mr-3">
                            <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                        </span>
                    </div>
                </dx-data-grid>
            </div>

            <div class="d-flex flex-column flex-fill pt-3">
                <dx-data-grid
                    #quickbooksSyncResultsGrid
                    keyExpr="id"
                    class="flex-fill d-flex flex-column"
                    height="100px"
                    [dataSource]="responses"
                    [showBorders]="true"
                    [showRowLines]="false"
                    [focusedRowEnabled]="false"
                    [columnAutoWidth]="true"
                    (onToolbarPreparing)="onToolbarPreparing($event)"
                >
                    <dxi-column dataField="quickBooksTaskId" caption="Task Id" width="65" alignment="left"></dxi-column>
                    <dxi-column dataField="invoice.batchNumber" caption="Batch #" width="65" alignment="left"></dxi-column>
                    <dxi-column dataField="id" caption="Id" width="65" alignment="left"></dxi-column>
                    <dxi-column dataField="type" caption="Type" width="80"></dxi-column>
                    <dxi-column
                        dataField="invoice.customer.quickBooksId"
                        caption="Customer Id"
                        width="100"
                        alignment="left"
                        cellTemplate="linkTemplate"
                    ></dxi-column>
                    <dxi-column caption="Customer" cellTemplate="customerTemplate" width="200"></dxi-column>
                    <dxi-column
                        dataField="invoiceId"
                        caption="Invoice Id"
                        width="100"
                        alignment="left"
                        cellTemplate="linkTemplate"
                    ></dxi-column>
                    <dxi-column dataField="invoice.invoiceNumber" caption="Inv#" cellTemplate="linkTemplate" width="70"></dxi-column>
                    <dxi-column
                        dataField="paymentId"
                        caption="Payment Id"
                        width="100"
                        alignment="left"
                        cellTemplate="linkTemplate"
                    ></dxi-column>
                    <dxi-column dataField="payment.referenceNumber" caption="Ref #" cellTemplate="linkTemplate" width="70"></dxi-column>
                    <dxi-column dataField="message" alignment="left"></dxi-column>
                    <dxi-column dataField="status" width="100" alignment="left"></dxi-column>
                    <dxi-column dataField="created" width="100"></dxi-column>
                    <dxi-column dataField="invoice.invoiceDate" caption="Invoice Date" width="100"></dxi-column>
                    <dxi-column></dxi-column>

                    <div *dxTemplate="let cell of 'customerTemplate'">
                        <a *ngIf="cell" [routerLink]="[]" (click)="open(cell.data, 'customerDisplayName')">
                            {{
                                cell.data.type === 'Customer'
                                    ? cell.data.customer.displayName
                                    : cell.data.type === 'Payment'
                                    ? cell.data.payment.customer.displayName
                                    : cell.data.type === 'Invoice'
                                    ? cell.data.invoice.customer.displayName
                                    : ''
                            }}
                        </a>
                    </div>
                    <div *dxTemplate="let cell of 'linkTemplate'">
                        <a *ngIf="cell" [routerLink]="[]" [innerHtml]="cell.text" (click)="open(cell.data, cell.column.dataField)"></a>
                    </div>
                    <div *dxTemplate="let data of 'toolbarButtons'"></div>
                    <div *dxTemplate="let data of 'toolbarText'">
                        <span *ngIf="responses" class="mr-3">
                            <strong>{{ quickbooksSyncResultsGrid.instance.totalCount() + ' items' }}</strong>
                        </span>
                    </div>

                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-selection [mode]="'multiple'"></dxo-selection>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-state-storing [enabled]="true" [storageKey]="'quickbooks-sync-grid'"></dxo-state-storing>
                </dx-data-grid>
            </div>
        </div>
    </div>
</div>
