<div class="d-flex flex-column flex-fill dx-card">
    <div class="d-flex mt-3 ml-3">
        <dx-form [formData]="filter" style="max-width: 150px">
            <dxi-item dataField="startDate" editorType="dxDateBox"></dxi-item>
            <dxi-item dataField="endDate" editorType="dxDateBox"></dxi-item>
        </dx-form>
        <div class="d-flex flex-column ml-3 mt-3">
            <button class="btn btn-secondary" (click)="toggleShowPreviousWeek()">
                {{ showPreviousWeek ? 'This Week' : 'Previous Week' }}
            </button>
            <button class="btn btn-primary mt-2" (click)="getData()">Search</button>
        </div>
        <div *ngIf="!!feeRevenue" class="summary-table">
            <table class="table">
                <thead>
                    <h4 style="text-align: center">Fee Revenue</h4>
                    <tr>
                        <th>Name</th>
                        <th>First</th>
                        <th>Last</th>
                        <th>Count</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let revenue of feeRevenue">
                        <td class="table-little-padding">{{ revenue.name }}</td>
                        <td class="table-little-padding">{{ revenue.first | date : 'shortDate' }}</td>
                        <td class="table-little-padding">{{ revenue.last | date : 'shortDate' }}</td>
                        <td class="table-little-padding total-right-align">{{ revenue.count | number }}</td>
                        <td class="table-little-padding total-right-align">{{ revenue.total | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="flex-row flex-fill d-flex pt-3">
        <div class="d-flex flex-fill flex-column pb-2 px-2">
            <h4 class="mx-2 my-0">Customer Totals</h4>
            <dx-data-grid
                #customerTotalsGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="350px"
                [dataSource]="customerTotals"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="customer" width="300"></dxi-column>
                <dxi-column dataField="invoiceCount" caption="Invoices" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="150"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-summary>
                    <dxi-total-item column="total" summaryType="sum" [valueFormat]="{ type: 'currency', precision: 2 }"> </dxi-total-item>
                </dxo-summary>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="report-customer-totals-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'"></div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="customerTotals" class="mr-3">
                        <strong>{{ customerTotalsGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
