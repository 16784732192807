import { Component, OnInit } from '@angular/core';
import { CdrUnitOfWork } from 'src/app/core/core.module';
import { User } from 'src/app/core/model/user';
import { DialogService } from 'src/app/shared/dialog.service';
import { UserDetailDialogComponent } from '../user-detail-dialog/user-detail-dialog.component';

@Component({
    selector: 'app-user-overview',
    templateUrl: './user-overview.component.html',
    styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {
    users: User[];

    constructor(private uow: CdrUnitOfWork, private dialogService: DialogService) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.users = await this.uow.users.all('isActive desc, userName', 'userRoles');
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async rowDoubleClick(e) {
        await this.open(e.data);
    }

    async open(user?: User) {
        if (!user) user = this.uow.users.createEntity();

        const dialogRef = this.dialogService.open(UserDetailDialogComponent);
        dialogRef.instance.user = user;
        const results = await dialogRef.instance.show();

        if (results.success) {
            results.value.addedRoleIds.forEach(roleId => {
                this.uow.userRoles.createEntity({
                    roleId: roleId,
                    userId: user.userId
                });
            });

            const userRoles = user.userRoles.filter(userRole => {
                return results.value.removedRoleIds.includes(userRole.roleId);
            });
            userRoles.forEach(userRole => {
                userRole.entityAspect.setDeleted();
            });

            await this.uow.commit();
            await this.getData();
        } else this.uow.rollback();
        this.dialogService.close(dialogRef);
    }
}
