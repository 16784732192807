import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { alert, confirm } from 'devextreme/ui/dialog';
import {
    AppInformationService,
    AuthService,
    CdrUnitOfWork,
    InvoiceService,
    ReportService,
    SessionInformationService,
    UtilityService
} from 'src/app/core/core.module';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceStatus } from 'src/app/core/model/invoice-status';
import { Payment } from 'src/app/core/model/payment';
import { QuickBooksService } from 'src/app/core/quickbooks.service';
import { EmailDialogComponent } from 'src/app/shared/email-dialog/email-dialog.component';
import { DialogService } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-payment-overview',
    templateUrl: './payment-overview.component.html',
    styleUrls: ['./payment-overview.component.scss']
})
export class PaymentOverviewComponent implements OnInit {
    payments: Payment[];
    invoices: Invoice[];
    invoiceStatuses: InvoiceStatus[];
    rentals: any[];
    currentUserData: any;
    processing = false;
    isPaymentAdmin = false;

    @Input() showAsComponent: boolean = false;
    @Input() customerId: number | null = null;
    @ViewChild('residentialJobsGrid') residentialJobsGrid: DxDataGridComponent;
    @ViewChild('overviewGrid') overviewGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private appInfo: AppInformationService,
        private auth: AuthService,
        private reportService: ReportService,
        private dialogService: DialogService,
        private utilityService: UtilityService,
        private qbService: QuickBooksService,
        private route: ActivatedRoute,
        private session: SessionInformationService,
        private invoiceService: InvoiceService
    ) {}

    async ngOnInit() {
        await this.checkParams();
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        const paymentMethods = this.uow.lookups.paymentMethods;
        this.isPaymentAdmin = this.auth.hasRole('Payments:Admin');
        await this.getData();
    }

    async checkParams() {
        this.route.queryParams.subscribe(params => {
            this.checkStatus(params);
        });
    }

    async checkStatus(params?: any, ignoreProcessing?: boolean) {
        if (!ignoreProcessing) {
            if (this.processing) return;
            this.processing = true;
        }
        let isAuthorized;
        if (params?.code && params?.realmId && params?.state) {
            isAuthorized = await this.qbService.authorizeCallback(params.code, params.realmId, params.state);
            this.session.setItem('currentUserData', isAuthorized.authData);
            this.router.navigate(['/payments'], { replaceUrl: true });
        } else {
            isAuthorized = await this.qbService.isAuthorized();
        }
        const status = isAuthorized ? 'Connected' : 'Disconnected';

        if (!ignoreProcessing) {
            this.processing = false;
        }
    }

    async getData() {
        this.payments = await this.uow.getPayments('customer, paymentLines');
        this.invoices = await this.uow.getActiveInvoices({ customerId: this.customerId, expand: ['customer'], onlyUnPaid: true });
    }

    async connectToQuickbooks() {
        this.currentUserData = await this.qbService.authorize();
        if (this.currentUserData.authorizeUrl && this.currentUserData.authorizeUrl.trim().length > 0)
            document.location.href = this.currentUserData.authorizeUrl;
    }
    async exportCustomer() {
        const params = this.session.getItem('currentUserData', true);
        const test = await this.qbService.exportCustomer(params.Code, params.RealmId, params.State, params.AccessToken);
    }
    async exportInvoices() {
        const params = this.session.getItem('currentUserData', true);
        const test = await this.qbService.exportInvoices(params.Code, params.RealmId, params.State, params.AccessToken);
    }
    async exportPayments() {
        const params = this.session.getItem('currentUserData', true);
        const test = await this.qbService.exportPayments(params.Code, params.RealmId, params.State, params.AccessToken);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    onRowDoubleClick(e) {
        if(!this.isPaymentAdmin) return;
        this.receivePayment();
    }

    onPaymentDoubleClick(e) {
        if(!this.isPaymentAdmin) return;
        this.openPayment(e.data.id);
    }

    openPayment(id: number) {
        if(!this.isPaymentAdmin) return;
        if (!id) return;

        this.router.navigate([`/payments/${id}`]);
    }

    open(invoice?: Invoice) {
        if(!this.isPaymentAdmin) return;
        this.router.navigate([`/invoices/${invoice ? invoice.id : 'new'}`]);
    }

    async delete(selectedRow: Payment) {
        const result = await confirm('Are you sure you want to delete the selected PAYMENT?', this.appInfo.appTitle);
        if (!result) return false;

        this.appInfo.pleaseWaitShow();
        const payment = (await this.uow.payments.byId(selectedRow.id, 'paymentLines.invoice')) as Payment;
        payment.paymentLines.forEach(paymentLine => {
            paymentLine.invoice.paid = null;
            paymentLine.invoice.statusId = InvoiceStatus.Statuses.Posted;
            paymentLine.deleted = new Date();
            paymentLine.deletedBy = this.auth.userValue.userName;
        });
        payment.deleted = new Date();
        payment.deletedBy = this.auth.userValue.userName;
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async receivePayment() {
        const invoice = this.overviewGrid.instance.getSelectedRowsData()[0];
        if (!invoice) return;

        const fullPaymentReceived = await this.invoiceService.receivePayment(invoice);

        await this.uow.commit();
        if (!fullPaymentReceived) {
            this.router.navigate([`/invoices/payments/new/${invoice.customerId}/${invoice.id}`]);
        } else {
            this.appInfo.pleaseWaitShow();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
    }

    printPaymentReceipt(payments: Payment[]) {
        const ids = payments.map(payment => payment.id);
        this.reportService.printPaymentReceiptReport(ids);
    }

    async emailPaymentReceipt(payment: Payment) {
        const dialogRef = this.dialogService.open(EmailDialogComponent);
        dialogRef.instance.entityIds = [payment.id];
        dialogRef.instance.mode = 'Payment';
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            const emailMessageIds = result.value.emailMessages.map(emailMessage => emailMessage.id);
            const success = await this.utilityService.sendEmail(emailMessageIds);
            if (success) await alert('Email sent', this.appInfo.appTitle);
            else await alert('Error sending email', this.appInfo.appTitle);
        }
    }

    createPayment(rentalId?) {
        this.router.navigate(['/invoices/new'], { queryParams: { rentalId } });
    }

    async sendForReview(invoices: Invoice[]) {
        if (!invoices) return;
        const success = await confirm(
            `Are you sure you want to mark the selected ${invoices.length === 1 ? 'invoice' : 'invoices'} for review?`,
            this.appInfo.appTitle
        );
        if (!success) return;

        invoices.forEach(invoice => (invoice.statusId = InvoiceStatus.Statuses.Review));
        await this.uow.commit();
    }

    openRental(rentalId: number) {
        this.router.navigate([`/rentals/${rentalId}`]);
    }

    async payWithoutQuickBooks(payment: Payment) {
        if (!payment || payment.quickBooksId || payment.quickBooksExport) return;

        const success = await confirm('Are you sure you want to mark this payment as paid without QuickBooks?', this.appInfo.appTitle);
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        payment.quickBooksExport = new Date();
        payment.quickBooksId = 'Paid w/o QB';
        await this.uow.commit([payment]);
        this.appInfo.pleaseWaitHide();
    }
}
