<div class="flex-fill d-flex" [ngClass]="readOnly ? '' : ' flex-column'">
    <div class="flex-fill content-block dx-card row" [ngClass]="readOnly ? '' : ' responsive-paddings'">
        <div class="d-flex flex-column p-3">
            <h4 class="ml-1 mb-0">Dispatch Notes</h4>
            <dx-data-grid
                #dataGrid
                keyExpr="id"
                class="{{!readOnly ? 'h-100' : 'readOnly-height'}}"
                [dataSource]="dispatchNotes"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowPrepared)="onRowPrepared($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
            >
                <dxi-column dataField="companyName" width="150"></dxi-column>
                <dxi-column dataField="siteName" width="150"></dxi-column>
                <dxi-column dataField="rentalDate" width="125"></dxi-column>
                <dxi-column dataField="created" width="150"></dxi-column>
                <dxi-column dataField="createdBy" width="150"></dxi-column>
                <dxi-column dataField="completed" width="150"></dxi-column>

                <dxo-selection [mode]="'multiple'"></dxo-selection>
                <dxo-search-panel [visible]="!readOnly"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <!-- <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export> -->
                <dxo-state-storing
                    [enabled]="true"
                    type="localStorage"
                    storageKey="{{!readOnly ? 'dispatch-notes-overview-grid' : 'rental-dispatch-notes-overview-grid'}}"
                ></dxo-state-storing>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button class="btn btn-success mr-1 mb-2" (click)="add()" [hidden]="readOnly">Add Note</button>
                    <button
                        class="btn btn-danger mr-2 mb-2"
                        (click)="delete(dataGrid.instance.getSelectedRowsData())"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                        [hidden]="readOnly"
                    >
                        Delete
                    </button>
                    <button
                        class="btn btn-primary ml-3 mr-1 mb-2"
                        (click)="createRental()"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length === 0 || canCreateRental"
                        [hidden]="readOnly"
                    >
                        Create Rental
                    </button>
                    <button
                        class="btn btn-secondary ml-3 mr-1 mb-2"
                        (click)="markComplete()"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length === 0 || canCreateRental"
                        [hidden]="readOnly"
                    >
                        Mark Complete
                    </button>
                    <button class="btn btn-secondary mb-2 mr-3" (click)="unComplete()" [disabled]="!canUnComplete" [hidden]="readOnly">
                        UnComplete
                    </button>
                    <button class="btn btn-sm btn-warning mb-2" (click)="toggleHideCompleted()" [hidden]="readOnly">
                        {{ hideCompleted ? 'Show All' : 'Hide Completed' }}
                    </button>
                </div>
            </dx-data-grid>
        </div>
        <div class="d-flex flex-fill flex-column p-3">
            <div class="mb-2">
                <button class="btn btn-success mr-1" *ngIf="hasChanges()" (click)="save()">Save</button>
                <button class="btn btn-danger" *ngIf="hasChanges()" (click)="cancel()">Cancel</button>
            </div>
            <h4 class="header-label mb-2">Note</h4>
            <app-dispatch-notes-detail
                style="display: block; padding-top: 1rem"
                *ngIf="selectedDispatchNote"
                [dispatchNote]="selectedDispatchNote"
                [readOnly]="!!selectedDispatchNote.completed"
                ></app-dispatch-notes-detail>
        </div>
    </div>
</div>
