// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { Dumpster } from './dumpster';
import { RateType } from './rate-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class CustomerRate extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static RateTypes = {
        Dumpster: 1,
        Hauling: 2,
        Shop: 3,
        Zone: 4
    };

    get dumpsterSize() {
        return `${this.dumpster ? this.dumpster.size : '----'}`;
    }

    get rangeDisplay() {
        return `${this.zone ? 'Zone ' + this.zone : this.range}`;
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  baseMileage: number;
  created: Date;
  createdBy: string;
  customerId: number;
  deleted: Date;
  deletedBy: string;
  displayOrder: number;
  dumpsterId: number;
  extendedRange: boolean;
  mileageOffset: number;
  mileageOffsetRate: number;
  outerRange: boolean;
  pricePerTon: number;
  range: string;
  rate: number;
  rateTypeId: number;
  regularRange: boolean;
  weightLimit: number;
  zone: number;
  customer: Customer;
  dumpster: Dumpster;
  rateType: RateType;
}

