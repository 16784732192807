<dx-popup title="Dumpster" [width]="400" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="dumpster" class="mb-4" validationGroup="mainValidationGroup">
        <dxi-item dataField="size"> </dxi-item>
        <dxi-item dataField="basePrice" [editorOptions]="{ format: { type: 'currency', precision: 2 } }">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="extendedPrice" [editorOptions]="{ format: { type: 'currency', precision: 2 } }">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="weightLimit" [editorOptions]="{ format: { type: 'fixedPoint', precision: 2 } }">
            <dxi-validation-rule type="required" message="Required"> </dxi-validation-rule>
        </dxi-item>
        <dxi-item
            dataField="categoryId"
            editorType="dxSelectBox"
            [editorOptions]="{ dataSource: dumpsterCategories, valueExpr: 'id', displayExpr: 'name' }"
            [label]="{ text: 'Category' }"
        >
        </dxi-item>
        <dxi-item dataField="trackInventory" editorType="dxCheckBox"> </dxi-item>
        <dxi-item dataField="chargeKentFee" editorType="dxCheckBox"> </dxi-item>
        <dxi-item dataField="chargeMichiganFee" editorType="dxCheckBox"> </dxi-item>
    </dx-form>
</dx-popup>
