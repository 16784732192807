import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { Photo } from 'src/app/core/model/photo';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { PhotoDialogComponent } from 'src/app/shared/photo-dialog/photo-dialog.component';
import { DialogService } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-photo-overview',
    templateUrl: './photo-overview.component.html',
    styleUrls: ['./photo-overview.component.scss']
})
export class PhotoOverviewComponent {
    uow = inject(CdrUnitOfWork);
    dialogService = inject(DialogService);
    auth = inject(AuthService);
    appInfo = inject(AppInformationService);
    router = inject(Router);

    photos: Photo[];

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.photos = await this.uow.getPhotos(null, null, 'rental.customer, rental.serviceAddress');
    }

    open(photo: Photo, dataField: string) {
        switch (dataField) {
            case 'rentalId':
                this.router.navigate([`/rentals/${photo.rentalId}`]);
                break;
            case 'rental.customer.displayName':
                if (!this.auth.hasRole('Customer:Admin')) break;
                this.router.navigate([`/customers/${photo.rental.customerId}`]);
                break;
            case 'rental.siteAddressHTML':
                this.router.navigate([`/jobs/sites/${photo.rental.serviceAddressId}`]);
                break;
        }
    }

    openFile(photo: Photo) {
        if (!photo) return;
        window.open(photo.filename, '_blank');
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                },
                location: 'after'
            },
            {
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                },
                location: 'after'
            }
        );
    }

    rowDoubleClick(e) {
        this.edit(e.data);
    }

    async edit(photo: Photo) {
        if (!photo) return;
        const dialogRef = this.dialogService.open(PhotoDialogComponent);
        dialogRef.instance.photo = photo;
        await dialogRef.instance.show();
        this.dialogService.close(dialogRef);
    }

    async delete(photos: Photo[]) {
        if (!photos || photos.length === 0) return;
        const success = await confirm(
            `Are you sure you want to DELETE ${photos.length > 1 ? 'these photos' : 'this photo'}?`,
            this.appInfo.company
        );
        if (!success) return;
        this.appInfo.pleaseWaitShow();
        photos.forEach(row => {
            row.deleted = new Date();
            row.deletedBy = this.auth.userValue.userName;
        });
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }
}
