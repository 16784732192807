import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService, DataServiceConfig, EntityManager, EntityQuery, NamingConvention } from 'breeze-client';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { environment } from 'src/environments/environment';
import { CdrRegistrationHelper } from '../model/registration-helper';
import { Entities } from './entities';

// The EntityManagerProvider manages a static master manager and a per instance sandbox manager.
@Injectable({
    providedIn: 'root'
})
export class EntityManagerProvider {
    private static _preparePromise: Promise<any>;
    private static _masterManager: EntityManager;
    static serviceName: string;

    constructor(http: HttpClient/*, private utilityService: UtilityService*/) {
        // the order is important
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(http);
        DataServiceWebApiAdapter.register();
    }

    async prepare(): Promise<any> {
        EntityManagerProvider.serviceName = environment.serviceName + '/breeze';

        if (!EntityManagerProvider._preparePromise) {
            NamingConvention.camelCase.setAsDefault();
            const dsconfig: DataServiceConfig = {
                serviceName: EntityManagerProvider.serviceName
            };
            const dataService = new DataService(dsconfig);

            const masterManager = (EntityManagerProvider._masterManager = new EntityManager({
                dataService
            }));

            CdrRegistrationHelper.register(masterManager.metadataStore);

            const metaPromise = <Promise<any[]>>(<any>masterManager.fetchMetadata().catch(e => {
                // if there's an error, we need to ensure prepare can be called fresh
                EntityManagerProvider._preparePromise = null;
                throw e;
            }));
            EntityManagerProvider._preparePromise = metaPromise.then(() => {
                // Load lookups
                const query = EntityQuery.from('lookups');
                return masterManager
                    .executeQuery(query)
                    .then(async data => {
                        Entities.lookups = data.results[0];
                        // const appInfo = await this.utilityService.getApplicationInfo();
                        return Entities.lookups;
                    })
                    .catch(error => {
                        // not sure we need this second catch
                        EntityManagerProvider._preparePromise = null;
                        throw error;
                    });
            });
            return EntityManagerProvider._preparePromise;
        }

        return EntityManagerProvider._preparePromise;
    }

    reset(manager: EntityManager): void {
        if (manager) {
            manager.clear();
            this.seedManager(manager);
        }
    }

    newManager(): EntityManager {
        const manager = EntityManagerProvider._masterManager.createEmptyCopy();
        this.seedManager(manager);
        return manager;
    }

    private seedManager(manager: EntityManager) {
        manager.importEntities(EntityManagerProvider._masterManager.exportEntities(null, { asString: false, includeMetadata: false }));
    }
}
