<div class="header-label">Communications</div>
<div class="flex-fill d-flex flex-column content-block dx-card p-3">
    <div class="flex-fill d-flex flex-column">
        <dx-data-grid
            #dataGrid
            keyExpr="id"
            class="flex-fill d-flex flex-column"
            height="100px"
            [dataSource]="communications"
            [showBorders]="true"
            (onToolbarPreparing)="onToolbarPreparing($event)"
            (onRowDblClick)="onRowDoubleClick($event)"
        >
            <dxi-column dataField="type" width="100"></dxi-column>
            <dxi-column dataField="sent" dataType="date" format="shortDateShortTime" width="200"></dxi-column>
            <dxi-column dataField="invoiceId" width="100" cellTemplate="invoiceLinkTemplate"></dxi-column>
            <dxi-column dataField="invoiceNumber" caption="Invoice #" width="100" cellTemplate="invoiceLinkTemplate"></dxi-column>
            <dxi-column dataField="rentalDisplay" caption="Rental" width="100" cellTemplate="rentalLinkTemplate"></dxi-column>
            <dxi-column dataField="status" width="125"></dxi-column>
            <dxi-column dataField="customer" width="300" cellTemplate="customerLinkTemplate"></dxi-column>
            <dxi-column dataField="created" dataType="date" format="shortDateShortTime" width="250"></dxi-column>
            <dxi-column dataField="createdBy" width="150"></dxi-column>
            <dxi-column dataField="error" width="350"></dxi-column>
            <dxi-column></dxi-column>

            <div *dxTemplate="let cell of 'invoiceLinkTemplate'">
                <a *ngIf="cell" [routerLink]="'/invoices/' + cell.data.invoiceId" [innerHtml]="cell.text"></a>
            </div>
            <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                <a *ngIf="cell" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
            </div>
            <div *dxTemplate="let cell of 'customerLinkTemplate'">
                <a *ngIf="cell" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
            </div>

            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-communications-grid"></dxo-state-storing>

            <div *dxTemplate="let data of 'toolbarButtons'"></div>
            <div *dxTemplate="let data of 'toolbarText'">
                <span *ngIf="communications" class="mr-3">
                    <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                </span>
            </div>
        </dx-data-grid>
    </div>
</div>
