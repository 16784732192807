import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, UtilityService } from 'src/app/core/core.module';
import { ServiceAddress } from 'src/app/core/model/service-address';
import { confirm, DialogService } from 'src/app/shared/shared.module';
import { ServiceAddressDialogComponent } from '../service-address-dialog/service-address-dialog.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-service-address-overview',
    templateUrl: './service-address-overview.component.html',
    styleUrls: ['./service-address-overview.component.scss']
})
export class ServiceAddressOverviewComponent implements OnInit {
    serviceAddresses: ServiceAddress[];

    @Input() customerId: number | null = null;
    @Input() readonly: boolean;

    constructor(
        private uow: CdrUnitOfWork,
        private dialogService: DialogService,
        private authService: AuthService,
        private appInfo: AppInformationService,
        private utilityService: UtilityService
    ) {}

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        this.serviceAddresses = await this.uow.getActiveServiceAddresses(this.customerId);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async rowDoubleClick(e) {
        if (this.readonly) return;
        await this.open(e.data);
    }

    async open(serviceAddress?: ServiceAddress) {
        const isNew = !serviceAddress;

        if (!serviceAddress) {
            serviceAddress = this.uow.serviceAddresses.createEntity({
                customerId: this.customerId,
                createDate: moment(new Date()),
                createdBy: this.authService.userValue.userName
            });
        }

        const dialogRef = this.dialogService.open(ServiceAddressDialogComponent);
        dialogRef.instance.serviceAddress = serviceAddress;
        const results = await dialogRef.instance.show();

        if (results.success) {
            if (
                isNew &&
                this.serviceAddresses.find(
                    address =>
                        address.address1?.toLowerCase() === serviceAddress.address1?.toLowerCase() &&
                        address.city?.toLowerCase() === serviceAddress.city?.toLowerCase() &&
                        address.state?.toLowerCase() === serviceAddress.state?.toLowerCase()
                )
            ) {
                const success = await confirm(
                    'Another address with the same address information already exists. Are you sure you want to create a new one?',
                    `${this.appInfo.company}`
                );
                if (!success) {
                    serviceAddress.entityAspect.rejectChanges();
                    return;
                }
            }
            await this.uow.commit([serviceAddress]);
            if (!isNew) {
                const success = await confirm(
                    'Do you want to update the Site Address information for all uncompleted jobs that use this service address?',
                    `${this.appInfo.company}`
                );
                if (success) await this.utilityService.updateUncompletedJobs(serviceAddress.id);
            }
            await this.getData();
        } else serviceAddress.entityAspect.rejectChanges();
        this.dialogService.close(dialogRef);
    }

    async delete(serviceAddress: ServiceAddress) {
        if (!(await confirm('Are you sure you want to delete the selected service address?', this.appInfo.appTitle))) return false;

        serviceAddress.deleted = moment(new Date()).toDate();
        await this.uow.commit([serviceAddress]);
        await this.getData();
    }

    async getDirections(a: ServiceAddress) {
        const address = (
            a.latitude && a.longitude
                ? [a.latitude, a.longitude]
                : _([a.address1, a.address2, a.city, a.state, a.zip]).filter(value => !!value).valueOf()
        ).join('+');
        try {
            const url = `https://maps.apple.com/?q=${address}`;
            window.open(url, '_blank');
        } catch (error) {
            alert(error);
        }
    }
}
