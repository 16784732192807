import { ErrorHandler, Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { AppInformationService } from './app-information.service';

@Injectable()
export class ToastErrorHandler extends ErrorHandler {
    constructor() {
        super();
    }

    async handleError(error) {
        console.error(error);

        if (AppInformationService.platform !== 'debug') {}

        let message: string = error.rejection ? error.rejection.message : error.message;
        if (error.rejection && error.rejection.entityErrors) {
            message = `${message}; Entity Errors:`;
            error.rejection.entityErrors.forEach(entityError => {
                message = `${message} ${entityError.errorMessage};`;
            });
        }
        if (message.indexOf(`<title>`) > 0) {
            message = 'Network error<br>' + message.substring(message.indexOf(`<title>`), message.indexOf(`</title>`));
        }
        if (message.includes('Entities may have been modified or deleted since entities were loaded')) {
            message = 'The item you are saving was modified by another user. Please refresh your browser and make your changes again.';
        }

        if (message.includes('ExpressionChangedAfterItHasBeenCheckedError')) return;
        if (message.includes(`Cannot read properties of undefined (reading '_updateBrokenRules')`)) return;
        if (message.includes(`Cannot read properties of undefined (reading 'resolve')`)) return;

        notify({
            position: { my: 'center top', at: 'center top', offset: '0 20', of: 'body' },
            message,
            closeOnClick: true,
            displayTime: 6000,
            width: '400px',
            type: 'error'
        });
    }
}
