// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Invoice } from './invoice';
import { PrintQueue } from './print-queue';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class PrintQueueInvoiceLink extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  invoiceId: number;
  printQueueId: number;
  invoice: Invoice;
  printQueue: PrintQueue;
}

