import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CdrUnitOfWork } from './core.module';
import { Rental, ServiceAddress } from './model/entity-model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class RentalService {
    constructor(private auth: AuthService, private uow: CdrUnitOfWork) {}

    createJob(rental: Rental, serviceAddress: ServiceAddress, startDate: Date, jobTypeId: number) {
        return this.uow.jobs.createEntity({
            rentalId: rental.id,
            dumpsterId: rental.dumpsterId,
            billAsDumpsterId: rental.dumpsterId,
            jobTypeId: jobTypeId,
            actionId: jobTypeId,
            created: new Date(),
            createdBy: this.auth.userValue.userName,
            startDate: moment(startDate).startOf('day').toDate(),
            siteName: serviceAddress.siteName,
            address1: serviceAddress.address1,
            address2: serviceAddress.address2,
            city: serviceAddress.city,
            state: serviceAddress.state,
            zip: serviceAddress.zip,
            country: serviceAddress.country,
            latitude: serviceAddress.latitude,
            longitude: serviceAddress.longitude,
            notes: serviceAddress.notes,
            driverNotes: rental.driverInstructions
        });
    }
}
