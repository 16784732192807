<dx-popup title="Dumpster Fee" [width]="400" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="dumpsterFeeType" class="mb-4" validationGroup="mainValidationGroup">
        <dxi-item dataField="name">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="visibleForPickup" editorType="dxCheckBox"></dxi-item>
        <dxi-item dataField="visibleForDropOff" editorType="dxCheckBox"></dxi-item>
        <dxi-item dataField="price" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'currency', precision: 2 } }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="range" [min]="0" message="cannot be negative"></dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ text: 'Associated Dumpsters' }">
            <div *dxTemplate>
                <dx-list
                    [items]="dumpsters"
                    keyExpr="id"
                    [(selectedItemKeys)]="selectedDumpsterIds"
                    selectionMode="multiple"
                    [showSelectionControls]="true"
                    itemTemplate="listItem"
                >
                    <div *dxTemplate="let item of 'listItem'">
                        {{ item.size }}
                    </div>
                </dx-list>
            </div>
        </dxi-item>
    </dx-form>
</dx-popup>
