import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { SiteDetailComponent } from './site-detail/site-detail.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'jobs',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'overview',
                        pathMatch: 'full'
                    },
                    {
                        path: 'overview',
                        component: JobOverviewComponent,
                        data: { title: 'Job Overview' }
                    },
                    {
                        path: 'sites/:id',
                        component: SiteDetailComponent,
                        data: { title: 'Site' }
                    },
                ]
            }
        ])
    ],
    declarations: [JobOverviewComponent, SiteDetailComponent]
})
export class JobModule {}
