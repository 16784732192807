import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class PhotoService {
    auth = inject(AuthService);

    static PhotoMode = {
        Rental: 1
    };
    static ImageExtensions = ['.jpg', '.jpeg', '.png', '.webp', '.gif'];

    getUploadUrl(id: string | number, mode: number) {
        return `${environment.serviceName}/file/savePhoto?id=${id}&mode=${mode}`;
    }

    async getUploadHeader() {
        const token = await this.auth.getAccessToken();
        return { Authorization: `Bearer ${token}` };
    }

    parseUploadResponse(e: any) {
        if (!e || !e.request || !e.request.responseText) return { success: false, message: 'Could not handle upload response' };
        return JSON.parse(e.request.responseText) as { success: boolean; message?: string };
    }
}
