import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInformationService } from './core/app-information.service';
import { CoreModule } from './core/core.module';
import { CustomersModule } from './customer/customer.module';
import { DispatchNoteModule } from './dispatch-notes/dispatch-notes.module';
import { DriverModule } from './drivers/driver.module';
import { DumpsterModule } from './dumpster/dumpster.module';
import { GreatPlainsModule } from './great-plains/great-plains.module';
import { InvoiceModule } from './invoice/invoice.module';
import { JobModule } from './job/job.module';
import { SingleCardModule } from './layouts';
import { TopNavModule } from './layouts/top-nav/top-nav.component';
import { RentalModule } from './rentals/rental.module';
import { ReportingModule } from './reporting/reporting.module';
import { ScheduleModule } from './scheduling/schedule.module';
import { ScreenService } from './shared/services';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        SharedModule,
        TopNavModule,
        SingleCardModule,
        CoreModule,
        CustomersModule,
        RentalModule,
        InvoiceModule,
        ReportingModule,
        ScheduleModule,
        DriverModule,
        AdminModule,
        DispatchNoteModule,
        JobModule,
        DumpsterModule,
        GreatPlainsModule,
        AppRoutingModule
    ],
    providers: [
        ScreenService, AppInformationService],
    bootstrap: [AppComponent]
})
export class AppModule {}
