import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork } from 'src/app/core/core.module';
import { DispatchNote } from 'src/app/core/model/dispatch-note';
import { confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-dispatch-notes-overview',
    templateUrl: './dispatch-notes-overview.component.html',
    styleUrls: ['./dispatch-notes-overview.component.scss']
})
export class DispatchNotesOverviewComponent implements OnInit {
    @Input() readOnly: boolean = false;

    dispatchNotes: DispatchNote[];
    selectedDispatchNote: any;
    canCreateRental = false;
    canUnComplete = false;
    hideCompleted: boolean = true;

    @ViewChild('dataGrid') dataGrid: DxDataGridComponent;

    constructor(private uow: CdrUnitOfWork, private auth: AuthService, private appInfo: AppInformationService, private router: Router) {}

    async ngOnInit() {
        this.selectedDispatchNote = {};
        await this.getData();
    }

    async getData() {
        this.dispatchNotes = await this.uow.dispatchNotes.getActiveDispatchNotes(this.hideCompleted);
    }

    async toggleHideCompleted() {
        this.hideCompleted = !this.hideCompleted;
        await this.getData();
    }

    hasChanges() {
        return this.uow.hasChanges();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'toolbarButtons'
        });
    }
    onRowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.completed) {
                e.rowElement.className = e.rowElement.className + ' completed-dispatchNote';
            }
        }
    }

    async onSelectionChanged(e) {
        if (e.selectedRowsData) {
            if (e.selectedRowsData.length === 1 && !e.selectedRowsData[0].completed) this.canCreateRental = false;
            else this.canCreateRental = true;
            this.canUnComplete = e.selectedRowsData.length > 0 && e.selectedRowsData.some(dispatchNote => {
                return dispatchNote.completed !== null
            });
        }

        if (this.hasChanges()) {
            const result = await confirm(
                'Selecting another dispatch note will discard any unsaved changes. Do you want to continue?',
                this.appInfo.appTitle
            );
            if (!result) return;
            await this.cancel(false);
        }

        if (e.selectedRowsData.length !== 1) this.selectedDispatchNote = {};
        else this.selectedDispatchNote = e.selectedRowsData[0];
    }

    async save() {
        this.appInfo.pleaseWaitShow();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async cancel(showConfirm: boolean = true) {
        if (showConfirm && !!this.selectedDispatchNote.companyName) {
            const result = await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle);
            if (!result) return;
        }

        if (this.selectedDispatchNote.id <= 0) this.selectedDispatchNote = null;
        this.uow.rollback();
    }

    add() {
        this.selectedDispatchNote = this.uow.dispatchNotes.createEntity();
        this.selectedDispatchNote.created = moment(new Date()).toDate();
        this.selectedDispatchNote.createdBy = this.auth.userValue.userName;
        this.selectedDispatchNote.rentalDate = new Date();
    }

    async delete(dispatchNotes: DispatchNote[]) {
        const result = await confirm(
            `Are you sure you want to delete the selected dispatch note${dispatchNotes.length > 1 ? 's' : ''}?`,
            this.appInfo.appTitle
        );
        if (!result) return;

        dispatchNotes.forEach(dispatchNote => {
            dispatchNote.deleted = moment(new Date()).toDate();
        });
        this.appInfo.pleaseWaitShow();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
        this.selectedDispatchNote = null;
    }

    async markComplete() {
        const selectedRows = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedRows || selectedRows.length !== 1) return;
        this.appInfo.pleaseWaitShow();
        selectedRows[0].completed = moment(new Date()).toDate();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
        this.selectedDispatchNote = null;
        this.dataGrid.instance.clearSelection();
    }

    async unComplete() {
        const selectedDispatchNotes = this.dataGrid.instance.getSelectedRowsData();
        if (!selectedDispatchNotes) return;
        selectedDispatchNotes.forEach(dispatchNote => {
            dispatchNote.completed = null;
        })
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
        this.canUnComplete = false;
    }

    createRental() {
        if (!this.selectedDispatchNote) return;
        this.selectedDispatchNote.completed = new Date();
        this.router.navigate([`/rentals/new`], { queryParams: { dispatchNoteId: this.selectedDispatchNote.id } });
    }
}
