import { EntityManager, EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
import { DispatchNote } from '../model/entity-model';
import { Repository } from './repository';
export class DispatchNoteRepository extends Repository<DispatchNote> {
    constructor(manager: EntityManager) {
        super(manager, 'DispatchNote', 'dispatchNotes');
    }

    async getActiveDispatchNotes(excludeCompleted?: boolean) {
        let query = this.baseQuery().where('deleted', FilterQueryOp.Equals, null).orderBy('created', true);
        if (excludeCompleted) query = query.where('completed', '==', null);
        const data = await this.manager.executeQuery(query);
        return data.results as DispatchNote[];
    }

    async getNonCompletedDispatchNotes() {
        const predicate = new Predicate('deleted', FilterQueryOp.Equals, null).and('completed', FilterQueryOp.Equals, null);
        const query = this.baseQuery().where(predicate).orderBy('created', true);
        const data = await this.manager.executeQuery(query);
        return data.results as DispatchNote[];
    }
}
