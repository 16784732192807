<dx-popup
    title="Edit Photo"
    [width]="400"
    [height]="'auto'"
    [(visible)]="visible"
    [hideOnOutsideClick]="false"
    (onHiding)="onHiding($event)"
>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form [formData]="photo" validationGroup="photoDialogValidationGroup">
        <dxi-item dataField="name" [label]="{ text: 'File Name' }" [isRequired]="true"></dxi-item>
        <dxi-item dataField="notes" editorType="dxTextArea" [editorOptions]="{ height: '150px' }"></dxi-item>
    </dx-form>
</dx-popup>
