import { EntityManager, EntityQuery, FilterQueryOp, Predicate } from 'breeze-client';
import * as moment from 'moment';
import { Job, Rental } from '../model/entity-model';
import { Repository } from './repository';
export class RentalRepository extends Repository<Rental> {
    constructor(manager: EntityManager) {
        super(manager, 'Rental', 'rentals');
    }

    async getRentals(expand: string, hideCompleted?: boolean) {
        const predicate = new Predicate('deleted', '==', null);
        let query = EntityQuery.from('Rentals').where(predicate);
        if (hideCompleted) query = query.where('completed', '==', null);
        if (expand) query = query.expand(expand);
        const data = await this.manager.executeQuery(query);
        return data.results as Rental[];
    }

    async getActiveRentals(options?: any) {
        let query = EntityQuery.from('Rentals').where('deleted', FilterQueryOp.Equals, null);
        if (options?.customerId) query = query.where('customerId', FilterQueryOp.Equals, options.customerId);
        const data = await this.manager.executeQuery(query);
        return data.results as Rental[];
    }

    async getUnassignedRentals() {
        //rentals that have no non-deleted jobs
        const query = EntityQuery.from('UnassignedRentals').orderBy('startDate').expand('customer');
        const data = await this.manager.executeQuery(query);
        return data.results as Rental[];
    }

    async getCommercialRentals() {
        const query = EntityQuery.from('GetCommercialJobsForInvoicing');
        const data = await this.manager.executeQuery(query);
        return data.results;
    }

    async getRentalInventory(rentalId?: number) {
        let query = EntityQuery.from('RentalInventory');
        if (rentalId) query = query.where('rentalId', '==', rentalId);
        const data = await this.manager.executeQuery(query);
        return data.results;
    }
}
