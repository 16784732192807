import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInformationService, AuthService, CdrUnitOfWork, SessionInformationService } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { CustomerRate } from 'src/app/core/model/customer-rate';

@Component({
    selector: 'app-customer-rates',
    templateUrl: './customer-rates.component.html',
    styleUrls: ['./customer-rates.component.scss']
})
export class CustomerRatesComponent implements OnInit {
    customerRates: CustomerRate[];

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private sessionInfo: SessionInformationService,
        private appInfo: AppInformationService,
        private auth: AuthService
    ) {}

    async ngOnInit() {
        this.appInfo.pleaseWaitShow();
        let customerRates = await this.uow.getCustomerRates({
            expand: 'customer, dumpster',
            orderBy: 'customer.companyName, rateTypeId, dumpster.size'
        });
        this.customerRates = customerRates.filter(x => x.deleted == null && x.customer.deleted == null);
        this.appInfo.pleaseWaitHide();
    }

    rowDoubleClick(e) {
        this.open(e.data.customer);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after',
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    open(customer?: Customer) {
        if (!customer) return;
        this.router.navigate([`/customers/${customer.id}`]);
    }
}
