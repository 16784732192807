<div class="flex-fill d-flex flex-column">
    <div class="d-flex dx-card responsive-paddings align-items-center">
        <label class="mr-3">Schedule Date</label>
        <dx-date-box style="max-width: '150px'" [(value)]="scheduleDate" (onValueChanged)="dateChange(0)"></dx-date-box>
        <button type="button" (click)="dateChange(-1)" class="btn btn-sm btn-secondary ml-4 mr-2" style="min-width: 100px">Previous</button>
        <button type="button" (click)="dateChange(1)" class="btn btn-sm btn-secondary mr-4" style="min-width: 100px">Next</button>
        <label class="mr-3">Driver</label>
        <dx-select-box
            [(value)]="selectedDriverId"
            [items]="drivers"
            displayExpr="name"
            valueExpr="userId"
            (onValueChanged)="driverChanged($event)"
        ></dx-select-box>
    </div>
    <div *ngFor="let job of jobs" class="m-1 ml-3">
        <button
            type="button"
            (click)="openJob(job)"
            [class]="'btn ' + (!job.completed ? 'btn-primary' : 'btn-success')"
            style="width: 200px; height: 120px"
        >
            <div *ngIf="job.rental">{{ job.rental.customer.displayName }}</div>
            <div>{{ job.jobType.name }}</div>
            <div *ngIf="job.rental">{{ job.rental.dumpster.size }}</div>
            <div *ngIf="!job.rental">{{ job.displayAddress }}</div>
        </button>
    </div>
</div>
