// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class SalesPerson extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static Options = {
    CDR: 1
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  deleted: Date;
  displayOrder: number;
  name: string;
  customers: Customer[];
}

