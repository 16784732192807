// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class StorageDumpSite extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get displayAddress() {
    return [this.address1, this.address2, this.city + ',', this.state, this.zip, this.country].join(' ');
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  address1: string;
  address2: string;
  city: string;
  country: string;
  created: Date;
  createdBy: string;
  deleted: Date;
  displayOrder: number;
  dump: boolean;
  name: string;
  note: string;
  state: string;
  storage: boolean;
  zip: string;
}

