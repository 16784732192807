import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { alert, confirm } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, RentalService, SessionInformationService, UtilityService } from 'src/app/core/core.module';
import { GridUtility } from 'src/app/core/grid-utility';
import { Dumpster } from 'src/app/core/model/dumpster';
import { Customer, DispatchNote } from 'src/app/core/model/entity-model';
import { JobType } from 'src/app/core/model/job-type';
import { Rental } from 'src/app/core/model/rental';
import { DialogService } from 'src/app/shared/dialog.service';
import { RentalJobDialogComponent } from '../rental-job-dialog/rental-job-dialog.component';

@Component({
    selector: 'app-rental-overview',
    templateUrl: './rental-overview.component.html',
    styleUrls: ['./rental-overview.component.scss']
})
export class RentalOverviewComponent implements OnInit {
    dumpsters: Dumpster[];
    jobTypes: JobType[];
    rentals: Rental[];
    customers: Customer[];
    dispatchNotes: DispatchNote[];
    gridUtil: GridUtility;
    isRentalAdmin = false;
    isCustomerAdmin = false;

    @ViewChild('rentalsGrid') rentalsGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private appInfo: AppInformationService,
        private router: Router,
        private dialogService: DialogService,
        private rentalService: RentalService,
        private sessionInfo: SessionInformationService,
        private auth: AuthService
    ) {}

    async ngOnInit() {
        this.appInfo.pleaseWaitShow();
        this.dumpsters = this.uow.lookups.dumpsters as Dumpster[];
        this.jobTypes = this.uow.lookups.jobTypes as JobType[];
        await this.getData();
        this.gridUtil = new GridUtility(this.rentalsGrid, 'rentals-grid', this.sessionInfo);
        this.gridUtil.setScrollPosition();
        this.appInfo.pleaseWaitHide();
        this.isRentalAdmin = this.auth.hasRole('Rentals:Admin');
        this.isCustomerAdmin = this.auth.hasRole('Customer:Admin');
    }

    async getData() {
        await this.getActiveRentalCustomers();
        await this.getRentals();
        await this.getDispatchNotes();
    }

    async getActiveRentalCustomers() {
        //get the customer for active rentals, rather than doing an expand
        this.customers = await this.uow.customers.getActiveRentalCustomers();
    }

    async getRentals() {
        this.rentals = await this.uow.rentals.getRentals('serviceAddress, jobs', true);

        // inventoryHtml persists when navigating away and then back to page. This is cleared to prevent duplicate inventory lines
        this.rentals.forEach(rental => ((rental as any).inventoryHtml = null));

        const rentalInventories = await this.uow.rentals.getRentalInventory();
        rentalInventories.forEach(rentalInventory => {
            const rental = this.rentals.find(rental => rental.id === rentalInventory.rentalId) as any;
            if (!rental) return;
            const onSiteDumpsters = [];
            rental.daysOnSite = 0;
            rentalInventory.data.forEach(dumpster => {
                const daysOnSite = moment().diff(moment(dumpster.placed), 'days');
                rental.daysOnSite = rental.daysOnSite < daysOnSite ? daysOnSite : rental.daysOnSite;
                onSiteDumpsters.push(`${dumpster.barcode} - ${moment(dumpster.placed).format('MM/DD/YYYY')} - ${daysOnSite} days`);
            });
            rental.inventoryHtml = onSiteDumpsters.join('<br/>');
        });
    }

    async getDispatchNotes() {
        this.dispatchNotes = await this.uow.dispatchNotes.getActiveDispatchNotes();
    }

    onRowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.daysOnSite > 29) {
                e.rowElement.className = e.rowElement.className + ' over-30-days';
            }
            if (e.data.daysOnSite > 59) {
                e.rowElement.className = e.rowElement.className + ' over-60-days';
            }
        }
    }
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            }
        );
    }

    onRentalRowDoubleClick(e) {
        this.openRental(e.data.id);
    }

    openRental(id?: number) {
        if (!this.isRentalAdmin) return;
        this.router.navigate([`/rentals/${id ? id : 'new'}`]);
    }

    createJob(rental: Rental, startDate: Date, jobTypeId: number) {
        return this.rentalService.createJob(rental, rental.serviceAddress, startDate, jobTypeId);
    }

    async add(rental: Rental, type: 'Delivery' | 'PickUp' | 'Exchange' | 'RoundTrip' | 'Service') {
        const jobType = JobType.getJobTypIdeByName(type);
        let job = this.createJob(rental, moment(new Date()).startOf('day').toDate(), jobType);
        job.dumpsterId = rental.dumpsterId;

        const dialogRef = this.dialogService.open(RentalJobDialogComponent);
        dialogRef.instance.job = job;
        dialogRef.instance.dialogHeader = type;
        dialogRef.instance.dumpsters = this.uow.lookups.dumpsters;
        dialogRef.instance.dumpsterRequired = true;
        dialogRef.instance.showPreferredTime = type !== 'Service';

        const results = await dialogRef.instance.show();

        if (results.success) {
            if (jobType === JobType.Types.RoundTrip || jobType === JobType.Types.Exchange) {
                //make the job a delivery for RoundTrip/Exchange and the additional job the Pickup
                job.actionId = JobType.Types.Delivery;
                const additionalJob = this.createJob(rental, job.startDate, jobType);
                additionalJob.dumpsterId =
                    jobType === JobType.Types.Exchange ? results.value.job.exchangeDumpsterId : results.value.job.dumpsterId;
                additionalJob.actionId = JobType.Types.PickUp;
                additionalJob.billAsDumpsterId = results.value.job.billExchangeAs;
            }

            this.appInfo.pleaseWaitShow();
            await this.uow.commit();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        } else this.uow.rollback();
        this.dialogService.close(dialogRef);
    }

    getRentalJobs(rental: Rental) {
        let rentalJobs = rental.jobs.filter(job => {
            return !job.completed && job.dumpsterId && job.jobTypeId !== JobType.Types.PickUp;
        });
        return _(rentalJobs).orderBy('dumpster.size').valueOf();
    }

    async deleteRental(rentals: Rental[]) {
        if (!(await confirm(`Are you sure you want to delete the selected rental${rentals.length > 1 ? 's' : ''}?`, this.appInfo.appTitle)))
            return;

        rentals.forEach(rental => {
            rental.deleted = new Date();
            rental.jobs.forEach(job => {
                job.deleted = new Date();
            });
        });

        this.appInfo.pleaseWaitShow();
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    getStatus(rental: Rental) {
        if (rental.jobs.length === 0) return 'New';
        else if (rental.jobs.every(job => job.completed)) return 'Complete';
        else return 'In Progress';
    }

    getStatusClass(rental: Rental) {
        const status = this.getStatus(rental);
        return status === 'New' ? 'new' : status === 'Complete' ? 'complete' : 'in-progress';
    }

    async completeRental(rentals: Rental[]) {
        if (!rentals) return;

        const incompleteRentals = rentals.filter(rental => !rental.jobs.every(j => j.completed || j.deleted));
        if (incompleteRentals.length > 0) {
            await alert('The selected rentals cannot be complete. One or more still have active jobs', this.appInfo.appTitle);
            return;
        }

        const success = await confirm('Are you sure you want to complete the selected rental(s)?', this.appInfo.appTitle);
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        rentals.forEach(rental => (rental.completed = new Date()));
        await this.uow.commit();
        await this.getRentals();
        this.appInfo.pleaseWaitHide();
    }

    async markComplete(dispatchNote: DispatchNote) {
        if (!dispatchNote) return;

        this.appInfo.pleaseWaitShow();
        dispatchNote.completed = moment(new Date()).toDate();
        await this.uow.commit();
        await this.getDispatchNotes();
        this.appInfo.pleaseWaitHide();
    }

    open(rental: Rental, dataField: string) {
        switch (dataField) {
            case 'id':
                this.openRental(rental.id);
                break;
            case 'customer.displayName':
                if (!this.isCustomerAdmin) break;
                this.router.navigate([`/customers/${rental.customerId}`]);
                break;
            case 'siteAddressHTML':
                this.router.navigate([`/jobs/sites/${rental.serviceAddressId}`]);
                break;
        }
    }

    statusSortValue = (rental: Rental) => {
        const status = this.getStatus(rental);
        return status === 'Complete' ? 0 : status === 'In Progress' ? 1 : 2;
    };

    statusCellValue = (rental: Rental) => {
        return this.getStatus(rental);
    };
}
