import { Component, Input, OnInit } from '@angular/core';
import { AppInformationService, CdrUnitOfWork } from 'src/app/core/core.module';
import { DispatchNote } from 'src/app/core/model/dispatch-note';
import { Dumpster } from 'src/app/core/model/dumpster';
import { JobType } from 'src/app/core/model/job-type';
import { confirm } from '../shared.module';
@Component({
    selector: 'app-dispatch-notes-detail',
    templateUrl: './dispatch-notes-detail.component.html',
    styleUrls: ['./dispatch-notes-detail.component.scss']
})
export class DispatchNotesDetailComponent implements OnInit {
    dumpsters: Dumpster[];
    jobTypes: JobType[];
    selectedHourSegment: 'AM' | 'PM';

    @Input() dispatchNote: DispatchNote;
    @Input() readOnly: boolean = false;

    constructor(private uow: CdrUnitOfWork, private appInfo: AppInformationService) { }

    ngOnInit() {
        this.dumpsters = this.uow.lookups.dumpsters;
        this.jobTypes = this.uow.lookups.jobTypes;

        this.setSelectedHourSegment();
    }

    rentalHourSegmentChanged(e) {
        this.dispatchNote.rentalAM = e.value === 'AM';
        this.dispatchNote.rentalPM = e.value === 'PM';
    }

    setSelectedHourSegment() {
        if (this.dispatchNote.rentalAM) this.selectedHourSegment = 'AM';
        if (this.dispatchNote.rentalPM) this.selectedHourSegment = 'PM';
    }

    async locationChanged(addressLookup) {
        if (!addressLookup) return;

        if (
            this.dispatchNote.address1 ||
            this.dispatchNote.address2 ||
            this.dispatchNote.city ||
            this.dispatchNote.state ||
            this.dispatchNote.zip
        ) {
            const result = await confirm(`Are you sure you want to override existing address data?`, `${this.appInfo.company}`);
            if (!result) {
                return;
            }
        }
        this.dispatchNote.address1 = addressLookup.addressLabel;
        this.dispatchNote.address2 = addressLookup.county;
        this.dispatchNote.city = addressLookup.city;
        this.dispatchNote.state = addressLookup.stateCode;
        this.dispatchNote.zip = addressLookup.postalCode;
    }
}
