import { environment } from 'src/environments/environment';

export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_signupsignin1'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://cdrdisposal.b2clogin.com/cdrdisposal.onmicrosoft.com/B2C_1_signupsignin1'
        }
    },
    authorityDomain: 'cdrdisposal.b2clogin.com'
};

export const apiConfig: { scopes: string[]; uri: string } = {
    scopes: ['https://cdrdisposal.onmicrosoft.com/cdr-disposal-api/api-access'],
    uri:  location.origin.indexOf('localhost') > 0 ? environment.serviceName : location.origin + '/api'
};
