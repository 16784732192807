// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class EventLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  application: string;
  created: Date;
  exception: string;
  https: boolean;
  level: string;
  logger: string;
  machineName: string;
  message: string;
  port: string;
  remoteAddress: string;
  serverAddress: string;
  serverName: string;
  source: string;
  url: string;
  userName: string;
}

