<div class="flex-fill d-flex dx-card">
    <div class="d-flex flex-fill flex-column col-sm-6">
        <div class="d-flex flex-column flex-fill py-3 px-1">
            <h4>Review</h4>
            <dx-data-grid
                #overviewGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                [dataSource]="invoices"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onRowDoubleClick($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="id" width="75"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="75"></dxi-column>
                <dxi-column dataField="status.name" caption="Status" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="customer.displayName" caption="Customer" width="175"></dxi-column>
                <dxi-column dataField="customer.phone" caption="Phone" width="150"></dxi-column>
                <dxi-column dataField="customer.email" caption="Email" width="175"></dxi-column>
                <dxi-column dataField="displayBillAddress" caption="Billing Address" width="300"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-review-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-primary mr-1 mb-2"
                        (click)="open(overviewGrid.instance.getSelectedRowsData()[0]?.id)"
                        [disabled]="overviewGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Open
                    </button>
                    <button
                        class="btn btn-secondary mr-1 mb-2"
                        (click)="openReport(overviewGrid.instance.getSelectedRowsData())"
                        [disabled]="overviewGrid.instance.getSelectedRowsData().length === 0"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Print
                    </button>
                    <button
                        class="btn btn-secondary mb-2"
                        (click)="bulkApprove(overviewGrid.instance.getVisibleRows())"
                        [disabled]="overviewGrid.instance.getVisibleRows().length === 0"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Bulk Approve
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="invoices" class="mr-3">
                        <strong>{{ overviewGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
    <div class="d-flex flex-fill flex-column col-sm-6">
        <div class="d-flex flex-column flex-fill py-3 px-1">
            <h4>Queue</h4>
            <dx-data-grid
                #queueGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                [dataSource]="queueInvoices"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onRowDoubleClick($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="id" width="75"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="75"></dxi-column>
                <dxi-column dataField="status.name" caption="Status" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="100"></dxi-column>
                <dxi-column dataField="customer.displayName" caption="Customer" width="175"></dxi-column>
                <dxi-column dataField="customer.billingCommunication" caption="Preferred Communication" width="125"></dxi-column>
                <dxi-column dataField="customer.email" caption="Email" width="175"></dxi-column>
                <dxi-column dataField="displayBillAddress" caption="Billing Address" width="300"></dxi-column>
                <dxi-column></dxi-column>

                <dxo-selection mode="multiple"></dxo-selection>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="queue-invoice-review-grid"></dxo-state-storing>
                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-primary mr-1 mb-2"
                        (click)="open(queueGrid.instance.getSelectedRowsData()[0]?.id)"
                        [disabled]="queueGrid.instance.getSelectedRowsData().length !== 1"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Open
                    </button>
                </div>
                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="queueInvoices" class="mr-3">
                        <strong>{{ queueGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
