<dx-popup title="Job" [width]="600" [height]="'auto'" [(visible)]="visible" [hideOnOutsideClick]="false" (onHiding)="onHiding($event)">
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Audit Log', type: 'default', onClick: openAuditLog }">
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Save', type: 'success', onClick: save }"> </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" [options]="{ text: 'Cancel', type: 'danger', onClick: cancel }">
    </dxi-toolbar-item>
    <dx-form *ngIf="job" [formData]="job">
        <dxi-item dataField="rental.customer.displayName" *ngIf="job.rentalId" [label]="{ text: 'Customer' }" [disabled]="true"> </dxi-item>
        <dxi-item dataField="displayId" [label]="{ text: 'Id' }" [disabled]="true"> </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
            <dxi-item [label]="{ text: 'Dumpster' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="job.dumpsterId"
                        [items]="dumpsters"
                        valueExpr="id"
                        displayExpr="size"
                        [width]="'250px'"
                        [disabled]="!canChangeDumpster"
                        (onValueChanged)="dumpsterChanged($event)"
                    ></dx-select-box>
                </div>
            </dxi-item>
            <dxi-item [label]="{ text: 'Bill As' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="job.billAsDumpsterId"
                        [items]="dumpsters"
                        valueExpr="id"
                        displayExpr="size"
                        [width]="'250px'"
                        [disabled]="!isDifferentThanRental"
                    ></dx-select-box>
                </div>
            </dxi-item>
        </dxi-item>

        <dxi-item dataField="jobType.name" [label]="{ text: 'Type' }" [disabled]="true"> </dxi-item>
        <dxi-item [label]="{ text: 'Address' }">
            <div *dxTemplate class="d-flex">
                <dx-text-box [value]="job.displayAddress" class="flex-fill" [disabled]="true"></dx-text-box>
                <button
                    *ngIf="job.rental && job.rental.serviceAddressId"
                    class="btn btn-primary mx-3"
                    (click)="openServiceAddress(job.rental.serviceAddressId)"
                >
                    Edit
                </button>
            </div>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="weight" [editorOptions]="{ width: '250px' }" [disabled]="job.completed && !completeJobMode"> </dxi-item>
            <dxi-item *ngIf="completeJobMode" [label]="{ text: '' }">
                <div *dxTemplate class="d-flex">
                    <button
                        class="btn btn-sm btn-danger mx-3 mt-2"
                        (click)="completeJob()"
                    >
                        Complete Job
                    </button>
                </div>
            </dxi-item>

        </dxi-item>

        <dxi-item
            *ngIf="!completeJobMode"
            dataField="inventory.dumpsterBarcode"
            [label]="{ text: 'Barcode' }"
            [editorOptions]="{ width: '250px' }"
            [disabled]="true"
        >
        </dxi-item>

        <dxi-item *ngIf="completeJobMode && inventories" [label]="{ text: 'Use Dumpster' }">
            <div *dxTemplate>
                <dx-select-box
                    [(value)]="inventoryId"
                    [items]="inventories"
                    valueExpr="id"
                    displayExpr="dumpsterBarcode"
                    [width]="'250px'"
                ></dx-select-box>
            </div>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
            <dxi-item *ngIf="completeJobMode && isStorageDestination" [label]="{ text: 'Pick Dumpster' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="destinationPickId"
                        [items]="storagePickInventories"
                        valueExpr="id"
                        displayExpr="dumpsterBarcode"
                        [width]="'250px'"
                    ></dx-select-box>
                </div>
            </dxi-item>
            <dxi-item *ngIf="completeJobMode && isStorageDestination" [label]="{ text: 'Drop Dumpster' }">
                <div *dxTemplate>
                    <dx-select-box
                        [(value)]="destinationDropId"
                        [items]="storageDropInventories"
                        valueExpr="id"
                        displayExpr="dumpsterBarcode"
                        [width]="'250px'"
                    ></dx-select-box>
                </div>
            </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2">
            <dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ type: 'date', width: '150px' }"> </dxi-item>

            <dxi-item
                dataField="preferredStartTime"
                editorType="dxDateBox"
                [label]="{ text: 'Preferred Start' }"
                [editorOptions]="{ type: 'time', pickerType: 'list', width: '150px', onValueChanged: startTimeValueChanged }"
            ></dxi-item>

            <dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ type: 'date', width: '150px' }"> </dxi-item>

            <dxi-item
                dataField="preferredEndTime"
                editorType="dxDateBox"
                [label]="{ text: 'Preferred End' }"
                [editorOptions]="{ type: 'time', pickerType: 'list', width: '150px', onValueChanged: endTimeValueChanged }"
            ></dxi-item>
        </dxi-item>
        <dxi-item dataField="notes" editorType="dxTextArea" [label]="{ text: 'Site Notes' }" [editorOptions]="{ height: '100px' }">
        </dxi-item>
        <dxi-item dataField="driverNotes" editorType="dxTextArea" [editorOptions]="{ height: '100px' }"> </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item dataField="started" editorType="dxDateBox" [editorOptions]="{ type: 'datetime', width: '150px' }" [disabled]="true">
            </dxi-item>
            <dxi-item dataField="completed" editorType="dxDateBox" [editorOptions]="{ type: 'datetime', width: '150px' }" [disabled]="true">
            </dxi-item>
            <dxi-item dataField="fuelRatePercentage.rate" [label]="{ text: 'Fuel Rate Percentage' }" [disabled]="true">
            </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="3">
            <dxi-item [label]="{ text: 'Latest Dumpster' }">
                <div *dxTemplate>
                    <dx-text-box [value]="latestDumpsterInfo.barcode" width="150" [disabled]="true"></dx-text-box>
                </div>
            </dxi-item>
            <dxi-item [label]="{ text: 'By User' }">
                <div *dxTemplate>
                    <dx-text-box [value]="latestDumpsterInfo.byUser" width="150" [disabled]="true"></dx-text-box>
                </div>
            </dxi-item>
            <dxi-item [label]="{ text: 'Date' }">
                <div *dxTemplate>
                    <dx-text-box [value]="latestDumpsterInfo.date" width="150" [disabled]="true"></dx-text-box>
                </div>
            </dxi-item>
            <dxi-item *ngIf="dumpsterLocations && dumpsterLocations.length > 0">
                <div id="additional-dumpsters" *dxTemplate class="mt-3">
                    <div>Additional Dumpsters</div>
                    <dx-context-menu [dataSource]="dumpsterLocations" [width]="250" target="#additional-dumpsters"> </dx-context-menu>
                </div>
            </dxi-item>
        </dxi-item>
    </dx-form>
</dx-popup>
