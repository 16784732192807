import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { apiConfig } from './b2c-config';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IUserModel {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    userName: string;
    roles: { id: number; name: string }[];
}
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userSubject: BehaviorSubject<IUserModel>;
    public user$: Observable<IUserModel>;
    private accountSubject: BehaviorSubject<AccountInfo>;
    public account$: Observable<AccountInfo>;

    isAuthenticated = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msal: MsalService
    ) {
        this.accountSubject = new BehaviorSubject<AccountInfo>(null);
        this.account$ = this.accountSubject.asObservable();

        this.userSubject = new BehaviorSubject<IUserModel>(null);
        this.user$ = this.userSubject.asObservable();

        this.checkAccount();
    }

    public get userValue(): IUserModel {
        return this.userSubject.value;
    }

    getUser() {
        return this.http.post<IUserModel>(`${environment.serviceName}/account/currentUser`, {}, { withCredentials: true }).pipe(
            map(user => {
                this.setUser(user);
            })
        );
    }

    private setUser(user: IUserModel) {
        this.userSubject.next(user);
        return user;
    }

    getActiveAccount() {
        return this.msal.instance.getActiveAccount();
    }

    checkAccount() {
        let activeAccount = this.getActiveAccount();

        if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
            let accounts = this.msal.instance.getAllAccounts();
            this.msal.instance.setActiveAccount(accounts[0]);
            activeAccount = this.getActiveAccount();
        }
        this.accountSubject.next(activeAccount);
        this.isAuthenticated = !!this.accountSubject.value;
    }

    public login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.msal
                    .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.msal.instance.setActiveAccount(response.account);
                    });
            } else {
                this.msal.loginPopup().subscribe((response: AuthenticationResult) => {
                    this.msal.instance.setActiveAccount(response.account);
                });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.msal.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            } else {
                this.msal.loginRedirect();
            }
        }
    }

    public logout() {
        this.msal.logout();
        this.userSubject.next(null);

        this.router.navigate(['/']);
    }

    public async getAccessToken() {
        let accessTokenRequest = {
            scopes: apiConfig.scopes
        };
        try {
            const result = await firstValueFrom(this.msal.acquireTokenSilent(accessTokenRequest));
            return result && result.accessToken;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    hasRole(role: string) {
        return this.userHasRole(this.userValue, role);
    }

    hasRoleStartsWith(role: string) {
        return this.userHasRoleStartsWith(this.userValue, role);
    }

    hasAnyRole(roles: string[]) {
        for (let idx = 0; idx < roles.length; idx++) {
            if (this.hasRole(roles[idx])) return true;
        }
        return false;
    }

    userHasRole(user, roleName: string) {
        if (!user || !user.roles) return false;

        return user.roles.some((role: any) => {
            // see if user permission starts with permission to check
            // return role.Name.lastIndexOf(roleName, 0) === 0;

            // see if user permission equals permission to check
            return role.Name === roleName;
        });
    }

    userHasRoleStartsWith(user, roleName: string) {
        if (!user || !user.roles) return false;

        return user.roles.some((role: any) => {
            // see if user permission starts with permission to check
            return role.Name.startsWith(roleName);
        });
    }
}
